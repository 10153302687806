/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getFormattedDateTimeByLocale, getLocaleByUserPreferenceTimeZone } from '../../../pages/Reports/View/utils';
import PivotTableHeader from '../DrillDownTable/TableHeader';
import DataTable from '../../../components/DataTable';
import { getARMTicketsTableColumns } from './Columns';
import { severityStyles } from '../../../pages/Reports/View/Executive/Express';
import { useDrillDownStyles } from '../DrillDownTable/Incidents';

const DATA_TABLE_NAME = 'ARM_TICKETS_DRILL_DOWN_TABLE';

function getAggregationTableTitle(requestPayload, t) {
	if (
		requestPayload?.routingCategory === 'AVERAGE_RESPONSE_TIME' ||
		requestPayload?.routingCategory === 'TICKET_VOLUMES_SEVERITY_SLA'
	) {
		const severity = requestPayload?.priorities;
		if (severity && severity.length === 1 && 'allseverity' !== severity[0].toLowerCase()) {
			return t('incidents:prioritiesTicketList', {
				severity: t('incidents:severities.' + severity[0].toLowerCase())
			});
		}
	}
	return t('incidents:ticketList');
}

function getAggregationTableSubTitle(requestPayload) {
	const options = { timeFormat: 'HH:mm ZZZZ', timeZoneLocale: getLocaleByUserPreferenceTimeZone() };

	return (
		getFormattedDateTimeByLocale(requestPayload.startTime, options) +
		' - ' +
		getFormattedDateTimeByLocale(requestPayload.endTime, options)
	);
}

const ARMTicketsDrillDownContainer = ({ apiUrl, requestPayload, method, downloadCsv }) => {
	const { t } = useTranslation(['dashboard', 'incidents']);
	const classes = useDrillDownStyles();
	const severityClasses = severityStyles();

	const downloadMenu = useMemo(
		() => [
			{
				title: t('dashboard:drilldown.common.download'),
				subMenus: [
					{
						title: t('dashboard:drilldown.common.csv'),
						onClick: () => {
							downloadCsv();
						}
					}
				]
			}
		],
		[downloadCsv, t]
	);

	const options = useMemo(() => {
		return {
			url: apiUrl,
			params: requestPayload,
			method
		};
	}, [apiUrl, requestPayload, method]);

	const columns = useMemo(
		() =>
			getARMTicketsTableColumns({
				t,
				classes,
				severityClasses,
				params: requestPayload
			}),
		[t, classes, severityClasses, requestPayload]
	);

	const processPaginationFn = (pagination) => {
		pagination = {
			...pagination,
			count: pagination.totalPages,
			current: pagination.currentPage - 1
		};

		return pagination;
	};

	return (
		<>
			<Box display="flex" flexGrow={1}>
				<PivotTableHeader
					menus={downloadMenu}
					headingText={getAggregationTableTitle(requestPayload, t)}
					subHeadingText={getAggregationTableSubTitle(requestPayload)}
				/>
			</Box>
			<Box marginTop={2} marginBottom={4} marginLeft={1} marginRight={1}>
				<DataTable
					title="Incidents"
					name={DATA_TABLE_NAME}
					columns={columns}
					options={options}
					processDataFn={(data) => data}
					nameSpace={['incidents']}
					emptyMessage={'incidents:summaryMessages.empty'}
					errorMessage={'incidents:summaryMessages.error'}
					loadingMessage={'incidents:summaryMessages.loading'}
					canSelectAll={false}
					showPagination={true}
					processPaginationFn={processPaginationFn}
					serverSidePagination={true}
					canToggleColumns={false}
					paginationType={'DROPDOWN'}
				/>
			</Box>
		</>
	);
};

ARMTicketsDrillDownContainer.propTypes = {
	apiUrl: PropTypes.string,
	requestPayload: PropTypes.object,
	method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
	downloadCsv: PropTypes.func
};

export default ARMTicketsDrillDownContainer;
