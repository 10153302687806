/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import { post } from 'axios';
import { getQueryString } from '../../../../Dashboards/Dashboard/useExportDrillDown';
import { downloadFile } from '../../../../../utils';

export function getEventURL(selectedEvent, clientId) {
	const eventURL =
		'/portal/events/event#details/' +
		selectedEvent.eventId +
		'?locationId=' +
		selectedEvent.locationId +
		'&createdTimestamp=' +
		selectedEvent.eventTime +
		'&clientId=' +
		clientId +
		'&source=REPORTING';

	return eventURL;
}

export const useExportDrillDown = () => {
	let isExporting = false;
	let isError = null;

	const exportDrillDownData = (filter, fileType) => {
		isExporting = true;
		const axiosOptions = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			responseType: 'arraybuffer'
		};
		post(
			'/portal/events/eventsStreamDownload',
			getQueryString({
				eventSearchFormJson: JSON.stringify(filter),
				csrfToken: window?.APP_DATA?.meta?.csrfToken,
				downloadContentType: fileType
			}),
			axiosOptions
		)
			.then((response) => {
				isExporting = false;
				downloadFile(response);
			})
			.catch((e) => {
				isExporting = false;
				isError = e;
			});
	};
	return { isExporting, isError, exportDrillDownData };
};

export function getRowGroupingFilter({ drillDownParams, tableHeaderModel }) {
	const rowGrouping = tableHeaderModel?.rowGroupingHeader;
	const expandedRow = drillDownParams?.expandedRow;

	if (expandedRow && expandedRow[rowGrouping]) {
		const filter = expandedRow[rowGrouping + 'Filter'];
		const filterJson = { filter: filter ? filter : expandedRow[rowGrouping], text: expandedRow[rowGrouping] };
		return filterJson;
	} else {
		return null;
	}
}

export const getGroupingOptions = function (t, subReportType) {
	const options = [];
	switch (subReportType) {
		case 'SRCIP':
			options.push({ id: 'SRCIP', text: t('reports:topTalkers.drillDown.sourceIPs') });
			return options;
		case 'DSTIP':
			options.push({ id: 'DSTIP', text: t('reports:topTalkers.drillDown.destinationIPs') });
			return options;
		case 'SRC_COUNTRY':
			options.push({ id: 'SRC_COUNTRY', text: t('reports:topTalkers.drillDown.sourceCountries') });
			return options;
		case 'DST_COUNTRY':
			options.push({ id: 'DST_COUNTRY', text: t('reports:topTalkers.drillDown.destinationCountries') });
			return options;
		case 'SERVICES':
			options.push({ id: 'SRCIP', text: t('reports:topTalkers.topServices.drillDown.sourceIPs') });
			options.push({ id: 'DSTIP', text: t('reports:topTalkers.topServices.drillDown.destinationIPs') });
			return options;
		default:
			return options;
	}
};

const defaultPayload = {
	reportType: '',
	endTime: '',
	startTime: '',
	groupBy: '',
	dstPort: '',
	clientHref: '',
	locations: [],
	serviceLevels: [],
	deviceTypes: [],
	threatLevels: [],
	eventClasses: [],
	includeInactiveAssets: [],
	assetTags: [],
	advancedParameters: '',
	source: 'NRS'
};

const getOthersGroupingFilterString = function (headerId, otherGroupColumnValue) {
	if (otherGroupColumnValue) {
		let lastDelimiterIndex = otherGroupColumnValue.lastIndexOf('|');
		let ipsStr = otherGroupColumnValue.slice(0, lastDelimiterIndex);
		let nullIPsCovered = otherGroupColumnValue.slice(lastDelimiterIndex + 1);
		let notNullConstraint =
			'{"id":"' +
			headerId +
			'","field":"' +
			headerId +
			'","type":"string","input":"text","operator":"not_equal","value":"' +
			ipsStr +
			'"}';
		if (nullIPsCovered === 'NullNotIncluded') {
			return notNullConstraint;
		} else {
			return (
				'{"condition":"AND","rules":[{"id":"' +
				headerId +
				'","field":"' +
				headerId +
				'","type":"string","input":"text","operator":"is_not_null"},' +
				notNullConstraint +
				']}'
			);
		}
	}
};

const getNonEmptyAddOnFilters = function (addOnFilterString) {
	if (!addOnFilterString) {
		return null;
	}
	let addOnFilter = JSON.parse(addOnFilterString);
	if (addOnFilter.rules.length == 0) {
		return null;
	}
	return addOnFilterString;
};

const getAdvancedFilters = function (
	payload,
	advancedFilterRule1,
	advancedFilterRule2,
	advancedFilterRule3,
	advancedFilterRule4
) {
	var advancedParameters = '{"condition":"AND","rules":[';
	if (advancedFilterRule1 !== null) {
		advancedParameters += advancedFilterRule1 + ' ,';
	}
	if (advancedFilterRule2 !== null) {
		advancedParameters += advancedFilterRule2 + ' ,';
	}
	if (advancedFilterRule3 !== null) {
		advancedParameters += advancedFilterRule3 + ' ,';
	}
	if (advancedFilterRule4 !== null) {
		advancedParameters += advancedFilterRule4;
	}
	advancedParameters = advancedParameters.replace(/(^\s*,)|(,\s*$)/g, '');
	advancedParameters += ']}';
	payload.advancedParameters = advancedParameters;
	return payload;
};

const validatePayloadParam = function (paramKey, params) {
	if (params && params[paramKey] && params[paramKey][0]) {
		return true;
	} else {
		return false;
	}
};

const getDrillDownFilterRule = function (headerid, value, otherGroupColumnValue) {
	if (headerid === 'SRCIP') {
		headerid = 'SRC_IP';
	} else if (headerid === 'DSTIP') {
		headerid = 'DST_IP';
	} else if (headerid === 'SRC_COUNTRY') {
		headerid = 'SRC_IP_COUNTRY';
	} else if (headerid === 'DST_COUNTRY') {
		headerid = 'DST_IP_COUNTRY';
	}
	var operator = 'equal';
	if (value === 'No Country' || value === 'UNCLS') {
		return (
			'{"condition":"OR","rules":[{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"is_null","value":null},' +
			'{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"equal","value":"UNCLS"}]}'
		);
	} else if (headerid === 'ACTION' && (value === 'Not Blocked' || value === 'NotBlocked')) {
		return (
			'{"condition":"OR","rules":[{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"is_null","value":null},' +
			'{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"equal","value":"' +
			value +
			'"}]}'
		);
	} else if (value === 'No Service' || value === '-') {
		return (
			'{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"is_null","value":null}'
		);
	} else if (value === "All Other Source IP's" || value === "All Other Destination IP's") {
		return getOthersGroupingFilterString(headerid, otherGroupColumnValue);
	} else {
		return (
			'{"id":"' +
			headerid +
			'","field":"' +
			headerid +
			'","type":"string","input":"text","operator":"' +
			operator +
			'","value":"' +
			value +
			'"}'
		);
	}
};

export function getDownloadFileName(aggrName, dstPort, reportName) {
	let fileName = '';
	if (reportName) {
		fileName = reportName;
	}
	if (dstPort !== null) {
		fileName += '_' + dstPort;
	}
	if (!aggrName) {
		fileName += '_' + aggrName;
	}
	return fileName.replace(/ /g, '_');
}

const getPortNum = function (dst_port_str) {
	if (dst_port_str && dst_port_str !== 'No Service') {
		const firstIndex = dst_port_str.indexOf('[');
		const lastIndex = dst_port_str.indexOf(']');
		if (firstIndex && lastIndex) {
			const dst_port_num = dst_port_str.substring(firstIndex + 1, lastIndex);
			return dst_port_num;
		}
	}
	return dst_port_str;
};

const getActionFilterRule = function (actionType) {
	if (actionType === 'Blocked') {
		return getDrillDownFilterRule('ACTION', 'Blocked', null);
	} else if (actionType === 'Not Blocked' || actionType === 'NotBlocked') {
		return getDrillDownFilterRule('ACTION', 'Not Blocked', null);
	} else {
		return null;
	}
};

export function getSelectedEventsDrillDownFilter({
	rowGroupingHeaderId,
	rowGroupingValue,
	rowGroupingText,
	columnGroupingValue,
	params,
	reportName,
	otherGroupColumnValue
}) {
	let eventSearchFormModel = {};
	if (validatePayloadParam('addOnFiltersJson', params)) {
		let filterRule1, filterRule2, filterRule3, filterRule4;
		filterRule1 = getDrillDownFilterRule(rowGroupingHeaderId, rowGroupingValue, otherGroupColumnValue);
		filterRule2 = getNonEmptyAddOnFilters(params['addOnFiltersJson'][0]);
		if (params.subReportType === 'SERVICES') {
			filterRule4 = getActionFilterRule(params.actionType);
		} else {
			filterRule4 = getActionFilterRule(columnGroupingValue);
		}
		if (params.dstPort) {
			filterRule3 = getDrillDownFilterRule('DST_PORT', getPortNum(params.dstPort), otherGroupColumnValue);
			eventSearchFormModel = getAdvancedFilters(
				eventSearchFormModel,
				filterRule1,
				filterRule2,
				filterRule3,
				filterRule4
			);
		} else {
			eventSearchFormModel = getAdvancedFilters(
				eventSearchFormModel,
				filterRule1,
				filterRule2,
				null,
				filterRule4
			);
		}
	}

	if (params?.dstPort) {
		eventSearchFormModel.fileName = getDownloadFileName(rowGroupingText, params.dstPort, reportName);
		eventSearchFormModel.source = 'TOP_SERVICES_AGGR_DRILL_DOWN';
	} else {
		eventSearchFormModel.fileName = getDownloadFileName(rowGroupingText, null, reportName);
		eventSearchFormModel.source = 'TOP_TALKERS_AGGR_DRILL_DOWN';
	}

	return eventSearchFormModel;
}

export const getTopServicesAggregationsSearchFormModel = function (params) {
	const portNum = getPortNum(params.dstPort);
	let payload = {
		...defaultPayload,
		reportType: params.subReportType,
		fileId: params.reportId,
		endTime: params.endDate,
		limit: 50,
		clientHref: window.APP_DATA?.client?.href,
		page: 1,
		startTime: params.startDate,
		dstPort: portNum,
		groupBy: 'SRCIP'
	};
	if (validatePayloadParam('addOnFiltersJson', params)) {
		const filterRule1 = getDrillDownFilterRule('DST_PORT', portNum, null);
		const filterRule2 = getNonEmptyAddOnFilters(params['addOnFiltersJson'][0]);
		const filterRule4 = getActionFilterRule(params.actionType);
		payload = getAdvancedFilters(payload, filterRule1, filterRule2, null, filterRule4);
	}
	if (validatePayloadParam('clientLocationHrefs', params)) {
		payload.locations = params['clientLocationHrefs'];
	}
	if (validatePayloadParam('threatLevels', params)) {
		payload.threatLevels = params['threatLevels'];
	}
	if (validatePayloadParam('serviceLevels', params)) {
		payload.serviceLevels = params['serviceLevels'];
	}
	if (validatePayloadParam('portalDataTags', params)) {
		payload.assetTags = params['portalDataTags'];
	}
	if (validatePayloadParam('deviceTypes', params)) {
		payload.deviceTypes = params['deviceTypes'];
	}
	if (validatePayloadParam('eventClasses', params)) {
		payload.eventClasses = params['eventClasses'];
	}
	return payload;
};
