import React, { useMemo } from 'react';
import DataTable from '../../../../components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { severityStyles } from '../../../../pages/Reports/View/Executive/Express';
import { useTranslation } from 'react-i18next';
import { useDrillDownStyles } from '../Incidents';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../../../utils/dashboard';
import { fetchDataTableData, updateDataTableParams } from '../../../../state/actions/components/datatables';
import {
	getEventURL,
	getRowGroupingFilter,
	useExportDrillDown
} from '../../../../pages/Reports/View/TopTalkers/Events/util';

const DATA_TABLE_NAME = 'PIVOT_DRILL_DOWN_EVENTS_TABLE';
const EVENTS_DATA_TABLE_PROPERTY = 'securityEventOutputRecord';

const getEventSearchParams = (requestPayload, fileName) => {
	const dateFormat = 'MM/dd/yyyy HH:mm:ss';
	return {
		action: 'shallowSearch',
		advancedParameters: '',
		aggregationFields: [],
		assets: '',
		clientHref: window.APP_DATA?.client?.href,
		deviceTypes: '',
		eedParameters: '',
		startDateTime: Dashboard.DrillDown.getDateInUserTimeZone(requestPayload.startTime, dateFormat),
		endDateTime: Dashboard.DrillDown.getDateInUserTimeZone(requestPayload.endTime, dateFormat),
		fileName: fileName?.replaceAll(' ', '-'),
		groupByFields: [],
		locations: requestPayload?.locations?.join(','),
		orderByFields: [],
		serviceLevels: '',
		source: 'REPORTS_AGGR_DRILL_DOWN',
		threatLevels: '',
		timePeriod: 'Custom....',
		uuid: ''
	};
};

function getOtherGroupingValue(tableHeaderModel) {
	if (tableHeaderModel && tableHeaderModel['otherGroupColumn']) {
		return tableHeaderModel['otherGroupColumn'];
	} else {
		return '';
	}
}

function getEventCategoryFilter(rowGroupingValue, tableHeaderModel) {
	if (rowGroupingValue === 'All Other Event Categories') {
		return getOtherGroupingValue(tableHeaderModel);
	} else {
		return rowGroupingValue;
	}
}

function setAdvancedFilters(eventSearchFormModel, advancedFilterRule1, advancedFilterRule2) {
	let advancedParameters = '';
	if (advancedFilterRule1 !== null && advancedFilterRule2 !== null) {
		advancedParameters = '{"condition":"AND","rules":[' + advancedFilterRule1 + ',' + advancedFilterRule2 + ']}';
	} else if (advancedFilterRule1 !== null && advancedFilterRule2 === null) {
		advancedParameters = '{"condition":"AND","rules":[' + advancedFilterRule1 + ']}';
	} else if (advancedFilterRule1 === null && advancedFilterRule2 !== null) {
		advancedParameters = '{"condition":"AND","rules":[' + advancedFilterRule2 + ']}';
	}

	eventSearchFormModel.advancedParameters = advancedParameters;
	return eventSearchFormModel;
}

function getSelectedEventsFilter({ rowGroupingValue, tableHeaderModel, categoryType }) {
	let mpleResolvedFilterRule = null;
	let eventSearchFormModel = {};
	const rowGroupingHeaderId = tableHeaderModel?.rowGroupingHeader;

	if (categoryType === 'SOC_ANALYZED_EVENTS') {
		mpleResolvedFilterRule =
			'{"id":"IS_MPLE_RESOLVED","field":"IS_MPLE_RESOLVED","type":"string","input":"select","operator":"equal","value":"false"}';
	}

	if (rowGroupingHeaderId === 'deviceType') {
		eventSearchFormModel.deviceTypes = rowGroupingValue;
		eventSearchFormModel = setAdvancedFilters(eventSearchFormModel, null, mpleResolvedFilterRule);
	} else if (rowGroupingHeaderId === 'device') {
		if (rowGroupingValue === 'All Other Devices') {
			eventSearchFormModel.assets = getOtherGroupingValue(tableHeaderModel);
			eventSearchFormModel.inverseDeviceFilter = true;
		} else {
			eventSearchFormModel.assets = rowGroupingValue;
		}
		eventSearchFormModel = setAdvancedFilters(eventSearchFormModel, null, mpleResolvedFilterRule);
	} else if (rowGroupingHeaderId === 'eventCategory') {
		const eventCategoryRule =
			'{"id":"EVENT_CATEGORY","field":"EVENT_CATEGORY","type":"string","input":"select","operator":"in","value":[' +
			getEventCategoryFilter(rowGroupingValue, tableHeaderModel) +
			']}';
		eventSearchFormModel = setAdvancedFilters(eventSearchFormModel, eventCategoryRule, mpleResolvedFilterRule);
	} else if (rowGroupingHeaderId === 'escalatedVsResolved') {
		if (rowGroupingValue === 'Escalated') {
			eventSearchFormModel = setAdvancedFilters(
				eventSearchFormModel,
				'{"id":"INCIDENT_ID","field":"INCIDENT_ID","type":"string","input":"text","operator":"is_not_null"}',
				mpleResolvedFilterRule
			);
		} else {
			eventSearchFormModel = setAdvancedFilters(
				eventSearchFormModel,
				'{"id":"INCIDENT_ID","field":"INCIDENT_ID","type":"string","input":"text","operator":"is_null"}',
				mpleResolvedFilterRule
			);
		}
	}
	return eventSearchFormModel;
}

function getDownloadFileName({ reportName, aggrName }) {
	var fileName = '';
	if (!reportName && !aggrName) {
		fileName = reportName + '_' + aggrName;
	}
	return fileName.replace(/ /g, '_');
}

export function getEventsDrillDownFilters(pivotDataTable) {
	const { params = {}, drillDownParams = {}, tableHeaderModel = {} } = pivotDataTable || {};

	const selectedRowGrouping = getRowGroupingFilter({
		drillDownParams,
		tableHeaderModel
	});
	const selectedFilters = getSelectedEventsFilter({
		rowGroupingValue: selectedRowGrouping?.filter,
		tableHeaderModel: tableHeaderModel,
		categoryType: params?.categoryType
	});

	return {
		url: '/portal/events/search',
		method: 'POST',
		params: {
			...getEventSearchParams(params, params.eventCategoryName),
			...selectedFilters
		},
		fileName: getDownloadFileName({
			reportName: params?.fileName, //TODO
			aggrName: selectedRowGrouping?.text
		})
	};
}

export const EventsDataTable = (props) => {
	const { t } = useTranslation('events');
	const classes = useDrillDownStyles();
	const severityClasses = severityStyles();
	const clientId = window.APP_DATA?.client?.id;

	const pivotDataTable = useSelector((state) => state.pivotdatatables[props.name]);

	const eventsDrillDownOptions = useMemo(
		() => ({
			...getEventsDrillDownFilters(pivotDataTable)
		}),
		[pivotDataTable]
	);

	const eventsDataTable = useSelector((state) => state.datatables[DATA_TABLE_NAME]);
	const { data = {}, params = {}, isLoading = false } = eventsDataTable || {};

	const dispatch = useDispatch();

	const dataCallbackFn = (response) => {
		const uuid = response.uuid;
		if (uuid) {
			dispatch(
				updateDataTableParams({
					name: DATA_TABLE_NAME,
					params: {
						...params,
						uuid: uuid,
						action: 'deeperSearch'
					}
				})
			);

			dispatch(
				fetchDataTableData({
					name: DATA_TABLE_NAME,
					action: 'APPEND',
					dataProperty: EVENTS_DATA_TABLE_PROPERTY
				})
			);
		}
	};

	const columns = useMemo(
		() => [
			{
				headerTitle: t('events:headers.dateTime'),
				id: 'eventTimeStamp',
				accessor: 'eventTimeStamp',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.eventId'),
				id: 'eventId',
				accessor: 'eventId',
				Cell: (e) => {
					const number = e.value;
					if (number) {
						const href = getEventURL(e.row?.original, clientId);
						return (
							<a href={href} className={classes.downloadLink} target="_blank" rel="noreferrer">
								{number}
							</a>
						);
					} else {
						return <></>;
					}
				},
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.severity'),
				id: 'severity',
				accessor: (row) => {
					if (!row) {
						return null;
					}

					return row.severity?.toLowerCase();
				},
				Cell: (e) => {
					if (e.value) {
						const severity = e.value.toString();
						return (
							<>
								<FontAwesomeIcon
									title={t('events:severities.' + severity)}
									className={severityClasses[severity]}
									icon={faCircle}
								></FontAwesomeIcon>
								<span className={severityClasses.label}>{t('events:severities.' + severity)}</span>
							</>
						);
					} else {
						return <></>;
					}
				},
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.eventSummary'),
				id: 'eventSummary',
				accessor: 'eventSummary',
				background: 'red',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.category'),
				id: 'category',
				accessor: 'category',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.action'),
				id: 'action',
				accessor: 'action',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.device'),
				id: 'device',
				accessor: 'device',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.sourceIp'),
				id: 'source',
				accessor: 'source',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.destIp'),
				id: 'destination',
				accessor: 'destination',
				className: classes.cell
			},
			{
				headerTitle: t('events:headers.count'),
				id: 'count',
				accessor: 'count',
				className: classes.cell
			}
		],
		[t, clientId, severityClasses, classes]
	);

	const { exportDrillDownData } = useExportDrillDown();

	const infoMessage = useMemo(() => {
		const limit = 50;
		let msgKey, downloadMsgKey;
		if (data?.elements?.length >= limit) {
			msgKey = 'events:text.nrs.mostRecentEvents';
			downloadMsgKey = 'events:text.downloadFullEventsList';
		} else {
			msgKey = 'events:text.nrs.eventsMessage';
			downloadMsgKey = 'events:text.downloadEventsList';
		}
		return !isLoading ? (
			<div className={classes.message}>
				{t(msgKey, { limit: data?.elements?.length })}
				<a
					className={classes.downloadLink}
					onClick={() => {
						exportDrillDownData(params, 'csv');
					}}
					target="_blank"
					rel="noreferrer"
				>
					{t(downloadMsgKey)}
				</a>
			</div>
		) : null;
	}, [t, classes, data, exportDrillDownData, params, isLoading]);

	return (
		<>
			<DataTable
				title="Events"
				nameSpace={['events']}
				name={DATA_TABLE_NAME}
				columns={columns}
				options={eventsDrillDownOptions}
				processDataFn={(data) => data}
				emptyMessage={'events:summaryMessages.empty'}
				errorMessage={'events:summaryMessages.error'}
				loadingMessage={'events:summaryMessages.loading'}
				canSelectAll={false}
				showPagination={false}
				serverSidePagination={false}
				canToggleColumns={false}
				dataProperty={EVENTS_DATA_TABLE_PROPERTY}
				infoMessage={infoMessage}
				dataCallbackFn={dataCallbackFn}
			/>
		</>
	);
};
