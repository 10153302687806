/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import axios from 'axios';
import { auth, meta } from '../state/bootstrap';

export default () => {
	let headersCommonOptions = {
		'X-Requested-With': 'XMLHttpRequest'
	};

	if (meta.csrfToken) {
		headersCommonOptions['X-CSRF-Token'] = meta.csrfToken;
	}

	axios.defaults.headers.common = headersCommonOptions;
	axios.defaults.withCredentials = auth.isAuthenticated;
};
