/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { ClickAwayListener, TableCell, TableSortLabel, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from '../../../../../Inputs/Dropdown';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

export const useStyle = makeStyles((theme) => ({
	tableHeadCell: {
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(2),
		'backgroundColor': theme.palette.table.cell.head.background,
		'color': theme.palette.table.cell.head.color,
		'fontSize': 13,
		'fontWeight': 700,
		'lineHeight': '22px',
		'borderRightWidth': 1,
		'borderRightColor': theme.palette.table.cell.body.border,
		'borderRightStyle': 'solid',
		'&:last-child': {
			borderRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			'display': 'none',
			// making first child visible to show dropdown options only.
			'&:first-child': {
				display: 'table-cell',
				borderRightWidth: 0
			}
		},
		'& .MuiPaper-root': {
			'color': 'inherit !important',
			'borderColor': theme.palette.table.cell.head.dropdown.border,
			'borderRadius': theme.spacing(1),
			'& + span': {
				'&:before': {
					borderBottomColor: `${theme.palette.table.cell.head.dropdown.title.background} !important`
				}
			}
		}
	},
	tableHeadCellContentBox: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		}
	},
	tableSortLabel: {
		'&:hover': {
			color: theme.palette.table.cell.head.color
		},
		'&:focus': {
			color: theme.palette.table.cell.head.color
		},
		'& svg': {
			color: `${theme.palette.table.cell.head.color} !important`
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	tableFilter: {
		display: 'inline-flex',
		alignItems: 'center',
		marginLeft: theme.spacing(1)
	},
	dropdownHeading: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.table.cell.head.dropdown.title.background,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		borderTopLeftRadius: theme.spacing(1),
		borderTopRightRadius: theme.spacing(1),
		marginBottom: 4
	},
	dropdownHeadingBtn: {
		fontSize: 'inherit'
	},
	menuList: {
		paddingTop: theme.spacing(0),
		minWidth: 160,
		paddingBottom: theme.spacing(1)
	},
	menuItem: {
		'paddingTop': 3,
		'paddingBottom': 3,
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(1),
		'color': theme.palette.table.cell.head.dropdown.dropdownItem.color,
		'&:hover': {
			color: theme.palette.common.white,
			backgroundColor: `${theme.palette.table.cell.head.dropdown.dropdownItem.backgroundActive} !important`
		}
	},
	menuItemSelected: {
		color: theme.palette.common.white,
		backgroundColor: `${theme.palette.table.cell.head.dropdown.dropdownItem.backgroundActive} !important`
	},
	filterIcon: {
		color: theme.palette.link.color,
		cursor: 'pointer'
	}
}));
/* *
 * Table cell with dropdown options. It will show dropdown options on click of gear icon.
 * This can be used when you want to perform some pivoting or custom filter on column level.
 * @param {function} onOptionClick - Function which will get called on click of dropdown option.
 * @param {array} dropDownOptions - Options to show in dropdown.
 * @param {string} defaultSelectedOptionId - If you want some option from dropdown to be selected for first time. Set that option id here.
 * @param {boolean} showSelectedOptionInTitle - Instead of showing headerTitle of column if you want selected option text from dropdown options to be shown as heading/title, set this this to true
 * */
const PivotDataTableHeadCellWithDropdownOptions = ({
	column,
	onClick,
	onOptionClick = () => {},
	dropDownOptions = [],
	defaultSelectedOptionId,
	showSelectedOptionInTitle = true
}) => {
	const classes = useStyle();
	const gearIconRef = createRef();
	const [elRef, setElRef] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const isMenuOpen = Boolean(elRef);
	useEffect(() => {
		if (!selectedOption) {
			setSelectedOption(dropDownOptions.filter((op) => op.id === defaultSelectedOptionId)?.[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultSelectedOptionId]);
	const openMenus = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setElRef(elRef ? null : gearIconRef.current);
	};
	const onMenuItemClicked = (option) => {
		onOptionClick(option);
		setSelectedOption(option);
	};
	const width = column.width ? { width: column.width } : {};

	return (
		<TableCell size={'small'} className={classes.tableHeadCell} onClick={onClick} {...width}>
			<Box display="flex" alignItems="center" className={classes.tableHeadCellContentBox}>
				<TableSortLabel
					active={column.isSorted}
					direction={column.isSortedDesc ? 'desc' : 'asc'}
					hideSortIcon={true}
					className={classes.tableSortLabel}
				>
					{showSelectedOptionInTitle ? selectedOption?.text : column?.headerTitle}
				</TableSortLabel>
				{dropDownOptions.length > 1 && (
					<Box onClick={openMenus} className={classes.tableFilter} ref={gearIconRef}>
						<FontAwesomeIcon icon={faCog} className={classes.filterIcon} />
						<Dropdown
							anchorEl={elRef}
							isOpen={isMenuOpen}
							placement="bottom-start"
							hasArrow={true}
							PaperProps={{
								component: 'div',
								square: false
							}}
						>
							<ClickAwayListener
								onClickAway={() => {
									setElRef(null);
								}}
							>
								<MenuList className={classes.menuList}>
									<Box pb={0.3} className={classes.dropdownHeading}>
										<Typography variant={'body1'} component={'span'}>
											Change to:
										</Typography>
										<IconButton
											aria-label="Close"
											component="span"
											size={'small'}
											color={'inherit'}
											className={classes.dropdownHeadingBtn}
										>
											<FontAwesomeIcon icon={faTimes} />
										</IconButton>
									</Box>
									{dropDownOptions.map((option, index) => {
										return (
											<MenuItem
												classes={{
													root: classes.menuItem,
													selected: classes.menuItemSelected
												}}
												key={column.id + '_option_' + index}
												onClick={() => onMenuItemClicked(option)}
												selected={selectedOption?.id === option?.id}
											>
												{option?.text}
											</MenuItem>
										);
									})}
								</MenuList>
							</ClickAwayListener>
						</Dropdown>
					</Box>
				)}
			</Box>
		</TableCell>
	);
};
PivotDataTableHeadCellWithDropdownOptions.propTypes = {
	column: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	dropDownOptions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.string
		})
	).isRequired,
	defaultSelectedOptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showSelectedOptionInTitle: PropTypes.bool
};
export { PivotDataTableHeadCellWithDropdownOptions };
