/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { get } from 'axios';

// action type definition
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_SEARCH_PROGRESS = 'SET_SEARCH_PROGRESS';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

// action creator to update the search results data
export function setSearchData({ data }) {
	return {
		type: SET_SEARCH_DATA,
		payload: {
			data
		}
	};
}

// action creator to update the data fetching process
export function setSearchProgress({ isError, isLoading }) {
	return {
		type: SET_SEARCH_PROGRESS,
		payload: {
			isError,
			isLoading
		}
	};
}

export function setSearchQuery({ query }) {
	return {
		type: SET_SEARCH_QUERY,
		payload: {
			query
		}
	};
}

// thunk action creator to fetch data
export function getSearchResults() {
	return (dispatch, getState) => {
		// get current search state to access the query parameters
		const { search } = getState();

		// dispatch(setSearchQuery(search.query));
		// set search state to in-progress
		dispatch(
			setSearchProgress({
				isError: false,
				isLoading: true
			})
		);

		// fetch data from the server
		return get('/portal/search', {
			params: {
				query: search.query
			}
		})
			.then(({ data }) => {
				dispatch(
					setSearchData({
						data
					})
				);
				dispatch(
					setSearchProgress({
						isError: false,
						isLoading: false
					})
				);
			})
			.catch(() => {
				dispatch(
					setSearchProgress({
						isError: true,
						isLaoding: false
					})
				);
			});
	};
}
