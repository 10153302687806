import React, { useEffect, useMemo, useState } from 'react';
import axios, { get } from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import { getQueryString } from '../../Dashboards/Dashboard/useExportDrillDown';
import { meta, urls } from '../../../state/bootstrap';
import { Pagination } from '../../../components/Pagination';
import { ExportMenu, LoadingData } from '@portal-reporting/report-ui-templates/dist/components/commons';
import { ErrorMsg } from '@portal-reporting/report-ui-templates/dist/components/commons/generics/generics';
import { DraggableDialog } from '@portal-reporting/report-ui-templates/dist/containers/index';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

const staticUrl = `${urls?.cdn}/portal/static` + (meta.staticVersion ? `/${meta.staticVersion}` : '');
pdfjs.GlobalWorkerOptions.workerSrc = staticUrl + '/thirdparty/pdf-worker/pdf-worker.js';

export const useStyles = makeStyles(() => ({
	pdfHelp: {
		padding: '0 !important'
	},
	helpText: {
		fontSize: '12.75px !important',
		marginTop: '5px !important'
	},
	helpNote: {
		fontSize: '12.75px !important',
		lineHeight: 1.25
	},
	noteLabel: {
		backgroundColor: '#FCC911',
		fontSize: '13px',
		fontWeight: '700',
		marginRight: '8px',
		padding: '5px',
		borderRadius: '1px',
		color: '#3d3d3d'
	},
	pdfHelpContent: {
		marginTop: '-10px',
		display: 'flex'
	}
}));

const axiosOptions = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	responseType: 'arraybuffer'
};

function getReportDataInputParams(viewParams) {
	return getQueryString({
		reportId: viewParams.reportId,
		reportType: viewParams.reportType,
		reportName: viewParams.reportName,
		section: 'pdf',
		iHubOnlyReport: true
	});
}

const SECURITY_EVENTS_REPORT_NAME = 'secevents';

const ReportPDFViewContainer = (props) => {
	const { viewParams = {}, t } = props;

	const [pdfPageData, setPdfPageData] = useState({});
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [openReportDialog, setOpenReportDialog] = useState(false);
	const [attackTrendIncluded, setAttackTrendIncluded] = useState(false);
	const classes = useStyles();

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const handlePageChange = (event, value) => {
		setPageNumber(value);
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		setLoading(true);

		get('/portal/reports/exportReportData?' + getReportDataInputParams(viewParams), axiosOptions)
			.then((response) => {
				const contentDisposition = response.headers['content-disposition'];
				const downloadedFileType = response.headers['content-type'];
				if (contentDisposition && downloadedFileType) {
					const obj = new Blob([response.data], {
						type: downloadedFileType
					});
					setPdfPageData({ pdfObj: obj });
				}

				setLoading(false);
			})
			.catch((e) => {
				setError(!!e);
				setLoading(false);
			});
	}, [viewParams]);

	useEffect(() => {
		if (viewParams.reportType === SECURITY_EVENTS_REPORT_NAME) {
			axios
				.get('/portal/reports/reportParameters', {
					params: { reportId: viewParams.reportId, reportType: viewParams.reportType }
				})
				.then(({ data }) => {
					const obj = data?.filter((c) => c.name === 'includeAttackTrend');
					setAttackTrendIncluded(obj[0]?.value === 'Yes');
				});
		}
	}, [viewParams]);

	const menuItems = useMemo(
		() => [
			{
				key: 'pdf',
				text: t('reportsUI:export.exportFormat.pdf'),
				handler: () => {
					window.location = '/portal/reports/exportReportData?' + getReportDataInputParams(viewParams);
				}
			},
			{
				key: 'exportData',
				text: t('reportsUI:export.exportData'),
				handler: () => {
					setOpenReportDialog(true);
				}
			}
		],
		[viewParams, t]
	);

	const handleClose = () => {
		setOpenReportDialog(false);
	};

	return (
		<>
			<div className={classes.pdfHelpContent}>
				<div>
					<div className={classes.noteLabel}>{t('reportsUI:common.note')}</div>
				</div>
				<div className={classes.pdfHelp}>
					<p className={classes.helpText}>{t('reports:viewReport.pdfViewerHelpText')}</p>
					<ul className={classes.helpNote}>
						<li>{t('reports:viewReport.pdfViewerHelpNote1')}</li>
						<li>
							<Trans i18nKey={'reports:viewReport.pdfViewerHelpNote2'}>
								<strong />
							</Trans>
						</li>
					</ul>
					<p className={classes.helpText}>{t('reports:viewReport.pdfViewerHelpNote3')}</p>
				</div>
			</div>
			<div className={'rpt arm-tickets'}>
				<div className="header">
					<div className="left-header">
						{<ExportMenu menuItems={menuItems} tooltip={t('reportsUI:export.tooltipInfo')} />}
					</div>
					<DraggableDialog
						dataParams={viewParams}
						openReportDialog={openReportDialog}
						handleReportDialogClose={handleClose}
						reportName={viewParams.reportName}
						otherOptions={{ attackTrendIncluded: attackTrendIncluded }}
						t={t}
					/>
				</div>
				<div className="content-col">
					<div className="page-content">
						<div className="pdf-page">
							{viewParams.reportType && !loading ? (
								!error ? (
									<>
										<Document
											error={t('reports:executedReports.messages.error')}
											loading={<LoadingData message={t('events:summaryMessages.loadingPage')} />}
											file={pdfPageData.pdfObj}
											onLoadSuccess={onDocumentLoadSuccess}
										>
											<Page pageNumber={pageNumber} width={1400} />
										</Document>
										{numPages && numPages > 0 && (
											<Pagination
												data={{ totalPages: numPages, currentPage: pageNumber }}
												handleChange={handlePageChange}
											/>
										)}
									</>
								) : (
									<ErrorMsg message={t('reports:executedReports.messages.error')} />
								)
							) : (
								<LoadingData message={t('events:summaryMessages.loadingPage')} />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReportPDFViewContainer;
