/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { usePagination } from '@material-ui/lab/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '../Loaders/Circular';
import Autocomplete from '../Inputs/Autocomplete';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	pagination: {
		position: 'relative',
		padding: theme.spacing(0, 5)
	},
	ul: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	li: {
		'&:first-of-type': {
			'& $button': {
				borderRadius: theme.spacing(1, 0, 0, 1),
				borderLeft: `1px solid ${theme.palette.pagination.borderColor}`
			}
		},
		'&:last-of-type': {
			'& $button': {
				borderLeft: 'none',
				borderRadius: theme.spacing(0, 1, 1, 0)
			}
		}
	},
	button: {
		'display': 'inline-block',
		'border': `1px solid ${theme.palette.pagination.borderColor}`,
		'borderLeft': 0,
		'backgroundColor': theme.palette.pagination.background,
		'padding': theme.spacing(1, 2),
		'color': theme.palette.pagination.color,
		'minHeight': 27,
		'fontSize': 12,
		'lineHeight': '15px',
		'textDecoration': 'none',
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.pagination.colorHover,
			backgroundColor: theme.palette.pagination.backgroundHover
		},
		'&:focus': {
			outline: 0
		}
	},
	active: {
		'pointerEvents': 'none',
		'userSelect': 'none',
		'backgroundColor': theme.palette.pagination.activeBackground,
		'color': theme.palette.pagination.activeColor,
		'&:focus': {
			outline: 0
		}
	},
	disabled: {
		pointerEvents: 'none',
		userSelect: 'none',
		color: theme.palette.pagination.disabledColor + ' !important'
	},
	loading: {
		position: 'absolute',
		top: 6,
		right: 0
	},
	dropDownBox: {
		padding: theme.spacing(0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	dropDown: {
		'padding': theme.spacing(0, 1),
		'& [class*="MuiAutocomplete-inputRoot"]': {
			padding: theme.spacing(0, 3, 0, 2) + ' !important'
		}
	},
	link: {
		'display': 'inline-block',
		'padding': 6,
		'color': theme.palette.pagination.color,
		'minHeight': 27,
		'fontSize': 13,
		'lineHeight': '15px',
		'textDecoration': 'none',
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.pagination.colorHover
		},
		'&:focus': {
			outline: 0
		}
	}
}));

export const Pagination = ({ data, handleChange, isLoading, type = 'LINKS' }) => {
	const classes = useStyles();
	const { currentPage, totalPages } = data || {};

	const { items } = usePagination({
		count: totalPages,
		onChange: handleChange,
		siblingCount: 1,
		defaultPage: 1,
		page: currentPage,
		disabled: isLoading
	});

	const pages = Array.from(Array(totalPages + 1).keys()).slice(1);

	const handleClick = (onClick) => (event) => {
		if (event) {
			event.preventDefault();
		}

		if (onClick) {
			onClick();
		}
	};

	return (
		// "pagination" class name is used by e2e automation tests
		<nav className={classNames('pagination', classes.pagination)}>
			{type === 'DROPDOWN' ? (
				<Box margin={0} padding={0} className={classes.dropDownBox}>
					<a
						onClick={(event) => {
							handleChange(event, currentPage - 1);
						}}
						disabled={currentPage === 1}
						className={classNames(classes.link, {
							[classes.disabled]: currentPage === 1,
							// "disabled" class name is used by e2e automation tests
							'disabled': currentPage === 1,
							// "icon-angle-left" class name is used by e2e automation tests
							'icon-angle-left': true
						})}
					>
						<FontAwesomeIcon icon={faAngleLeft} /> Prev
					</a>
					<div className={classes.dropDown}>
						<Autocomplete
							id="pageNumbers"
							value={currentPage}
							data={pages}
							size={'small'}
							disableClearable={true}
							disabled={isLoading}
							placeholder={''}
							getOptionLabel={(option) => option + ''}
							getOptionSelected={(option, value) => {
								if (value) {
									return option === value;
								} else {
									return false;
								}
							}}
							onChange={(event, newValue) => {
								if (newValue) {
									handleChange(event, newValue);
								}
							}}
						/>
					</div>
					{'  '}
					of {totalPages}
					<a
						onClick={(event) => {
							handleChange(event, currentPage + 1);
						}}
						disabled={currentPage === totalPages}
						className={classNames(classes.link, {
							[classes.disabled]: currentPage === totalPages,
							// "disabled" class name is used by e2e automation tests
							'disabled': currentPage === totalPages,
							// "icon-angle-left" class name is used by e2e automation tests
							'icon-angle-right': true
						})}
					>
						Next <FontAwesomeIcon icon={faAngleRight} />
					</a>
				</Box>
			) : (
				<ul className={classes.ul}>
					{items.map(({ page, type, selected, disabled, onClick }, index) => {
						let children = null;

						if (type === 'start-ellipsis' || type === 'end-ellipsis') {
							children = (
								<a
									href="#"
									disabled={true}
									onClick={handleClick(onClick)}
									className={classNames(classes.button, classes.disabled)}
								>
									…
								</a>
							);
						} else if (type === 'page') {
							children = (
								<a
									href="#"
									onClick={handleClick(onClick)}
									className={classNames(classes.button, {
										[classes.active]: selected,
										[classes.disabled]: disabled || selected
									})}
								>
									{page}
								</a>
							);
						} else {
							children = (
								<a
									href="#"
									onClick={handleClick(onClick)}
									className={classNames(classes.button, {
										[classes.disabled]: disabled,
										// "disabled" class name is used by e2e automation tests
										'disabled': disabled,
										// "icon-angle-left" class name is used by e2e automation tests
										'icon-angle-left': type === 'previous',
										// "icon-angle-right" class name is used by e2e automation tests
										'icon-angle-right': type !== 'previous'
									})}
								>
									{type === 'previous' ? (
										<FontAwesomeIcon icon={faAngleLeft} />
									) : (
										<FontAwesomeIcon icon={faAngleRight} />
									)}
								</a>
							);
						}

						return (
							<li
								key={index}
								className={classNames(classes.li, {
									[classes.active]: selected,
									// "active" class name is used by e2e automation tests
									active: selected,
									// "disabled" class name is used by e2e automation tests
									disabled: disabled || selected
								})}
							>
								{children}
							</li>
						);
					})}
				</ul>
			)}
			{isLoading ? (
				// "loading-indicator", and "in" class names are used by e2e automation tests
				<CircularProgress size={15} className={classNames('loading-indicator', 'in', classes.loading)} />
			) : null}
		</nav>
	);
};
