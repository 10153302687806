/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import {
	PREFERENCES_CHANGE_LANGUAGE_PROGRESS,
	PREFERENCES_CHANGE_LANGUAGE_SUCCESS,
	PREFERENCES_CHANGE_THEME_PROGRESS,
	PREFERENCES_CHANGE_THEME_SUCCESS
} from '../../actions/app/preferences';

const initialState = {
	language: 'en_US',
	timeZone: 'UTC',
	hasTimeZone: false,
	theme: {
		contactPreferenceId: null,
		preferenceId: null,
		preferenceName: null,
		preferenceValue: 'light-theme',
		isError: false,
		isLoading: false
	},
	isError: false,
	isLoading: false
};

export default function preferencesReducer(state = initialState, action) {
	switch (action.type) {
		case PREFERENCES_CHANGE_LANGUAGE_SUCCESS:
			return {
				...state,
				language: action.payload.language
			};
		case PREFERENCES_CHANGE_LANGUAGE_PROGRESS:
			return {
				...state,
				isError: action.payload.isError,
				isLoading: action.payload.isLoading
			};
		case PREFERENCES_CHANGE_THEME_SUCCESS:
			return {
				...state,
				theme: {
					...state.theme,
					...action.payload.theme
				}
			};
		case PREFERENCES_CHANGE_THEME_PROGRESS:
			return {
				...state,
				theme: {
					...state.theme,
					isError: action.payload.isError,
					isLoading: action.payload.isLoading
				}
			};
		default:
			return state;
	}
}
