/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { hideAlert } from '../../../state/actions/components/alerts';
import { AlertCustom } from '../../../components/Alert';

const useStyles = makeStyles(() => ({
	link: {
		color: 'inherit'
	}
}));

const GlobalAlertTIEW = ({ id, data }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();

	const { messageId, message, severity } = data;
	const messageHref = messageId ? `/portal/intel/tiew-messages/${messageId}` : '/portal/intel/tiew';

	function handleClose() {
		dispatch(hideAlert({ id }));

		if (messageId) {
			get(`/portal/intel/tiew/clear-message/${messageId}`);
		}
	}

	return (
		<AlertCustom
			variant="filled"
			severity={severity}
			onClose={handleClose}
			closeText={t('common:buttons.close')}
			icon={<FontAwesomeIcon size="sm" icon={faExclamationTriangle} />}
			noSideBorders
		>
			<a
				target="_blank"
				rel="noopener noreferrer"
				className={classes.link}
				href={messageHref}
				onClick={handleClose}
			>
				{messageId ? message : t(message)}
			</a>
		</AlertCustom>
	);
};

GlobalAlertTIEW.defaultProps = {
	severity: 'warning'
};

GlobalAlertTIEW.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired
};

export default GlobalAlertTIEW;
