/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import axios, { all, get, post, spread } from 'axios';
import { batch } from 'react-redux';
import { debugMessage } from '../../../utils';
import { MessageTypes, getMessageId } from '../../../components/Chat/Views/Conversation/Message/message';
import { DateTime } from 'luxon';
import { getCampaignOption } from '../../../components/Chat/Views/Information/campaignList';

export const CHAT_PREFIX = '@@chat';

export const RESET = `${CHAT_PREFIX}/RESET`;
export const SET_EWT = `${CHAT_PREFIX}/SET_EWT`;
export const ADD_AGENT = `${CHAT_PREFIX}/ADD_AGENT`;
export const REMOVE_AGENT = `${CHAT_PREFIX}/REMOVE_AGENT`;
export const IS_OPEN = `${CHAT_PREFIX}/IS_OPEN`;
export const IS_DUPLICATE = `${CHAT_PREFIX}/IS_DUPLICATE`;
export const SET_MESSAGES = `${CHAT_PREFIX}/SET_MESSAGES`;
export const SET_MESSAGE = `${CHAT_PREFIX}/SET_MESSAGE`;
export const ADD_MESSAGE = `${CHAT_PREFIX}/ADD_MESSAGE`;
export const SET_PROGRESS = `${CHAT_PREFIX}/SET_PROGRESS`;
export const SET_METADATA = `${CHAT_PREFIX}/SET_METADATA`;
export const SET_SESSION = `${CHAT_PREFIX}/SET_SESSION`;
export const INIT_SESSION = `${CHAT_PREFIX}/INIT_SESSION`;
export const SET_POSITION = `${CHAT_PREFIX}/SET_POSITION`;
export const SET_SIZE = `${CHAT_PREFIX}/SET_SIZE`;
export const SET_FORM = `${CHAT_PREFIX}/SET_FORM`;
export const SET_AGENT_TYPING = `${CHAT_PREFIX}/SET_AGENT_TYPING`;

export const SET_ROOT_URL = `${CHAT_PREFIX}/SET_ROOT_URL`;
export const SET_SESSION_URL = `${CHAT_PREFIX}/SET_SESSION_URL`;
export const SET_SOCKET_URL = `${CHAT_PREFIX}/SET_SOCKET_URL`;
export const SET_SOCKET_META = `${CHAT_PREFIX}/SET_SOCKET_META`;
export const SET_API_URL = `${CHAT_PREFIX}/SET_API_URL`;

export const TOGGLE_SOUND = `${CHAT_PREFIX}/TOGGLE_SOUND`;
export const TOGGLE_MODAL = `${CHAT_PREFIX}/TOGGLE_MODAL`;

export const PATHS = {
	apiRestPath: '/appsvcs/rs/svc',
	apiWsPath: '/appsvcs/ws'
};

const FIVE9_API = axios.create({
	baseURL: 'https://app.five9.com',
	headers: null
});

const getAgentIds = (agents) => {
	return Object.values(agents).map(({ id }) => id);
};

const getCookieValue = (name) => {
	var value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

	return value ? value.pop() : '';
};

export const setEWT = ({ content, timestamp }) => ({
	type: SET_EWT,
	payload: {
		content,
		timestamp
	}
});

export const setForm = ({ ticketId, campaign, question, isGeneratedTicket = false }) => ({
	type: SET_FORM,
	payload: {
		ticketId,
		campaign,
		question,
		isGeneratedTicket
	}
});

export const setSize = (data) => ({
	type: SET_SIZE,
	payload: data
});

export const setPosition = (data) => ({
	type: SET_POSITION,
	payload: data
});

export const setAgentTyping = (value) => ({
	type: SET_AGENT_TYPING,
	payload: value
});

export const reset = (payload) => ({
	type: RESET,
	payload: payload || {}
});

export const toggleModal = ({ name, value }) => ({
	type: TOGGLE_MODAL,
	payload: {
		name,
		value
	}
});

export const toggleSound = ({ isEnabled }) => ({
	type: TOGGLE_SOUND,
	payload: isEnabled
});

export const addAgent = ({ id, name }) => ({
	type: ADD_AGENT,
	payload: {
		id,
		name
	}
});

export function removeAgent({ id }) {
	return (dispatch, getState) => {
		const { chat } = getState();

		const { [id]: omit, ...agents } = chat.session.agents;

		if (omit) {
			dispatch({
				type: REMOVE_AGENT,
				payload: agents
			});
		}
	};
}

export const setOpen = ({ isOpen }) => ({
	type: IS_OPEN,
	payload: {
		isOpen
	}
});

export const setDuplicate = ({ isDuplicate }) => ({
	type: IS_DUPLICATE,
	payload: {
		isDuplicate
	}
});

export const setMessages = (messages) => ({
	type: SET_MESSAGES,
	payload: messages
});

export const setMessage = (payload) => ({
	type: SET_MESSAGE,
	payload
});

/**
 * Adds message to the store.
 *
 * @param {Object} message A message to send.
 * @param {String} message.id: '',
 * @param {String} message.messageId: '',
 * @param {Number} message.messageType: 0,
 * @param {String} message.content: '',
 * @param {Number} message.contentType: 1,
 * @param {String} message.displayName: '',
 * @param {Number} message.fromType: 2,
 * @param {String} message.ownerId: '',
 * @param {Number} message.timestamp: 1234,
 * @param {Array} message.userIds: []
 *
 * @returns {Object} Redux action.
 */
export const addMessage = (message) => ({
	type: ADD_MESSAGE,
	payload: message
});

export const setProgress = ({ progress }) => ({
	type: SET_PROGRESS,
	payload: {
		progress
	}
});

export const setMetaData = ({ host, port, farmId, tokenId }) => ({
	type: SET_METADATA,
	payload: {
		host,
		port,
		farmId,
		tokenId
	}
});

export const initSession = ({ ...props }) => ({
	type: INIT_SESSION,
	payload: {
		...props
	}
});

export const setSession = ({ ...props }) => ({
	type: SET_SESSION,
	payload: {
		...props
	}
});

export const setRootUrl = ({ url }) => ({
	type: SET_ROOT_URL,
	payload: {
		url
	}
});

export const setApiUrl = ({ url }) => ({
	type: SET_API_URL,
	payload: {
		url
	}
});

export const setSessionUrl = ({ url }) => ({
	type: SET_SESSION_URL,
	payload: {
		url
	}
});

export const setSocketUrl = ({ url }) => ({
	type: SET_SOCKET_URL,
	payload: {
		url
	}
});

export const setSocketMeta = (payload) => ({
	type: SET_SOCKET_META,
	payload
});

export function createSession() {
	return (dispatch, getState) => {
		const { contact, other } = getState();

		const COOKIE_NAME = 'PORTALCHATPROFILES';
		const COOKIE_VALUE = getCookieValue(COOKIE_NAME);

		dispatch(
			setSession({
				isLoading: new Date().getTime()
			})
		);

		const handleResponse = ({ data }) => {
			const profiles = data || {};

			debugMessage('[CHAT] [createSession] Retrieved profiles', profiles);

			document.cookie = `${COOKIE_NAME}=${JSON.stringify(data)};path=/;SameSite=None;Secure`;

			return batch(() => {
				dispatch(
					initSession({
						isLoading: true,
						contactId: contact.id,
						contactName: contact.name,
						contactEmail: contact.email,
						tenantName: other.chatTenant,
						profiles
					})
				);

				dispatch(updateRootUrl());
				dispatch(updateSessionUrl());
				dispatch(getAuthSession());
				dispatch(getAvailableCampaigns());
			});
		};

		if (COOKIE_VALUE) {
			return handleResponse({ data: JSON.parse(COOKIE_VALUE) });
		}

		return get('/portal/chat/profiles')
			.then(handleResponse)
			.catch(() => {
				debugMessage('[CHAT] [createSession] Failed to retrieve profiles');

				dispatch(
					setSession({
						isLoading: false
					})
				);

				return dispatch(
					setProgress({
						progress: 'showError'
					})
				);
			});
	};
}

const buildUrl = (protocol, hostname, port) => {
	if (protocol.indexOf('http') >= 0 || protocol.indexOf('ws') >= 0) {
		protocol = protocol.replace(/:/g, '');
		protocol = protocol.replace(/\//g, '');
		hostname = hostname.replace(/\//g, '');
		port = port.replace(/:/g, '');
		port = port.replace(/\//g, '');

		var url = protocol + '://' + hostname;

		if (port) {
			url += ':' + port;
		}

		return url;
	}

	return '';
};

export function updateRootUrl() {
	return (dispatch, getState) => {
		const { chat } = getState();

		dispatch(
			setRootUrl({
				url: buildUrl('https', chat.session.apiHost, chat.session.apiPort)
			})
		);
	};
}

export function updateApiUrl() {
	return (dispatch, getState) => {
		const { chat } = getState();

		dispatch(
			setApiUrl({
				url: `${chat.session.urls.root}${PATHS.apiRestPath}/agents/${chat.session.owner.id}/interactions/client_chat/${chat.session.tokenId}`
			})
		);
	};
}

export function updateSessionUrl() {
	return (dispatch, getState) => {
		const { chat } = getState();

		dispatch(
			setSessionUrl({
				url: `${chat.session.urls.root}${PATHS.apiRestPath}`
			})
		);
	};
}

export function updateSocketUrl() {
	return (dispatch, getState) => {
		const { chat } = getState();

		let url = buildUrl('wss', chat.session.apiHost, chat.session.apiPort) + PATHS.apiWsPath;

		if (chat.session.tokenId) {
			url += '?Authorization=Bearer-' + chat.session.tokenId;
		}

		if (chat.session.farmId) {
			url += '&farmId=' + chat.session.farmId;
		}

		dispatch(
			setSocketUrl({
				url
			})
		);
	};
}

export function getAuthSession() {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'post',
			url: `${chat.session.urls.session}/auth/anon`,
			data: {
				tenantName: chat.session.tenantName
			},
			params: {
				cookieless: true
			}
		})
			.then(({ data }) => {
				debugMessage('[CHAT] [getAuthSession] Success');

				let session = {};

				const dataCenter = data.metadata.dataCenters.find(({ active }) => !!active);
				const { host, port } = dataCenter.apiUrls[0];

				session.apiHost = host;

				if (port && port !== 'null') {
					session.apiPort = port;
				} else {
					session.apiPort = '';
				}

				if (data.metadata && data.metadata.dataCenters.length > 0) {
					var index = -1;
					for (var i = 0; i < data.metadata.dataCenters.length; ++i) {
						if (data.metadata.dataCenters[i].active === true) {
							index = i;
							break;
						}
					}
					if (index >= 0) {
						var activeDataCenter = data.metadata.dataCenters[index];
						session.apiRouteKey = activeDataCenter.apiUrls[0].routeKey;
						session.uiRouteKey = activeDataCenter.uiUrls[0].routeKey;
					}
					if (data.context && data.context.farmId) {
						session.farmId = data.context.farmId;
					}
				}

				session.tokenId = data.tokenId;
				session.tenantId = data.orgId;
				session.sessionId = data.sessionId;
				session.owner = {
					id: data.userId
				};

				session.headers = {
					Authorization: `Bearer-${session.tokenId}`,
					farmId: session.farmId
				};

				batch(() => {
					dispatch(setSession(session));
					dispatch(updateRootUrl());
					dispatch(updateSessionUrl());
					dispatch(updateApiUrl());
					dispatch(updateSocketUrl());

					dispatch(
						setProgress({
							progress: 'showChatForm'
						})
					);
				});
			})
			.catch((error) => {
				debugMessage('[CHAT] [getAuthSession]', error);
				dispatch(
					setProgress({
						progress: 'showError'
					})
				);
			});
	};
}

export function getAuthMetadata({ socket }) {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'get',
			url: `${chat.session.urls.session}/auth/metadata`,
			headers: chat.session.headers
		})
			.then(({ data }) => {
				let session = {};

				const apiInfo = data.metadata.dataCenters[0].apiUrls[0];

				session.apiHost = apiInfo.host;

				if (apiInfo.port !== 'null') {
					session.apiPort = apiInfo.port;
				}

				if (chat.session.farmId) {
					session.farmId = data.context.farmId;
				}

				dispatch(setSession(session));

				if (socket) {
					// reinitialize websocket connetion
					socket.reinitialize();
				}

				// TODO : Retry previous call here
			})
			.catch((error) => {
				debugMessage('[CHAT] [getAuthMetadata]', error);
				dispatch(
					setProgress({
						progress: 'showError'
					})
				);
			});
	};
}

export function getAvailableCampaigns() {
	return (dispatch, getState) => {
		const { chat } = getState();

		const campaignNames = Object.keys(chat.session.profiles);

		return FIVE9_API({
			method: 'get',
			url: `${chat.session.urls.session}/orgs/available_campaigns`,
			params: {
				tenantName: chat.session.tenantName,
				campaignNames: campaignNames.join(',')
			}
		})
			.then(({ data }) => {
				const availableCampaigns = campaignNames.filter((profile) => data.availableCampaigns.includes(profile));

				debugMessage('[CHAT] [getAvailableCampaigns] Available campaigns:', availableCampaigns);

				dispatch(setSession({ availableCampaigns }));

				dispatch(getCampaignWaitTime());
			})
			.catch((error) => {
				debugMessage('[CHAT] [getAvailableCampaigns]', error);

				batch(() => {
					dispatch(
						setSession({
							isLoading: false
						})
					);
					dispatch(
						setProgress({
							progress: 'showError'
						})
					);
				});
			});
	};
}

export function getCampaignWaitTime() {
	return (dispatch, getState) => {
		const { chat } = getState();

		return all(
			chat.session.availableCampaigns.map((profile) =>
				FIVE9_API({
					method: 'get',
					url: `${chat.session.urls.session}/orgs/estimatedwaittime/1000`,
					params: {
						tenantName: chat.session.tenantName,
						campaignName: profile
					}
				})
			)
		)
			.then(
				spread((...data) => {
					const campaigns = data
						.filter(({ data }) => data.resultCode !== -1 && data.businessHours.openForBusiness)
						.map(({ config, data }) => ({
							name: config.params.campaignName,
							data,
							labels: chat.session.profiles[config.params.campaignName] || {}
						}));

					debugMessage('[CHAT] [getCampaignWaitTime] Campaigns with agents online:', campaigns);

					dispatch(
						setSession({
							campaigns,
							isLoading: false
						})
					);
				})
			)
			.catch((errors) => {
				debugMessage('[CHAT] [getCampaignWaitTime]', errors);

				batch(() => {
					dispatch(
						setSession({
							isLoading: false
						})
					);
					dispatch(
						setProgress({
							progress: 'showError'
						})
					);
				});
			});
	};
}

export function getConversationInfo() {
	return (dispatch, getState) => {
		const { chat } = getState();

		/**
		 * @param {Object} data - Information about conversation.
		 * @param {String} data.id - Conversation ID.
		 * @param {String} data.status - Conversation status types, possible values are PENDING, ACTIVE, and TERMINATED
		 * @param {BigInt} data.disposition - Conversation’s disposition code.
		 */
		function handleSuccess({ data }) {
			const { id, status, disposition } = data;

			debugMessage('[CHAT] [getConversationInfo]', id, status, disposition);

			if (
				status === 'TERMINATED' &&
				chat.progress !== 'terminatedByAgent' &&
				chat.progress !== 'terminatedByClient'
			) {
				dispatch(
					setProgress({
						progress: chat.progress === 'acceptedByAgent' ? 'terminatedByAgent' : 'showTerminated'
					})
				);
			}
		}

		return FIVE9_API({
			method: 'get',
			url: `${chat.session.urls.session}/conversations/${chat.session.tokenId}/info`,
			headers: chat.session.headers
		})
			.then(handleSuccess)
			.catch((error) => {
				debugMessage('[CHAT] [getConversationInfo] Error:', error);
			});
	};
}

export function sendChatRequest({ campaign, question, ticketId }) {
	return (dispatch, getState) => {
		const { chat, client, preferences } = getState();

		function startChat(ticketIdNumber) {
			return get('/portal/chat/authenticate')
				.then(({ data }) => {
					debugMessage('[CHAT] [startChat] Starting Chat Request');
					const { token } = data;
					const { chat, preferences } = getState();

					const customFields = [
						{
							key: 'Subject',
							value: question,
							analyze: 0
						},
						{
							key: 'Question',
							value: question,
							analyze: 1
						},
						{
							key: 'SecureWorks.TicketID',
							value: ticketIdNumber,
							analyze: 0
						},
						{
							key: 'SecureWorks.auth_token',
							value: token,
							analyze: 0
						},
						{
							key: 'SecureWorks.language_preference',
							value: preferences.language,
							analyze: 0
						},
						{
							key: 'SecureWorks.chat_platform',
							value: 'Web',
							analyze: 0
						}
					].filter(Boolean);

					return FIVE9_API({
						method: 'post',
						url: `${chat.session.urls.api}`,
						headers: chat.session.headers,
						timeout: 30000,
						data: {
							campaign,
							customFields,
							groupId: chat.session.groupId,
							name: chat.session.contactName,
							email: chat.session.contactEmail,
							timezone: preferences.timeZone
						}
					})
						.then(({ data }) => {
							let session = {};

							if (data && data.profileId) {
								if (typeof data.profileId !== 'string') {
									data.profileId = data.profileId.toString();
								}

								session.profileId - data.profileId;
							}

							session.profileSurvey = data;

							dispatch(setSession(session));
						})
						.catch((error) => {
							debugMessage('[CHAT] [sendChatRequest]', error);
							dispatch(
								setProgress({
									progress: 'showError'
								})
							);
						});
				})
				.catch(() => {
					debugMessage('[CHAT] [startChat] Failed to obtain auth token');
					dispatch(
						setProgress({
							progress: 'showError'
						})
					);
				});
		}

		if (ticketId) {
			return startChat(ticketId);
		}

		const date = DateTime.utc();
		let title = getCampaignOption(campaign, chat.session.campaigns, preferences.language);

		if (title) {
			title = title.label;
		} else {
			title = campaign;
		}

		const data = {
			attachments: { attachments: [] },
			fwRequestTypes: null,
			ticket: {
				externalTicket: '',
				title: title,
				detail: `Ticket raised on behalf of ${chat.session.contactName} on ${date} initiated by a chat conversation`,
				watchers: [],
				requestType: 'Live Chat',
				incidentId: '',
				isIncidentSR: '',
				clientLocationRef: {
					href: client.locationRefs[0]
				}
			}
		};

		const url = '/portal/servicerequests';

		return post(url, data)
			.then((data) => {
				const generatedTicket = data.data.model.id;
				dispatch(
					setForm({
						campaign: campaign,
						question: question,
						ticketId: generatedTicket,
						isGeneratedTicket: true
					})
				);
				startChat(generatedTicket);
			})
			.catch(() => {
				debugMessage('[CHAT] [startChat] Failed to create service request');
				dispatch(
					setProgress({
						progress: 'showError'
					})
				);
			});
	};
}

export function terminateChat() {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'post',
			url: `${chat.session.urls.api}/terminate`,
			headers: chat.session.headers,
			data: {
				messageId: getMessageId(),
				displayName: chat.session.contactName,
				content: '',
				contentType: MessageTypes.MSG_TEXT,
				ownerId: chat.session.owner.id,
				profileId: chat.session.profileId,
				userIds: getAgentIds(chat.session.agents)
			}
		}).catch((error) => {
			debugMessage('[CHAT] [terminateChat]', error);
			dispatch(reset());
		});
	};
}

let RETRY_SEND_MESSAGE_TIMEOUT_TRACKER;

export function sendMessage({ content, retryCount = 0, data = null }) {
	return (dispatch, getState) => {
		const { chat } = getState();

		const RETRY_SEND_MESSAGE_TIMEOUT_LENGTH = 1500;

		if (!data) {
			data = {
				messageId: getMessageId(),
				displayName: chat.session.contactName,
				content: content,
				contentType: MessageTypes.MSG_TEXT,
				ownerId: chat.session.owner.id,
				userIds: getAgentIds(chat.session.agents)
			};

			dispatch(
				addMessage({
					...data,
					id: chat.session.id,
					messageType: MessageTypes.MSG_CHAT_CLIENT_MESSAGE,
					fromType: MessageTypes.OUT,
					originatorType: MessageTypes.FROM_CLIENT,
					acknowledged: false,
					timestamp: Date.now()
				})
			);
		}

		return FIVE9_API({
			method: 'post',
			url: `${chat.session.urls.api}/message`,
			headers: chat.session.headers,
			data
		})
			.then(() => {
				dispatch(
					setMessage({
						messageId: data.messageId,
						acknowledged: true,
						failedToSend: false
					})
				);

				return data.messageId;
			})
			.catch((error) => {
				debugMessage('[CHAT] [sendMessage]', error);

				window.clearTimeout(RETRY_SEND_MESSAGE_TIMEOUT_TRACKER);

				RETRY_SEND_MESSAGE_TIMEOUT_TRACKER = window.setTimeout(() => {
					if (retryCount < 3) {
						debugMessage('[CHAT] [sendMessage] Retrying to send client message', data);

						dispatch(
							sendMessage({
								content: content,
								retryCount: (retryCount += 1),
								data
							})
						);
					} else {
						debugMessage('[CHAT] [sendMessage] Retrying to send client message has failed', data);

						dispatch(
							setMessage({
								messageId: data.messageId,
								failedToSend: true
							})
						);
					}
				}, RETRY_SEND_MESSAGE_TIMEOUT_LENGTH);
			});
	};
}

export function sendClientTyping() {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'post',
			url: `${chat.session.urls.api}/typing`,
			headers: chat.session.headers,
			data: {
				messageId: getMessageId(),
				displayName: chat.session.contactName,
				content: '',
				contentType: MessageTypes.MSG_TEXT,
				ownerId: chat.session.owner.id,
				userIds: getAgentIds(chat.session.agents)
			}
		});
	};
}

export function getPreviousMessages() {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'get',
			url: `${chat.session.urls.api}/messages`,
			headers: chat.session.headers
		})
			.then(({ data }) => {
				dispatch(setAgentTyping(false));

				// if (Object.keys(chat.messages).length !== data.length) {
				debugMessage('[CHAT] [getPreviousMessages]', data);

				dispatch(
					setMessages(
						data.reduce((acc, curr) => {
							const existingMessage = chat.messages[curr.messageId];
							const messageNeedsAck =
								(!existingMessage && curr.messageId.indexOf('client') === -1) ||
								(existingMessage && !existingMessage.acknowledged);

							if (messageNeedsAck) {
								dispatch(
									acknowledgeAgentMessage({
										messageId: curr.messageId
									})
								);
							} else {
								curr.acknowledged = true;
							}

							if (curr.displayName === 'System') {
								curr.fromType = MessageTypes.IN;
							}

							// convert to milliseconds
							curr.timestamp = curr.timestamp * 1000;

							acc[curr.messageId] = curr;

							return acc;
						}, {})
					)
				);
				// }
			})
			.catch((error) => {
				debugMessage('[CHAT] [getPreviousMessages]', error);
				dispatch(
					setProgress({
						progress: 'showError'
					})
				);
			});
	};
}

export function acknowledgeAgentMessage({ messageId }) {
	return (dispatch, getState) => {
		const { chat } = getState();

		return FIVE9_API({
			method: 'post',
			url: `${chat.session.urls.api}/acknowledge`,
			headers: chat.session.headers,
			data: {
				messageId,
				displayName: chat.session.contactName,
				content: '',
				contentType: MessageTypes.MSG_TEXT,
				ownerId: chat.session.owner.id,
				userIds: getAgentIds(chat.session.agents)
			}
		})
			.then(() => {
				dispatch(
					setMessage({
						messageId,
						acknowledged: true
					})
				);
			})
			.catch((error) => {
				debugMessage('[CHAT] [acknowledgeAgentMessage]', error);
			});
	};
}

let standaloneWindow = null;
let standaloneWindowTimer = null;

export function popOutChat() {
	return (dispatch, getState) => {
		const { chat } = getState();

		if (chat.isDuplicate && chat.session.isStandalone) {
			return;
		}

		if (standaloneWindow && !standaloneWindow.closed) {
			return standaloneWindow.focus();
		}

		const width = 320;
		const height = 600;
		const top = window.innerHeight - height - 10;
		const left = window.innerWidth - width - 40;

		standaloneWindow = window.open(
			'/portal/chat',
			'SecureworksChat',
			`directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,top=${top},left=${left},width=${width},height=${height}`
		);

		window.clearInterval(standaloneWindowTimer);

		standaloneWindowTimer = setInterval(() => {
			if (standaloneWindow && standaloneWindow.closed) {
				standaloneWindow = null;

				dispatch(
					setSession({
						isStandalone: false
					})
				);

				const { chat } = getState();

				if (chat.progress) {
					dispatch(
						setOpen({
							isOpen: true
						})
					);
				}

				window.clearInterval(standaloneWindowTimer);
			}
		}, 500);

		return { standaloneWindow };
	};
}
