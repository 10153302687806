/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useEventAnalysisDrillDown } from './useEventAnalysisDrillDown';
import React from 'react';
import DrillDownTableContainer from '../../../../../containers/Reports/DrillDownTable';

/**
 * @param {object} fromChart - Data returned from Chart
 * @returns {JSX.Element}
 */
export const EventAnalysisDrillDown = ({ fromChart }) => {
	const drillDownProps = useEventAnalysisDrillDown(fromChart);
	return <DrillDownTableContainer fromChart={fromChart} {...drillDownProps} />;
};
