/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, InputBase, InputLabel, Select, Chip } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const CustomInputLabel = withStyles((theme) => ({
	root: {
		'color': theme.palette.text.primary,
		'fontSize': theme.typography.body2.fontSize,
		'fontWeight': theme.typography.fontWeightBold,
		'&$focused': {
			color: theme.palette.text.primary
		},
		'&$outlined$shrink': {
			transform: `translate(0, 0) scale(1)`
		}
	},
	shrink: {
		transform: `translate(0, 0) scale(1)`
	},
	focused: {},
	outlined: {}
}))(InputLabel);

const useMenuItemStyles = makeStyles((theme) => ({
	root: {
		...theme.typography.body2,
		'outline': 0,
		'boxSizing': 'border-box',
		'display': 'block',
		'width': '100%',
		'overflow': 'hidden',
		'whiteSpace': 'nowrap',
		'minHeight': 'auto',
		'padding': theme.spacing(1, 2),
		'color': theme.palette.dropdownItem.color,
		'transition': theme.transitions.create(['color', 'background-color'], {
			duration: theme.transitions.duration.short
		}),
		'&:hover, &:focus': {
			color: theme.palette.dropdownItem.colorHover,
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		},
		'&$selected': {
			color: theme.palette.dropdownItem.colorHover,
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		}
	},
	gutters: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	selected: {}
}));

const useStyles = makeStyles((theme) => ({
	paper: {
		maxHeight: '40vh'
	},
	hidden: {
		display: 'none'
	},
	placeholder: {
		color: theme.palette.input.placeholder
	},
	selectMenu: {
		minHeight: 0
	},
	inputIcon: {
		top: 0,
		right: 0,
		bottom: 0,
		position: 'absolute',
		pointerEvents: 'none',
		margin: theme.spacing('auto', 3),
		width: theme.spacing(3)
	},
	inputRoot: {
		'flexWrap': 'wrap',
		'fontSize': theme.typography.body1.fontSize,
		'position': 'relative',
		'label + &': {
			marginTop: theme.spacing(4)
		},
		'&$error $inputInput': {
			'boxShadow': `inset 0 0 0 1px ${theme.palette.error.main}`,
			'&:focus': {
				borderRadius: theme.spacing(1),
				backgroundColor: theme.palette.input.background,
				boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}, 0 0 3px 1px ${fade(
					theme.palette.error.main,
					0.3
				)}`
			}
		}
	},
	inputInput: {
		'width': 'auto',
		'flexGrow': 1,
		'lineHeight': 1,
		'color': theme.palette.input.color,
		'border': 0,
		'height': theme.typography.body1.fontSize,
		'boxShadow': `inset 0 0 0 1px ${theme.palette.input.border}`,
		'backgroundColor': theme.palette.input.background,
		'padding': theme.spacing(2, 8, 2, 3),
		'borderRadius': theme.spacing(1),
		'transition': theme.transitions.create('box-shadow'),
		'&::-ms-clear': {
			display: 'none'
		},
		'&:focus': {
			borderRadius: theme.spacing(1),
			backgroundColor: theme.palette.input.background,
			boxShadow: `inset 0 0 0 1px ${theme.palette.common.blue}, 0 0 3px 1px ${fade(
				theme.palette.common.blue,
				0.3
			)}`
		},
		'&$disabled': {
			'color': theme.palette.input.disabled,
			'boxShadow': 'none',
			'backgroundColor': theme.palette.input.disabledBackground,
			'& $placeholder': {
				color: theme.palette.input.disabled
			}
		}
	},
	inputInputMulti: {
		height: 'auto',
		minHeight: theme.typography.body1.fontSize
	},
	disabled: {},
	error: {},
	chips: {
		display: 'flex',
		flexFlow: 'row wrap',
		marginTop: theme.spacing(-0.5)
	},
	chip: {
		marginTop: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
		minWidth: 50
	}
}));

function DeletableChip(props) {
	const { onDelete, value } = props;

	return (
		<Chip
			value={value}
			deleteIcon={
				<div
					onMouseDown={(event) => {
						if (!props.disabled && event.button === 0) {
							event.stopPropagation();

							onDelete(value);
						}
					}}
				>
					<FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: 16 }} />
				</div>
			}
			{...props}
		/>
	);
}

const SelectInput = ({
	error,
	disabled,
	value,
	options,
	label,
	onChange,
	onBlur,
	placeholder = '',
	className,
	multipleWithChips,
	formikUpdateValue,
	...other
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const menuItemClasses = useMenuItemStyles();
	const [selectedValue, setSelectedValue] = useState(() => value);

	function handleChange(event) {
		setSelectedValue(event.target.value);

		if (onChange) {
			onChange(event);
		}
	}

	function handleBlur(event) {
		if (onBlur) {
			onBlur(event);
		}
	}

	function handleDelete(value) {
		const option = options.find(({ label }) => value === label);
		const result = selectedValue.filter((selected) => selected !== option.value);

		setSelectedValue(result);

		if (formikUpdateValue) {
			formikUpdateValue(result);
		}
	}

	useEffect(() => {
		setSelectedValue(value);
	}, [value]);

	return (
		<FormControl fullWidth variant="outlined" className={className} error={!!error}>
			{label && (
				<CustomInputLabel shrink htmlFor={other.name || other.id}>
					{t(label, { keySeparator: false, nsSeparator: false })}
				</CustomInputLabel>
			)}

			<Select
				value={selectedValue}
				onChange={handleChange}
				onBlur={handleBlur}
				classes={{
					selectMenu: classes.selectMenu
				}}
				input={
					<InputBase
						id={other.name || other.id}
						disabled={disabled}
						classes={{
							root: classes.inputRoot,
							input: classNames(classes.inputInput, {
								[classes.inputInputMulti]: multipleWithChips
							}),
							error: classes.error,
							disabled: classes.disabled
						}}
					/>
				}
				defaultValue={''}
				IconComponent={() => <FontAwesomeIcon icon={faCaretDown} className={classes.inputIcon} />}
				displayEmpty={true}
				renderValue={(selected) => {
					const isArraySelected = Array.isArray(selected);

					if (
						(!isArraySelected && selected === placeholder) ||
						(!isArraySelected && !`${selected}` && placeholder) ||
						(isArraySelected && !selected.length && placeholder) ||
						(isArraySelected && selected.length === 1 && selected[0] === placeholder)
					) {
						return <span className={classes.placeholder}>{placeholder}</span>;
					}

					if (other.multiple) {
						if (!isArraySelected) {
							return [];
						}

						const values = selected
							.map((item) => {
								const option = options.find(({ value }) => item === value);

								if (option) {
									return option.label;
								}
								return null;
							})
							.filter(Boolean);

						if (multipleWithChips) {
							return (
								<div className={classes.chips}>
									{values.map((value) => (
										<DeletableChip
											key={value}
											label={value}
											onDelete={() => handleDelete(value)}
											className={classes.chip}
											size="small"
											variant="outlined"
										/>
									))}
								</div>
							);
						} else {
							return values.join(', ');
						}
					}

					const option = options.find(({ value }) => value === selected);

					if (option) {
						return option.label;
					}

					return '';
				}}
				MenuProps={{
					PaperProps: {
						elevation: 3
					},
					MenuListProps: {
						disablePadding: true
					},
					getContentAnchorEl: null,
					marginThreshold: 12,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left'
					},
					classes: {
						paper: classes.paper
					}
				}}
				{...other}
			>
				{placeholder ? (
					<MenuItem value={placeholder} className={classes.hidden}>
						{placeholder}
					</MenuItem>
				) : null}

				{options.map((option) => (
					<MenuItem key={option.value} value={option.value} classes={menuItemClasses}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectInput;
