/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import BarInfo from './Info';
import BarNav from './Nav';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.headerBar.background,
		color: theme.palette.headerBar.color,
		paddingTop: '3px',
		paddingBottom: '3px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	skeletonRoot: {
		backgroundColor: theme.palette.headerBar.background
	},
	containerRoot: {
		lineHeight: 1
	}
}));

const HeaderBar = () => {
	const classes = useStyles();

	return (
		<Suspense fallback={<Skeleton variant="rect" height={38} className={`${classes.skeletonRoot} nav-bar-mini`} />}>
			<div className={`${classes.root} nav-bar-mini`}>
				<Container maxWidth={false} classes={{ root: classes.containerRoot }}>
					<Grid container alignItems="center">
						<Grid item xs>
							<BarInfo />
						</Grid>
						<Grid item>
							<BarNav />
						</Grid>
					</Grid>
				</Container>
			</div>
		</Suspense>
	);
};

export default HeaderBar;
