/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { get, CancelToken, isCancel } from 'axios';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import Button from '../../../../Inputs/Button';
import TextFieldCustom from '../../../../Inputs/Formik/TextField';
import { BaseView, BaseViewContent } from '../../Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import InformationViewTicketFlowStart from './Start';
import ChatHelpLink from '../../ChatHelpLink';

const useFormStyles = makeStyles((theme) => ({
	form: {
		marginTop: theme.spacing(4)
	},
	field: {
		marginTop: theme.spacing(2)
	},
	label: {
		display: 'block',
		marginBottom: theme.spacing(1)
	},
	button: {
		'marginTop': theme.spacing(1),
		'color': theme.palette.action.active,
		'padding': theme.spacing(1, 2, 1, 0),
		'&.Mui-disabled': {
			color: theme.palette.text.hint
		},
		'& svg': {
			width: theme.spacing(3)
		}
	},
	submit: {
		minWidth: 35,
		margin: theme.spacing(8, 0, 0, 1),
		padding: theme.spacing(2)
	},
	buttonSwitch: {
		'marginTop': theme.spacing(2),
		'paddingTop': theme.spacing(2),
		'& svg': {
			marginLeft: '5px',
			width: '0.6rem'
		}
	},
	chatHelp: {
		marginTop: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.chat.divider}`
	}
}));

const InformationViewTicketFlow = ({ handleSwap }) => {
	const { t } = useTranslation();
	const classes = useFormStyles();
	const cancelRequest = useRef(false);
	const [ticketId, setTicketId] = useState(null);

	const ValidationSchema = Yup.object().shape({
		ticketId: Yup.string()
			.trim()
			.matches(/^[0-9]*$/, t('common:chat.information.fields.ticketId.errors.format'))
			.required(t('common:chat.information.fields.ticketId.errors.required'))
	});

	const validateTicketId = (values, { setSubmitting, setFieldError }) => {
		if (cancelRequest.current) {
			// cancel any running AJAX requests
			cancelRequest.current('User is typing...');
		}

		get('/portal/chat/validate', {
			params: { ticketId: values.ticketId },
			cancelToken: new CancelToken(function executor(c) {
				cancelRequest.current = c;
			})
		})
			.then(() => {
				setTicketId(values.ticketId);
			})
			.catch((error) => {
				if (!isCancel(error)) {
					setSubmitting(false);
					setFieldError('ticketId', t('common:chat.information.fields.ticketId.errors.format'));
				}
			});
	};

	if (ticketId) {
		return (
			<InformationViewTicketFlowStart
				handleSwap={(value) => {
					setTicketId(null);
					handleSwap(value);
				}}
				ticketId={ticketId}
			/>
		);
	}

	return (
		<Formik
			onSubmit={validateTicketId}
			validationSchema={ValidationSchema}
			initialValues={{
				ticketId: ''
			}}
			initialErrors={{
				ticketId: ''
			}}
		>
			{({ submitForm, isSubmitting, isValid }) => (
				<BaseView className="sw-chat-view-info-ticket">
					<BaseViewContent>
						<Form autoComplete="off" className={classes.form}>
							<Grid container>
								<Grid item xs={8}>
									<Typography
										className={classes.label}
										component="label"
										variant="body2"
										htmlFor="ticketId"
									>
										<Typography component="span" variant="h5" display="block">
											{t('common:chat.information.fields.ticketId.label1')}
										</Typography>
										{t('common:chat.information.fields.ticketId.label2')}
									</Typography>
									<Field
										fullWidth
										name="ticketId"
										component={TextFieldCustom}
										helperText={t('common:chat.information.fields.ticketId.helperText')}
										inputProps={{ maxLength: 8 }}
									/>
								</Grid>
								<Grid item xs="auto">
									<Button
										variant="text"
										className={classNames(classes.button, classes.submit)}
										disabled={!isValid}
										isLoading={isSubmitting}
										loaderSize={15}
										onClick={submitForm}
									>
										<FontAwesomeIcon icon={faArrowRight} />
									</Button>
								</Grid>
							</Grid>
						</Form>
						<div className={classNames(classes.buttonSwitch)}>
							<Button
								size="small"
								variant="text"
								className={classNames(classes.button)}
								disabled={isSubmitting}
								onClick={() => {
									handleSwap(false);
								}}
							>
								{t('common:chat.buttons.chooseCategory')}
								<FontAwesomeIcon icon={faArrowRight} />
							</Button>
						</div>
						<div className={classNames(classes.chatHelp)}>
							<ChatHelpLink />
						</div>
					</BaseViewContent>
				</BaseView>
			)}
		</Formik>
	);
};

export default InformationViewTicketFlow;
