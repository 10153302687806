/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

const PublicHeader = () => {
	const { t, ready } = useTranslation('common', { useSuspense: false });

	return (
		<header className="sw-header public" role="banner">
			<img
				className="sw-brand public"
				alt={ready ? t('legal.brand') : null}
				role="presentation"
				src="/portal/static/img/brand/secureworks-logo-black.svg"
			/>
		</header>
	);
};

export default PublicHeader;
