/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	pivotTableMessage: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: 13,
		minHeight: 100
	},
	icon: {
		marginLeft: theme.spacing(1)
	}
}));

export const PivotTableMessage = ({ message, isLoading, numberOfColumns = 1 }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<tr>
			<td colSpan={numberOfColumns}>
				<Box className={classes.pivotTableMessage} margin={2}>
					<span>{t(message)}</span>
					{isLoading ? <FontAwesomeIcon icon={faSpinner} spin className={classes.icon} /> : null}
				</Box>
			</td>
		</tr>
	);
};
