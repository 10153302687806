/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';

const Main = ({ children }) => (
	<main className="sw-content public" role="main">
		{children}
	</main>
);

export default Main;
