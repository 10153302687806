/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useCallback, useState } from 'react';
import { get } from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { hideModal } from '../../../state/actions/components/modals';

import Button from '../../../components/Inputs/Button';
import Autocomplete from '../../../components/Inputs/Autocomplete';
import LoaderSkeleton from '../../../components/Loaders/Skeleton';

const GET_STATUS_API = '/portal/intel/health-status';

const GET_DATA_OPTIONS = {
	url: '/portal/intel/get-device-details',
	preFetch: true
};

const getOptionLabel = (option) => option.internalDeviceName;

const LogRetentionModal = ({ isOpen }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const [selected, setSelected] = useState(null);

	const isLoading = selected && selected.isLoading;

	function handleClose() {
		dispatch(
			hideModal({
				name: 'LOG_RETENTION'
			})
		);
	}

	function handleSubmit() {
		let openUrl;

		if (!selected || (selected && selected.status !== 'UP')) {
			return;
		}

		const devIp = selected.devip;
		const consoleUrl = selected.consoleUrl;

		if (consoleUrl) {
			openUrl = consoleUrl.replace('http://', 'https://');
		} else if (devIp) {
			openUrl = `https://${devIp.trim()}`;
		}

		if (openUrl) {
			window.open(openUrl, '', 'noreferrer,noopener');
		}
	}

	const handleOptionSelect = useCallback((event, value) => {
		const deviceHref = value && value.deviceHref;

		setSelected(
			deviceHref
				? {
						isError: false,
						isLoading: true
				  }
				: null
		);

		if (deviceHref) {
			get(GET_STATUS_API, {
				params: { deviceHref }
			})
				.then((resp) =>
					setSelected({
						...value,
						status: resp.data.status || 'UNAVAILABLE',
						isError: false,
						isLoading: false
					})
				)
				.catch((err) =>
					setSelected({
						...value,
						isError: err,
						isLoading: false
					})
				);
		}
	}, []);

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="log-retention-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle id="log-retention-modal" disableTypography>
				<Typography component="h2" variant="h3">
					{t('modals.logRetention.title')}
				</Typography>
			</DialogTitle>
			<DialogContent dividers>
				<Autocomplete
					id="log-retention"
					label={t('modals.logRetention.description')}
					placeholder={t('buttons.select')}
					options={GET_DATA_OPTIONS}
					getOptionLabel={getOptionLabel}
					disabled={isLoading}
					disablePortal={false}
					callback={handleOptionSelect}
				/>
				{selected && !selected.isError ? (
					<Box mt={2}>
						{selected.isLoading ? (
							<LoaderSkeleton height={22} />
						) : (
							<Typography component="p">
								{t('modals.logRetention.status', {
									name: selected.internalDeviceName,
									status: selected.status
								})}
								{selected.status !== 'UP' ? (
									<Box ml={1} display="inline-block" component="span">
										<Tooltip title={t('modals.logRetention.tooltip')} placement="top">
											<span>
												<FontAwesomeIcon icon={faInfoCircle} />
											</span>
										</Tooltip>
									</Box>
								) : null}
							</Typography>
						)}
					</Box>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="default" disabled={isLoading}>
					{t('buttons.close')}
				</Button>
				<Button
					onClick={handleSubmit}
					disabled={!selected || (selected && selected.status !== 'UP')}
					isLoading={isLoading}
				>
					{t('buttons.submit')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LogRetentionModal;
