/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const TOGGLE_SMALL_NAV = 'TOGGLE_SMALL_NAV';

export const toggleNav = ({ isOpen }) => ({
	type: TOGGLE_NAV,
	payload: {
		isOpen
	}
});

export const toggleSmallNav = ({ isSmall }) => ({
	type: TOGGLE_SMALL_NAV,
	payload: {
		isSmall
	}
});
