/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		'color': theme.palette.headerNavResp.secondary.level2.link,
		'backgroundColor': theme.palette.headerNavResp.secondary.level2.background,
		'borderBottomColor': theme.palette.headerNavResp.secondary.level2.border,
		'padding': theme.spacing(2.4),
		'fontSize': theme.typography.body2.fontSize,
		'& + $title': {
			paddingTop: 0
		},
		'& + li:not($title)': {
			borderTop: `1px solid ${theme.palette.headerNavResp.secondary.level2.border}`
		}
	}
}));

const Title = ({ title }) => {
	const classes = useStyles();
	const titles = Array.isArray(title) ? title : [title];
	const { t } = useTranslation('common');

	return titles.map((entry) => (
		<li key={entry} className={classes.title}>
			{t(entry)}
		</li>
	));
};

export default Title;
