/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setProgress } from '../../../../../state/actions/components/reports';
import { REPORT_CONSTANTS } from '../../../../../pages/Reports/Executed';

const useStyles = makeStyles((theme) => ({
	listItem: {
		'color': theme.palette.sidebar.link,
		'margin': theme.spacing(0.5, 0),
		'padding': theme.spacing(1.5, 3),
		'&:hover': {
			background: theme.palette.sidebar.linkBackgroundHover,
			color: theme.palette.sidebar.linkHover
		},
		'&.Mui-selected, &.Mui-selected:hover': {
			'background': theme.palette.sidebar.linkBackgroundActive,
			'color': theme.palette.sidebar.linkActive,
			'font-weight': 'bold'
		}
	},
	listItemText: {
		margin: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	listItemTextType: {
		fontWeight: 'inherit'
	},
	subMenu: {
		'background': `${theme.palette.sidebar.linkBackgroundHover}!important`,
		'margin-top': -theme.spacing(0.5),
		'padding': theme.spacing(1.5, 2, 1.5, 5.25),
		'&.Mui-selected, &:hover': {
			'background': `${theme.palette.sidebar.linkBackgroundHover}!important`,
			'font-weight': 'normal',
			'fontSize': 16,
			'& $icon': {
				display: 'block'
			}
		},
		'&.Mui-selected, &.Mui-selected:hover': {
			'color': `${theme.palette.sidebar.subMenu.active}`,
			'cursor': 'default',
			'font-weight': 'normal',
			'textDecoration': 'none'
		}
	},
	icon: {
		color: theme.palette.sidebar.linkHover,
		display: 'none',
		fontSize: '14px',
		margin: theme.spacing(-0.25, 0, 0, -2.25),
		position: 'absolute'
	}
}));

const EXECUTED_REPORTS_NRS_PATH = '/portal/reports/executed';

const SidebarOption = ({
	children,
	name,
	description,
	path,
	isActive,
	isSubMenu = false,
	skipNameTranslation = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleOnNRSExecutedReportsClick = () => {
		dispatch(
			setProgress({
				progress: isSubMenu ? REPORT_CONSTANTS.VIEWPAGE : REPORT_CONSTANTS.EXECUTED
			})
		);
	};

	const otherOptions = {};

	if (path === EXECUTED_REPORTS_NRS_PATH && window.location.href.includes(EXECUTED_REPORTS_NRS_PATH)) {
		otherOptions.button = true;
		otherOptions.onClick = handleOnNRSExecutedReportsClick;
	} else {
		otherOptions.component = 'a';
		otherOptions.href = path;
	}

	return (
		<>
			<ListItem
				selected={isActive}
				classes={{
					root: classNames(classes.listItem, { [classes.subMenu]: isSubMenu })
				}}
				{...otherOptions}
			>
				{isSubMenu && <FontAwesomeIcon icon={faAngleRight} className={classes.icon} />}

				<ListItemText
					primary={skipNameTranslation ? name : t(name)}
					secondary={t(description)}
					classes={{
						root: classes.listItemText
					}}
					primaryTypographyProps={{
						classes: {
							body1: classes.listItemTextType
						}
					}}
				/>
			</ListItem>

			{children ? children : null}
		</>
	);
};

export default SidebarOption;
