/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ScrollToTop from '../ScrollToTop';

const useStyles = makeStyles((theme) => ({
	main: {
		flex: '1 0 auto',
		minHeight: 500,
		transform: 'translateX(0)',
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	mainShift: {
		transform: 'translateX(-80%)',
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	}
}));

const Main = ({ children }) => {
	const classes = useStyles();
	const isMenuOpen = useSelector((state) => state.nav.isOpen);

	return (
		<>
			<main
				className={classNames('sw-content', 'private', classes.main, {
					[classes.mainShift]: isMenuOpen
				})}
				role="main"
			>
				{children}
			</main>
			<ScrollToTop />
		</>
	);
};

export default Main;
