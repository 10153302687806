/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { SET_SEARCH_DATA, SET_SEARCH_PROGRESS, SET_SEARCH_QUERY } from '../../actions/components/search';

const initalState = {
	data: null,
	query: '',
	isError: false,
	isLoading: false
};

export default function search(state = initalState, action) {
	switch (action.type) {
		case SET_SEARCH_DATA:
			return {
				...state,
				data: action.payload.data
			};
		case SET_SEARCH_PROGRESS:
			return {
				...state,
				isError: action.payload.isError,
				isLoading: action.payload.isLoading
			};
		case SET_SEARCH_QUERY:
			return {
				...state,
				query: action.payload.query
			};
		default:
			return state;
	}
}
