/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TableHeaderMenus from '../../../../components/PivotDataTable/TableHeader/HeaderMenus';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
	pivotTableHeader: {
		marginTop: theme.spacing(4)
	},
	pivotTableSubHeader: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		fontSize: 15
	},
	heading: {
		textAlign: 'center',
		fontSize: '18px',
		lineHeight: '22px',
		color: theme.palette.reports.sectionHeader.color
	},
	subHeading: {
		textAlign: 'center',
		paddingLeft: theme.spacing(4),
		fontSize: '15px',
		lineHeight: '22px',
		color: theme.palette.reports.sectionHeader.color
	},
	mlAuto: {
		marginLeft: 'auto'
	},
	menu: {
		margin: 0,
		padding: 0,
		listStyle: 'none'
	},
	downloadIcon: {
		color: theme.palette.reports.sectionHeader.color,
		fontWeight: 900,
		fontSize: '18px'
	}
}));

const PivotTableHeader = ({ headingText, subHeadingText, menus }) => {
	const classes = useStyles();
	return (
		<Grid item xs={12}>
			<Grid container direction="column" className={classNames(classes.pivotTableHeader)}>
				<div className={classNames(classes.heading)}>{headingText}</div>
			</Grid>
			<Grid container direction="row" className={classNames(classes.pivotTableSubHeader)}>
				<Box display="flex" flexGrow={1}>
					<Grid container direction="column">
						<div className={classNames(classes.subHeading)}>{subHeadingText}</div>
					</Grid>
				</Box>
				<Box display="flex" flexDirection="row">
					<Box display="flex" mx={1}>
						<TableHeaderMenus menus={menus} viewType={'advanced'} placement={'bottom-end'}>
							<FontAwesomeIcon icon={faDownload} className={classes.downloadIcon}></FontAwesomeIcon>
						</TableHeaderMenus>
					</Box>
				</Box>
				<div className={classes.mlAuto}></div>
			</Grid>
		</Grid>
	);
};
export default PivotTableHeader;
