/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import routeList from '../../../../../routes/bootstrap';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0, 0, 0, 1),
		display: 'flex',
		alignItems: 'center',
		width: 180,
		background: theme.palette.headerBar.input
	},
	rootLarge: {
		padding: theme.spacing(1, 1, 1, 2),
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		background: theme.palette.headerBar.input
	},
	closed: {
		background: 'transparent',
		color: theme.palette.headerBar.link
	},
	input: {
		flex: 1,
		fontSize: 12,
		lineHeight: 1
	},
	inputLarge: {
		flex: 1,
		fontSize: 15,
		lineHeight: 1
	},
	inputRoot: {
		padding: 0,
		appearance: 'none',
		color: theme.palette.headerBar.color
	},
	buttonOpen: {
		fontSize: 13,
		padding: 5,
		color: theme.palette.headerBar.icon
	},
	buttonClosed: {
		fontSize: 13,
		padding: 5,
		marginLeft: -5,
		color: theme.palette.headerBar.link
	}
}));

const actionUri = routeList.search.results.path;

const useSearchQuery = () => {
	const { pathname, search } = useLocation();
	const params = new URLSearchParams(search);
	const query = params.get('query') || '';

	return pathname === actionUri ? query : '';
};

const BarSearch = ({ keepOpen = false, size = 'small' }) => {
	const classes = useStyles();
	const { t } = useTranslation('common');
	const searchQueryValue = useSearchQuery();

	const [isOpen, setIsOpen] = useState(keepOpen || searchQueryValue);

	return (
		<Paper
			action={actionUri}
			component="form"
			// "search-form" class name is used by e2e automation tests
			className={classNames('search-form', {
				[classes.root]: isOpen && size === 'small',
				[classes.rootLarge]: isOpen && size !== 'small',
				[classes.closed]: !isOpen,
				closed: !isOpen
			})}
			elevation={0}
		>
			{isOpen ? (
				<InputBase
					required
					name="query"
					type="search"
					placeholder={t('labels.search')}
					autoComplete="off"
					inputProps={{
						'aria-label': t('labels.search')
					}}
					defaultValue={searchQueryValue}
					classes={{
						root: size === 'small' ? classes.input : classes.inputLarge,
						// "search-box" class name is used by e2e automation tests
						input: classNames('search-box', classes.inputRoot)
					}}
				/>
			) : null}
			<IconButton
				size="small"
				type="submit"
				aria-label={t('labels.search')}
				// "btn", and "search-toggle" class names are used by e2e automation tests
				className={classNames({
					'btn': isOpen,
					'search-toggle': !isOpen,
					[classes.buttonOpen]: isOpen,
					[classes.buttonClosed]: !isOpen
				})}
				onClick={(event) => {
					if (!isOpen) {
						event.preventDefault();

						return setIsOpen(true);
					}
				}}
			>
				<FontAwesomeIcon icon={faSearch} />
			</IconButton>
		</Paper>
	);
};

export default BarSearch;
