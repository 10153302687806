/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

// action type definitions
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_TABS = 'SET_TABS';

// action creator to toggle the tabs
export function setActiveTab({ id, active }) {
	return {
		type: SET_ACTIVE_TAB,
		payload: {
			id,
			active
		}
	};
}

export function setTabs({ id, active, options }) {
	return {
		type: SET_TABS,
		payload: {
			id,
			active,
			options
		}
	};
}
