/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import {
	ADD_PIVOT_DATA_TABLE,
	UPDATE_PIVOT_DATA_TABLE_COLUMNS,
	UPDATE_PIVOT_DATA_TABLE_DATA,
	UPDATE_PIVOT_DATA_TABLE_HEADER_MODEL,
	UPDATE_PIVOT_DATA_TABLE_PARAMS,
	UPDATE_PIVOT_DATA_TABLE_PROGRESS,
	UPDATE_PIVOT_DATA_TABLE_SETTINGS,
	UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_MSG_OPTIONS,
	UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_PARAMS
} from '../../actions/components/pivotdatatables';

export default function datatableReducer(state = {}, action) {
	switch (action.type) {
		case ADD_PIVOT_DATA_TABLE:
			return {
				...state,
				...action.payload
			};
		case UPDATE_PIVOT_DATA_TABLE_DATA:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					data: action.payload.data
				}
			};
		case UPDATE_PIVOT_DATA_TABLE_COLUMNS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					columns: action.payload.columns
				}
			};
		case UPDATE_PIVOT_DATA_TABLE_PARAMS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					params: {
						...state[action.payload.name].params,
						...action.payload.params
					},
					drillDownParams: {}
				}
			};
		case UPDATE_PIVOT_DATA_TABLE_PROGRESS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					isError: action.payload.isError,
					isLoading: action.payload.isLoading
				}
			};
		case UPDATE_PIVOT_DATA_TABLE_SETTINGS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					settings: {
						...state[action.payload.name].settings,
						...action.payload.settings
					}
				}
			};
		case UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_PARAMS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					drillDownParams: {
						...state[action.payload.name]?.drillDownParams,
						...action.payload?.drillDownParams
					}
				}
			};
		case UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_MSG_OPTIONS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					msgOptions: {
						...state[action.payload.name]?.msgOptions,
						...action.payload?.msgOptions
					}
				}
			};
		case UPDATE_PIVOT_DATA_TABLE_HEADER_MODEL:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					tableHeaderModel: {
						...state[action.payload.name].tableHeaderModel,
						...action.payload.tableHeaderModel
					}
				}
			};
		default:
			return state;
	}
}
