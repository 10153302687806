/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_PROGRESS } from '../../actions/app/auth';

const initialState = {
	request: null,
	isAuthenticated: false,
	isEngineer: false,
	isLoggedIn: false,
	isMultiClient: false,
	isFFIEC: false,
	isError: false,
	isLoading: true
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case AUTH_LOGOUT_SUCCESS:
			return {
				...state,
				isAuthenticated: action.payload.isAuthenticated
			};
		case AUTH_LOGOUT_PROGRESS:
			return {
				...state,
				isError: action.payload.isError,
				isLoading: action.payload.isLoading
			};
		default:
			return state;
	}
}
