/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseView, BaseViewContent, BaseViewFooter } from '../Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../Inputs/Button';
import { reset, createSession } from '../../../../state/actions/components/chat';

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.error.main
	}
}));

const ErrorView = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setIsLoading(true);
		dispatch(reset({ isOpen: !window.isStandaloneChat }));
		dispatch(createSession());
	};

	return (
		<BaseView className="sw-chat-view-error">
			<BaseViewContent>
				<Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
					<Box textAlign="center">
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							className={classes.icon + ' chat-error-icon'}
							size="3x"
						/>
						<Box mt={3}>
							<Typography variant="h3" paragraph>
								{t('common:chat.error.connecting')}
							</Typography>
							<Typography>{t('common:chat.error.tryAgain')}</Typography>
						</Box>
					</Box>
				</Box>
			</BaseViewContent>
			<BaseViewFooter>
				<Button fullWidth onClick={handleClick} isLoading={isLoading}>
					{t('common:chat.buttons.startOver')}
				</Button>
			</BaseViewFooter>
		</BaseView>
	);
};

export default ErrorView;
