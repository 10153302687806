/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
export const ADD_ACCESSED_PROFILES = 'ADD_ACCESSED_PROFILES';

export function addAccessedProfile({ contactId, clientId, deviceIds }) {
	return {
		type: ADD_ACCESSED_PROFILES,
		payload: {
			contactId: contactId,
			clientId: clientId,
			deviceIds: deviceIds
		}
	};
}
