/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DataTable from '../../../components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '../../../components/Inputs/Button';
import { faCaretDown, faCircle, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { showModal } from '../../../state/actions/components/modals';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Box, MenuItem, Popper, Typography } from '@material-ui/core';
import { datetime } from 'react-table/src/sortTypes';
import Tooltip from '@material-ui/core/Tooltip';
import CustomCheckbox from '../../../components/Inputs/Checkbox';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import axios from 'axios';
import { fetchDataTableData } from '../../../state/actions/components/datatables';
import { getDateTime } from '../../../pages/Reports/View/utils';
import { DateTime } from 'luxon';

const REPORT_EXECUTION_SUCCESS = 'SUCCESS';

const GET_EXECUTED_REPORTS_URL = '/portal/reports/executedReports';
const CREATE_TEMPLATE_URL = '/portal/reports/createTemplateFromExecutedReports';
const EXECUTED_REPORT_URL = '/portal/reports/executedReport';

const useStyles = makeStyles((theme) => ({
	link: {
		'color': theme.palette.link.color,
		'&:hover': {
			color: theme.palette.link.colorHover
		},
		'transition': theme.transitions.create('color')
	},
	caretDown: {
		marginLeft: '3px'
	},
	menuItem: {
		'&:hover': {
			color: '#fff',
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		},
		'color': theme.palette.dropdownItem.color,
		'fontSize': '13px',
		'paddingTop': '4px',
		'paddingBottom': '5px',
		'paddingLeft': '20px',
		'paddingRight': '35px'
	},
	optionsButton: {
		'&:hover': {
			backgroundColor: theme.palette.reports.button.hoverBackgroundColor
		},
		'paddingTop': '4px',
		'paddingBottom': '4px',
		'paddingLeft': '12px',
		'paddingRight': '12px',
		'backgroundColor': theme.palette.reports.button.backgroundColor
	},
	viewedCircle: {
		marginRight: '5px',
		color: theme.palette.common.blue,
		fontSize: '14px'
	},
	noteStrong: {
		fontWeight: 700,
		fontSize: '85%'
	},
	checkBox: {
		paddingLeft: 8,
		paddingRight: 8,
		paddingTop: 10,
		paddingBottom: 12
	},
	requestAcknowledgement: {
		marginTop: -20,
		display: 'flex',
		justifyContent: 'flex-end'
	},
	reportAck: {
		display: 'flex',
		marginTop: 5,
		justifyContent: 'flex-end'
	},
	requestAckTitle: {
		paddingLeft: 5
	},
	menuList: {
		border: `1px solid ${theme.palette.widgetDrillDown.menu.border}`,
		paddingTop: 2,
		paddingBottom: 2,
		marginTop: theme.spacing(1),
		borderRadius: 6
	},
	spinner: {
		marginLeft: theme.spacing(1)
	},
	infoDiv: {
		textAlign: 'center',
		padding: 10
	},
	emptyDiv: {
		padding: 17
	},
	infoIcon: {
		'cursor': 'pointer',
		'color': theme.palette.reports.infoIcon.color,
		'&:hover': {
			color: theme.palette.action.hover
		}
	},
	sharedInfo: {
		display: 'inline-block'
	},
	time: {
		marginTop: 20,
		display: 'block'
	}
}));

const CustomTooltip = withStyles((theme) => ({
	arrow: {
		color: theme.palette.reports.tooltip.backgroundColor
	},
	tooltip: {
		fontSize: 12,
		color: theme.palette.reports.tooltip.color,
		backgroundColor: theme.palette.reports.tooltip.backgroundColor
	}
}))(Tooltip);

const getFormattedTime = (time) => {
	return getDateTime(time).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
};

const AdministerOptionsDropDown = ({ row, dataCallbackFn }) => {
	const classes = useStyles();
	const { t } = useTranslation('reports');
	const [menuState, setMenuState] = useState({
		isOpen: false,
		anchorEl: null
	});
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const appData = window.APP_DATA || {};
	const isEngineer = appData?.auth?.isEngineer;

	function handleClose() {
		setMenuState({
			isOpen: false,
			anchorEl: null
		});
	}

	const handleClick = async (event) => {
		const element = event.currentTarget;
		await setMenuState({
			isOpen: !menuState.isOpen,
			anchorEl: element
		});
	};

	const dropDownOptions = [
		t('reports:executedReports.buttons.dropDown.addRecipients'),
		t('reports:executedReports.buttons.dropDown.createTemplate'),
		t('reports:executedReports.buttons.dropDown.viewParameters'),
		t('reports:executedReports.buttons.dropDown.acknowledge')
	];

	const onAddRecipients = useCallback(() => {
		dispatch(
			showModal({
				name: 'ADD_RECIPIENTS_MODAL',
				data: { row, dataCallbackFn }
			})
		);
		handleClose();
	}, [dispatch, row, dataCallbackFn]);

	const onViewParameters = useCallback(() => {
		dispatch(
			showModal({
				name: 'VIEW_PARAMETERS_MODAL',
				data: { row }
			})
		);
		handleClose();
	}, [dispatch, row]);

	const onCreateTemplate = useCallback(() => {
		setLoading(true);
		axios
			.get(CREATE_TEMPLATE_URL, {
				params: {
					jobId: row.original.reportId,
					reportType: row.original.reportType,
					reportName: row.original.reportName,
					source: 'NRS'
				}
			})
			.then(() => {
				setLoading(false);
				dataCallbackFn();
			})
			.catch(() => {
				dataCallbackFn();
			});
		handleClose();
	}, [row, dataCallbackFn]);

	const handleAckClick = useCallback(() => {
		const data = {
			submitFn: (rows, modalUtils) => {
				modalUtils.setLoading(true);
				axios(EXECUTED_REPORT_URL, {
					method: 'PUT',
					data: {
						acknowledged: true
					},
					params: {
						reportIds: row.original.reportId,
						reportName: row.original.reportName
					}
				})
					.then(() => {
						modalUtils.setLoading(false);
						dispatch(
							fetchDataTableData({
								name: 'EXECUTED_REPORTS',
								action: 'MODIFY',
								dataCallbackFn: dataCallbackFn
							})
						);
					})
					.catch(() => {
						modalUtils.setLoading(false);
						dataCallbackFn();
					});
				modalUtils.handleClose();
			},
			title: t('reports:modals.ackModal.title'),
			body: (
				<Trans i18nKey="reports:modals.executedAckModalBody">
					<b />
				</Trans>
			),
			disableSubmitButton: isEngineer,
			cancelButton: t('reports:modals.ackModal.buttons.ackLater'),
			submitButton: t('reports:modals.ackModal.buttons.ackNow')
		};
		dispatch(showModal({ name: 'REPORTS_GENERIC_MODAL', data: data }));
		handleClose();
	}, [row, dispatch, t, isEngineer, dataCallbackFn]);

	return (
		<div>
			<Button
				id="demo-customized-button"
				aria-controls={menuState.isOpen ? 'demo-customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={menuState.isOpen ? 'true' : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
				endIcon={<FontAwesomeIcon icon={faCaretDown} />}
				className={classes.optionsButton}
			>
				{t('reports:executedReports.buttons.options')}
			</Button>
			{isLoading && <FontAwesomeIcon icon={faSpinner} spin={true} className={classes.spinner} />}
			<Popper
				open={menuState.isOpen}
				anchorEl={menuState.anchorEl}
				role={undefined}
				transition
				placement={'bottom-start'}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}
					>
						<Paper>
							<ClickAwayListener
								onClickAway={() => {
									handleClose();
								}}
							>
								<MenuList className={classes.menuList}>
									{row.original.reportState === REPORT_EXECUTION_SUCCESS && !row.original.isShared && (
										<MenuItem disableRipple className={classes.menuItem} onClick={onAddRecipients}>
											{dropDownOptions[0]}
										</MenuItem>
									)}
									<MenuItem disableRipple className={classes.menuItem} onClick={onCreateTemplate}>
										{dropDownOptions[1]}
									</MenuItem>
									<MenuItem disableRipple className={classes.menuItem} onClick={onViewParameters}>
										{dropDownOptions[2]}
									</MenuItem>
									{row.original.ackRequired &&
										row.original.acknowledged === false &&
										row.original.isShared && (
											<MenuItem
												disableRipple
												className={classes.menuItem}
												onClick={handleAckClick}
												disabled={isEngineer}
											>
												{dropDownOptions[3]}
											</MenuItem>
										)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

const ExecutedReportsDataTable = ({ title, handleSelectSingleRowClick, dataCallbackFn }) => {
	const { t } = useTranslation('reports');
	const classes = useStyles();
	const tableState = useSelector((state) => state.datatables.EXECUTED_REPORTS);
	const [filterByAcks, setFilterByAcks] = useState({
		requested: tableState?.params?.ackRequested || false,
		outstanding: tableState?.params?.ackOutstanding || false
	});
	const dispatch = useDispatch();

	const handleReceivedFromClick = useCallback(
		(ownerContactName, sharedTime) => {
			const modal = 'receivedFromModal';
			const dateTime = getFormattedTime(sharedTime);
			const modalData = {
				title: t(`reports:modals.${modal}.title`),
				body: (
					<>
						<Typography component={'span'}>
							{t(`reports:modals.${modal}.body`, { contactName: ownerContactName })}
						</Typography>
						<span>{dateTime}</span>
					</>
				),
				cancelButton: t(`reports:modals.${modal}.buttons.cancel`)
			};

			dispatch(showModal({ name: 'REPORTS_GENERIC_MODAL', data: modalData }));
		},
		[t, dispatch]
	);

	const handleRecipientsClick = useCallback(
		(sharingInfoModels) => {
			const modal = 'recipientsModal';
			let sharedTimeContactsMap = {};
			sharingInfoModels?.map((sharingInfoModel) => {
				const sharedTime = sharingInfoModel.sharedTime;
				const value = sharingInfoModel.isInternalUser
					? sharingInfoModel.contactName
					: sharingInfoModel.emailAddress;
				if (sharedTimeContactsMap[sharedTime] !== undefined) {
					sharedTimeContactsMap[sharedTime].push(value);
				} else {
					sharedTimeContactsMap[sharedTime] = [value];
				}
			});
			const modalData = {
				title: t(`reports:modals.${modal}.title`),
				body: (
					<>
						<Typography component={'span'}>{t(`reports:modals.${modal}.body`)}</Typography>
						{Object.keys(sharedTimeContactsMap)?.map((key) => {
							const sharedTime = getFormattedTime(parseInt(key, 10));
							return (
								<span key={key} className={classes.time}>
									<Typography component={'span'}>
										<b>{sharedTime}</b>
									</Typography>
									<br />
									{sharedTimeContactsMap[key]?.map((contactName, i) => {
										return (
											<span key={contactName} className={classes.sharedInfo}>
												{i === sharedTimeContactsMap[key].length - 1
													? contactName
													: contactName + ' ,'}
											</span>
										);
									})}
								</span>
							);
						})}
					</>
				),
				cancelButton: t(`reports:modals.${modal}.buttons.cancel`)
			};
			dispatch(showModal({ name: 'REPORTS_GENERIC_MODAL', data: modalData }));
		},
		[t, dispatch, classes]
	);

	const getSharedContacts = useCallback(
		(sharingInfoModels) => {
			const contactInfo = sharingInfoModels[0];
			return (
				(contactInfo.isInternalUser ? contactInfo.contactName : contactInfo.emailAddress) +
				(sharingInfoModels.length === 1
					? ''
					: ` + ${sharingInfoModels.length - 1 + ' ' + t('reports:executedReports.more')} `)
			);
		},
		[t]
	);

	const columns = useMemo(
		() => [
			{
				id: 'reportName',
				headerTitle: t('reports:executedReports.columns.reportName'),
				accessor: 'reportName',
				width: '30%',
				maxWidth: '400px',
				Cell({ row, value }) {
					return (
						<div>
							{!row.original.isViewed && row.original.reportState === REPORT_EXECUTION_SUCCESS && (
								<FontAwesomeIcon icon={faCircle} className={classes.viewedCircle} />
							)}
							{value}
						</div>
					);
				},
				disableSortBy: false
			},
			{
				id: 'received-from',
				headerTitle: t('reports:executedReports.columns.receivedFrom'),
				accessor: () => {
					return ' ';
				},
				Cell({ row }) {
					return (
						<div className={row.original.isShared ? classes.infoDiv : classes.emptyDiv}>
							{row.original.isShared && (
								<CustomTooltip arrow={true} title={row.original.ownerContactName || ''} placement="top">
									<span>
										<FontAwesomeIcon
											className={classes.infoIcon}
											onClick={() =>
												handleReceivedFromClick(
													row.original.ownerContactName,
													row.original.sharedTime
												)
											}
											icon={faInfoCircle}
										/>
									</span>
								</CustomTooltip>
							)}
						</div>
					);
				},
				width: '5%',
				stopPropagationField: true,
				disableSortBy: true
			},
			{
				id: 'recipients',
				headerTitle: t('reports:executedReports.columns.recipients'),
				accessor: '',
				width: '5%',
				stopPropagationField: true,
				Cell({ row }) {
					return (
						<div
							className={row.original.sharingInfoModels?.length > 0 ? classes.infoDiv : classes.emptyDiv}
						>
							{row.original.sharingInfoModels?.length > 0 && (
								<CustomTooltip
									arrow={true}
									title={getSharedContacts(row.original.sharingInfoModels) || ''}
									placement="top"
								>
									<span>
										<FontAwesomeIcon
											className={classes.infoIcon}
											onClick={() => handleRecipientsClick(row.original.sharingInfoModels)}
											icon={faInfoCircle}
										/>
									</span>
								</CustomTooltip>
							)}
						</div>
					);
				},
				disableSortBy: true
			},
			{
				id: 'reportType',
				headerTitle: t('reports:executedReports.columns.reportType'),
				width: '18%',
				accessor: (data) => {
					if (!data) {
						return null;
					}
					return data.reportType && t('reportsType.displayName.' + data.reportType);
				},
				disableSortBy: false
			},
			{
				id: 'reportState',
				headerTitle: t('executedReports.columns.status'),
				width: '13%',
				accessor: (data) => {
					if (!data) {
						return null;
					}
					return data.reportState && t('status.' + data.reportState);
				},
				disableSortBy: false
			},
			{
				id: 'reportTime',
				headerTitle: t('executedReports.columns.date'),
				width: '18%',
				accessor: (data) => {
					if (!data) {
						return null;
					}
					return data.reportTime;
				},
				Cell({ value }) {
					return getFormattedTime(value);
				},
				sortType: datetime,
				disableSortBy: false
			},
			{
				id: 'Administer',
				headerTitle: t('executedReports.columns.administer'),
				accessor: '',
				width: '12%',
				minWidth: 150,
				disableSortBy: true,
				stopPropagationField: true,
				Cell({ row }) {
					return <AdministerOptionsDropDown row={row} dataCallbackFn={dataCallbackFn} />;
				}
			}
		],
		[t, classes, handleRecipientsClick, handleReceivedFromClick, getSharedContacts, dataCallbackFn]
	);

	const options = useMemo(
		() => ({
			url: GET_EXECUTED_REPORTS_URL,
			params: {
				...tableState?.params,
				limit: 50,
				offset: 0,
				includeLastHourOnly: false,
				ackRequested: filterByAcks.requested,
				ackOutstanding: filterByAcks.outstanding
			}
		}),
		//eslint-disable-next-line
		[filterByAcks]
	);

	const additionalTableOptions = useMemo(
		() => ({
			autoResetSelectedRows: false,
			manualSortBy: true,
			initialStateSortBy: [
				{
					id: tableState?.params?.sortField || 'reportTime',
					desc: tableState?.params?.sortOrder !== 'ASC'
				}
			],
			disableSortRemove: true,
			sortByOptions: { title: t('reports:executedReports.columnTooltip') }
		}),
		//eslint-disable-next-line
		[]
	);

	/**
	 * List of additional controls for the ActionBar.
	 * Requires:
	 * Event handler - Any.
	 * controlType - currently 'button' or 'checkbox' - extend in ActionBar
	 */

	const infiniteScrollOptions = useMemo(
		() => ({
			params: {
				limit: 50
			}
		}),
		[]
	);

	const additionalActionBarItems = useMemo(() => {
		return (
			<div className={classes.reportAck}>
				<div>
					<CustomTooltip
						arrow={true}
						title={
							<Trans i18nKey="reports:ackFilter.toolTipInfo">
								<br />
							</Trans>
						}
						placement="top"
					>
						<span>
							<FontAwesomeIcon className={classes.infoIcon} icon={faInfoCircle} />
						</span>
					</CustomTooltip>
					<span className={classes.requestAckTitle}>
						{t('common:pages.reports.acknowledge.title') + ': '}{' '}
					</span>
					<CustomCheckbox
						size="small"
						className={classes.checkBox}
						checked={filterByAcks.requested}
						onChange={(evt) => {
							setFilterByAcks({ ...filterByAcks, requested: evt.target.checked });
						}}
					/>
					<span>{t('executedReports.acknowledgement.requested')} </span>
					<CustomCheckbox
						size="small"
						className={classes.checkBox}
						checked={filterByAcks.outstanding}
						onChange={(evt) => {
							setFilterByAcks({ ...filterByAcks, outstanding: evt.target.checked });
						}}
					/>
					<span>{t('executedReports.acknowledgement.outstanding')} </span>
				</div>
			</div>
		);
	}, [classes, filterByAcks, t]);

	const getRowId = useCallback((row, relativeIndex) => {
		return row.reportId ? row.reportId : relativeIndex;
	}, []);

	const requestAckRows = (rows, toggleAllRowsSelected, modalUtils) => {
		const reportIds = rows.map((row) => {
			return row.reportId;
		});
		modalUtils.setLoading(true);
		axios(EXECUTED_REPORT_URL, {
			method: 'PUT',
			data: {
				ackRequired: true
			},
			params: {
				reportIds: reportIds.join(',')
			}
		})
			.then(() => {
				modalUtils.setLoading(false);
				modalUtils.handleClose();
				toggleAllRowsSelected(false);
				dataCallbackFn();
			})
			.catch(() => {
				modalUtils.setLoading(false);
				dataCallbackFn();
				modalUtils.handleClose();
			});
	};

	const deleteRows = (rows, toggleAllRowsSelected, modalUtils) => {
		const reportIds = rows.map((row) => row.reportId);
		modalUtils.setLoading(true);
		axios(EXECUTED_REPORT_URL, {
			method: 'DELETE',
			params: {
				reportIds: reportIds.join(',')
			}
		})
			.then(() => {
				modalUtils.setLoading(false);
				modalUtils.handleClose();
				toggleAllRowsSelected(false);
				batch(() => {
					dispatch(
						fetchDataTableData({
							name: 'EXECUTED_REPORTS',
							action: 'MODIFY',
							dataCallbackFn: dataCallbackFn
						})
					);
				});
			})
			.catch(() => {
				modalUtils.setErrorMessage({ show: true, message: t('reports:modals.deleteModal.errorMessage') });
				modalUtils.setLoading(false);
			});
		toggleAllRowsSelected(false);
	};

	const handleActionClick = (rows, evt, toggleAllRowsSelected) => {
		let modal = '';
		if (evt.currentTarget.id === 'requestAck') {
			modal = 'requestAckModal';
		} else if (evt.currentTarget.id === 'delete') {
			modal = 'deleteModal';
		}
		if (modal !== '') {
			const modalData = {
				submitFn: (rows, modalUtils) => {
					modal === 'deleteModal'
						? deleteRows(rows, toggleAllRowsSelected, modalUtils)
						: requestAckRows(rows, toggleAllRowsSelected, modalUtils);
				},
				title: t(`reports:modals.${modal}.title`),
				body: t(`reports:modals.${modal}.body`),
				cancelButton: t(`reports:modals.${modal}.buttons.cancel`),
				submitButton: t(`reports:modals.${modal}.buttons.submit`)
			};
			dispatch(
				showModal({
					name: 'REPORTS_GENERIC_MODAL',
					data: { ...modalData, rows }
				})
			);
		}
	};

	return (
		<>
			<DataTable
				title={title}
				name="EXECUTED_REPORTS"
				columns={columns}
				options={options}
				processDataFn={(data) => data}
				emptyMessage={t('reports:executedReports.messages.empty')}
				errorMessage={t('reports:executedReports.messages.error')}
				loadingMessage={t('reports:executedReports.messages.loading')}
				canSelectRows
				canSelectSingleRow
				showDeleteOption={false}
				canSelectAll={false}
				showActionBar={true}
				canToggleColumns={false}
				additionalActionBarItems={additionalActionBarItems}
				showFilter={true}
				showPagination={false}
				showInfiniteScroll={true}
				canSelectMultipleRows={true}
				handleSelectSingleRowClick={handleSelectSingleRowClick}
				additionalTableOptions={additionalTableOptions}
				serverSidePagination={true}
				serverSideSorting={true}
				infiniteScrollOptions={infiniteScrollOptions}
				getRowId={getRowId}
				onDelete={handleActionClick}
				dataCallbackFn={dataCallbackFn}
			/>
			<Box mt={2}>
				<Typography className={classes.noteStrong}>{t('executedReports.note')}</Typography>
			</Box>
		</>
	);
};

export default ExecutedReportsDataTable;
