/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { BaseView, BaseViewContent, BaseViewHeader } from '../Base';
import LoaderCircular from '../../../Loaders/Circular';
import { getEWT } from '../Conversation/Message/message';

const QueueView = () => {
	const { t } = useTranslation();
	const { content } = useSelector((state) => state.chat.session.ewt);
	const estimateWaitTime = getEWT(content);

	return (
		<BaseView className="sw-chat-view-queue">
			<BaseViewHeader />
			<BaseViewContent>
				<Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
					<Box textAlign="center" mb={12}>
						<LoaderCircular />
						<Box mt={3}>
							<Typography paragraph={!!estimateWaitTime}>{t('common:chat.queue.message')}</Typography>
							{estimateWaitTime ? (
								<Typography variant="body2">
									{t('common:chat.queue.estimate', { count: estimateWaitTime })}
								</Typography>
							) : null}
						</Box>
					</Box>
				</Box>
			</BaseViewContent>
		</BaseView>
	);
};

export default QueueView;
