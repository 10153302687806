/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	dataWrap: {
		'overflow-wrap': 'break-word'
	}
}));

/**
 * Label and value pair
 * @param {String} label - Translated string
 * @param {String} value - Translated string
 * @returns {Object}
 */
const ReviewRow = ({ label, value }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={1}>
			<Grid item xs={4}>
				<Box ml={4}>{label}</Box>
			</Grid>
			<Grid item xs={8} className={classes.dataWrap}>
				<Box pr={2}>{value}</Box>
			</Grid>
		</Grid>
	);
};

export default ReviewRow;
