import React, { useEffect, useState } from 'react';
import { AlertCustom } from '../../../components/Alert';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	alert: {
		marginTop: 12,
		fontSize: 15,
		borderRadius: 4,
		marginLeft: -15,
		marginRight: -15,
		wordBreak: 'break-word'
	}
}));

const ALERTS_REFRESH_URI = '/portal/reports/refreshAlertsList';

const getInfo = (alert, t) => {
	switch (alert.alertType) {
		case 'DELETED':
			return { message: t('reports:alerts.messages.successfulDeletion', { reportName: alert.messageName }) };
		case 'SHAREDBY_SCHEDULE':
			return { message: t('reports:alerts.messages.sharedByMessage', { reportName: alert.messageName }) };
		case 'SHARING_SUCCESS':
			return { message: t('reports:alerts.messages.sharingSuccess', { reportName: alert.messageName }) };
		case 'SHARING_SUCCESS_EMAIL_ERR':
			return { severity: 'warning', message: t('reports:alerts.messages.sharingSuccessEmailError') };
		case 'SHARING_FAILED':
			return {
				severity: 'warning',
				message: t('reports:alerts.messages.sharingFailed', { reportName: alert.messageName })
			};
		case 'SHARED':
			return {
				message: t('reports:alerts.messages.sharedMessage', {
					ownerName: alert.reportOwnerName,
					reportName: alert.messageName
				})
			};
		case 'RUNNOW_SUCCESS':
			return {
				message: t('reports:alerts.messages.scheduleRunNowSuccess', { scheduleName: alert.messageName })
			};
		case 'RUNLATER_SUCCESS':
			return {
				message: t('reports:alerts.messages.scheduleRunLaterSuccess', { scheduleName: alert.messageName })
			};
		case 'SCHEDULE_FAILED':
			return { severity: 'warning', message: t('reports:alerts.messages.scheduleFailed') };
		case 'SAVE_SUCCESS':
			return { message: t('reports:alerts.messages.templateSaveSuccess', { reportName: alert.messageName }) };
		case 'SAVE_FAILED':
			return {
				severity: 'warning',
				message: t('reports:alerts.messages.templateSaveFailed', { reportName: alert.messageName })
			};
		case 'DELETE_SUCCESS':
			return { message: t('reports:alerts.messages.deleteSuccess', { reportName: alert.messageName }) };
		case 'DELETE_FAILED':
			return {
				severity: 'warning',
				message: t('reports:alerts.messages.deleteFailed', { reportName: alert.messageName })
			};
		case 'SHARE_SUCCESS':
			return {
				message: t('reports:alerts.messages.templateSharingSuccess', { templateName: alert.messageName })
			};
		case 'SHARE_FAILED':
			return { severity: 'warning', message: t('reports:alerts.messages.templateSharingFailure') };
		case 'ACKNOWLEDGE_SUCCESS':
			return { message: t('reports:alerts.messages.ackSuccess', { reportName: alert.messageName }) };
		case 'ACKNOWLEDGE_FAILURE':
			return {
				severity: 'warning',
				message: t('reports:alerts.messages.ackFailure', { reportName: alert.messageName })
			};
		case 'TEMPLATE_REQUEST_ACK_SUCCESS':
			return { message: t('reports:alerts.messages.templateAckSuccess') };
		case 'TEMPLATE_REQUEST_ACK_FAILURE':
			return { severity: 'warning', message: t('reports:alerts.messages.templateAckFailed') };
		case 'REQUEST_ACK_SUCCESS':
			return { message: t('reports:alerts.messages.ackRequestSuccess') };
		case 'REQUEST_ACK_FAILURE':
			return { severity: 'warning', message: t('reports:alerts.messages.ackRequestFailed') };
		case 'SCHEDULE_REQUEST_ACK_SUCCESS':
			return { message: t('reports:alerts.messages.scheduleAckRequestSuccess') };
		case 'SCHEDULE_REQUEST_ACK_FAILURE':
			return { severity: 'warning', message: t('reports:alerts.messages.scheduleAckRequestFailed') };
		case 'SCHEDULE_DRILL_DOWN':
			return { message: t('reports:alerts.messages.secEventDrillDown') };
		case 'SUCCEEDED':
			return { message: t('reports:alerts.messages.successfulExecution', { reportName: alert.messageName }) };
		case 'FAILED':
			return {
				severity: 'warning',
				message: t('reports:alerts.messages.failedReport', { reportName: alert.messageName })
			};
	}
};

const ShowAlerts = ({ alertsRef }) => {
	const classes = useStyles();
	const { t } = useTranslation('reports');
	const [visibleAlerts, setVisibleAlerts] = useState([]);

	useEffect(() => {
		const refreshAlerts = () => {
			axios
				.get(ALERTS_REFRESH_URI)
				.then(({ data }) => {
					setVisibleAlerts(data);
				})
				.catch(() => {
					setVisibleAlerts([]);
				});
		};
		alertsRef.current = refreshAlerts;
	}, [alertsRef]);

	return (
		<>
			{visibleAlerts?.map((alert) => {
				const alertInfo = getInfo(alert, t);
				return (
					alertInfo && (
						<AlertCustom
							variant="outlined"
							key={alert.messageId}
							className={classes.alert}
							onClose={() => {
								setVisibleAlerts(visibleAlerts.filter((item) => item !== alert));
								axios('/portal/reports/removeAlertNRS', {
									method: 'GET',
									params: { messageId: alert.messageId + '/' + alert.alertType }
								});
							}}
							gutterBottom
							icon={' '}
							severity={alertInfo?.severity}
						>
							{alertInfo?.message}
						</AlertCustom>
					)
				);
			})}
		</>
	);
};

export default ShowAlerts;
