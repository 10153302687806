/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { SET_ACTIVE_TAB, SET_TABS } from '../../actions/components/tabs';

const initialState = {
	PAGE_SIDEBAR_TABS: {
		active: null,
		options: []
	}
};

export default function tabs(state = initialState, action) {
	switch (action.type) {
		case SET_ACTIVE_TAB:
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					active: action.payload.active
				}
			};
		case SET_TABS:
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					active: action.payload.active,
					options: action.payload.options
				}
			};
		default:
			return state;
	}
}
