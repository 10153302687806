/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { get } from 'axios';

import { useDispatch } from 'react-redux';

import { hasAccess } from '../../../utils/access';
import { showAlert } from '../../../state/actions/components/alerts';
import { faFileAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const alertConfig = {
	CTA_HOSTDOWN: {
		name: 'CTA_HOSTDOWN',
		icon: faExclamationTriangle,
		headerText: 'common:alerts.urgent.hostDown.header',
		messageText: 'common:alerts.urgent.hostDown.message',
		severity: 'error',
		variant: 'filled',
		joinByType: true,
		displayRestrictions: {
			feature: 'HOSTS_DOWN_UN'
		},
		snoozingOptions: ['1Hour', '4Hours', 'tomorrow']
	},
	RELEASE: {
		name: 'RELEASE',
		icon: faFileAlt,
		headerText: 'common:alerts.urgent.release.header',
		messageText: 'common:alerts.urgent.release.message',
		severity: 'info',
		variant: 'outlined',
		joinByType: false,
		displayRestrictions: {
			feature: 'RELEASE_NOTES_UN'
		},
		snoozingOptions: ['1Hour', '4Hours', 'tomorrow', 'dismiss']
	},
	URGENT: {
		name: 'URGENT',
		icon: '',
		messageText: '',
		headerText: '',
		severity: 'warning',
		variant: 'outlined',
		joinByType: false,
		displayRestrictions: null
	}
};

function canShowAlertType(restrictions) {
	if (!restrictions) {
		return true;
	}

	return hasAccess({
		toFeatures: {
			list: [restrictions.feature]
		}
	});
}

export const useUrgentAlerts = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (
			hasAccess({
				toFeatures: {
					list: ['URGENT_NOTIFICATIONS']
				}
			})
		) {
			get('/portal/alerts').then((resp) => {
				if (!resp.data) {
					return;
				}

				const groupByType = resp.data.reduce(function (r, a) {
					r[a.type] = r[a.type] || [];
					r[a.type].push(a);
					return r;
				}, Object.create(null));

				for (const [key, alerts] of Object.entries(groupByType)) {
					const config = alertConfig[key] ? alertConfig[key] : null;

					if (!config) {
						return;
					}

					if (canShowAlertType(config.displayRestrictions)) {
						if (config.joinByType) {
							dispatch(
								showAlert({
									id: `URGENT_ALERT_MULTIPLE_${alerts[0].id}`,
									type: 'URGENT_ALERT',
									data: {
										alerts: alerts,
										config: config
									}
								})
							);
						} else {
							alerts.forEach((alert) =>
								dispatch(
									showAlert({
										id: `URGENT_ALERT_${alert.id}`,
										type: 'URGENT_ALERT',
										data: {
											alerts: [alert],
											config: config
										}
									})
								)
							);
						}
					}
				}
			});
		}
	}, [dispatch]);
};
