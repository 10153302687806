/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { hasRouteAccess } from '../../../utils/access';
import PageMeta from '../../../components/Layouts/PageMeta';
import Sidebar from '../../../components/Content/Private/Sidebar';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		position: 'relative',
		display: 'flex',
		flexFlow: 'row wrap',
		zIndex: 9,
		fontFamily: 'Arial, sans-serif'
	},
	content: {
		width: `calc(100% - 280px)`,
		flex: '1 0 auto',
		padding: theme.spacing(4, 3),
		overflowX: 'auto'
	},
	contentBackgroundDefault: {
		backgroundColor: theme.palette.content.default.background
	},
	contentBackgroundLight: {
		backgroundColor: theme.palette.content.light.background
	}
}));

const PageWithSidebarLayout = ({
	children,
	title,
	customTitle,
	subtitle,
	description,
	navRoutes,
	tabRoutes,
	hideTitleInSmallView = false,
	isSidebarStatic = false,
	contentBackgroundColor = 'default'
}) => {
	const classes = useStyles();
	const sidebarRoutes = hasRouteAccess(navRoutes);
	const hasRoutes = navRoutes || tabRoutes;
	return (
		<div className={classNames(classes.root, 'react-backbone-page-with-sidebar-layout')}>
			{hasRoutes && <Sidebar navRoutes={sidebarRoutes} tabRoutes={tabRoutes} isStatic={isSidebarStatic} />}
			<section
				className={classNames(classes.content, {
					[classes.contentBackgroundDefault]: contentBackgroundColor === 'default',
					[classes.contentBackgroundLight]: contentBackgroundColor === 'light'
				})}
			>
				<PageMeta
					title={title}
					customTitle={customTitle}
					subtitle={subtitle}
					description={description}
					hideTitleInSmallView={hideTitleInSmallView}
				/>
				{children}
			</section>
		</div>
	);
};

export default PageWithSidebarLayout;
