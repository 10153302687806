/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseView, BaseViewContent, BaseViewHeader } from '../Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { getEWT } from '../Conversation/Message/message';

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.success.main
	}
}));

const TransferView = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { content } = useSelector((state) => state.chat.session.ewt);
	const estimateWaitTime = getEWT(content);

	return (
		<BaseView className="sw-chat-view-transfer">
			<BaseViewHeader />
			<BaseViewContent>
				<Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
					<Box textAlign="center" mb={12}>
						<FontAwesomeIcon
							icon={faExchangeAlt}
							className={classes.icon + ' chat-transfer-icon'}
							size="3x"
						/>
						<Box mt={3}>
							<Typography variant="h3" paragraph>
								{t('common:chat.transfer.title')}
							</Typography>
							<Typography paragraph={!!estimateWaitTime}>
								{t('common:chat.transfer.description')}
							</Typography>
							{estimateWaitTime ? (
								<Typography variant="body2">
									{t('common:chat.transfer.estimate', { count: estimateWaitTime })}
								</Typography>
							) : null}
						</Box>
					</Box>
				</Box>
			</BaseViewContent>
		</BaseView>
	);
};

export default TransferView;
