/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import QualtricsFeedback from '../../../../Qualtrics/Feedback';

const useStyles = makeStyles((theme) => ({
	container: {
		whiteSpace: 'nowrap',
		padding: theme.spacing(1, 0)
	},
	welcome: {
		[theme.breakpoints.down('md')]: {
			fontSize: 10
		}
	},
	feedback: {
		'margin': theme.spacing(0, 2),
		'& a': {
			...theme.typography.body2,
			'color': theme.palette.headerBar.feedback,
			'display': 'block',
			'textDecoration': 'none',
			'&:hover, &:focus': {
				textDecoration: 'underline'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: 10
			}
		}
	}
}));

const BarInfo = () => {
	const classes = useStyles();
	const { t } = useTranslation('common');
	const clientName = useSelector((state) => state.client.name);
	const contactName = useSelector((state) => state.contact.name);
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const isMultiClient = useSelector((state) => state.auth.isMultiClient);

	let name = [];

	if (contactName) {
		name.push(contactName);
	}

	if (clientName && isMultiClient && isLoggedIn) {
		name.push(clientName);
	}

	name = name.join(', ');

	return (
		<Grid container alignItems="center" wrap="nowrap" className={classes.container}>
			<Grid item>
				<Typography variant="body2" className={classes.welcome}>
					{t('app.greeting', { name })}
				</Typography>
			</Grid>
			<Grid item>
				<QualtricsFeedback id="qualtrics-general-feedback-header" className={classes.feedback} />
			</Grid>
		</Grid>
	);
};

export default BarInfo;
