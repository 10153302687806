/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Grid } from '@material-ui/core';
import { headerRoutes } from '../../../../../routes/components/header';

import NavItem from '../../../../Nav/Primary/Item';
import NavToggle from '../../../../Nav/Responsive/Toggle';

const navRoutes = headerRoutes();

const HeaderMenu = () => (
	<Grid container alignItems="flex-end" wrap="nowrap">
		{navRoutes.map((route, idx) => (route ? <NavItem key={route.name || idx} {...route} /> : null))}
		<NavToggle />
	</Grid>
);

export default HeaderMenu;
