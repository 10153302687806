/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import localforage from 'localforage';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import auth from './app/auth';
import client from './app/client';
import contact from './app/contact';
import features from './app/features';
import preferences from './app/preferences';
import privileges from './app/privileges';
import roles from './app/roles';
import other from './app/other';

import alerts from './components/alerts';
import chat from './components/chat';
import datacube from './components/datacube';
import legacy from './app/legacy';
import datatables from './components/datatables';
import pivotdatatables from './components/pivotdatatables';
import modals from './components/modals';
import monitoring from './components/monitoring';
import nav from './components/nav';
import widgets from './components/widgets';
import search from './components/search';
import tabs from './components/tabs';
import reports from './components/reports';
import session from './components/session';

export const storage = localforage.createInstance({
	name: 'swcp',
	storeName: 'app'
});

const chatPersistConfig = {
	key: 'chat',
	version: 1,
	storage: storage,
	blacklist: ['isDuplicate', 'socketMeta'],
	stateReconciler: autoMergeLevel2
};

const reportsPersistConfig = {
	key: 'reports',
	version: 1,
	storage: storage,
	blacklist: ['alerts'],
	stateReconciler: autoMergeLevel2
};

const datatablesPersistConfig = {
	key: 'app',
	version: 1,
	storage: storage,
	blacklist: ['EXECUTED_REPORTS'],
	stateReconciler: autoMergeLevel2
};

export default (history) =>
	combineReducers({
		auth,
		client,
		contact,
		features,
		preferences,
		privileges,
		roles,
		other,
		nav,
		alerts,
		chat: persistReducer(chatPersistConfig, chat),
		legacy,
		modals,
		monitoring,
		widgets,
		datacube,
		datatables: persistReducer(datatablesPersistConfig, datatables),
		pivotdatatables,
		search,
		tabs,
		reports: persistReducer(reportsPersistConfig, reports),
		router: connectRouter(history),
		session
	});
