/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useCallback, useState } from 'react';
import { post } from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { hideModal } from '../../../state/actions/components/modals';

import Alert from '../../../components/Alert';
import Button from '../../../components/Inputs/Button';
import Autocomplete from '../../../components/Inputs/Autocomplete';

const getOptionLabel = (option) => option.name;

const GET_TIME_ZONE_OPTIONS = {
	url: '/portal/preferences/timezones',
	preFetch: true
};

const SET_TIME_ZONE_API = '/portal/preferences/timezone';

const TimeZoneModal = ({ isOpen }) => {
	const { t } = useTranslation('common');
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(null);

	const [stateSave, setStateSave] = useState({
		isError: false,
		isLoading: false,
		isSuccess: false
	});

	function handleClose() {
		dispatch(
			hideModal({
				name: 'TIME_ZONE'
			})
		);
	}

	function handleSubmit() {
		if (!selected) {
			return;
		}

		setStateSave({
			isError: false,
			isLoading: true,
			isSuccess: false
		});

		post(SET_TIME_ZONE_API, { name: '', offset: '', value: selected.value })
			.then(() =>
				setStateSave({
					isError: false,
					isLoading: false,
					isSuccess: true
				})
			)
			.catch(() =>
				setStateSave({
					isError: true,
					isLoading: false,
					isSuccess: false
				})
			);
	}

	const handleOptionSelect = useCallback((event, value) => {
		const hasOption = value && value.value;

		setSelected(hasOption ? value : null);
	}, []);

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			open={isOpen}
			onClose={handleClose}
			maxWidth="sm"
			aria-labelledby="time-zone-modal"
		>
			<DialogTitle id="time-zone-modal" disableTypography>
				<Typography component="h2" variant="h3">
					{t('modals.timeZone.title')}
				</Typography>
			</DialogTitle>

			<DialogContent dividers={true}>
				<Autocomplete
					id="time-zone"
					label={t('modals.timeZone.description')}
					disabled={stateSave.isLoading || stateSave.isSuccess}
					getOptionLabel={getOptionLabel}
					disablePortal={false}
					placeholder={t('buttons.select')}
					callback={handleOptionSelect}
					options={GET_TIME_ZONE_OPTIONS}
				/>
				{stateSave.isError ? (
					<Alert gutterTop variant="error" message={t('modals.timeZone.messages.error')} />
				) : null}
				{stateSave.isSuccess ? (
					<Alert gutterTop variant="success" message={t('modals.timeZone.messages.success')} />
				) : null}
			</DialogContent>
			<DialogActions>
				{stateSave.isSuccess ? (
					<Button onClick={handleClose}>{t('buttons.close')}</Button>
				) : (
					<Button disabled={!selected} isLoading={stateSave.isLoading} onClick={handleSubmit}>
						{t('buttons.submit')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default TimeZoneModal;
