import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Grow from '@material-ui/core/Grow';
import StepsExpressImg from '../../../../main/webapp/images/steps-express.gif';
import StepsEnterpriseImg from '../../../../main/webapp/images/steps-enterprise.gif';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioWithLabel from '../../../../components/Inputs/Radio';
import { CustomDialogTitle, CloseButton, DialogContent, DialogFooter } from '../../../../components/Dialog';

const useStyles = makeStyles((theme) => ({
	drillDownMode: {
		padding: '20px 0px 10px 0px'
	},
	drillDownModeLabel: {
		fontFamily: '"Arial", sans-serif',
		fontSize: '15px',
		fontWeight: 700,
		color: theme.palette.reports.label.color
	},
	modalNoteText: {
		fontSize: '12.5px',
		marginBottom: '11px',
		color: theme.palette.reports.label.color
	},
	modalText: {
		fontFamily: '"Arial", sans-serif',
		fontSize: '15px',
		marginBottom: '11px',
		color: theme.palette.reports.label.color
	},
	helpModalLink: {
		'color': theme.palette.link.color,
		'cursor': 'pointer',
		'fontWeight': 400,
		'fontSize': '13px',
		'marginLeft': '30px',
		'&:hover': {
			textDecoration: 'underline',
			color: theme.palette.link.colorHover
		}
	},
	expressModeNote: {
		margin: '5px 0 5px 0'
	},
	ddModeOptionStyle: {
		marginLeft: '1%',
		paddingBottom: '0.2em',
		paddingLeft: '2em'
	},
	greenCheck: {
		marginLeft: '5px',
		fontSize: '15px',
		fontWeight: 700,
		color: theme.palette.reports.greenCheck.color,
		transformOrigin: '0 0 0',
		transitionDelay: '500ms'
	}
}));

export const DrillDownModeDialog = (props) => {
	const classes = useStyles();

	const { onClose, t, open } = props;
	return (
		<Dialog aria-labelledby="dd-mode-dialog-title" open={open} fullWidth={true}>
			<CustomDialogTitle
				id="dd-mode-dialog-title"
				disableTypography={true}
				onClose={() => {
					onClose();
				}}
			>
				{t('drillDown.view.modalHeader')}
			</CustomDialogTitle>
			<DialogContent dividers>
				<DialogContentText className={classes.modalText}>
					<b>{t('drillDown.view.express')}</b>
					<br /> {t('drillDown.view.expressModeDesc')}
				</DialogContentText>
				<img src={StepsExpressImg} />
				<DialogContentText className={classes.modalText}>
					<b>{t('drillDown.view.enterprise')}</b>
					<br /> {t('drillDown.view.enterpriseModeDesc')}
				</DialogContentText>
				<img src={StepsEnterpriseImg} />
				<DialogContentText className={classes.modalNoteText}>
					<b>{t('drillDown.view.modalNote')}</b> {t('drillDown.view.modalNoteText')}
				</DialogContentText>
			</DialogContent>
			<DialogFooter>
				<CloseButton onClick={onClose} color="primary">
					{t('drillDown.view.modalClose')}
				</CloseButton>
			</DialogFooter>
		</Dialog>
	);
};

export const DrillDownMode = (props) => {
	const { t, drillDownMode, handleDrillDownModeChange, expressDrillDownLimit } = props;
	const [showHelpModal, setShowHelpModal] = useState(false);
	const [showTick, setShowTick] = useState(false);
	const greenCheckTimer = useRef();

	const classes = useStyles();

	const handleChange = (event) => {
		handleDrillDownModeChange(event.target.value);
		setShowTick(true);

		window.clearTimeout(greenCheckTimer.current);

		greenCheckTimer.current = window.setTimeout(() => {
			setShowTick(false);
		}, 3000);
	};

	const DrillDownOption = ({ labelKey, value }) => {
		return (
			<span>
				{`${t(labelKey)}`}
				{showTick && value === drillDownMode && (
					<Grow in={showTick} className={classes.greenCheck} timeout={1000}>
						<FontAwesomeIcon icon={faCheck} />
					</Grow>
				)}
			</span>
		);
	};

	return (
		<>
			<div className={classes.drillDownMode}>
				<div>
					<span id="drill-down-view-mode" className={classes.drillDownModeLabel}>
						{t('drillDown.view.mode')}
					</span>
					<a
						className={classes.helpModalLink}
						onClick={() => {
							setShowHelpModal(true);
						}}
					>
						{t('drillDown.view.helpModalLink')}
					</a>
				</div>
				<div className={classes.expressModeNote}>
					<small>{t('drillDown.view.expressModeNote', { limit: expressDrillDownLimit })}</small>
				</div>
				<FormControl className={classes.ddModeOptionStyle}>
					<RadioGroup
						aria-labelledby="drill-down-view-mode"
						name="drill-down-view-mode"
						value={drillDownMode}
						onChange={handleChange}
					>
						<FormControlLabel
							value="Express"
							key="Express"
							control={<RadioWithLabel />}
							label={<DrillDownOption labelKey={'drillDown.view.express'} value="Express" />}
						/>
						<FormControlLabel
							value="Enterprise"
							key="Enterprise"
							control={<RadioWithLabel />}
							label={<DrillDownOption labelKey={'drillDown.view.enterprise'} value="Enterprise" />}
						/>
					</RadioGroup>
				</FormControl>
			</div>
			<DrillDownModeDialog
				t={t}
				open={showHelpModal}
				onClose={() => {
					setShowHelpModal(false);
				}}
			></DrillDownModeDialog>
		</>
	);
};
