/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { fetchDataTableData, updateDataTableParams } from '../../state/actions/components/datatables';
import { TableContainer } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useGlobalFilter,
	useGroupBy,
	useExpanded,
	useRowSelect
} from 'react-table';

import {
	StyledTable,
	StyledTableBody,
	StyledTableHead,
	TableHeadRow,
	TableBodyRow,
	TableMessage,
	TablePagination,
	TableToolbar,
	TableActionbar,
	TableBannerToolbar,
	tableControlHooks,
	defaultColumn
} from './Table';

import { useTableState, useTableData, useTablePagination } from './hooks';

const DataTable = ({
	name,
	title,
	options,
	columns,
	tableHeaderComponents,
	nameSpace,
	emptyMessage,
	errorMessage,
	loadingMessage,
	hiddenColumns,
	canSelectRows,
	canSelectSingleRow,
	customRowFunction,
	handleSelectSingleRowClick,
	selectedSingleRowData,
	canExpandRows,
	canToggleColumns,
	expandedRowDataKey,
	tableSize,
	initialData,
	processDataFn,
	processPaginationFn,
	paginationType,
	showPagination,
	infiniteScrollOptions,
	serverSidePagination,
	showDeleteOption,
	showFilter,
	canSelectMultipleRows,
	canSelectAll,
	onDelete,
	onEdit,
	infoMessage,
	customActions,
	filterOutCloudNetworks,
	styledOptions,
	skipDataCache,
	showStickyHeader,
	exportFormats,
	exportFunction,
	showActionBar,
	additionalActionBarItems,
	dataProperty,
	dataCallbackFn,
	additionalTableOptions,
	serverSideSorting,
	getRowId
}) => {
	const dispatch = useDispatch();
	const pageSizeLimit = (options && options.params && options.params.limit) || 50;
	const { data, isError, isLoading, isAppendLoading, skipResetRef, hasMore } = useTableState({
		name,
		options,
		skipDataCache,
		initialData,
		dataProperty,
		dataCallbackFn
	});

	const updateMyData = (action) => {
		skipResetRef.current = true;

		dispatch(fetchDataTableData({ name, action, dataProperty, dataCallbackFn }));
	};

	const tableData = useTableData({
		data: data.elements,
		processDataFn
	});

	const tablePagination = useTablePagination({
		data: tableData,
		pagination: data.pagination,
		pageSizeLimit,
		processPaginationFn
	});

	/**
	 * @see https://react-table.tanstack.com/docs/api/useFilters#table-options
	 */
	const filterOptions = {
		manualFilters: true,
		defaultCanFilter: false,
		autoResetFilters: !skipResetRef.current,
		filterTypes: useMemo(
			() => ({
				// text is default filter
				text: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];

						return rowValue !== undefined
							? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
							: true;
					});
				}
			}),
			[]
		)
	};

	/**
	 * Pagination's initial state for `tableOptions` configuration
	 * @see https://react-table.tanstack.com/docs/api/usePagination#table-options
	 */
	const paginationInitialStateOptions = {
		pageSize: pageSizeLimit,
		pageIndex: tablePagination.current
	};

	/**
	 * Table's initial state for `useTable` hook
	 * @see https://react-table.tanstack.com/docs/api/useTable#table-options
	 */
	const tableOptions = {
		columns,
		getRowId,
		data: tableData,
		disableMultiSort: true,
		manualSortBy: additionalTableOptions?.manualSortBy || false,
		autoResetPage: !skipResetRef.current,
		autoResetSelectedRows: additionalTableOptions?.autoResetSelectedRows === false ? false : !skipResetRef.current,
		disableSortRemove: additionalTableOptions?.disableSortRemove || false,
		updateMyData,
		getSubRows: (row) => (expandedRowDataKey ? row[expandedRowDataKey] : []),
		defaultColumn: useMemo(() => defaultColumn, []),
		initialState: {
			sortBy: additionalTableOptions?.initialStateSortBy || [],
			hiddenColumns,
			...paginationInitialStateOptions
		},
		tableHeaderComponents,
		...filterOptions
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		columns: stateColumns,
		page,
		gotoPage,
		setPageSize,
		selectedFlatRows,
		state: { pageSize, sortBy },
		setGlobalFilter,
		toggleAllRowsSelected,
		state,
		rows
	} = useTable(
		tableOptions,
		useGlobalFilter,
		useFilters,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) =>
				tableControlHooks({
					columns,
					canSelectRows,
					canExpandRows,
					canToggleColumns,
					isLoading,
					onEdit,
					infoMessage,
					onDelete,
					canSelectAll,
					canSelectMultipleRows
				})
			);
		}
	);

	const currentPageData = useMemo(() => (serverSidePagination ? rows : page), [rows, serverSidePagination, page]);

	const isEmpty = !isError && !isLoading && !isAppendLoading && !currentPageData.length;
	const { globalFilter } = state;
	let header;

	if (tableHeaderComponents) {
		header = <StyledTableHead className={styledOptions.styledTableHead}>{tableHeaderComponents()}</StyledTableHead>;
	} else {
		header = (
			<StyledTableHead className={styledOptions.styledTableHead}>
				{headerGroups.map((headerGroup) => {
					const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
					return (
						<TableHeadRow
							key={key}
							headerGroup={headerGroup}
							sortByOptions={additionalTableOptions?.sortByOptions}
							{...headerGroupProps}
						/>
					);
				})}
			</StyledTableHead>
		);
	}

	const initialRender = useRef(true);

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else if (serverSideSorting && sortBy?.length > 0) {
			const params = {
				sortField: sortBy[0].id,
				sortOrder: sortBy[0].desc ? 'DESC' : 'ASC',
				offset: 0,
				limit: 50
			};
			dispatch(updateDataTableParams({ name, params }));
			updateMyData();
		}

		//eslint-disable-next-line
	}, [serverSideSorting, sortBy]);
	return (
		<>
			{(canSelectRows && showDeleteOption && onDelete) || customActions.length || showActionBar ? (
				<TableActionbar
					canSelectRows={canSelectRows}
					showDeleteOption={showDeleteOption}
					onDelete={onDelete}
					selectedFlatRows={selectedFlatRows}
					isLoading={isLoading}
					customActions={customActions}
					filterOutCloudNetworks={filterOutCloudNetworks}
					globalFilter={globalFilter}
					setGlobalFilter={setGlobalFilter}
					showFilter={showFilter}
					updateMyData={updateMyData}
					name={name}
					additionalActionBarItems={additionalActionBarItems}
					toggleAllRowsSelected={toggleAllRowsSelected}
				/>
			) : null}
			{infoMessage ? infoMessage : null}
			<TableBannerToolbar exportFormats={exportFormats} exportFunction={exportFunction} />
			<TableToolbar
				title={title}
				columns={stateColumns}
				isLoading={isLoading}
				canToggleColumns={canToggleColumns}
			/>
			<TableContainer className={styledOptions.tableContainer}>
				<StyledTable size={tableSize} {...getTableProps()} stickyHeader={showStickyHeader}>
					{header}
					<StyledTableBody {...getTableBodyProps()}>
						{!isError &&
							!isLoading &&
							currentPageData.map((row) => {
								prepareRow(row);
								if (row.original.isCloudNetwork && filterOutCloudNetworks) {
									return;
								}

								const { key, ...rowProps } = row.getRowProps();

								let additionalRowProps = {};

								row.customRowFunction = customRowFunction;

								if (canSelectSingleRow) {
									additionalRowProps = {
										hover: true,
										selected:
											selectedSingleRowData &&
											row.values[selectedSingleRowData.id] === selectedSingleRowData.value
												? true
												: false,
										onClick: (event) => handleSelectSingleRowClick(event, row),
										style: { cursor: 'pointer' }
									};
								}
								return <TableBodyRow key={key} row={row} {...rowProps} {...additionalRowProps} />;
							})}
					</StyledTableBody>
				</StyledTable>
			</TableContainer>
			{!isError && !isLoading && infiniteScrollOptions && !isEmpty && (
				<InfiniteScroll
					next={() => {
						const params = { offset: rows.length, ...infiniteScrollOptions.params };
						dispatch(updateDataTableParams({ name, params }));
						updateMyData('APPEND');
					}}
					hasMore={hasMore}
					scrollThreshold={0.9}
					dataLength={rows.length}
				/>
			)}
			{isError ? (
				<TableMessage message={errorMessage} nameSpace={nameSpace} action={updateMyData} isError />
			) : null}
			{isEmpty ? <TableMessage message={emptyMessage} nameSpace={nameSpace} isEmpty /> : null}
			{isLoading || isAppendLoading ? (
				<TableMessage message={loadingMessage} nameSpace={nameSpace} isLoading={isLoading || isAppendLoading} />
			) : null}
			{showPagination ? (
				<TablePagination
					name={name}
					tablePagination={tablePagination}
					pageSize={pageSize}
					isLoading={isLoading}
					gotoPage={gotoPage}
					setPageSize={setPageSize}
					updateMyData={updateMyData}
					hasPaginationData={data.pagination}
					paginationType={paginationType}
				/>
			) : null}
		</>
	);
};

DataTable.defaultProps = {
	canSelectRows: false,
	canSelectSingleRow: false,
	customRowFunction: null,
	canExpandRows: false,
	canToggleColumns: true,
	showDeleteOption: false,
	showFilter: false,
	canSelectMultipleRows: false,
	canSelectAll: false,
	onDelete: null,
	onEdit: null,
	infoMessage: null,
	showPagination: true,
	serverSidePagination: false,
	expandedRowDataKey: null,
	selectedSingleRowData: null,
	handleSelectSingleRowClick: false,
	hiddenColumns: [],
	infiniteScrollOptions: null,
	nameSpace: ['common', 'datatables'],
	loadingMessage: 'datatables:messages.loading',
	emptyMessage: 'datatables:messages.empty',
	errorMessage: 'datatables:messages.error',
	tableSize: 'small',
	skipDataCache: true,
	customActions: [],
	filterOutCloudNetworks: false,
	styledOptions: {
		tableContainer: '',
		styledTableHead: ''
	},
	showStickyHeader: false,
	exportFormats: null,
	exportFunction: null,
	additionalTableOptions: null,
	showActionBar: false,
	additionalActionBarItems: null,
	dataProperty: null,
	serverSideSorting: false,
	paginationType: 'LINKS'
};

DataTable.propTypes = {
	name: PropTypes.string.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
			disableGroupBy: PropTypes.bool,
			disableFilters: PropTypes.bool,
			disableSortBy: PropTypes.bool,
			align: PropTypes.string,
			noWrap: PropTypes.bool,
			width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			minWidth: PropTypes.number,
			maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			headerTitle: PropTypes.string.isRequired,
			className: PropTypes.string
		})
	).isRequired,
	infiniteScrollOptions: PropTypes.object,
	options: PropTypes.object,
	hiddenColumns: PropTypes.array,
	nameSpace: PropTypes.array,
	errorMessage: PropTypes.string,
	emptyMessage: PropTypes.string,
	loadingMessage: PropTypes.string,
	canSelect: PropTypes.bool,
	canToggleColumns: PropTypes.bool,
	showDeleteOption: PropTypes.bool,
	showFilter: PropTypes.bool,
	canSelectMultipleRows: PropTypes.bool,
	canSelectAll: PropTypes.bool,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	infoMessage: PropTypes.object,
	customActions: PropTypes.array,
	filterOutCloudNetworks: PropTypes.bool,
	canSelectSingleRow: PropTypes.bool,
	customRowFunction: PropTypes.func,
	styledOptions: PropTypes.shape({
		tableContainer: PropTypes.className,
		styledTableHead: PropTypes.className
	}),
	showStickyHeader: PropTypes.bool,
	tableHeaderComponents: PropTypes.func,
	exportFormats: PropTypes.array,
	exportFunction: PropTypes.func,
	additionalTableOptions: PropTypes.object,
	showActionBar: PropTypes.bool,
	additionalActionBarItems: PropTypes.object,
	dataProperty: PropTypes.string,
	dataCallbackFn: PropTypes.func,
	serverSideSorting: PropTypes.bool,
	paginationType: PropTypes.string,
	getRowId: PropTypes.func
};

export default DataTable;
