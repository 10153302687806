/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { ADD_ACCESSED_PROFILES } from '../../actions/components/monitoring';

const initialState = {
	accessedProfiles: {}
};

export default function monitoringReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_ACCESSED_PROFILES:
			return {
				...state,
				accessedProfiles: {
					...state.accessedProfiles,
					[action.payload.contactId]: {
						...state.accessedProfiles[action.payload.contactId],
						[action.payload.clientId]: action.payload.deviceIds
					}
				}
			};
		default:
			return state;
	}
}
