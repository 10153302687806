/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

if (!!window.MSInputMethodContext && !!document.documentMode) {
	// Load polyfill for IE 11 only (it's big!)
	import('date-time-format-timezone');
}

import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { api, i18n } from './utils';
import { history, store, persistor } from './state';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import { PersistGate } from 'redux-persist/integration/react';

import { App, AppLegacy } from './containers/App';

/**
 * Global styles used across the portal
 */
import './global.css';

const isLegacyApp = !!window.isLegacyApp;
const isStandaloneChat = !!window.isStandaloneChat;

/**
 * Root DOM node to render main application in.
 */
let appNode =
	isLegacyApp && !isStandaloneChat
		? document.getElementById('sw-app-root-legacy')
		: document.getElementById('sw-app-root');

if (isLegacyApp && !appNode) {
	appNode = document.createElement('div');
	appNode.id = 'sw-app-root-legacy';
	document.body.appendChild(appNode);
}

/**
 * Customizes generated class names.
 */
const generateClassName = createGenerateClassName({
	productionPrefix: 'sw'
});

/**
 * Renders the main application frame.
 *
 * @returns {React.Component} An app shell.
 */
const renderApp = () =>
	render(
		<Provider store={store} context={ReactReduxContext}>
			<PersistGate loading={null} persistor={persistor}>
				<I18nextProvider i18n={i18n}>
					<StylesProvider generateClassName={generateClassName}>
						{isLegacyApp ? (
							<AppLegacy
								history={history}
								context={ReactReduxContext}
								isStandaloneChat={isStandaloneChat}
							/>
						) : (
							<App history={history} context={ReactReduxContext} />
						)}
					</StylesProvider>
				</I18nextProvider>
			</PersistGate>
		</Provider>,
		appNode
	);

/**
 * Initializes the application's API.
 */
api();

/**
 * Initializes and renders the application
 * within `appNode` root element.
 */
renderApp();

if (module.hot) {
	/**
	 * Hot module replacement in development mode.
	 */
	module.hot.accept(['./app.js', './containers/App'], () => {
		unmountComponentAtNode(appNode);
		renderApp();
	});

	/**
	 * Cleans console messages on updates.
	 */
	module.hot.addStatusHandler((status) => {
		if (status === 'prepare') {
			//eslint-disable-next-line
			console.clear();
		}
	});
}
