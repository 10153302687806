/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { CloseButton, CustomDialogTitle, DialogContent, DialogFooter } from '../../../../../components/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import { DateTime } from 'luxon';

export function validateTimeRange({ startDate, t }) {
	let isValidTimeRange = {
		isValid: true,
		message: ''
	};

	const now = DateTime.local();
	const rptStartDate = DateTime.fromISO(startDate);
	const twoYearsFromNow = now.minus({ years: 2 });
	const monthFromNow = now.minus({ days: 31 });
	if (rptStartDate < twoYearsFromNow) {
		isValidTimeRange = {
			isValid: false,
			message: t('reports:eventAnalysisStats.drillDown.invalidTimeRange')
		};
	} else if (rptStartDate < monthFromNow) {
		isValidTimeRange = {
			isValid: false,
			message: t('reports:eventAnalysisStats.drillDown.performanceNote')
		};
	}
	return isValidTimeRange;
}

const useStyles = makeStyles((theme) => ({
	modalText: {
		fontFamily: '"Arial", sans-serif',
		fontSize: '15px',
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(3),
		marginBottom: '11px',
		color: theme.palette.reports.label.color
	}
}));

export const TimePeriodDialog = (props) => {
	const classes = useStyles();

	const { t } = useTranslation('reports');

	const { onClose, open, message } = props;
	return (
		<Dialog maxWidth="md" aria-labelledby="dd-message-dialog-title" open={open} fullWidth={true}>
			<CustomDialogTitle
				id="dd-message-dialog-title"
				disableTypography={true}
				onClose={() => {
					onClose();
				}}
			></CustomDialogTitle>
			<DialogContent dividers>
				<DialogContentText className={classes.modalText}>{message}</DialogContentText>
			</DialogContent>
			<DialogFooter>
				<CloseButton onClick={onClose} color="primary">
					{t('reports:buttons.close')}
				</CloseButton>
			</DialogFooter>
		</Dialog>
	);
};
