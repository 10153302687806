/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'color': theme.palette.chat.message.in.color,
		'backgroundColor': theme.palette.chat.message.in.background,
		'whiteSpace': 'normal',
		'wordBreak': 'break-word',
		'padding': theme.spacing(1, 2),
		'borderRadius': theme.spacing(1),
		'&:not(:first-child)': {
			marginTop: theme.spacing(1)
		}
	}
}));

const ConverstationViewAgentTyping = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const agentTyping = useSelector((state) => state.chat.agentTyping);

	return agentTyping ? (
		<Fade in={agentTyping}>
			<Typography variant="body2" className={classes.root}>
				{t('common:chat.conversation.typing')}
			</Typography>
		</Fade>
	) : null;
};

export default ConverstationViewAgentTyping;
