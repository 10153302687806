/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import routeList from '../bootstrap';

/**
 * Application routes to pages with dynamic on-demand imports.
 *
 * PLEASE NOTE:
 * The order of the routes is important, as router renders first matching path.
 * @example A route with path `/portal/preferences` should be defined below `/portal/preferences/notifications`.
 * @see https://reactrouter.com/web/api/Switch
 */
export const pageRoutes = [
	routeList.auth.login,
	routeList.auth.logout,
	routeList.auth.multiClient,
	routeList.auth.switchUser,
	routeList.other.contactUs,
	routeList.other.siteOverview,
	routeList.other.acknowledgeReportFromEmail,
	{
		...routeList.dashboards.orchestration,
		routes: Object.values(routeList.dashboards)
	},
	{
		...routeList.dashboards.dashboard,
		routes: Object.values(routeList.dashboards)
	},
	{
		...routeList.administration.escalationProcedures,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.administration.addNetworks,
		routes: [
			routeList.administration.deviceRegistration,
			routeList.administration.monitoringConfiguration,
			routeList.administration.phishOps,
			routeList.administration.networks,
			routeList.administration.addNetworks,
			routeList.administration.escalationProcedures,
			routeList.administration.administration,
			routeList.administration.idm
		]
	},
	{
		...routeList.administration.networks,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.assets.efdConfiguration,
		routes: [
			routeList.assets.managed,
			routeList.assets.unmanaged,
			routeList.assets.healthTickets,
			routeList.assets.provisioningLibrary,
			routeList.assets.efdConfiguration
		]
	},
	{
		...routeList.other.eventHubConfiguration,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.other.tenantConfiguration,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.other.deviceConfiguration,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.administration.monitoringConfiguration,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.administration.deviceRegistration,
		routes: Object.values(routeList.administration)
	},
	{
		...routeList.preferences.token,
		routes: Object.values(routeList.preferences)
	},
	{
		...routeList.preferences.notifications,
		routes: Object.values(routeList.preferences)
	},
	{
		...routeList.preferences.preferences,
		routes: Object.values(routeList.preferences)
	},
	{
		...routeList.reports.create,
		routes: [
			routeList.reports.executedNRS,
			routeList.reports.scheduled,
			routeList.reports.templates,
			//routeList.reports.create,
			routeList.reports.createNew,
			routeList.reports.acknowledge
		]
	},
	{
		...routeList.reports.createNew,
		routes: [
			routeList.reports.executedNRS,
			routeList.reports.scheduled,
			routeList.reports.templates,
			//routeList.reports.create,
			routeList.reports.createNew,
			routeList.reports.acknowledge
		]
	},
	{
		...routeList.reports.executedNRS,
		routes: [
			routeList.reports.executedNRS,
			routeList.reports.scheduled,
			routeList.reports.templates,
			routeList.reports.createNew,
			routeList.reports.acknowledge
		]
	},
	{
		...routeList.reports.parameter,
		routes: [
			routeList.reports.executedNRS,
			routeList.reports.scheduled,
			routeList.reports.templates,
			//routeList.reports.create,
			routeList.reports.createNew,
			routeList.reports.acknowledge
		]
	},
	routeList.incidents.details.routes.datacube,
	routeList.assets.details.routes.datacube,
	routeList.search.results
];
