/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import HeaderLogo from './Logo';
import HeaderMenu from './Menu';
import HeaderNavResponsive from './Responsive';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.headerNav.background,
		color: theme.palette.headerNav.color,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			borderTop: `5px solid ${theme.palette.headerBar.background}`
		}
	},
	menu: {
		overflowX: 'auto',
		overflowY: 'hidden'
	},
	skeletonRoot: {
		backgroundColor: theme.palette.headerNav.background
	}
}));

const HeaderNav = () => {
	const classes = useStyles();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<Suspense fallback={<Skeleton variant="rect" height={67} classes={{ root: classes.skeletonRoot }} />}>
			<nav className={`${classes.root} nav-bar-main`}>
				<Container maxWidth={false}>
					<Grid container alignItems="center">
						<Grid item xs>
							<HeaderLogo />
						</Grid>
						{isAuthenticated && (
							<Grid item className={classes.menu}>
								<HeaderMenu />
							</Grid>
						)}
					</Grid>
				</Container>
			</nav>
			{isAuthenticated && <HeaderNavResponsive />}
		</Suspense>
	);
};

export default HeaderNav;
