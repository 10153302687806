/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

const isDev = process.env.NODE_ENV === 'development';
const isEnabled = process.env.SHOW_DEBUG_MESSAGES === 'true';

const debug = (...args) => {
	if (isDev && isEnabled && console && console.warn) {
		console.warn(...args);
	}
};

export default debug;
