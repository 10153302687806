/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';

import PrivateContentMain from '../../components/Content/Private/Main';
import PublicContentMain from '../../components/Content/Public/Main';
import { checkRouteIfPublic } from '../../utils/access';

const Content = ({ children }) => {
	const isPublicRoute = checkRouteIfPublic();

	return isPublicRoute ? (
		<PublicContentMain>{children}</PublicContentMain>
	) : (
		<PrivateContentMain>{children}</PrivateContentMain>
	);
};

export default Content;
