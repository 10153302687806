/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, Badge } from '@material-ui/core';

// tabs
import { setActiveTab } from '../../../../../state/actions/components/tabs';

const useMenuListStyles = makeStyles((theme) => ({
	listItem: {
		'padding': theme.spacing(1.5, 3),
		'margin': theme.spacing(0.5, 0),
		'color': theme.palette.sidebar.link,
		'minWidth': 249,
		'&:hover': {
			color: theme.palette.sidebar.linkHover,
			background: theme.palette.sidebar.linkBackgroundHover
		},
		'&.Mui-selected, &.Mui-selected:hover': {
			color: theme.palette.sidebar.linkActive,
			background: theme.palette.sidebar.linkBackgroundActive,
			fontWeight: 700
		}
	},
	listItemText: {
		margin: 0,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		minWidth: 219
	},
	listItemTextType: {
		fontWeight: 'inherit'
	},
	badgeRoot: {
		position: 'static' // ie 11 fix
	},
	badge: {
		backgroundColor: theme.palette.sidebar.badgeBackground,
		color: theme.palette.sidebar.badgeColor,
		fontWeight: 700,
		padding: theme.spacing(0.5, 1.75),
		transform: 'scale(1) translate(0%, 50%)',
		marginRight: theme.spacing(3)
	}
}));

const MenuListTabs = ({ routes }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useMenuListStyles();
	const activeTab = useSelector((state) => state.tabs.PAGE_SIDEBAR_TABS.active);

	function handleTabChange(tabName) {
		dispatch(
			setActiveTab({
				id: 'PAGE_SIDEBAR_TABS',
				active: tabName
			})
		);
	}

	return routes.map((tabname) => {
		if (!tabname) {
			return null;
		}

		const { name, data } = tabname;

		return (
			<ListItem
				button
				component="a"
				key={name}
				selected={name == activeTab ? true : false}
				classes={{
					root: classes.listItem
				}}
				onClick={() => handleTabChange(name)}
			>
				<ListItemText
					primary={t(data.label)}
					classes={{
						root: classes.listItemText
					}}
				/>
				<Badge
					badgeContent={data.total}
					showZero
					classes={{
						// "badge" class name is used by e2e automation tests
						badge: classNames('badge', classes.badge),
						root: classes.badgeRoot
					}}
					max={10000}
				/>
			</ListItem>
		);
	});
};

export default MenuListTabs;
