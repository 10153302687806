import React, { useState } from 'react';
import '@portal-reporting/report-ui-templates/dist/styles/report-styles.css';
import 'highcharts/css/highcharts.css';
import { ServiceReview } from '@portal-reporting/report-ui-templates';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceReviewDrillDown } from './Tickets';

const useStyles = makeStyles(() => ({
	drillDown: {
		padding: '0.1in'
	}
}));

const ServiceReviewMain = (props) => {
	const classes = useStyles();
	const drillDownRef = React.useRef(null);
	const { data, locale, t, dataParams = {}, reportName = '', staticUrl } = props;
	const [showTable, setShowTable] = useState(false);

	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [parameters, setParameters] = useState({});

	const renderDrillDownTable = (props) => {
		const updatedParams = { ...props.reportMetaData };
		loadParameters(updatedParams);
	};

	const hideDrillDownTable = () => {
		setShowTable(false);
	};

	const loadParameters = (params) => {
		setShowTable(false);
		setIsLoading(true);
		scrollToDDView();
		axios
			.get('/portal/reports/executedReport?reportId=' + dataParams.reportId)
			.then((response) => {
				const res = response.data;
				setParameters({
					...res,
					...params
				});
				setShowTable(true);
			})
			.catch(() => {
				setShowTable(false);
				setIsError(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const scrollToDDView = () => {
		drillDownRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	return (
		<>
			<ServiceReview
				data={data}
				t={t}
				dataParams={dataParams}
				locale={locale}
				staticUrl={staticUrl + '/img'}
				renderDrillDownTable={renderDrillDownTable}
				hideDrillDownTable={hideDrillDownTable}
				reportName={props.reportName}
			></ServiceReview>
			<div ref={drillDownRef} className={classes.drillDown}>
				{showTable && !isLoading ? (
					<ServiceReviewDrillDown
						fromChart={parameters}
						isError={isError}
						reportName={reportName}
					></ServiceReviewDrillDown>
				) : (
					isLoading && (
						<p>
							{t('events:summaryMessages.loadingPage')}
							<FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon>
						</p>
					)
				)}
			</div>
		</>
	);
};

export default ServiceReviewMain;
