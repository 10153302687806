/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const LinkComponent = withStyles((theme) => ({
	root: {
		'color': theme.palette.link.color,
		'transition': theme.transitions.create(['color'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		'&:hover': {
			color: theme.palette.link.colorHover
		}
	}
}))(Link);

export default LinkComponent;
