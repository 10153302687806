/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import i18n from 'i18next';
import i18nXHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { languages, preferences, meta, urls } from '../state/bootstrap';
import { DateTime } from 'luxon';

/**
 * All available languages for this application.
 */
export { languages };

/**
 * Language to load on startup.
 */
export const language = preferences.language.replace('_', '-');

const languagesDefault = languages.map((lng) => lng.value.replace('_', '-'));

/**
 * Initializes i18n at application level.
 */
i18n.use(i18nXHR)
	.use(initReactI18next)
	.init({
		ns: 'common',
		defaultNS: 'common',
		lng: language,
		languages: languagesDefault,
		fallbackLng: 'en-US',
		interpolation: {
			escapeValue: false,
			format: function (value, format, lng) {
				// luxon uses en-US instead of en_US format
				const locale = lng;

				if (value instanceof Date) {
					// handles format defined within the message
					return DateTime.fromJSDate(value).setLocale(locale).toFormat(format);
				} else if (value && value.value instanceof Date) {
					// handles format defined within the `date` variable
					return DateTime.fromJSDate(value.value).setLocale(locale).toFormat(value.format);
				}

				return value;
			}
		},
		backend: {
			loadPath: (lngs) => {
				let language = lngs[0].replace('_', '-');
				let staticVersion = 'static';

				if (!languagesDefault.includes(language)) {
					language = languagesDefault.find((lng) => lng.indexOf(language) !== -1);
				}

				if (!language) {
					language = '{{lng}}';
				} else {
					language = language.replace('-', '_');
				}

				if (meta.staticVersion) {
					// local development doesn't use version string
					staticVersion = `static/${meta.staticVersion}`;
				}

				return `${urls.cdn}/portal/${staticVersion}/locales/${language}/{{ns}}.json`;
			},
			crossDomain: true
		},
		react: {
			wait: true
		}
	});

export default i18n;
