/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useARMTicketsDrillDown } from './useARMTicketsDrillDown';
import React from 'react';
import ARMTicketsDrillDownContainer from '../../../../../containers/Reports/ARMTicketsDrillDown';

/**
 * @param {object} fromChart - Data returned from Chart
 * @returns {JSX.Element}
 */
export const ARMTicketsReportDrillDown = ({ fromChart, reportName }) => {
	const drillDownProps = useARMTicketsDrillDown(fromChart, reportName);
	return <ARMTicketsDrillDownContainer fromChart={fromChart} {...drillDownProps} />;
};
