/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
const initialState = {
	id: null,
	name: null,
	href: null,
	email: null,
	sourceEmail: null
};

export default function contactReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}
