/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import {
	RESET,
	SET_PROGRESS,
	SET_REPORT_NAME,
	SET_REPORT_VIEW,
	SET_REPORT_VIEW_PARAMS
} from '../../actions/components/reports';

const initialState = {
	progress: 'Executed',
	viewParams: {
		reportId: null,
		reportType: '',
		reportName: '',
		isViewed: false,
		isOnlyIhub: false,
		showAckModal: false,
		createdTime: 0
	},
	payload: {}
};

export default function reportsReducer(state = initialState, action) {
	switch (action.type) {
		case RESET:
			return {
				...initialState
			};
		case SET_PROGRESS:
			return {
				...state,
				progress: action.progress
			};
		case SET_REPORT_NAME:
			return {
				...state,
				reportName: action.reportName
			};
		case SET_REPORT_VIEW:
			return {
				...state,
				payload: action.payload
			};
		case SET_REPORT_VIEW_PARAMS:
			return {
				...state,
				viewParams: action.viewParams
			};
		default:
			return state;
	}
}
