/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useRef } from 'react';
import PageWithSidebarLayout from '../../../containers/Layouts/PageWithSidebar';
import ExecutedReportsDataTable from '../../../containers/DataTables/ExecutedReports';
import ReportTemplateViewContainer from '../View';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setProgress, setViewParams } from '../../../state/actions/components/reports';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ShowAlerts from '../Alerts';
import { fetchDataTableData, updateAutoRefreshDataTableParams } from '../../../state/actions/components/datatables';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles(() => ({
	title: {
		position: 'relative',
		display: 'flex',
		flexFlow: 'row wrap',
		padding: 'inherit',
		fontFamily: 'Arial, sans-serif',
		fontSize: 26.25,
		paddingLeft: 0,
		marginBottom: -30
	},
	form: {
		display: 'none'
	}
}));

const EXECUTED_REPORTS_REFRESH_INTERVAL = 60000;
const EXECUTED_REPORTS_MAX_REFRESH_INTERVAL = 900000;
const EXECUTED_REPORTS_NRS_PATH = '/portal/reports/executed';
const AUDIT_REPORT_ACTION_URL = '/portal/reports/auditAction';

export const REPORT_CONSTANTS = {
	VIEWPAGE: 'ViewPage',
	EXECUTED: 'Executed'
};

const ExecutedReportsPage = ({ name, routes }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('reports');
	const reports = useSelector((state) => state.reports);
	const sessionCounter = useSelector((state) => state.session.counter);
	const classes = useStyles();
	const { progress = REPORT_CONSTANTS.EXECUTED } = reports || {};
	const params = new URLSearchParams(useLocation().search);
	const appPageData = window.APP_PAGE_DATA || { showAck: false };

	useEffect(() => {
		axios(AUDIT_REPORT_ACTION_URL, {
			method: 'POST',
			params: {
				page: progress,
				reportName: reports.viewParams?.reportName,
				fromEmail: params.get('fromEmail') === 'true',
				createdTime: reports.viewParams?.createdTime
			}
		}).then(() => {});
		//eslint-disable-next-line
	}, [progress]);

	const autoRefreshFunction = () => {
		batch(() => {
			dispatch(updateAutoRefreshDataTableParams({ name: 'EXECUTED_REPORTS' }));
			dispatch(fetchDataTableData({ name: 'EXECUTED_REPORTS', action: 'MODIFY', dataCallbackFn: callbackFn }));
		});
	};

	const dispatchViewParams = (reportId, reportType, reportName, showAckModal, isViewed, isOnlyIhub, createdTime) => {
		dispatch(
			setViewParams({
				viewParams: {
					reportId: reportId,
					reportType: reportType,
					reportName: reportName,
					showAckModal: showAckModal,
					isViewed: isViewed,
					isOnlyIhub: isOnlyIhub,
					createdTime: createdTime
				}
			})
		);
	};

	useEffect(() => {
		const progress = params.get('fromEmail') === 'true' ? REPORT_CONSTANTS.VIEWPAGE : REPORT_CONSTANTS.EXECUTED;
		if (progress === REPORT_CONSTANTS.VIEWPAGE) {
			const reportId = params.get('reportId');
			const reportType = params.get('reportType');
			const reportName = params.get('reportName');
			const showAckModal = appPageData.showAck === true;
			const isViewed = params.get('isViewed') === 'true';
			const isOnlyIhub = params.get('isOldGen') === 'true';
			const createdTime = params.get('createdTime');
			dispatchViewParams(reportId, reportType, reportName, showAckModal, isViewed, isOnlyIhub, createdTime);
			window.history.replaceState(null, null, EXECUTED_REPORTS_NRS_PATH);
		}
		dispatch(
			setProgress({
				progress: progress
			})
		);

		//eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		window.lastActiveTime = DateTime.local();
		const startTime = new Date().getTime();
		const clearTime = setInterval(() => {
			const currentTime = new Date().getTime();
			if (currentTime - startTime > EXECUTED_REPORTS_MAX_REFRESH_INTERVAL) {
				clearInterval(clearTime);
				return;
			}
			autoRefreshFunction();
		}, EXECUTED_REPORTS_REFRESH_INTERVAL);
		return () => {
			clearInterval(clearTime);
		};
		//eslint-disable-next-line
	}, [progress, sessionCounter]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [progress]);

	const handleSelectSingleRowClick = async (event, row) => {
		const execReport = row.original;
		if (execReport.reportState !== 'SUCCESS') {
			return;
		}
		const showAckModal = execReport.isShared && execReport.ackRequired && !execReport.acknowledged;
		dispatchViewParams(
			execReport.reportId,
			execReport.reportType,
			execReport.reportName,
			showAckModal,
			execReport.isViewed,
			execReport.isOnlyIhub,
			execReport.completionTime
		);

		batch(() => {
			dispatch(
				setProgress({
					progress: REPORT_CONSTANTS.VIEWPAGE
				})
			);
		});
	};

	const viewReport = {
		name: reports?.viewParams?.reportName,
		skipNameTranslation: true,
		isActive: reports?.progress === REPORT_CONSTANTS.VIEWPAGE,
		description: t('reports:reportsType.displayName.' + reports?.viewParams?.reportType),
		path: EXECUTED_REPORTS_NRS_PATH
	};

	routes = routes.map((route) => {
		if (route?.path === EXECUTED_REPORTS_NRS_PATH && reports?.viewParams?.reportId) {
			route.routes = [{ viewReport }];
		}
		return route;
	});

	const alertsRef = useRef();

	const callbackFn = () => {
		alertsRef.current();
	};

	return (
		<>
			<PageWithSidebarLayout
				title={t('common:pages.reports.executedNRS.reportsTitle')}
				subtitle={t('common:pages.reports.create.description')}
				navRoutes={routes}
				contentBackgroundColor="light"
				hideTitleInSmallView
			>
				<ShowAlerts alertsRef={alertsRef} />
				{reports?.progress === REPORT_CONSTANTS.VIEWPAGE ? (
					<ReportTemplateViewContainer
						name={t('common:pages.reports.executedNRS.reportsTitle')}
						routes={routes}
						dataCallbackFn={callbackFn}
					/>
				) : (
					<>
						<div className={classes.title}>{t('executedReports.executedReports')}</div>
						<ExecutedReportsDataTable
							title={name}
							handleSelectSingleRowClick={handleSelectSingleRowClick}
							dataCallbackFn={callbackFn}
						/>
					</>
				)}
			</PageWithSidebarLayout>
		</>
	);
};

export default ExecutedReportsPage;
