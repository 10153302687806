/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PivotTableHeader from './TableHeader';
import PivotDataTable from '../../../components/PivotDataTable';
import { usePivotDataTable } from '../../../components/PivotDataTable/usePivotDataTable';
import PropTypes from 'prop-types';
import { getFormattedDateTimeByLocale, getLocaleByUserPreferenceTimeZone } from '../../../pages/Reports/View/utils';

function getAggregationTableTitle(categoryType, groupingsBy, t) {
	if (categoryType === 'TOTAL_ESCALATIONS') {
		return t('reports:eventAnalysisStats.drillDown.totalEscalationsTitle');
	} else if (categoryType === 'SOC_ANALYZED_EVENTS') {
		return t('reports:eventAnalysisStats.headers.socAnalyzedEventsTitle', { groupingsBy: groupingsBy });
	} else if (categoryType === 'CORRELATED_EVENTS') {
		return t('reports:eventAnalysisStats.headers.correlatedEventsTitle', { groupingsBy: groupingsBy });
	} else if (categoryType === 'SECURITY_EVENTS') {
		return t('reports:eventAnalysisStats.headers.securityEventsTitle', { groupingsBy: groupingsBy });
	} else {
		return '';
	}
}

function getDrillDownType(categoryType) {
	if (categoryType === 'TOTAL_ESCALATIONS') {
		return 'INCIDENTS';
	} else {
		return 'EVENTS';
	}
}

function getAggregationTableSubTitle(requestPayload) {
	const options = { timeFormat: 'HH:mm ZZZZ', timeZoneLocale: getLocaleByUserPreferenceTimeZone() };

	return (
		getFormattedDateTimeByLocale(requestPayload.startTime, options) +
		' - ' +
		getFormattedDateTimeByLocale(requestPayload.endTime, options)
	);
}

const DrillDownTableContainer = ({
	apiUrl,
	requestPayload,
	method,
	downloadPdf,
	downloadCsv,
	groupingOptions,
	defaultGroupingOption,
	fetchDataResponseFormatter,
	drillDownId,
	showPagination = true,
	rowGroupingWidth = '40%',
	enableDrillDown = true
}) => {
	const { t } = useTranslation(['reports', 'dashboard']);

	const [selectedRowGroupingOption, setSelectedRowGroupingOption] = useState(defaultGroupingOption);
	const downloadMenu = useMemo(
		() => [
			{
				title: t('dashboard:drilldown.common.download'),
				subMenus: [
					{
						title: t('dashboard:drilldown.common.pdf'),
						tooltip: t('dashboard:drilldown.events.downloadAggregateCounts'),
						onClick: () => {
							downloadPdf(selectedRowGroupingOption?.id);
						}
					},
					{
						title: t('dashboard:drilldown.common.csv'),
						tooltip: t('dashboard:drilldown.events.downloadAggregateCounts'),
						onClick: () => {
							downloadCsv(selectedRowGroupingOption?.id);
						}
					}
				]
			}
		],
		[downloadPdf, downloadCsv, t, selectedRowGroupingOption]
	);
	const drillDownType = getDrillDownType(requestPayload.categoryType);

	const options = useMemo(() => {
		return {
			url: apiUrl,
			params: requestPayload,
			method
		};
	}, [apiUrl, requestPayload, method]);
	const {
		data,
		columns,
		settings,
		isError,
		isLoading,
		skipResetRef,
		fetchData,
		updateParams,
		pageSize
	} = usePivotDataTable({
		name: drillDownId,
		options: options,
		fetchDataResponseFormatter: (data, params) => {
			return fetchDataResponseFormatter(
				data,
				groupingOptions,
				onGroupingOptionClick,
				params,
				t,
				rowGroupingWidth,
				enableDrillDown
			);
		},
		updateColumnsOnFetch: true,
		updateSettingOnFetch: true
	});

	const onGroupingOptionClick = (option) => {
		updateParams({ rowGrouping: option.id, page: 1 });
		setSelectedRowGroupingOption(option);
		fetchData();
	};

	return (
		<>
			<Box display="flex" flexGrow={1}>
				<PivotTableHeader
					menus={downloadMenu}
					headingText={getAggregationTableTitle(
						requestPayload.categoryType,
						selectedRowGroupingOption?.text,
						t
					)}
					subHeadingText={getAggregationTableSubTitle(requestPayload)}
				/>
			</Box>
			<Box marginTop={2} marginBottom={4} marginLeft={1} marginRight={1}>
				<PivotDataTable
					showPagination={showPagination}
					hasPaginationData={true}
					columns={columns ? columns : []}
					data={data.elements ? data.elements : []}
					isError={isError}
					isLoading={isLoading}
					skipResetRef={skipResetRef}
					name={drillDownId}
					errorMsg={'dashboard:drilldown.common.tableMsg.error'}
					emptyMsg={'dashboard:drilldown.common.tableMsg.empty'}
					loadingMsg={'dashboard:drilldown.common.tableMsg.loading'}
					fetchData={fetchData}
					pageChange={(page) => {
						updateParams({ page });
					}}
					totalPages={settings?.totalPages}
					pageSize={pageSize}
					drillDownType={drillDownType}
				/>
			</Box>
		</>
	);
};
DrillDownTableContainer.propTypes = {
	apiUrl: PropTypes.string,
	requestPayload: PropTypes.object,
	method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
	downloadPdf: PropTypes.func,
	downloadCsv: PropTypes.func,
	groupingOptions: PropTypes.array,
	defaultGroupingOption: PropTypes.object,
	fetchDataResponseFormatter: PropTypes.func,
	drillDownId: PropTypes.string
};
export default DrillDownTableContainer;
