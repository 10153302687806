/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { other } from '../../bootstrap';

export default function otherReducer(state = other, action) {
	switch (action.type) {
		default:
			return state;
	}
}
