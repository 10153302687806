/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { hideModal } from '../../../state/actions/components/modals';
import numeral from 'numeral';

import Button from '../../../components/Inputs/Button';
import Link from '../../../components/Link';
import { useStyles } from './useStyles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';

const DownloadDescriptionModal = ({ data, isOpen }) => {
	const { t } = useTranslation('administration');
	const dispatch = useDispatch();
	const [isChecked, setIsChecked] = useState(false);
	const classes = useStyles();

	function handleClose() {
		dispatch(
			hideModal({
				name: 'DOWNLOAD_DESCRIPTION'
			})
		);
	}

	function handleDownload() {
		window.location = addDownloadParameter(`/portal${data.responseMap.image.url}`);
	}

	function handleCheck() {
		setIsChecked(!isChecked);
	}

	function addDownloadParameter(url) {
		return url.indexOf('?') > -1 ? url.concat('&download=true') : url.concat('?download=true');
	}

	return (
		<React.Fragment>
			{data.errorState ? (
				<Dialog
					fullWidth
					disableBackdropClick
					disableEscapeKeyDown
					open={isOpen}
					onClose={handleClose}
					maxWidth="sm"
					aria-labelledby="customized-dialog-title"
				>
					<DialogTitle id="time-zone-modal" disableTypography>
						<Typography component="h2" variant="h3">
							{t('deviceRegistration.summary.actions.downloads')}
							<IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
								<FontAwesomeIcon icon={faTimes} size="1x" />
							</IconButton>
						</Typography>
					</DialogTitle>
					<DialogContent dividers={true}>
						<p>{t('deviceRegistration.summary.downloadDescriptionModal.downloads.body.error')}</p>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose} className={classes.btnClose}>
							{t('deviceRegistration.summary.actions.close')}
						</Button>
					</DialogActions>
				</Dialog>
			) : (
				<Dialog
					fullWidth
					disableBackdropClick
					disableEscapeKeyDown
					open={isOpen}
					onClose={handleClose}
					maxWidth="sm"
					aria-labelledby="time-zone-modal"
				>
					<DialogTitle id="time-zone-modal" disableTypography className={classes.title}>
						<Typography component="h2" variant="h3" className={classes.header}>
							{t('deviceRegistration.summary.actions.downloads')}
							<IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
								<FontAwesomeIcon icon={faTimes} size="1x" />
							</IconButton>
						</Typography>
					</DialogTitle>
					<DialogContent dividers={true} className={classes.dialogContent}>
						{data.responseMap.image ? (
							<React.Fragment>
								<p>
									<strong className={classes.strongClass}>
										{t(
											'deviceRegistration.summary.downloadDescriptionModal.downloads.body.fileForDownload',
											{
												fileName: `${data.responseMap.image.name}`
											}
										)}
									</strong>
									<span>
										{' '}
										{t(
											'deviceRegistration.summary.downloadDescriptionModal.downloads.body.fileSize',
											{ fileSize: `${numeral(data.responseMap.image.size).format('0 b')}` }
										)}
									</span>
								</p>
								<div>
									<p className={classes.paragraph}>
										<strong className={classes.strongClass}>
											{t(
												'deviceRegistration.summary.downloadDescriptionModal.downloads.body.registrationCode',
												{
													registrationKey: `${data.registrationKey}`
												}
											)}
										</strong>
										<br></br>
										{t(
											'deviceRegistration.summary.downloadDescriptionModal.downloads.body.enterCode',
											{ deviceType: `${data.deviceType}` }
										)}
									</p>
									<p className={classes.paragraph}>
										{t(
											'deviceRegistration.summary.downloadDescriptionModal.downloads.body.codeStatement'
										)}
									</p>
								</div>
								<div>
									<label className={classes.deviceRegistrationDownloadsModalCheckbox}>
										<input
											type={'checkbox'}
											onChange={handleCheck}
											value={isChecked}
											className={classes.checkBox}
										/>
									</label>
									<small>
										{t(
											'deviceRegistration.summary.downloadDescriptionModal.downloads.body.checkboxLabel'
										)}
									</small>
								</div>
								<br></br>
							</React.Fragment>
						) : (
							''
						)}
						{data.deviceType !== 'iSensor' ? (
							<p className={classes.deviceRegistrationDownloadsModalOpenSourceInfo}>
								{t(
									'deviceRegistration.summary.downloadDescriptionModal.downloads.body.openSourceInfo',
									{ deviceType: 'CTA' }
								)}
							</p>
						) : (
							<p className={classes.deviceRegistrationDownloadsModalOpenSourceInfo}>
								{t(
									'deviceRegistration.summary.downloadDescriptionModal.downloads.body.virtualISensorOpenSourceInfo',
									{ deviceType: 'iSensor' }
								)}
							</p>
						)}
						<Link
							className={classes.deviceRegistrationDownloadsModalOpenSourceInfo}
							href={addDownloadParameter(`/portal${data.responseMap.opensource.url}`)}
							target="_self"
							rel="noopener"
						>
							{data.responseMap.opensource.name}{' '}
							{t('deviceRegistration.summary.downloadDescriptionModal.downloads.body.fileSize', {
								fileSize: `${numeral(data.responseMap.opensource.size).format('0 b')}`
							})}
						</Link>
					</DialogContent>
					<DialogActions className={classes.footer}>
						<Button onClick={handleClose} size="small" className={classes.btnClose}>
							{t('deviceRegistration.summary.actions.close')}
						</Button>
						{data.responseMap.image ? (
							<Button
								disabled={!isChecked}
								size="small"
								className={classes.btnDownload}
								onClick={handleDownload}
							>
								{t('deviceRegistration.summary.downloadDescriptionModal.downloads.footer.downloadNow')}
							</Button>
						) : (
							''
						)}
					</DialogActions>
				</Dialog>
			)}
		</React.Fragment>
	);
};

export default DownloadDescriptionModal;
