/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import { sendMessage, sendClientTyping } from '../../../../../state/actions/components/chat';
import Button from '../../../../Inputs/Button';
import TextField from '../../../../Inputs/Formik/TextField';

const useStyles = makeStyles((theme) => ({
	button: {
		color: theme.palette.action.active,
		fontWeight: theme.typography.fontWeightBold
	}
}));

const ConverstationViewForm = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const canSendTyping = useRef(true);
	const typingTimer = useRef();

	const sendMessageToAgent = (values, { setSubmitting, setFieldValue }) => {
		const content = values.message.trim();

		if (content) {
			dispatch(sendMessage({ content })).then(() => {
				setSubmitting(false);
				setFieldValue('message', '');
			});
		} else {
			setSubmitting(false);
		}
	};

	const onKeyDown = (submitForm) => (event) => {
		window.clearTimeout(typingTimer.current);

		if (canSendTyping.current) {
			canSendTyping.current = false;
			dispatch(sendClientTyping());
		}

		typingTimer.current = window.setTimeout(() => {
			canSendTyping.current = true;
		}, 1000);

		if (event.keyCode === 13 && !event.shiftKey) {
			event.preventDefault();
			submitForm();
		}
	};

	return (
		<Formik
			onSubmit={sendMessageToAgent}
			initialValues={{
				message: ''
			}}
		>
			{({ submitForm, isSubmitting }) => (
				<Form autoComplete="off" className={classes.form}>
					<Field
						multiline
						fullWidth
						rows={2}
						rowsMax={3}
						name="message"
						component={TextField}
						placeholder={t('common:chat.conversation.type')}
						onKeyDown={onKeyDown(submitForm)}
					/>
					<div>
						<Button
							variant="text"
							className={classes.button}
							onClick={submitForm}
							isLoading={isSubmitting}
							fullWidth
						>
							{t('common:chat.buttons.send')}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ConverstationViewForm;
