/**
 * Classification': '//SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { createMuiTheme } from '@material-ui/core/styles';
import baseTheme from '../Base';

export default createMuiTheme({
	...baseTheme,
	themeName: 'Secureworks Light',
	palette: {
		type: 'light',
		common: { black: '#000', white: '#fff', blue: '#473eff', blueDark: '#2600b3' },
		primary: {
			light: '#777777',
			main: '#3d3d3d',
			dark: '#181818',
			contrastText: '#fff'
		},
		secondary: {
			light: '#9f9aff',
			main: '#473eff',
			dark: '#2600b3',
			contrastText: '#fff'
		},
		error: {
			light: '#ff463c',
			main: '#ce1126',
			dark: '#8e0b1b',
			contrastText: '#fff'
		},
		success: {
			light: '#40d67d',
			main: '#28bb64',
			dark: '#257545',
			contrastText: '#fff'
		},
		text: {
			primary: '#181818',
			secondary: '#969696',
			disabled: '#fff',
			hint: '#969696',
			icon: '#3d3d3d'
		},
		background: {
			paper: '#fff',
			default: '#efefef',
			stripe: '#efefef',
			level2: '#3d3d3d',
			level1: '#181818'
		},
		card: {
			background: '#efefef',
			border: '#eaeaea',
			width: '288px',
			minHeight: '105px',
			outlineColor: '#473eff',
			watermark: {
				opacity: 0.9
			},
			color: '#444',
			status: {
				ok: '#388e3c',
				warn: '#ff9800'
			},
			tooltip: {
				backgroundColor: '#000',
				color: 'rgba(F, F, F, 0.87)',
				opacity: '0.8',
				fontSize: '14px'
			},
			modal: {
				btnClose: {
					background: '#e8e8e8',
					fontColor: '#3d3c3c',
					btnHover: {
						background: '#fefefe',
						fontColor: '#2f2e2e'
					}
				}
			}
		},
		action: {
			active: '#473eff',
			hover: '#2600b3',
			hoverOpacity: 0.1,
			selected: '#473eff',
			disabled: '#ffffff',
			disabledBackground: '#d1d1d1'
		},
		link: {
			color: '#2600b3',
			colorHover: '#473eff',
			colorContrast: '#fff'
		},
		divider: '#d1d1d1',
		infoIcon: {
			color: '#3d3d3d'
		},
		severity: {
			medium: '#f28219',
			high: '#cf1125',
			low: '#ffc711'
		},
		healthStatus: {
			unknown: '#d1d1d1',
			warning: '#f28219',
			bad: '#cf1125',
			good: '#afc01a'
		},
		status: {
			error: {
				color: '#ce1126',
				border: '#ce1126',
				background: '#fff'
			},
			info: {
				color: '#181818',
				border: '#473eff',
				background: '#d9d7ff',
				outlined: {
					link: '#2600b3'
				}
			},
			success: {
				color: '#181818',
				border: '#28bb64',
				background: '#a9edc5'
			},
			warning: {
				color: '#181818',
				border: '#ffc80f',
				background: '#fff1c2'
			}
		},

		input: {
			color: '#444',
			border: '#b5b5b5',
			background: '#fff',
			placeholder: '#969696',
			disabled: '#ffffff',
			disabledBackground: '#d1d1d1'
		},

		skeleton: {
			background: '#efefef'
		},

		disabled: {
			color: '#fff',
			border: '#d1d1d1',
			background: '#d1d1d1'
		},

		/* <Chat /> */
		chat: {
			frame: {
				border: '#473eff',
				background: '#fff'
			},
			message: {
				in: {
					color: '#181818',
					background: '#FFF7DB'
				},
				out: {
					color: '#181818',
					background: '#D9D7FF'
				}
			},
			divider: '#ccc',
			button: {
				disabled: {
					color: '#ffffff',
					background: '#b5b5b5'
				}
			}
		},

		/* <DataTable /> <PivotDataTable/>*/
		table: {
			borderColor: '#d1d1d1',
			cell: {
				head: {
					color: '#3d3d3d',
					background: '#d1d1d1',
					dropdown: {
						border: '#b5b5b5',
						title: {
							background: '#d1d1d1'
						},
						dropdownItem: {
							color: '#3d3d3d',
							backgroundActive: '#473eef'
						}
					}
				},
				body: {
					color: '#3d3d3d',
					background: '#fff',
					border: '#fff'
				},
				selected: {
					color: '#3d3d3d',
					background: '#fff7db'
				},
				striped: {
					color: '#3d3d3d',
					background: '#efefef'
				},
				hover: {
					color: '#3d3d3d',
					background: '#fff7db'
				},
				highlight: {
					background: '#d4f1e0',
					border: '#28bb64',
					color: '#3d3d3d'
				},
				drillDownCell: {
					background: '#d4f1e0',
					color: '#3d3d3d'
				}
			},
			sort: {
				color: '#2600b3',
				colorHover: '#473eff'
			}
		},
		/* <ApexCharts /> */
		charts: {
			colors: ['#473eff', '#28bb64', '#ffc80f', '#d1007a', '#ff463c', '#969696']
		},
		/* <Accordion /> */
		accordion: {
			background: '#fff',
			border: '#d1d1d1',
			color: '#3d3d3d'
		},
		accordionSummary: {
			background: '#efefef',
			border: '#d1d1d1',
			color: '#3d3d3d',
			colorHover: '#473eff'
		},
		accordionDetails: {
			background: '#fff',
			color: '#3d3d3d'
		},
		/* <Autocomplete /> */
		autocomplete: {
			color: '#444',
			colorError: '#ce1126',
			border: '#b5b5b5',
			borderError: '#ce1126',
			background: '#fff',
			colorDisabled: '#d1d1d1',
			borderDisabled: '#d1d1d1',
			backgroundDisabled: '#fff',
			chip: {
				background: '#efefef',
				borderColor: '#b5b5b5',
				color: '#3d3d3d'
			}
		},
		/* <Dropdown /> */
		dropdown: {
			background: '#fff',
			border: '#fff',
			color: '#777'
		},
		dropdownItem: {
			color: '#3d3d3d',
			divider: '#efefef',
			colorActive: '#473eff',
			backgroundActive: '#efefef',
			colorHover: '#fff',
			backgroundHover: '#473eff',
			colorDisabled: '#777',
			backgroundDisabled: '#efefef'
		},
		/* <Header /> */
		header: {
			background: '#181818',
			color: '#969696'
		},
		headerBar: {
			background: '#181818',
			color: '#969696',
			link: '#efefef',
			icon: '#595959',
			iconHover: '#efefef',
			feedback: '#efefef',
			input: '#fff',
			searchInput: '#595959'
		},
		headerNav: {
			background: '#3d3d3d',
			color: '#fff',
			link: '#efefef',
			linkHover: '#efefef',
			linkBackgroundHover: '#595959',
			linkBorderHover: '#efefef',
			linkActive: '#efefef',
			linkBackgroundActive: '#28bb64',
			icon: '#595959',
			iconHover: '#efefef'
		},
		headerNavResp: {
			background: '#efefef',
			secondary: {
				level1: {
					background: '#d1d1d1',
					border: '#777',
					link: '#3d3d3d'
				},
				level2: {
					background: '#efefef',
					border: '#b5b5b5',
					link: '#3d3d3d'
				}
			}
		},
		/* Content */
		content: {
			default: {
				background: '#efefef'
			},
			light: {
				background: '#ffffff'
			}
		},
		/* <Footer /> */
		footer: {
			background: '#d1d1d1',
			text: '#777'
		},
		footerBar: {
			background: '#d1d1d1',
			border: '#777',
			text: '#777',
			link: '#2600b3',
			linkHover: '#473eff'
		},
		footerNav: {
			background: '#efefef',
			border: '#473eff',
			text: '#777',
			link: '#777',
			linkHover: '#473eff',
			icon: '#777',
			iconHover: '#473eff'
		},
		/* <Sidebar /> */
		sidebar: {
			background: '#fff',
			border: '#d1d1d1',
			link: '#2600b3',
			linkHover: '#473eff',
			linkBackgroundHover: '#efefef',
			linkActive: '#595959',
			linkBackgroundActive: '#d1d1d1',
			icon: '#b5b5b5',
			iconHover: '#473eff',
			badgeColor: '#ffffff',
			badgeBackground: '#b5b5b5',
			subMenu: {
				active: '#777'
			}
		},
		/* <Pagination /> <PivotDataTable/>*/
		pagination: {
			color: '#2600b3',
			colorHover: '#473eff',
			background: '#ffffff',
			backgroundHover: '#efefef',
			borderColor: '#d1d1d1',
			activeColor: '#969696',
			activeBackground: '#efefef',
			disabledColor: '#969696'
		},
		/* <Widgets /> */
		widget: {
			background: '#fff',
			divider: '#efefef',
			color: '#3d3d3d'
		},
		widgetHead: {
			background: '#fff',
			color: '#3d3d3d'
		},
		widgetTable: {
			background: '#fff',
			color: '#3d3d3d'
		},
		widgetTableHead: {
			background: '#fff',
			color: '#3d3d3d'
		},
		widgetTableBody: {
			background: '#fff',
			color: '#3d3d3d'
		},
		widgetChart: {
			background: '#fff',
			color: '#3d3d3d'
		},
		widgetDrillDown: {
			closeButton: {
				color: '#181818'
			},
			menu: {
				hoverColor: '#595959',
				border: '#d1d1d1'
			}
		},
		datacube: {
			panel: {
				summary: {
					background: '#564efc'
				},
				detail: {
					background: '#ffffff',
					content: {
						background: '#ffffff'
					}
				},
				rowKey: '#585858',
				rowValue: '#757575'
			},
			timesCircle: '#b4b4b4',
			label: {
				disabled: {
					text: '#bababa'
				},
				active: {
					text: '#111331'
				}
			},
			rootNodeItemAvatar: {
				border: '#fefefe',
				background: '#0f1523'
			},
			circle: {
				disabled: {
					fill: '#eeeeee',
					stroke: '#b8b8b8',
					text: '#b8b8b8'
				},
				active: {
					stroke: '#fefefe',
					fill: '#0f1523',
					text: '#e4e7eb'
				},
				stroke: '#cecece',
				fill: '#fefefe',
				text: '#969696'
			},
			groupIcon: {
				circle: {
					text: '#969696',
					fill: '#fefefe'
				},

				active: {
					circle: {
						fill: '#0f1523'
					},
					avatar: {
						border: '#fefefe'
					}
				},
				avatar: {
					border: '#d1d1d1'
				}
			},
			filters: {
				border: '#34c111',
				icon: {
					active: '#473eff',
					disabled: '#bababa'
				},
				label: {
					text: '#3d3d3d'
				}
			}
		},
		deviceRegistration: {
			credential: {
				status: {
					enabled: '#28bb64',
					disabled: '#ffc80f'
				}
			},
			configuration: {
				input: {
					warn: '#ce1126'
				}
			}
		},
		cancelButton: {
			backgroundColor: '#efefef',
			color: '#777',
			borderColor: '#b5b5b5',
			hover: {
				backgroundColor: '#fff',
				color: '#777'
			}
		},
		nextButton: {
			backgroundColor: '#2600b3',
			color: '#777',
			borderColor: '#2600b3',
			disabled: {
				backgroundColor: '#b5b5b5',
				color: '#777',
				borderColor: '#b5b5b5'
			},
			hover: {
				backgroundColor: '#473eff',
				borderColor: '#473eff'
			}
		},
		download: {
			modal: {
				footer: {
					backgroundColor: '#f5f5f5',
					closeButton: {
						fontSize: '15px',
						lineHeight: '22px',
						backgroundColor: '#efefef',
						color: '#777',
						borderColor: '#b5b5b5',
						hover: {
							backgroundColor: '#fff',
							borderColor: '#b5b5b5'
						}
					},
					downloadButton: {
						color: '#fff',
						backgroundColor: '#473eff',
						borderColor: '#473eff',
						disabled: {
							backgroundColor: '#b5b5b5',
							color: '#777',
							borderColor: '#b5b5b5'
						},
						hover: {
							backgroundColor: '#473eff',
							borderColor: '#473eff',
							color: '#fff'
						}
					}
				}
			},
			configurePage: {
				header: {
					downloadButton: {
						backgroundColor: '#efefef',
						color: '#777',
						borderColor: '#b5b5b5',
						hover: {
							borderColor: '#0f1523',
							backgroundColor: '#fff'
						}
					}
				}
			}
		},
		copyButton: {
			backgroundColor: '#efefef',
			color: '#777',
			borderColor: '#b5b5b5',
			hover: {
				backgroundColor: '#fff',
				color: '#777'
			},
			disabled: {
				backgroundColor: '#b5b5b5',
				color: '#777',
				borderColor: '#b5b5b5'
			}
		},
		copyToClipboardIcon: {
			color: '#d1d1d1'
		},
		filterSets: {
			color: '#fff',
			backgroundColor: '#28bb64'
		},
		reports: {
			errorMessage: {
				color: '#fff',
				border: '#ce1126',
				background: '#ce1126'
			},
			button: {
				backgroundColor: '#2600b3',
				hoverBackgroundColor: '#473eff'
			},
			sectionHeader: {
				color: '#3d3d3d'
			},
			arrow: {
				color: '#000'
			},
			tabBorder: {
				default: {
					color: '#efefef'
				},
				active: {
					color: '#473eff'
				}
			},
			tabsSeparator: {
				borderTopColor: '#efefef',
				borderBottomColor: '#fff'
			},
			tooltip: {
				color: '#fff',
				backgroundColor: '#555',
				border: '#b5b5b5'
			},
			cancelButton: {
				background: '#fff',
				color: '#777',
				hover: {
					background: '#fff',
					color: '#777'
				}
			},
			label: {
				color: '#3d3d3d'
			},
			error: {
				color: '#ce1126'
			},
			action: {
				disabledBackground: '#b5b5b5'
			},
			modal: {
				footer: {
					backgroundColor: '#f5f5f5'
				},
				dividers: {
					borderColor: '#efefef'
				}
			},
			infoIcon: {
				color: '#777'
			},
			greenCheck: {
				color: '#28bb64'
			}
		},
		newLabel: {
			color: '#3D3D3D',
			background: '#FCC911'
		},
		transferList: {
			infoContainer: '#acabab',
			newlyMoved: {
				backgroundColor: 'rgba(217, 215, 255, 0.53)',
				hover: '#d9d7ff'
			},
			ListItemEven: '#efefef',
			ListItemEvenHover: {
				hover: '#bbb'
			},
			ListItemOddHover: {
				hover: '#bbb'
			},
			textColor: '#000000',
			checkBox: '#000000'
		},
		logGroupRefresh: {
			color: '#473eff',
			border: '1px solid rgba(71, 62, 255, 0.5)'
		},
		filterbar: {
			background: '#efefef',
			filterbarHead: {
				background: '#28bb64',
				button: {
					hover: '#40d67d'
				},
				chipWrapperBg: '#fff',
				chipWrapperBgEmpty: '#efefef',
				applyBtn: {
					color: '#c4c4c4',
					hover: '#999999'
				},
				clearBtn: {
					color: '#3d3d3d',
					hover: '#473eff',
					expanded: {
						color: '#fff'
					},
					disabled: {
						color: '#777'
					},
					backgroundColor: '#efefef'
				},
				focused: {
					borderColor: '#473eff66'
				},
				placeholderColor: '#b5b5b5'
			},
			filterbarBody: {
				color: '#3d3d3d'
			},
			filterbarFooter: {
				background: '#94e8b6',
				button: {
					background: '#efefef',
					border: '#b5b5b5',
					color: '#777',
					hover: '#fff'
				}
			}
		}
	}
});
