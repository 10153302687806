/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useState, useCallback } from 'react';
import InformationViewTicketFlow from './Ticket';
import InformationViewTopicFlow from './Topic';

const InformationView = () => {
	const [hasTicketField, setHasTicketField] = useState(true);

	const handleSwap = useCallback((value) => {
		setHasTicketField(value);
	}, []);

	return hasTicketField ? (
		<InformationViewTicketFlow handleSwap={handleSwap} />
	) : (
		<InformationViewTopicFlow handleSwap={handleSwap} />
	);
};

export default InformationView;
