/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { post, get } from 'axios';
import { languages } from '../../bootstrap';

export const PREFERENCES_CHANGE_LANGUAGE_SUCCESS = 'PREFERENCES_CHANGE_LANGUAGE_SUCCESS';
export const PREFERENCES_CHANGE_LANGUAGE_PROGRESS = 'PREFERENCES_CHANGE_LANGUAGE_PROGRESS';

export const PREFERENCES_CHANGE_THEME_SUCCESS = 'PREFERENCES_CHANGE_THEME_SUCCESS';
export const PREFERENCES_CHANGE_THEME_PROGRESS = 'PREFERENCES_CHANGE_THEME_PROGRESS';

const SET_THEME_API = '/portal/preferences/contact-preferences?preference-name=PORTAL.SELECTED_THEME';

const setLocaleCookie = ({ value }) => {
	// update locale cookie
	document.cookie = `locale=${value};path=/;secure`;
};

const reloadQualtrics = () => {
	if (window.QSI && window.QSI.API) {
		// update all Qualtrics instances
		window.QSI.API.unload();
		window.QSI.API.load();
	}
};

const setLanguageOnHTML = ({ value }) => {
	const htmlNode = document.querySelector('html');

	if (htmlNode) {
		htmlNode.setAttribute('lang', value.replace('_', '-'));
	}
};

const setLanguageOnPortalData = ({ value }) => {
	get('/portal?lang=' + value);
};

export function changeLanguage({ language, i18n, reloadPage = false }) {
	const locale = languages.find((item) => item.value === language) || languages[0];

	return (dispatch) => {
		dispatch(
			changeLanguageProgress({
				isError: false,
				isLoading: true
			})
		);

		if (reloadPage) {
			// update locale cookie
			setLocaleCookie(locale);
			// reload the page (for consistency with backbone behaviour)
			location.reload();

			return;
		}

		return i18n
			.changeLanguage(locale.value.replace('_', '-'))
			.then(() => {
				dispatch(
					changeLanguageSuccess({
						language: locale.value
					})
				);

				dispatch(
					changeLanguageProgress({
						isError: false,
						isLoading: false
					})
				);

				setLanguageOnPortalData(locale);
				// updates html node
				setLanguageOnHTML(locale);
				// update locale cookie
				setLocaleCookie(locale);
				// update qualtrics tracking
				reloadQualtrics();
			})
			.catch((error) => {
				dispatch(
					changeLanguageProgress({
						isError: true,
						isLoading: false
					})
				);
				throw new Error(error.message);
			});
	};
}

export function changeTheme({ theme }) {
	const isDark = theme === 'dark-theme';
	const themeName = isDark ? 'dark-theme' : 'light-theme';
	const themePrev = isDark ? 'light-theme' : 'dark-theme';
	const domNode = document.querySelector('html');

	return (dispatch, getState) => {
		const { preferences } = getState();
		const { theme } = preferences || {};

		if (!theme) {
			return;
		}

		const data = {
			contactPreferenceId: theme.contactPreferenceId,
			preferenceId: theme.preferenceId,
			preferenceName: theme.preferenceName,
			preferenceValue: themeName
		};

		dispatch(
			changeThemeProgress({
				isError: false,
				isLoading: true
			})
		);

		// update html classes
		domNode.classList.add(themeName);
		domNode.classList.remove(themePrev);

		// update state store
		dispatch(
			changeThemeSuccess({
				theme: {
					preferenceValue: themeName
				}
			})
		);

		// persist theme change in backend
		post(SET_THEME_API, data)
			.then(() =>
				dispatch(
					changeThemeProgress({
						isError: false,
						isLoading: false
					})
				)
			)
			.catch(() =>
				dispatch(
					changeThemeProgress({
						isError: true,
						isLoading: false
					})
				)
			);
	};
}

export const changeLanguageSuccess = ({ language }) => ({
	type: PREFERENCES_CHANGE_LANGUAGE_SUCCESS,
	payload: {
		language
	}
});

export const changeLanguageProgress = ({ isError, isLoading }) => ({
	type: PREFERENCES_CHANGE_LANGUAGE_PROGRESS,
	payload: {
		isError,
		isLoading
	}
});

export const changeThemeSuccess = ({ theme }) => ({
	type: PREFERENCES_CHANGE_THEME_SUCCESS,
	payload: {
		theme
	}
});

export const changeThemeProgress = ({ isError, isLoading }) => ({
	type: PREFERENCES_CHANGE_THEME_PROGRESS,
	payload: {
		isError,
		isLoading
	}
});
