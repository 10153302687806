/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { get } from 'axios';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hideModal, showModal } from '../../../state/actions/components/modals';
import routeList from '../../../routes/bootstrap';
import { meta } from '../../../state/bootstrap';
import Button from '../../../components/Inputs/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { store } from '../../../state';
import { incrementSessionCounter } from '../../../state/actions/components/session';

const API_ENDPOINT_KEEP_ALIVE = '/portal/keepAlive';
const sessionRenewLimit = meta.sessionLimit * 1000; // convert seconds to milliseconds
const sessionRenewCheckInterval = 30000; // every 30s

export const startSessionChecks = () => {
	window.lastActiveTime = DateTime.local();

	// stop any running session timers
	window.clearInterval(window.APP_SESSION_TIMER);

	window.APP_SESSION_TIMER = window.setInterval(() => {
		const inactiveTimeDuration = DateTime.local().diff(window.lastActiveTime).valueOf();

		if (inactiveTimeDuration >= sessionRenewLimit) {
			// stop ticking and wait for user action
			window.clearInterval(window.APP_SESSION_TIMER);
			// add 5 minutes to session limit
			const timeOutTime = window.lastActiveTime.plus({ minutes: 5 }).toFormat('t');
			// show session renewal modal
			store.dispatch(
				showModal({
					name: 'SESSION_RENEWAL',
					priority: 999,
					data: { timeOutTime }
				})
			);
		}
	}, sessionRenewCheckInterval);
};

const SessionRenewalModal = ({ data, isOpen }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation('common');

	function handleClose() {
		dispatch(
			hideModal({
				name: 'SESSION_RENEWAL'
			})
		);
	}

	function handleRedirect() {
		dispatch(push(routeList.auth.login.path[0]));
	}

	function extendSession() {
		setIsLoading(true);

		get(API_ENDPOINT_KEEP_ALIVE)
			.then(() => {
				dispatch(incrementSessionCounter());
				setIsLoading(false);
				startSessionChecks({ dispatch });
				dispatch(
					hideModal({
						name: 'SESSION_RENEWAL'
					})
				);
			})
			.catch(handleRedirect);
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			open={isOpen}
			onClose={handleClose}
			maxWidth="sm"
			aria-labelledby="extend-session-modal"
		>
			<DialogTitle id="extend-session-modal" disableTypography>
				<Typography component="h2" variant="h3">
					{t('modals.sessionRenewal.title')}
				</Typography>
			</DialogTitle>
			<DialogContent dividers>
				<Typography>{t('modals.sessionRenewal.description', { time: data.timeOutTime })}</Typography>
			</DialogContent>
			<DialogActions>
				<Button isLoading={isLoading} onClick={extendSession}>
					{t('buttons.continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SessionRenewalModal;
