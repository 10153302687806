/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { hideModal } from '../../../state/actions/components/modals';
import { meta } from '../../../state/bootstrap';

import Button from '../../../components/Inputs/Button';
import SelectInput from '../../../components/Inputs/Select';
import LoaderSkeleton from '../../../components/Loaders/Skeleton';

const GET_DATA_API = '/portal/intel/service-regions';
const POST_DATA_API = '/portal/intel/qualys-sso';

const QualysSubscriptionModal = ({ isOpen }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [serviceRegionDocuments, setServiceRegionDocuments] = useState([]);
	const data = useSelector((state) => state.other.scanningSubscriptionQualysData);
	const options = data.map((opt) => ({ label: opt.displayName, value: opt.subscriptionId }));
	const [selectedOption, setSelectedOption] = useState(data[0]);
	const [fetchStatus, setFetchStatus] = useState({
		isError: false,
		isLoading: true
	});

	useEffect(() => {
		setFetchStatus({
			isError: false,
			isLoading: true
		});

		get(GET_DATA_API)
			.then((resp) => {
				setServiceRegionDocuments(
					resp.data.serviceRegionDocuments.map(({ domainName, statusNotes }) => ({
						domainName,
						statusNotes
					}))
				);
				setFetchStatus({
					isError: false,
					isLoading: false
				});
			})
			.catch(() =>
				setFetchStatus({
					isError: true,
					isLoading: false
				})
			);
	}, []);

	function handleClose() {
		dispatch(
			hideModal({
				name: 'QUALYS_SUBSCRIPTION'
			})
		);
	}

	function handleSubmit() {
		const form = document.createElement('form');

		form.method = 'post';
		form.target = '_blank';
		form.action = POST_DATA_API;

		const params = {
			'csrfToken': meta.csrfToken,
			'sso-sub': selectedOption.subscriptionId
		};

		for (const key in params) {
			if (Object.prototype.hasOwnProperty.call(params, key)) {
				const hiddenField = document.createElement('input');
				hiddenField.type = 'hidden';
				hiddenField.name = key;
				hiddenField.value = params[key];

				form.appendChild(hiddenField);
			}
		}

		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}

	const handleOptionSelect = useCallback(
		(event) => {
			setSelectedOption(data.find((item) => item.subscriptionId === event.target.value));
		},
		[data]
	);

	const selectedServiceRegionDocument =
		selectedOption && serviceRegionDocuments.find((item) => item.domainName === selectedOption.apiDomainName);

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="log-retention-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle id="log-retention-modal" disableTypography>
				<Typography component="h2" variant="h3">
					{t('common:modals.qualysSubscription.title')}
				</Typography>
			</DialogTitle>
			<DialogContent dividers>
				{selectedOption &&
				(fetchStatus.isLoading ||
					(selectedServiceRegionDocument && selectedServiceRegionDocument.statusNotes)) ? (
					<Box mb={3}>
						{fetchStatus.isLoading ? (
							<LoaderSkeleton height={22} />
						) : selectedServiceRegionDocument ? (
							<Typography component="p">
								<Trans i18nKey="common:modals.qualysSubscription.status">
									<b />
									{fetchStatus.isLoading ? (
										<LoaderSkeleton height={22} />
									) : (
										selectedServiceRegionDocument.statusNotes || ''
									)}
								</Trans>
							</Typography>
						) : null}
					</Box>
				) : null}
				<Typography component="label" id="sso-sub-label" htmlFor="sso-sub" display="block" gutterBottom>
					{t('common:modals.qualysSubscription.description')}
				</Typography>
				<SelectInput
					id="sso-sub"
					name="sso-sub"
					labelId="sso-sub-label"
					value={selectedOption.subscriptionId}
					options={options}
					disabled={fetchStatus.isLoading || !(data && data.length)}
					onChange={handleOptionSelect}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="text" color="default" disabled={fetchStatus.isLoading}>
					{t('common:buttons.cancel')}
				</Button>
				<Button onClick={handleSubmit} disabled={!selectedOption} isLoading={fetchStatus.isLoading}>
					{t('common:buttons.submit')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default QualysSubscriptionModal;
