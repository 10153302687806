/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

const DefaultColumnCell = ({ cell: { value } }) => {
	const result = value ? value : '\u2014\u2014\u2014';
	return `${result}`;
};

const DefaultColumnHeader = ({ column }) => {
	return column.headerTitle;
};

const DefaultColumnFilter = ({ column: { id, headerTitle, filterValue, setFilter } }) => {
	const { t } = useTranslation();

	return (
		<TextField
			fullWidth
			id={`${id}-filter`}
			size="small"
			variant="outlined"
			label={t(headerTitle)}
			placeholder={t('common:labels.search')}
			value={filterValue || ''}
			InputLabelProps={{
				shrink: true
			}}
			onChange={(e) => {
				// Set undefined to remove the filter entirely
				setFilter(e.target.value || undefined);
			}}
		/>
	);
};

export const defaultColumn = {
	disableGroupBy: true,
	disableFilters: true,
	disableSortBy: true,
	align: 'inherit',
	noWrap: false,
	noPadding: false,
	cellLinkHref: '',
	escapeLeftClick: false,
	width: 'auto',
	minWidth: 30,
	maxWidth: 300,
	headerTitle: '',
	Header: DefaultColumnHeader,
	Filter: DefaultColumnFilter,
	Cell: DefaultColumnCell
};
