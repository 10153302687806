/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { get } from 'axios';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { hasAccess } from '../../../utils/access';
import { showAlert } from '../../../state/actions/components/alerts';
import routeList from '../../../routes/bootstrap';

function canShowTiewAlerts() {
	return hasAccess({
		toFeatures: {
			list: ['TIEW']
		},
		toCustom: ({ other, router }) => {
			const currentPath = router.location.pathname;
			const contactPagePath = routeList.other.contactUs.path;
			const isNotContactUsPage = currentPath.indexOf(contactPagePath) === -1;
			const allowTiewContactUs = hasAccess({
				toFeatures: {
					list: ['ALLOW_TIEW_CONTACT_US']
				}
			});

			return other.hasTiewAlertsEnabled && (allowTiewContactUs || isNotContactUsPage);
		}
	});
}

export const useTiewAlerts = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (canShowTiewAlerts()) {
			get('/portal/intel/tiew-messages', {
				params: { limit: 50, page: 1 }
			}).then((resp) => {
				const elements = resp.data.elements;
				const messageCountLimit = 3;
				const messageDaysAgeLimit = 4;

				const messages = elements.filter((element) => {
					const isUnread = element.read === 'false';
					const createdAt = DateTime.fromJSDate(new Date(element.createdAt)).diffNow('days').days;

					return isUnread && Math.abs(createdAt) <= messageDaysAgeLimit;
				});

				if (messages.length > messageCountLimit) {
					dispatch(
						showAlert({
							id: 'TIEW_ALERT_SINGLE',
							type: 'TIEW',
							data: {
								messageId: null,
								severity: 'error',
								message: 'common:alerts.tiew.multiple'
							}
						})
					);
				} else {
					messages.splice(0, messageCountLimit).forEach(({ id, subject }) =>
						dispatch(
							showAlert({
								id: `TIEW_ALERT_${id}`,
								type: 'TIEW',
								data: {
									messageId: id,
									severity: 'error',
									message: subject
								}
							})
						)
					);
				}
			});
		}
	}, [dispatch]);
};
