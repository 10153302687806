/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import { Grid } from '@material-ui/core';
import { ExportControl } from './ExportControl';

export const TableBannerToolbar = ({ exportFormats, exportFunction }) => {
	return (
		<>
			{exportFormats && exportFormats.length ? (
				<Grid container justify="space-between">
					<Grid item />
					<Grid item>
						<ExportControl exportFormats={exportFormats} exportFunction={exportFunction} />
					</Grid>
				</Grid>
			) : null}
		</>
	);
};
