/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import React, { useState, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'axios';
import DataTable from '../../../../../components/DataTable';
import Tooltip from '@material-ui/core/Tooltip';
import { getFormattedDateTimeByLocale, getLocaleByUserPreferenceTimeZone } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faCircle, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import { ExportControl } from './ExportControl';

const severityStyles = makeStyles((theme) => ({
	label: {
		paddingLeft: '5px'
	},
	low: {
		color: theme.palette.severity.low
	},
	medium: {
		color: theme.palette.severity.medium
	},
	high: {
		color: theme.palette.severity.high
	}
}));

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.reports.tooltip.backgroundColor,
		fontSize: 13,
		color: theme.palette.reports.tooltip.color,
		border: `1px solid ${theme.palette.reports.tooltip.color}`
	},
	eventDetailsRoot: {
		marginTop: '20px'
	},
	arrow: {
		'color': theme.palette.reports.tooltip.backgroundColor,
		'&::before': {
			backgroundColor: theme.palette.reports.tooltip.backgroundColor,
			border: `1px solid ${theme.palette.reports.tooltip.color}`
		}
	},
	dataType: {
		cursor: 'pointer'
	},
	eedInfoIcon: {
		cursor: 'pointer',
		color: theme.palette.reports.infoIcon.color,
		paddingLeft: '5px',
		fontSize: '16px'
	},
	eedTableHeader: {
		padding: '10px 0px'
	},
	eventSectionFormBody: {
		paddingTop: '5px',
		paddingBottom: '10px'
	},
	eventSummaryHeader: {
		fontWeight: 700,
		marginTop: '10px'
	},
	eventSectionHeader: {
		fontWeight: 700,
		paddingTop: '15px'
	},
	label: {
		marginBottom: '10px'
	},
	link: {
		'textDecoration': 'none',
		'wordBreak': 'break-all',
		'color': theme.palette.link.color,
		'cursor': 'pointer',
		'paddingLeft': '3px',
		'paddingRight': '5px',
		'&:hover': {
			textDecoration: 'underline',
			color: theme.palette.link.colorHover
		},
		'transition': theme.transitions.create('color')
	},
	controlsRow: {
		marginTop: '5px',
		marginBottom: '5px'
	},
	categoryDescriptions: {
		marginTop: '15px',
		marginBottom: '5px',
		marginLeft: '5px'
	},
	breakWord: {
		wordBreak: 'break-all',
		wordWrap: 'break-word'
	}
}));

const EventDetails = ({ selectedEvent }) => {
	const { t } = useTranslation('events');
	const severityClasses = severityStyles();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [eventDetails, setEventDetails] = useState({});
	const appData = window.APP_DATA || {};
	const clientId = appData.client.id;
	const language = appData.preferences?.language || '';
	let helpUrl = appData.urls.cdn + '/help';
	if (language && language !== 'en_US') {
		helpUrl = helpUrl + '/' + language + '/Extra_Event_Data_EED.htm';
	} else {
		helpUrl = helpUrl + '/Extra_Event_Data_EED.htm';
	}

	const queryString =
		'?locationId=' +
		selectedEvent.locationID +
		'&createdTimestamp=' +
		selectedEvent.eventTime +
		'&clientId=' +
		clientId;
	const pcapURL = '/portal/events/downloadPCAP/' + selectedEvent?.eventId + queryString;

	const loadEventDetails = () => {
		if (selectedEvent.eventId) {
			setIsLoading(true);
			get('/portal/events/' + selectedEvent.eventId + queryString)
				.then((response) => {
					const data = response.data;
					setEventDetails(data);
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	};

	const columns = useMemo(
		() => [
			{
				headerTitle: t('events:headers.dataType'),
				id: 'datatype',
				accessor: (data) => {
					if (!data) {
						return null;
					}

					var i18Key = 'descriptions.' + data.name;
					var description = t('expandedEeds:' + i18Key);
					if (i18Key === description) {
						description = data.definition ? data.definition : '';
					}

					return {
						name: data.displayName,
						definition: description
					};
				},
				width: '40%',
				Cell: (e) => {
					const description = e.value.definition.toString();

					const htmlTooltip = (
						<React.Fragment>
							<div>{description}</div>
						</React.Fragment>
					);
					if (description) {
						return (
							<Tooltip
								title={htmlTooltip}
								classes={{ tooltip: classes.root, arrow: classes.arrow }}
								arrow
							>
								<span className={classes.dataType}>{e.value.name.toString()}</span>
							</Tooltip>
						);
					} else {
						return e.value.name.toString();
					}
				}
			},
			{
				headerTitle: t('events:headers.value'),
				id: 'value',
				accessor: 'value',
				width: '60%'
			}
		],
		[t, classes]
	);

	useEffect(() => {
		loadEventDetails();
		// eslint-disable-next-line
	}, [selectedEvent]);

	const eedTableHeaderInfo = useMemo(() => {
		const htmlTooltip = (
			<React.Fragment>
				<div>{t('events:tooltips.eedDefinitionsInfo')}</div>
			</React.Fragment>
		);
		return (
			<>
				<div className={classes.eedTableHeader}>
					<b>
						<strong> {t('events:headers.additionalEventData')}</strong>
					</b>
					<Tooltip title={htmlTooltip} classes={{ tooltip: classes.root, arrow: classes.arrow }} arrow>
						<a href={helpUrl} target="_blank" rel="noreferrer" className={classes.eedInfoIcon}>
							<FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
						</a>
					</Tooltip>
				</div>
			</>
		);
	}, [t, classes, helpUrl]);

	const FieldWithLabel = ({ label, text }) => {
		return (
			<>
				<Grid item xs={12} sm={2}>
					<span className={classes.label}>{label}:</span>
				</Grid>
				<Grid item xs={12} sm={4}>
					<span className={classes.label}>{text}</span>
				</Grid>
			</>
		);
	};

	const getSeverityView = (severity) => {
		if (severity) {
			let severityText = severity.toLowerCase();
			return (
				<>
					<FontAwesomeIcon
						title={t('events:severities.' + severityText)}
						className={severityClasses[severityText]}
						icon={faCircle}
					></FontAwesomeIcon>
					<span className={severityClasses.label}>{t('events:severities.' + severityText)}</span>
				</>
			);
		} else {
			return <></>;
		}
	};

	const VulnerabilitySection = () => {
		const source = eventDetails?.source;
		const destination = eventDetails?.destination;
		const sourceDeviceHref = eventDetails?.sourceDeviceHref;
		const destinationDeviceHref = eventDetails?.destinationDeviceHref;
		const showVulnSection = (source && sourceDeviceHref) || (destination && destinationDeviceHref);
		return (
			<>
				{showVulnSection ? (
					<>
						<div className={classes.eventSectionHeader}>
							<strong>{t('events:labels.vulnerabilities')}</strong>
						</div>
						<Grid className={classes.eventSectionFormBody} item xs={12}>
							{source && sourceDeviceHref && (
								<Grid container spacing={1} className={classes.controlsRow}>
									<Trans
										i18nKey="events:labels.vulnerabilityDetailsReact"
										values={{
											url: t('events:labels.srcIPVulnerabilityInformation'),
											deviceName: source,
											label: t('events:labels.source')
										}}
										components={
											<a
												target="_blank"
												rel="noreferrer"
												href={
													'/portal/assets/details/asset/managed/' +
													sourceDeviceHref
														.substr(sourceDeviceHref.lastIndexOf('/') + 1)
														.trim()
												}
												className={classes.link}
											>
												{t('events:labels.srcIPVulnerabilityInformation')}
											</a>
										}
									/>
								</Grid>
							)}
							{destination && destinationDeviceHref && (
								<Grid container spacing={1} className={classes.controlsRow}>
									<Trans
										i18nKey="events:labels.vulnerabilityDetailsReact"
										values={{
											url: t('events:labels.dstIPVulnerabilityInformation'),
											deviceName: destination,
											label: t('events:labels.destination')
										}}
										components={
											<a
												target="_blank"
												rel="noreferrer"
												href={
													'/portal/assets/details/asset/managed/' +
													destinationDeviceHref
														.substr(destinationDeviceHref.lastIndexOf('/') + 1)
														.trim()
												}
												className={classes.link}
											/>
										}
									/>
								</Grid>
							)}
						</Grid>
					</>
				) : (
					<></>
				)}
			</>
		);
	};
	const dateFormatOptions = { timeZoneLocale: getLocaleByUserPreferenceTimeZone() };

	return (
		<div className={classes.eventDetailsRoot}>
			{isLoading ? (
				<p>
					{t('events:summaryMessages.loadingPage')}
					<FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon>
				</p>
			) : (
				<>
					<Grid container justify="space-between">
						<Grid item className={classes.eventSummaryHeader}>
							<strong>{t('events:headers.event_summary')}</strong>
						</Grid>
						<Grid item>
							<ExportControl
								exportFormats={['csv', 'json', 'xml', 'pdf']}
								exportFunction={(exportType) => {
									window.location.href =
										'/portal/events/eventDetailsExport/' +
										selectedEvent?.eventId +
										queryString +
										'&exportContentType=' +
										exportType;
								}}
							/>
						</Grid>
					</Grid>
					<div className={classes.eventSectionFormBody}>{eventDetails?.summary}</div>
					<div className={classes.eventSectionHeader}>
						<strong>{t('events:headers.overview')}</strong>
					</div>
					<Grid className={classes.eventSectionFormBody} item xs={12} sm={12} md={9}>
						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel label={t('events:labels.id')} text={eventDetails?.eventId}></FieldWithLabel>
							<FieldWithLabel
								label={t('events:headers.sourceIp')}
								text={eventDetails?.source}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel
								label={t('events:labels.eventDateTime')}
								text={getFormattedDateTimeByLocale(eventDetails?.createdTimestamp, dateFormatOptions)}
							></FieldWithLabel>
							<FieldWithLabel
								label={t('events:headers.sourcePort')}
								text={eventDetails?.sourcePort}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel
								label={t('events:labels.logTimestamp')}
								text={getFormattedDateTimeByLocale(eventDetails?.logTimeStamp, dateFormatOptions)}
							></FieldWithLabel>
							<FieldWithLabel
								label={t('events:headers.destIp')}
								text={eventDetails?.destination}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel
								label={t('events:labels.severity')}
								text={getSeverityView(eventDetails?.severity)}
							></FieldWithLabel>
							<FieldWithLabel
								label={t('events:headers.destPort')}
								text={eventDetails?.destinationPort}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel
								label={t('events:headers.device')}
								text={
									<a
										target="_blank"
										rel="noreferrer"
										href={'/portal/assets/details/asset/managed/' + eventDetails?.deviceId}
										className={classes.link}
									>
										{eventDetails?.deviceHostname && eventDetails?.deviceIp
											? eventDetails?.deviceHostname + ' [' + eventDetails?.deviceIp + ']'
											: eventDetails?.deviceHostname || eventDetails?.deviceIp}
									</a>
								}
							></FieldWithLabel>

							<FieldWithLabel
								label={t('events:headers.ipProtocolNumber')}
								text={eventDetails?.ipProtocolNumber}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<FieldWithLabel
								label={t('events:headers.deviceDescription')}
								text={eventDetails?.deviceDescription}
							></FieldWithLabel>
							<FieldWithLabel
								label={t('events:headers.observationEvent')}
								text={eventDetails?.observationEvent ? t('events:labels.yes') : t('events:labels.no')}
							></FieldWithLabel>
						</Grid>

						<Grid container spacing={1} className={classes.controlsRow}>
							<Grid item xs={12} sm={6}></Grid>
							<FieldWithLabel
								label={t('events:headers.action')}
								text={eventDetails?.action}
							></FieldWithLabel>
						</Grid>
					</Grid>

					<div className={classes.eventSectionHeader}>
						<strong>{t('events:headers.mitreAttackDetails')}</strong>
					</div>
					<Grid className={classes.eventSectionFormBody} item xs={12}>
						<Grid container spacing={1} className={classes.controlsRow}>
							{t('events:labels.mitreAttackTactic') + ': '}
							{' ' +
								(eventDetails?.mitreAttackTactics
									? eventDetails.mitreAttackTacticst
									: t('events:text.notAvailable'))}
						</Grid>
						<Grid container spacing={1} className={classes.controlsRow}>
							{t('events:labels.mitreAttackTechnique') + ': '}
							{' ' +
								(eventDetails?.mitreAttackTechniques
									? eventDetails.mitreAttackTechniques
									: t('events:text.notAvailable'))}
						</Grid>
					</Grid>

					<div className={classes.eventSectionHeader}>
						<strong>{t('events:headers.eventCategoryResponse')}</strong>
					</div>
					<Grid className={classes.eventSectionFormBody} item xs={12}>
						<Grid container spacing={1} className={classes.controlsRow}>
							<span>
								{t('events:labels.lastUpdatedTimestamp') +
									': ' +
									getFormattedDateTimeByLocale(
										eventDetails?.endOccurrenceTimestamp,
										dateFormatOptions
									)}
							</span>
						</Grid>
					</Grid>
					<Grid className={classes.eventSectionFormBody} item xs={12}>
						<Grid container spacing={1} className={classes.controlsRow}>
							<span>{t('events:headers.family') + ': ' + eventDetails?.categoryName}</span>
						</Grid>

						<Grid container spacing={1} className={classes.categoryDescriptions}>
							{eventDetails?.categoryDescriptions &&
								eventDetails?.categoryDescriptions.map((description) => (
									<Grid container spacing={1} className={classes.controlsRow} key={description}>
										<li>{description}</li>
									</Grid>
								))}
							{eventDetails?.resolution && (
								<Grid container spacing={1} className={classes.controlsRow}>
									<li>{t('events:labels.response') + eventDetails?.resolution}</li>
								</Grid>
							)}
							{eventDetails?.ticketId && (
								<Grid container spacing={1} className={classes.controlsRow}>
									<li>
										{t('events:labels.incidentTicket') + ' '}
										<a
											target="_blank"
											rel="noreferrer"
											href={'/portal/incidents/' + eventDetails?.ticketId}
											className={classes.link}
										>
											{eventDetails.ticketId}
										</a>
									</li>
								</Grid>
							)}
							{eventDetails?.pcap && (
								<Grid container spacing={1} className={classes.controlsRow}>
									<li>
										{t('events:labels.packetCapture') + ' '}
										<a rel="noreferrer" href={pcapURL} className={classes.link}>
											{t('events:links.download') + ' '}
											<FontAwesomeIcon icon={faArrowCircleDown}></FontAwesomeIcon>
										</a>
									</li>
								</Grid>
							)}
						</Grid>
					</Grid>

					<VulnerabilitySection></VulnerabilitySection>

					<div className={classes.eventSectionHeader}>
						<strong>{t('events:headers.eventDetails')}</strong>
					</div>
					<Grid className={classes.eventSectionFormBody} item xs={12}>
						<Grid container spacing={1} className={classes.controlsRow}>
							<span className={classes.breakWord}>{eventDetails?.eventDetail}</span>
						</Grid>
					</Grid>

					<DataTable
						title="EED Data"
						name="EXTRA_EVENT_DATA_TABLE"
						columns={columns}
						initialData={{ elements: eventDetails?.eventExtraData }}
						processDataFn={(data) => data}
						emptyMessage="events:summaryMessages.eedTableEmpty"
						errorMessage="events:summaryMessages.eedTableError"
						loadingMessage="events:summaryMessages.loading"
						canSelectAll={false}
						showPagination={false}
						canToggleColumns={false}
						infoMessage={eedTableHeaderInfo}
					/>
				</>
			)}
		</div>
	);
};

export default EventDetails;
