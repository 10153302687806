/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { Settings } from 'luxon';
import { useSelector } from 'react-redux';

/**
 * Initializes Luxon package with configured settings.
 */
const useLuxon = () => {
	const language = useSelector((state) => state.preferences.language);
	const timeZone = useSelector((state) => state.preferences.timeZone);

	useEffect(() => {
		Settings.defaultLocale = language.replace('_', '-');
		Settings.defaultZoneName = timeZone;
	}, [language, timeZone]);
};

export default useLuxon;
