/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { DateTime } from 'luxon';
import { PivotDataTableHeadCellWithDropdownOptions } from '../../components/PivotDataTable/Table/Cells/Head/WithDropdownOptions';
import React from 'react';
import PropTypes from 'prop-types';

const getLocationHrefPrefix = function () {
	return window.APP_DATA.client.href.split('/prov/clients')[0];
};

const prepareLocations = function (locationsStr) {
	let locationHrefPrefix, locationIds;
	if (locationsStr) {
		locationIds = locationsStr.split(',');
		locationHrefPrefix = getLocationHrefPrefix() + '/prov/client-locations/';
		return locationIds.map(function (locId) {
			return locationHrefPrefix + locId;
		});
	} else {
		return [];
	}
};

const getDateInUserTimeZone = function (dateStr, dateFormat) {
	return DateTime.fromISO(dateStr).toFormat(dateFormat);
};

const fetchDataResponseFormatter = (
	data,
	groupingOptions = [],
	onGroupingOptionClick = () => {},
	params = {},
	t,
	rowGroupingWidth = '40%',
	enableDrillDown = false
) => {
	let tableData = [];
	let columns,
		columns1 = [];
	let settings = {};
	if (data?.rowData?.elements && Array.isArray(data?.rowData?.elements)) {
		tableData = data.rowData.elements;
	}
	if (data?.rowData?.pagination) {
		settings = {
			hasMorePages: data.rowData.pagination.hasMorePages,
			totalPages: data.rowData.pagination.totalPages
		};
	}
	if (data?.headers && Array.isArray(data?.headers)) {
		columns1 = data.headers
			.filter((h) => h.id !== params?.rowGrouping)
			.map((item) => {
				const col = { headerTitle: item.name, accessor: item.id };

				if (
					enableDrillDown &&
					(item.id === data?.tableHeaderModel?.columnGroupingHeader || item.enableDrillDown)
				) {
					col.enableDrillDown = enableDrillDown;
				}
				return col;
			});

		columns = data.headers
			.filter((h) => h.id === params?.rowGrouping)
			.map((item) => {
				const col = { headerTitle: item.name, accessor: item.id };

				col.width = rowGroupingWidth;
				col.Header = function Header(props) {
					return (
						<PivotDataTableHeadCellWithDropdownOptions
							{...props}
							dropDownOptions={groupingOptions}
							onOptionClick={onGroupingOptionClick}
							defaultSelectedOptionId={params?.rowGrouping}
							showSelectedOptionInTitle={true}
						/>
					);
				};
				return col;
			});
		columns = columns.concat(columns1);
	}

	return { data: { elements: tableData }, columns, settings };
};

const hookPropTypes = {
	widgetDefinition: PropTypes.shape({
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		locationIds: PropTypes.string.isRequired
	}).isRequired,
	event: PropTypes.shape({
		dataItem: PropTypes.shape({
			dataContext: PropTypes.shape({
				title: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}).isRequired,
	backbone: PropTypes.shape({
		trigger: PropTypes.func.isRequired
	}).isRequired
};

/**
 *
 * @param {Object} requestPayload - requestPayload
 * @param {String} fileName: requestPayload
 * @param {Array} categoryIds: is expected to be array of string sets eg: ["1, 2", "122, 140, 300"]
 * @return {Object}
 */
const getExportDataParamsForCsvDownload = (requestPayload, fileName, categoryIds = []) => {
	const dateFormat = 'MM/dd/yyyy HH:mm:ss';
	return {
		timePeriod: 'Custom....',
		startDateTime: getDateInUserTimeZone(requestPayload.startTime, dateFormat),
		endDateTime: getDateInUserTimeZone(requestPayload.endTime, dateFormat),
		locations: requestPayload.locations.join(','),
		clientHref: window?.APP_PAGE_DATA?.clientHrefUrl,
		serviceLevels: '',
		deviceTypes: '',
		assets: '',
		threatLevels: '',
		advancedParameters: `{"condition": "AND", "rules": [{"id": "EVENT_CATEGORY","field": "EVENT_CATEGORY", "type": "string", "input": "select", "operator": "in", "value": ${JSON.stringify(
			categoryIds
		)}}]}`,
		eedParameters: '',
		action: 'shallowSearch',
		uuid: '',
		groupByFields: [],
		aggregationFields: [],
		orderByFields: [],
		fileName: fileName?.replaceAll(' ', '-'),
		source: 'REPORTS_AGGR_DRILL_DOWN'
	};
};

export const DrillDown = {
	getLocationHrefPrefix,
	prepareLocations,
	getDateInUserTimeZone,
	fetchDataResponseFormatter,
	hookPropTypes,
	getExportDataParamsForCsvDownload
};
