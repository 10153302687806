/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	createPivotDataTable,
	fetchPivotDataTableData,
	updatePivotDataTableData,
	updatePivotDataTableParams
} from '../../state/actions/components/pivotdatatables';
import PropTypes from 'prop-types';

/* *
 * If you want to manage PivotDataTable component by storing its relevant state in redux store then this hook can be used.
 * It wil provide you all relevant methods/data which you just need to pass to PivotDataTable component
 * Assumptions are
 * 		1. you are loading data from server API
 * 		2. single API is returning columns, table settings/pagination information. For Eg: Check response of API '/portal/reportsdrilldown/eventAnalysisStatsAggrData'
 * Note: This can go under heavy modification when we will be adding pivoting features to table.
 * @param {string} name - is used as key to store table data in redux. See addPivotDataTable action from state/actions/components/pivotdatatable.js
 * @param {function} fetchDataResponseFormatter - can be used in case you want to alter data coming from API response before saving it to store.
 * If you are setting updateColumnsOnFetch or updateSettingOnFetch, you must handle relevant cases in fetchDataResponseFormatter function.
 * @param {boolean} updateColumnsOnFetch - If set to true then, your fetchDataResponseFormatter must return columns
 * @param {boolean} updateSettingOnFetch - If is set to true then, your fetchDataResponseFormatter must return settings
 * @param {object} options - refer to 'defaultPivotDataTableOptions' from /actions/components/pivotdatatable.js
 * @param {boolean} resetTableDataBeforeFetch - set this to true if you want to empty table data from redux store before every fetch
 * */
const usePivotDataTable = ({
	name,
	options,
	skipDataCache = true,
	initialData = undefined,
	fetchDataResponseFormatter = undefined,
	updateColumnsOnFetch = false,
	updateSettingOnFetch = false,
	resetTableDataBeforeFetch = true
}) => {
	const skipResetRef = useRef(false);
	const dispatch = useDispatch();
	const dataTable = useSelector((state) => state.pivotdatatables[name]);
	const { data = {}, columns = [], isError, isLoading, settings = {}, params = {} } = dataTable || {};
	// function to fetch table data and update state in store.
	const fetchData = () => {
		skipResetRef.current = true;
		if (resetTableDataBeforeFetch) {
			dispatch(updatePivotDataTableData({ name, data: [] }));
		}
		dispatch(
			fetchPivotDataTableData({ name, fetchDataResponseFormatter, updateColumnsOnFetch, updateSettingOnFetch })
		);
	};
	const updateParams = (params) => {
		dispatch(updatePivotDataTableParams({ name, params }));
	};

	useEffect(() => {
		if (!dataTable || !data.updated || skipDataCache) {
			dispatch(
				createPivotDataTable({
					name,
					options,
					initialData,
					fetchDataResponseFormatter,
					updateColumnsOnFetch,
					updateSettingOnFetch
				})
			);
		}
		// eslint-disable-next-line
	}, [name, options, initialData]);

	useEffect(() => {
		skipResetRef.current = false;
	}, [data.elements]);

	return {
		data,
		columns,
		params,
		isError,
		isLoading,
		skipResetRef,
		fetchData,
		updateParams,
		settings,
		pageSize: params.limit
	};
};
usePivotDataTable.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.shape({
		url: PropTypes.string,
		data: PropTypes.object,
		columns: PropTypes.array,
		filters: PropTypes.array,
		params: PropTypes.shape({
			limit: PropTypes.number,
			page: PropTypes.number
		}),
		method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
		settings: PropTypes.object,
		isError: PropTypes.bool,
		isLoading: PropTypes.bool
	}),
	skipDataCache: PropTypes.bool,
	initialData: PropTypes.object,
	fetchDataResponseFormatter: PropTypes.func,
	updateColumnsOnFetch: PropTypes.bool,
	updateSettingOnFetch: PropTypes.bool,
	resetTableDataBeforeFetch: PropTypes.bool
};
export { usePivotDataTable };
