/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import {
	UPDATE_DATACUBE_DATA,
	UPDATE_DATACUBE_STATUS,
	UPDATE_DATACUBE_FILTERS,
	RESET_DATACUBE_FILTERS,
	UPDATE_DATACUBE_EXPANDED,
	UPDATE_DATACUBE_SUMMARY,
	UPDATE_DATACUBE_ROTATION,
	UPDATE_DATACUBE_DOWNLOAD_PATH
} from '../../actions/components/datacube';

const initialState = {
	data: {},
	sort: {},
	filters: {},
	expanded: {},
	summary: {
		node: null,
		isExpanded: true
	},
	rotateAngle: 0,
	downloadPath: {
		type: '',
		url: ''
	},
	status: {
		root: {
			isError: false,
			isLoaded: false,
			isLoading: true
		}
	}
};

export default function dataCubeReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_DATACUBE_DATA:
			return {
				...state,
				data: action.payload.data
			};
		case UPDATE_DATACUBE_STATUS:
			return {
				...state,
				status: {
					...state.status,
					...action.payload
				}
			};
		case UPDATE_DATACUBE_FILTERS:
			return {
				...state,
				filters: {
					[action.payload.id]: {
						...state.filters[action.payload.id],
						...action.payload.state
					}
				}
			};
		case RESET_DATACUBE_FILTERS:
			return {
				...state,
				filters: {
					[action.payload.id]: {
						...state.filters[action.payload.id].initialValues
					}
				}
			};
		case UPDATE_DATACUBE_EXPANDED:
			return {
				...state,
				expanded: action.payload.expanded
			};
		case UPDATE_DATACUBE_SUMMARY:
			return {
				...state,
				summary: {
					...state.summary,
					...action.payload.summary
				}
			};
		case UPDATE_DATACUBE_ROTATION:
			return {
				...state,
				rotateAngle: action.payload.rotateAngle
			};
		case UPDATE_DATACUBE_DOWNLOAD_PATH:
			return {
				...state,
				downloadPath: action.payload.downloadPath
			};
		default:
			return state;
	}
}
