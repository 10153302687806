/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import NavItem from '../../../../Nav/Secondary/Item';
import { headerBarRoutes } from '../../../../../routes/components/header';
import { hasAccess } from '../../../../../utils/access';
import BarSearch from '../Search';

const navRoutes = headerBarRoutes();

const useStyles = makeStyles(() => ({
	menu: {
		margin: 0,
		padding: 0,
		listStyle: 'none',
		alignItems: 'center'
	}
}));

const ItemSearchBar = () =>
	hasAccess() ? (
		<NavItem
			useHeaderStyles={true}
			link={{
				renderItem() {
					return <BarSearch />;
				}
			}}
		/>
	) : null;

const BarNav = () => {
	const classes = useStyles();

	return (
		<nav>
			<Grid container component="ul" className={classes.menu}>
				{navRoutes.map((link, idx) =>
					link ? <NavItem key={link.name || idx} link={link} useHeaderStyles={true} /> : null
				)}
				<ItemSearchBar />
			</Grid>
		</nav>
	);
};

export default BarNav;
