/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { commonRoutes, commonBarRoutes } from './common';

/**
 * Header navigation menu routes.
 *
 * @param {Object} options - Options for rendering menu routes.
 * @returns {Array} A list of configured routes.
 */
export const headerRoutes = () => commonRoutes;

/**
 * Header bar navigation menu routes.
 *
 * @param {Object} options - Options for rendering menu routes.
 * @returns {Array} A list of configured routes.
 */
export const headerBarRoutes = () =>
	commonBarRoutes({
		showThemeSwitch: true
	});

/**
 * Header responsive navigation menu routes.
 * @returns {Array} A list of configured routes.
 */
export const headerResponsiveRoutes = () => ({
	primary: headerRoutes(),
	secondary: commonBarRoutes({
		showPreferencesItems: true
	})
});
