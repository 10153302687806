/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hideAlert } from '../../../state/actions/components/alerts';
import { AlertCustom } from '../../../components/Alert';

const GlobalAlert = ({ id, data }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	let { message, severity } = data;

	if (Array.isArray(message)) {
		message = t(...message);
	} else {
		message = t(message);
	}

	function handleClose() {
		dispatch(hideAlert({ id }));
	}

	return (
		<AlertCustom
			variant="outlined"
			severity={severity}
			onClose={handleClose}
			closeText={t('common:buttons.close')}
			noSideBorders
		>
			{message}
		</AlertCustom>
	);
};

GlobalAlert.defaultProps = {
	severity: 'warning'
};

GlobalAlert.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired
};

export default GlobalAlert;
