/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import classNames from 'classnames';

import GlobalAlert from './GlobalAlert';
import { useTimeZoneUnavailableAlerts } from './GlobalAlert/hooks';

import GlobalAlertTIEW from './GlobalAlertTIEW';
import { useTiewAlerts } from './GlobalAlertTIEW/hooks';

import UrgentAlerts from './UrgentAlerts';
import { useUrgentAlerts } from './UrgentAlerts/hooks';

const ALERTS_COMPONENT_LIST = {
	TIEW: GlobalAlertTIEW,
	URGENT_ALERT: UrgentAlerts
};

export const useGlobalAlertRoot = () => {
	useTiewAlerts();
	useTimeZoneUnavailableAlerts();
	useUrgentAlerts();
};

const useStyles = makeStyles((theme) => ({
	root: {
		'width': '100%',
		'& > div:not(:last-child):not(.MuiCollapse-hidden)': {
			marginBottom: theme.spacing(1)
		}
	}
}));

const GlobalAlertRoot = () => {
	const classes = useStyles();

	const limit = useSelector((state) => state.alerts.limit);
	const queue = useSelector((state) => state.alerts.queue);

	const alerts = Object.keys(queue);
	const visibleAlerts = alerts.filter((id) => queue[id].show);

	return alerts.length ? (
		<section className={classNames(classes.root, 'react-backbone-alerts-container')}>
			{alerts.map((id) => {
				const { data, show, type } = queue[id];
				const isExpanded = show && visibleAlerts.indexOf(id) < limit;
				const Component = ALERTS_COMPONENT_LIST[type] || GlobalAlert;

				return (
					<Collapse in={isExpanded} key={id}>
						<Component id={id} data={data} />
					</Collapse>
				);
			})}
		</section>
	) : null;
};

export default GlobalAlertRoot;
