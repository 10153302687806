/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import { useState } from 'react';
import { useExportDrillDown } from '../../../../../containers/Reports/DrillDownTable/Incidents';
import { getDateTime } from '../../utils';
import { DateTime } from 'luxon';

const apiUrl = '/portal/incidentStatistics/aggregationDrillDownData?source=NRS&limit=20&page=0';
const method = 'POST';

function getSearchParameterValue(fromChart, paramKey, isArrayType) {
	let paramVal = fromChart[paramKey];
	if (paramVal && paramVal[0]) {
		if (isArrayType) {
			return paramVal;
		} else {
			return paramVal[0];
		}
	}
}

function getIncidentSeverityFilter(fromChart) {
	const severity = fromChart.severity;
	if (severity && severity[0] && 'allseverity' !== severity[0].toLowerCase()) {
		return severity;
	} else {
		return ['HIGH', 'MEDIUM', 'LOW'];
	}
}

function getStartTime(startTime, rptStartTime) {
	let startTimeMomentObj = getDateTime(startTime);
	let rptStartTimeMomentObj = getDateTime(rptStartTime);
	if (
		startTimeMomentObj.toMillis() < rptStartTimeMomentObj.toMillis() &&
		rptStartTimeMomentObj.toMillis() < DateTime.local().toMillis()
	) {
		return rptStartTime;
	} else {
		return startTime;
	}
}

function getEndTime(endTime, rptEndTime) {
	let endTimeMomentObj = getDateTime(endTime);
	let rptEndTimeMomentObj = getDateTime(rptEndTime);
	if (endTimeMomentObj.toMillis() < rptEndTimeMomentObj.toMillis()) {
		return endTime;
	} else {
		return rptEndTime;
	}
}

function getLocations(clientLocationHrefs) {
	return clientLocationHrefs?.length > 0 && clientLocationHrefs[0] !== 'alllocations' ? clientLocationHrefs : [];
}

function getPayloadParameters(fromChart, reportName) {
	return {
		routingCategory: fromChart.subReportType,
		endTime: getEndTime(fromChart.endTime, fromChart.endDate),
		limit: 20,
		locations: getLocations(fromChart.clientLocationHrefs),
		page: 0,
		startTime: getStartTime(fromChart.startTime, fromChart.startDate),
		priorities: getIncidentSeverityFilter(fromChart),
		closeCodes: getSearchParameterValue(fromChart, 'closeCodes', true),
		closeCodesOperator: getSearchParameterValue(fromChart, 'closeCodesOperator', false),
		ticketsOperator: getSearchParameterValue(fromChart, 'ticketsOperator', false),
		ticketIDs: getSearchParameterValue(fromChart, 'ticketIDs', false),
		externalTicketsOperator: getSearchParameterValue(fromChart, 'externalTicketsOperator', false),
		externalTickets: getSearchParameterValue(fromChart, 'externalTickets', false),
		fileName: getDownloadFileName(fromChart, reportName)
	};
}

function getDownloadFileName(fromChart, reportName) {
	let subReportType = '';
	if (fromChart.subReportType === 'AVERAGE_RESPONSE_TIME') {
		subReportType = 'Average Response Time';
	} else if (fromChart.subReportType === 'TICKET_VOLUMES_SEVERITY_SLA') {
		subReportType = 'Ticket Volumes by Severity';
	} else {
		subReportType = 'Ticket Volumes';
	}

	let fileName = '';
	if (reportName && subReportType) {
		fileName = reportName + '_' + subReportType;
	}
	return fileName.replace(/ /g, '_');
}

const useARMTicketsDrillDown = (fromChart, reportName) => {
	const [requestPayload, setRequestPayload] = useState(getPayloadParameters(fromChart, reportName));
	const { exportDrillDownData } = useExportDrillDown();

	const downloadCsv = () => {
		exportDrillDownData(
			{
				...requestPayload
			},
			'csv'
		);
	};

	return {
		apiUrl,
		method,
		requestPayload,
		setRequestPayload,
		downloadCsv
	};
};

export { useARMTicketsDrillDown };
