/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddNetworkModal from './AddNetwork';
import DeleteNetworksModal from './DeleteNetworks';
import EditNetworkModal from './EditNetwork';
import LogRetentionModal from './LogRetention';
import QualysSubscriptionModal from './QualysSubscription';
import SessionRenewalModal, { startSessionChecks } from './SessionRenewal';
import TimeZoneModal from './TimeZone';
import TokenRenewalModal from './TokenRenewal';
import { hasAccess } from '../../utils/access';
import { showModal } from '../../state/actions/components/modals';
import DownloadDescriptionModal from './DownloadDescription';
import AddRecipientsModal from './ExecutedReports/AddRecipientsModal';
import ViewParametersModal from './ExecutedReports/ViewParametersModal';
import ReportsGenericModal from './ExecutedReports';

const MODALS_COMPONENT_LIST = {
	ADD_NETWORK: AddNetworkModal,
	DELETE_NETWORKS: DeleteNetworksModal,
	EDIT_NETWORK: EditNetworkModal,
	LOG_RETENTION: LogRetentionModal,
	QUALYS_SUBSCRIPTION: QualysSubscriptionModal,
	SESSION_RENEWAL: SessionRenewalModal,
	TIME_ZONE: TimeZoneModal,
	TOKEN_RENEWAL: TokenRenewalModal,
	DOWNLOAD_DESCRIPTION: DownloadDescriptionModal,
	ADD_RECIPIENTS_MODAL: AddRecipientsModal,
	VIEW_PARAMETERS_MODAL: ViewParametersModal,
	REPORTS_GENERIC_MODAL: ReportsGenericModal
};

export const useGlobalModalRoot = () => {
	const dispatch = useDispatch();
	const hasTimeZone = useSelector((state) => state.preferences.hasTimeZone);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	useEffect(() => {
		if (!hasTimeZone && hasAccess()) {
			dispatch(showModal({ name: 'TIME_ZONE' }));
		}
		if (isAuthenticated) {
			startSessionChecks();
		}
	}, [dispatch, hasTimeZone, isAuthenticated]);
};

const GlobalModalRoot = () => {
	const limit = useSelector((state) => state.modals.limit);
	const queue = useSelector((state) => state.modals.queue);

	const modals = Object.keys(queue).sort((a, b) => queue[b].priority - queue[a].priority);
	const visibleModals = modals.filter((id) => queue[id].show);

	return modals.length
		? modals
				.map((id) => {
					const { data, name, show } = queue[id];
					const isExpanded = show && visibleModals.indexOf(id) < limit;
					const Component = MODALS_COMPONENT_LIST[name];

					if (!Component) {
						return null;
					}

					return <Component key={id} isOpen={isExpanded} data={data} />;
				})
				.filter(Boolean)
		: null;
};

export default GlobalModalRoot;
