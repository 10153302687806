/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDataTable } from '../../state/actions/components/datatables';

export const useTableState = ({
	name,
	options,
	skipDataCache = true,
	initialData = undefined,
	dataProperty = undefined,
	dataCallbackFn = undefined
}) => {
	const isInitial = useRef(true);
	const skipResetRef = useRef(false);
	const dispatch = useDispatch();
	let dataTable = useSelector((state) => state.datatables[name]);

	let { data = {}, isError, isLoading, hasMore, isAppendLoading } = dataTable || {};

	if (skipDataCache && isInitial.current && !initialData) {
		isInitial.current = false;
		isLoading = true;
		dataTable = null;
		hasMore = true;
		isAppendLoading = false;
	}

	useEffect(() => {
		if (!dataTable || !data.updated || skipDataCache) {
			dispatch(createDataTable({ name, options, initialData, dataProperty, dataCallbackFn }));
		}
		// eslint-disable-next-line
	}, [name, options, initialData, dataProperty]);

	useEffect(() => {
		skipResetRef.current = false;
	}, [data.elements]);

	return { data, isError, isLoading, skipResetRef, hasMore, isAppendLoading };
};

export const useTableData = ({ data, processDataFn }) =>
	useMemo(() => {
		return data ? (processDataFn ? processDataFn(data) : data.map((item) => item.model)) : [];
	}, [data, processDataFn]);

export const useTablePagination = ({ data, pagination, pageSizeLimit, processPaginationFn }) =>
	useMemo(() => {
		return pagination
			? processPaginationFn
				? processPaginationFn(pagination)
				: {
						count: pagination.numberOfElements,
						current: pagination.currentPage - 1
				  }
			: {
					count: data && data.length > pageSizeLimit ? Math.ceil(data.length / pageSizeLimit) : 1,
					current: 0
			  };
	}, [data, pagination, pageSizeLimit, processPaginationFn]);
