/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import BrandLogo from '../../../../Brand/Logo';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(4.8, 4, 4.8, 0),
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('xl')]: {
			padding: theme.spacing(4.5, 2, 4.5, 0)
		},
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2.7, 2, 2.7, 0)
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 2, 2, 0)
		}
	},
	containerTiny: {
		padding: theme.spacing(2, 2, 2, 0)
	},
	logo: {
		color: theme.palette.secondary.contrastText,
		textDecoration: 'none'
	},
	caption: {
		display: 'none',
		fontSize: '1rem',
		lineHeight: '1.1rem',
		textTransform: 'capitalize',
		marginLeft: theme.spacing(2),
		[theme.breakpoints.up('lg')]: {
			display: 'block'
		}
	}
}));

const HeaderLogo = () => {
	const classes = useStyles();
	const { t } = useTranslation('common');
	const isNavSmall = useSelector((state) => state.nav.isSmall);

	return (
		<Grid
			container
			alignItems="flex-end"
			wrap="nowrap"
			className={classNames(classes.container, {
				[classes.containerTiny]: isNavSmall
			})}
		>
			<Grid item component="a" href="/portal" className={classes.logo}>
				<BrandLogo size={isNavSmall ? 'tiny' : 'small'} variant="white" />
			</Grid>
			{isNavSmall ? null : (
				<Grid item component="span" className={classes.caption}>
					{t('app.name')}
				</Grid>
			)}
		</Grid>
	);
};

export default HeaderLogo;
