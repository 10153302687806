/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/Inputs/Button';
import axios from 'axios';
import { hideModal } from '../../../../state/actions/components/modals';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { TableMessage } from '../../../../components/DataTable/Table';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.grey[500],
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(3)
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	},
	dialogContent: {
		maxHeight: '70vh'
	},
	field: {
		marginTop: theme.spacing(3)
	},
	tableBodyRow: {
		'color': theme.palette.table.cell.body.color,
		'backgroundColor': theme.palette.table.cell.body.background,
		'&:last-child td': {
			'&:first-child': {
				borderBottomLeftRadius: theme.spacing(1)
			},
			'&:last-child': {
				borderBottomRightRadius: theme.spacing(1)
			}
		}
	},
	tableBodyRowStriped: {
		'&:nth-of-type(even)': {
			color: theme.palette.table.cell.striped.color,
			backgroundColor: theme.palette.table.cell.striped.background
		}
	},
	paramName: {
		fontWeight: 'bold'
	},
	table: {
		borderCollapse: 'separate',
		borderSpacing: 0,
		borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
		border: `1px solid ${theme.palette.table.borderColor}`
	},
	tableCell: {
		padding: 5
	},
	viewRuleContainer: {
		margin: '4px 0px',
		borderRadius: 5,
		padding: 5,
		border: '1px solid #777777',
		display: 'inline-block'
	},
	viewRulesGroupBody: {
		paddingLeft: 10
	},
	viewRulesGroupHeader: {
		marginBottom: 4
	},
	viewRulesList: {
		'listStyle': 'none',
		'padding': '0 0 0 20px',
		'margin': 0,
		'fontSize': 15,
		'&>*': {
			position: 'relative'
		},
		'&>*:before': {
			content: '""',
			position: 'absolute',
			left: -15,
			width: 15,

			borderColor: '#777777',
			borderStyle: 'solid',
			display: 'inline-block',
			color: theme.palette.card.color,
			top: '-10px',
			height: 'calc(50% + 10px)',
			borderWidth: '0 0 1px 1px'
		},
		'&>*:after': {
			content: '""',
			position: 'absolute',
			left: -15,
			width: 15,
			height: 'calc(50% + 14px)',
			borderColor: '#777777',
			borderStyle: 'solid',
			display: 'inline-block',
			color: theme.palette.card.color,
			top: '40%',
			borderWidth: '0 0 0 1px'
		},
		'&>*:first-child:before': {
			top: '-12px',
			height: 'calc(50% + 14px)'
		},
		'&>*:last-child:before': {
			borderRadius: '0 0 0 4px'
		},
		'&>*:last-child:after': {
			display: 'none'
		}
	},
	viewRulesGroupContainer: {
		margin: '10px 0 4px 0',
		padding: '10px 10px 15px 10px',
		lineHeight: '29px',
		borderRadius: '4px',
		border: '1px solid #777777 !important',
		boxShadow: `inset 0 1px 1px ${theme.palette.card.border}`,
		maxWidth: 536
	},
	andOrButtonsLabel: {
		border: '1px solid  #777777',
		color: '#efefef',
		background: '#777777',
		borderRadius: 2,
		padding: 2
	}
}));

const VIEW_PARAMETERS_URL = '/portal/reports/reportParameters';
const EVENT_CATEGORIES_URL = '/portal/reports/eventCategories';
const GET_COUNTRIES_URL = '/portal/reports/getCountryCodes';
const GET_NETWORK_RANGES_URL = '/portal/reports/networkRanges';

const ADVANCED_FIELDS_I18n_KEYS = {
	INCIDENT_ID: 'reports:filterKeys.label_incident_id',
	EVENT_ID: 'reports:filterKeys.label_event_id',
	EVTSUM: 'reports:filterKeys.label_event_summary',
	SRC_IP: 'reports:filterKeys.label_src_ip',
	SRC_IP_INVALID: 'reports:filterKeys.label_src_ip_text',
	SRC_IP_CIDR: 'reports:filterKeys.label_src_ip_cidr',
	DST_IP: 'reports:filterKeys.label_dst_ip',
	DST_IP_INVALID: 'reports:filterKeys.label_dst_ip_text',
	DST_IP_CIDR: 'reports:filterKeys.label_dst_ip_cidr',
	DEVICE_IP: 'reports:filterKeys.label_device_ip',
	DEVICE_IP_CIDR: 'reports:filterKeys.label_device_ip_cidr',
	DEVICE_HOST_NAME: 'reports:filterKeys.label_device_host_name',
	OBSERVATIONAL_EVENTS: 'reports:filterKeys.label_observational_events',
	NO_CATEGORY_RESPONSE: 'reports:filterKeys.label_no_category_reponse',
	EVENT_CATEGORY: 'reports:filterKeys.label_category',
	SRC_PORT: 'reports:filterKeys.label_src_port',
	DST_PORT: 'reports:filterKeys.label_dst_port',
	ACTION: 'reports:filterKeys.label_action',
	MITRE_ATTACK_TACTICS: 'reports:filterKeys.label_mitre_attack_tactic',
	MITRE_ATTACK_TECHNIQUES: 'reports:filterKeys.label_mitre_attack_technique',
	SRC_MAC_ADDRESS: 'reports:filterKeys.label_src_mac_address',
	DST_MAC_ADDRESS: 'reports:filterKeys.label_dst_mac_address',
	SRC_IP_COUNTRY: 'reports:filterKeys.label_src_ip_country',
	DST_IP_COUNTRY: 'reports:filterKeys.label_dst_ip_country'
};

const AdvancedViewParams = ({ advancedParamsData, eventCategoryNamesMap, countriesMap, networkRangesMap, isEED }) => {
	const classes = useStyles();
	const data = advancedParamsData;
	const { t } = useTranslation(['reports', 'translation', 'expandedEeds']);

	const getEventCategoryDisplayName = (eventCategoryIds) => {
		let eventCatNames = [],
			eventCatName,
			idsArray;
		if (!_.isArray(eventCategoryIds)) {
			eventCategoryIds = eventCategoryIds.split(',');
		}
		_.each(eventCategoryIds, function (ids) {
			idsArray = ids && !_.isArray(ids) ? ids.split(',') : ids;
			_.each(idsArray, function (id) {
				eventCatName = eventCategoryNamesMap[id];
				eventCatName = eventCatName ? eventCatName : 'Not found - ' + id;
				eventCatNames.push(eventCatName);
			});
		});
		return _.uniq(eventCatNames).join(', ');
	};

	const getCountryDisplayNames = (countryCodes) => {
		let countryNames = [],
			countryName;
		if (!_.isArray(countryCodes)) {
			countryCodes = countryCodes.split(',');
		}
		_.each(countryCodes, function (id) {
			countryName = countriesMap[id];
			countryName = countryName ? countryName : id;
			countryNames.push(countryName);
		});
		return _.uniq(countryNames).join(', ');
	};

	const getCIDRIPDisplayName = (selectedIps) => {
		let cidrIpNames = [],
			cidrIpName;
		if (!_.isArray(selectedIps)) {
			selectedIps = selectedIps.split('|');
		}
		_.each(selectedIps, function (cidrIp) {
			cidrIpName = networkRangesMap[cidrIp];
			cidrIpName = cidrIpName ? cidrIpName : cidrIp;
			cidrIpNames.push(cidrIpName);
		});
		return _.uniq(cidrIpNames).join(', ');
	};

	const getActionDisplayName = (value) => {
		let key = '';
		if (value === 'Blocked') {
			key = 'reports:filterKeys.value.blocked';
		} else if (value === 'Not Blocked') {
			key = 'reports:filterKeys.value.not_blocked';
		}
		return t(key);
	};

	const formatFieldValue = (value) => {
		return value ? value.split('|').join(' | ') : value;
	};

	const translateAdvancedRuleValue = (fieldID, value) => {
		switch (fieldID) {
			case 'EVENT_CATEGORY':
				return getEventCategoryDisplayName(value);
			case 'ACTION':
				return getActionDisplayName(value);
			case 'OBSERVATIONAL_EVENTS':
			case 'NO_CATEGORY_RESPONSE':
				return t('reports:filterKeys.value.' + value);
			case 'SRC_IP_COUNTRY':
			case 'DST_IP_COUNTRY':
				return getCountryDisplayNames(value);
			case 'SRC_IP_CIDR':
			case 'DST_IP_CIDR':
				return getCIDRIPDisplayName(value);
			default:
				return formatFieldValue(value);
		}
	};

	const getRuleFieldId = (rule) => {
		return rule.field || rule.id || rule.fieldName;
	};
	return (
		<>
			<div>
				<div className={classes.viewRulesGroupContainer}>
					<div className={classes.viewRulesGroupHeader}>
						<span className={classes.andOrButtonsLabel}>{data.condition}</span>
					</div>
					<div className={classes.viewRulesGroupBody}>
						<ul className={classes.viewRulesList}>
							{data &&
								data.rules &&
								data.rules.map((rule, i) => {
									return (
										<div key={i}>
											{!rule.condition && !rule.rules && (
												<div>
													<li className={classes.viewRuleContainer}>
														<div>
															{isEED
																? t(
																		'expandedEeds:displayNames.' +
																			getRuleFieldId(rule)
																  ) + ' '
																: t(ADVANCED_FIELDS_I18n_KEYS[getRuleFieldId(rule)]) +
																  ' '}
															<b>
																<i>
																	{t(
																		'translation:queryBuilder.lang.operator_' +
																			rule.operator
																	) + ' '}
																</i>
															</b>
															{isEED
																? rule.value
																: translateAdvancedRuleValue(rule.field, rule.value)}
														</div>
													</li>
												</div>
											)}
											{rule.condition && rule.rules.length > 0 && (
												<AdvancedViewParams
													advancedParamsData={rule}
													eventCategoryNamesMap={eventCategoryNamesMap}
													networkRangesMap={networkRangesMap}
													countriesMap={countriesMap}
													isEED={isEED}
												/>
											)}
										</div>
									);
								})}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

const ViewParametersModal = ({ isOpen, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation(['reports', 'viewParams']);
	const { row } = data;
	const [reportParams, setReportParams] = useState([]);
	const [requestState, setRequestState] = useState({
		isError: false,
		isLoading: false
	});
	const [eventCategoryNamesMap, setEventCategoryNamesMap] = useState({});
	const [countriesMap, setCountriesMap] = useState({});
	const [networkRangesMap, setNetworkRangesMap] = useState({});

	const makeAjaxRequest = (requestURL, params, prepareFunction) => {
		requestURL === VIEW_PARAMETERS_URL && setRequestState({ isLoading: true, isError: false });
		axios
			.get(requestURL, { params: { ...params } })
			.then(({ data }) => {
				prepareFunction(data);
				requestURL === VIEW_PARAMETERS_URL && setRequestState({ isLoading: false, isError: false });
			})
			.catch(() => {
				requestURL === VIEW_PARAMETERS_URL && setRequestState({ isLoading: false, isError: true });
			});
	};

	const prepareCountryMap = (data) => {
		setCountriesMap(data);
	};

	const prepareEventCategoryNamesMap = (data) => {
		let eventCategoryNames = {};
		Object.keys(data).forEach((obj) => {
			data[obj].map((id) => {
				eventCategoryNames[id] = obj;
			});
		});
		setEventCategoryNamesMap(eventCategoryNames);
	};

	const prepareNetworkRangesMap = (data) => {
		setNetworkRangesMap(data);
	};

	const prepareReportParams = (data) => {
		const reportParams = data.map((c) => {
			return {
				name: c.name,
				key: c.key,
				value: c.value,
				isValueACode: c.isValueACode,
				isAdvancedParams: c.isAdvancedParams
			};
		});
		setReportParams(reportParams);
	};

	const preFetchValues = useCallback(() => {
		makeAjaxRequest(
			VIEW_PARAMETERS_URL,
			{
				reportId: row.original.reportId,
				reportType: row.original.reportType
			},
			prepareReportParams
		);
		if (row.original.reportType === 'secevents' || row.original.reportType === 'toptalkers') {
			makeAjaxRequest(EVENT_CATEGORIES_URL, {}, prepareEventCategoryNamesMap);
			makeAjaxRequest(GET_COUNTRIES_URL, {}, prepareCountryMap);
		}
		if (row.original.reportType === 'deviceeventtrend') {
			makeAjaxRequest(GET_NETWORK_RANGES_URL, {}, prepareNetworkRangesMap);
		}
	}, [row]);

	useEffect(() => {
		preFetchValues();
	}, [preFetchValues, row]);

	function handleClose() {
		dispatch(hideModal({ name: 'VIEW_PARAMETERS_MODAL' }));
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="view-parameters-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle id="view-parameters-modal" disableTypography>
				<Typography variant="h2">
					<Box fontWeight="fontWeightRegular">{t('reports:modals.viewParamsModal.title')}</Box>
					<IconButton size="small" aria-label="close" onClick={handleClose} className={classes.closeButton}>
						<FontAwesomeIcon icon={faTimes} size="1x" />
					</IconButton>
				</Typography>
			</DialogTitle>

			<DialogContent dividers className={classes.dialogContent}>
				{requestState.isError ? (
					<TableMessage
						message={t('reports:modals.viewParamsModal.messages.error')}
						action={preFetchValues}
						isError
					/>
				) : null}
				{requestState.isLoading ? (
					<TableMessage message={t('reports:modals.viewParamsModal.messages.fetchingParams')} isLoading />
				) : null}
				{!requestState.isError && !requestState.isLoading && (
					<TableContainer>
						<Table className={classes.table}>
							<TableBody>
								{reportParams &&
									reportParams.map((param, i) => {
										return (
											<TableRow
												key={i}
												className={classNames(classes.tableBodyRow, {
													[classes.tableBodyRowStriped]: true
												})}
											>
												<TableCell className={classes.tableCell}>
													<Box>
														<Typography className={classes.paramName}>
															{' '}
															{param.key}
														</Typography>
													</Box>
													<Box>
														{param.isAdvancedParams ? (
															<AdvancedViewParams
																advancedParamsData={JSON.parse(param.value)}
																eventCategoryNamesMap={eventCategoryNamesMap}
																networkRangesMap={networkRangesMap}
																countriesMap={countriesMap}
																isEED={param.name === 'eedAddOnFiltersJson'}
															/>
														) : param.isValueACode ? (
															t('viewParams:' + param.value)
														) : (
															ReactHtmlParser(param.value)
														)}
													</Box>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</DialogContent>

			<DialogActions className={classes.dialogActions}>
				<Button onClick={handleClose} variant="outlined" color="default">
					{t('buttons.close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ViewParametersModal;
