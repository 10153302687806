/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../Inputs/Button';
import CheckboxLabelled from '../../../Inputs/CheckboxLabelled';
import GlobalFilter from './GlobalFilter/GlobalFilter';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 0)
	},
	left: {
		flexGrow: 1,
		justifyContent: 'flex-start'
	},
	filterBar: {
		justifyContent: 'flex-start'
	},
	right: {
		'display': 'flex',
		'align-items': 'center',
		'& div': {
			'margin-left': theme.spacing(2)
		},
		'justifyContent': 'flex-end'
	},
	checkBox: {
		paddingLeft: 8,
		paddingRight: 8,
		paddingTop: 10,
		paddingBottom: 12
	},
	requestAcknowledgement: {
		marginTop: -20,
		display: 'flex',
		justifyContent: 'flex-end'
	},
	requestAck: {
		'marginRight': 10,
		'fontSize': 15,
		'&:disabled': {
			backgroundColor: theme.palette.reports.action.disabledBackground,
			cursor: 'not-allowed'
		},
		'&:hover': {
			backgroundColor: theme.palette.reports.button.hoverBackgroundColor
		},
		'paddingTop': '4px',
		'paddingBottom': '4px',
		'paddingLeft': '12px',
		'paddingRight': '12px',
		'backgroundColor': theme.palette.reports.button.backgroundColor
	},
	toolTip: {
		'color': theme.palette.card.color,
		'cursor': 'pointer',
		'&:hover': {
			color: theme.palette.sidebar.iconHover
		}
	}
}));

export const TableActionbar = ({
	selectedFlatRows,
	canSelectRows,
	showDeleteOption,
	onDelete,
	isLoading,
	customActions,
	filterOutCloudNetworks,
	name,
	showFilter,
	updateMyData,
	additionalActionBarItems,
	toggleAllRowsSelected
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handleActionClick = (evt) => {
		const selectedRows = selectedFlatRows.map((d) => d.original);

		onDelete(selectedRows, evt, toggleAllRowsSelected); //single prop "OnDelete" to handle multiple actions
	};

	const CustomActionControl = ({ customAction }) => {
		const { name, controlType, props } = customAction;
		switch (controlType) {
			case 'button':
				return (
					<div>
						<Button size="small" disabled={isLoading} {...props}>
							{t(name)}
						</Button>
					</div>
				);
			case 'checkbox':
				return (
					<div>
						<CheckboxLabelled
							size="small"
							checked={filterOutCloudNetworks}
							disabled={isLoading}
							labelPlacement={'end'}
							name={name}
							{...props}
						/>
					</div>
				);
			default:
		}
	};

	return (
		<>
			<div className={classes.root}>
				{showFilter ? (
					<div>
						<div className={classes.requestAcknowledgement}>
							<Button
								size="small"
								disabled={!selectedFlatRows.length || isLoading}
								onClick={handleActionClick}
								id="requestAck"
								className={classes.requestAck}
							>
								{t('common:buttons.requestAcknowledgement')}
							</Button>{' '}
							{'  '}
							<Button
								size="small"
								disabled={!selectedFlatRows.length || isLoading}
								onClick={handleActionClick}
								id="delete"
								className={classes.requestAck}
							>
								{t('common:buttons.delete')}
							</Button>
						</div>
						<div className={classes.filterBar}>
							<GlobalFilter name={name} showFilter={showFilter} updateMyData={updateMyData} />
						</div>
					</div>
				) : null}
				{canSelectRows && showDeleteOption && onDelete ? (
					<div className={classes.left}>
						<Button
							size="small"
							disabled={!selectedFlatRows.length || isLoading}
							onClick={handleActionClick}
						>
							{t('common:buttons.delete')}
						</Button>
					</div>
				) : null}
				{additionalActionBarItems ? additionalActionBarItems : null}
				{customActions.length ? (
					<div className={classes.right}>
						{customActions.map((customAction, i) => (
							<CustomActionControl key={`customAction_${i}`} customAction={customAction} />
						))}
					</div>
				) : null}
			</div>
		</>
	);
};
