/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ReduxThunk from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import createRootReducer, { storage } from './reducers';
import { auth, client, contact, features, preferences, privileges, roles, other } from './bootstrap';
import { CHAT_PREFIX, SET_SIZE, SET_POSITION, IS_DUPLICATE } from './actions/components/chat';

/**
 * Application's browser history.
 */
const history = createBrowserHistory({
	forceRefresh: true
});

const persistConfig = {
	key: 'app',
	version: 1,
	storage: storage,
	whitelist: ['datatables', 'monitoring'],
	stateReconciler: autoMergeLevel2
};

const syncConfig = {
	channel: 'swcp-app',
	predicate: (action) => {
		const whitelist = action.type.indexOf(CHAT_PREFIX) !== -1;
		const blacklist = [SET_SIZE, SET_POSITION, IS_DUPLICATE];

		return whitelist && !blacklist.includes(action.type);
	}
};

/**
 * Configures redux state store.
 *
 * @param {Object} preloadedState - Initial state.
 *
 * @returns {Object} A configured store.
 */
function configureStore(preloadedState) {
	const rootReducer = createRootReducer(history);
	const reducers = persistReducer(persistConfig, rootReducer);
	const middlewares = [routerMiddleware(history), ReduxThunk];

	if (process.env.NODE_ENV !== 'test') {
		// disabled in test mode, causes test suite to hang (requires mock)
		middlewares.push(createStateSyncMiddleware(syncConfig));
	}

	const enhancers = composeWithDevTools(applyMiddleware(...middlewares));

	const store = createStore(reducers, preloadedState, enhancers);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./reducers', () => store.replaceReducer(reducers));
	}

	return store;
}

/**
 * Configured redux state store.
 */
const store = configureStore({
	auth,
	client,
	contact,
	features,
	preferences,
	privileges,
	roles,
	other
});

/**
 * Configured redux persist storage.
 */
const persistor = persistStore(store);

if (process.env.NODE_ENV !== 'test') {
	// disabled in test mode, causes test suite to hang (requires mock)
	initMessageListener(store);
}

export { store, persistor, history, configureStore };
