/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import LoaderCircular from '../../../Loaders/Circular';
import Button from '../../../Inputs/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 200,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		border: `1px solid ${theme.palette.table.cell.head.background}`,
		backgroundColor: theme.palette.table.cell.body.background,
		borderBottomLeftRadius: theme.spacing(1),
		borderBottomRightRadius: theme.spacing(1)
	},
	icon: {
		marginBottom: theme.spacing(2)
	},
	iconError: {
		color: theme.palette.status.error.border
	},
	iconEmpty: {
		color: theme.palette.status.warning.border
	}
}));

export const TableMessage = ({ message, nameSpace, isLoading, isEmpty, isError, action }) => {
	const classes = useStyles();
	const { t } = useTranslation(nameSpace);

	return (
		<div className={classes.root}>
			<div>
				<div className={classes.icon}>
					{isLoading ? (
						<LoaderCircular size={30} />
					) : isEmpty ? (
						<FontAwesomeIcon icon={faBan} size="2x" className={classes.iconEmpty} />
					) : isError ? (
						<FontAwesomeIcon icon={faExclamationTriangle} size="2x" className={classes.iconError} />
					) : null}
				</div>
				<Typography paragraph={!!action}>{t(message)}</Typography>
				{action ? (
					<Button onClick={action} size="small">
						{t('common:buttons.retry')}
					</Button>
				) : null}
			</div>
		</div>
	);
};
