/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../Inputs/Button';
import { terminateChat, toggleModal, setProgress } from '../../../../../state/actions/components/chat';

const useStyles = makeStyles((theme) => ({
	dialog: {
		top: ({ isStandaloneChat }) => (isStandaloneChat ? '0px !important' : '40px !important'),
		position: 'absolute !important',
		textAlign: 'center'
	},
	dialogBackdrop: {
		position: 'absolute !important'
	},
	dialogActions: {
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default
	}
}));

const EndChatModal = ({ container, isStandaloneChat }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles({ isStandaloneChat });
	const isOpen = useSelector((state) => state.chat.isOpen) || isStandaloneChat;
	const modals = useSelector((state) => state.chat.modals);
	const isDialogOpen = !!(isOpen && modals.end);

	const onClose = () => {
		dispatch(
			toggleModal({
				name: 'end',
				value: false
			})
		);
	};

	const onSubmit = () => {
		dispatch(terminateChat()).then(() => {
			onClose();
			dispatch(
				setProgress({
					progress: 'terminatedByClient'
				})
			);
		});
	};

	return container ? (
		<Dialog
			fullWidth
			disablePortal
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			container={container.current}
			className={classes.dialog}
			BackdropProps={{
				className: classes.dialogBackdrop
			}}
			aria-labelledby="end-chat-confirmation"
			open={isDialogOpen}
			onClose={onClose}
		>
			<DialogContent dividers>
				<Typography>{t('common:chat.labels.terminateConfirmation')}</Typography>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button onClick={onClose} color="default">
					{t('common:buttons.cancel')}
				</Button>
				<Button onClick={onSubmit}>{t('common:chat.buttons.end')}</Button>
			</DialogActions>
		</Dialog>
	) : null;
};

export default EndChatModal;
