/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';

const useItemStyles = makeStyles(() => ({
	menuItem: {
		margin: 0,
		padding: 0,
		display: 'block'
	}
}));

const useButtonStyles = makeStyles((theme) => ({
	root: {
		...theme.typography.body2,
		'outline': 0,
		'boxSizing': 'border-box',
		'display': 'block',
		'width': '100%',
		'overflow': 'hidden',
		'whiteSpace': 'nowrap',
		'minHeight': 'auto',
		'padding': theme.spacing(1, 2),
		'color': theme.palette.dropdownItem.color,
		'transition': theme.transitions.create(['color', 'background-color'], {
			duration: theme.transitions.duration.short
		}),
		'&:hover, &:focus, &$selected, &$selected:hover, &$selected:focus': {
			color: theme.palette.dropdownItem.colorHover,
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		},
		'&$disabled': {
			'opacity': 1,
			'pointerEvents': 'none',
			'color': theme.palette.dropdownItem.colorDisabled,
			'backgroundColor': theme.palette.dropdownItem.backgroundDisabled,
			'&:hover, &:focus': {
				color: theme.palette.dropdownItem.colorDisabled,
				backgroundColor: theme.palette.dropdownItem.backgroundDisabled
			}
		}
	},
	gutters: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2)
	},
	disabled: {},
	selected: {}
}));

const DropdownItem = ({ children, button = false, href = null, ...other }) => {
	const itemClasses = useItemStyles();
	const buttonClasses = useButtonStyles();

	return (
		<li className={itemClasses.menuItem}>
			<ListItem
				href={href}
				button={button}
				tabIndex={0}
				classes={buttonClasses}
				component={href ? 'a' : button ? 'button' : 'span'}
				{...other}
			>
				{children}
			</ListItem>
		</li>
	);
};

export default DropdownItem;
