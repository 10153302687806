/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledTableCell, StyledTableRow, StyledTableSortLabel } from '../Base';

export const TableHeadRowCell = ({ column, sortByProps, ...headerProps }) => {
	let style = {};
	const customTitle = column.headerTitle + ': ' + sortByProps?.title;

	if (column.width) {
		style.width = column.width;
	}

	if (column.maxWidth) {
		style.maxWidth = column.maxWidth;
	}

	if (column.minWidth) {
		style.minWidth = column.minWidth;
	}

	if (column.noPadding) {
		style.padding = 0;
	}

	return (
		<StyledTableCell align={column.align} {...headerProps} style={style}>
			{column.customContent ? (
				column.customContent
			) : (
				<Typography
					noWrap
					component="div"
					variant="inherit"
					style={{ fontWeight: column.isSorted ? 700 : 400 }}
				>
					{column.canGroupBy ? (
						// If the column can be grouped, let's add a toggle
						<span {...column.getGroupByToggleProps()}>
							<FontAwesomeIcon size="1x" icon={column.isGrouped ? faMinusCircle : faPlusCircle} />{' '}
						</span>
					) : null}
					{!column.canSort ? (
						column.render('Header')
					) : (
						<StyledTableSortLabel
							active={column.isSorted}
							direction={column.isSortedDesc ? 'asc' : 'desc'}
							IconComponent={(props) => (
								<FontAwesomeIcon
									icon={column.isSorted ? (column.isSortedDesc ? faSortDown : faSortUp) : faSort}
									{...props}
								/>
							)}
							{...column.getSortByToggleProps(customTitle && { title: customTitle })}
						>
							{column.render('Header')}
						</StyledTableSortLabel>
					)}
				</Typography>
			)}
		</StyledTableCell>
	);
};

export const TableHeadRow = ({ headerGroup, sortByOptions, ...headerGroupProps }) => {
	return (
		<StyledTableRow {...headerGroupProps}>
			{headerGroup.headers.map((column) => {
				const { key, ...headerProps } = column.getHeaderProps();
				return <TableHeadRowCell key={key} column={column} sortByProps={sortByOptions} {...headerProps} />;
			})}
		</StyledTableRow>
	);
};
