import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getFormattedDateTimeByLocale } from '../../../../pages/Reports/View/utils';

export const getSRRTicketsTableColumns = function ({ t, severityClasses, classes }) {
	return [
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.ticketId'),
			id: 'number',
			accessor: 'number',
			Cell: (e) => {
				const number = e.value;
				const href = '/portal/incidents/' + number;
				if (number) {
					return (
						<a href={href} className={classes.downloadLink} target="_blank" rel="noreferrer">
							{number}
						</a>
					);
				} else {
					return <></>;
				}
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.status'),
			id: 'recordStatus',
			accessor: 'recordStatus'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.externalTicketNumber'),
			id: 'externalTicket',
			accessor: 'externalTicket'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.ticketType'),
			id: 'ticketType',
			accessor: 'ticketType'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.severity'),
			id: 'severity',
			accessor: (row) => {
				if (!row) {
					return null;
				}

				return row.priority?.toLowerCase();
			},
			Cell: (e) => {
				if (e.value) {
					const severity = e.value.toString();
					return (
						<>
							<FontAwesomeIcon
								title={t('incidents:severities.' + severity)}
								className={severityClasses[severity]}
								icon={faCircle}
							/>
							<span className={severityClasses.label}>{t('incidents:severities.' + severity)}</span>
						</>
					);
				} else {
					return <></>;
				}
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.created'),
			id: 'createdDateFormat',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.createdTimestamp, { timeFormat: 'hh:mm a' });
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.daysOpen'),
			id: 'daysOpen',
			accessor: 'daysOpen'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.modifiedDate'),
			id: 'modifiedDateFormat',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.modifiedTimestamp, { timeFormat: 'hh:mm a' });
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.closeDate'),
			id: 'closedDateFormat',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.closedTimestamp, { timeFormat: 'hh:mm a' });
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.closeCode'),
			id: 'closeCode',
			accessor: 'closeCode'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.summary'),
			id: 'summary',
			accessor: 'summary'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.deviceManufacturer'),
			id: 'deviceManufacturer',
			accessor: 'deviceManufacturer'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.deviceName'),
			id: 'deviceName',
			accessor: 'deviceName'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.deviceType'),
			id: 'deviceType',
			accessor: 'deviceType'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.securityFlag'),
			id: 'securityFlag',
			accessor: 'eventSource'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.category'),
			id: 'category',
			accessor: 'categorization'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.originalRequester'),
			id: 'originator',
			accessor: 'originator'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.pocName'),
			id: 'pocName',
			accessor: 'pocName'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.currentlyWorkedBy'),
			id: 'currentlyWorkedBy',
			accessor: 'currentlyWorkedBy'
		},
		{
			headerTitle: t('incidents:incidentStatistics.drillDownTable.clientPointOfContact'),
			id: 'assignedEmployee',
			accessor: 'assignedEmployee'
		}
	];
};
