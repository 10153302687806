/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import { store } from '../../../../state';
import { debugMessage } from '../../../../utils';
import {
	getAuthMetadata,
	setSocketMeta,
	setDuplicate,
	getPreviousMessages
} from '../../../../state/actions/components/chat';
import { MessageHandler } from '../Conversation/Message/message';
import { useSelector } from 'react-redux';

const SOCKET_PING_INTERVAL = 30000;
const SOCKET_NO_STATUS_RECIEVED_CODE = 1005;
const SOCKET_ABNORMAL_CLOSURE_CODE = 1006;
const MAX_DUPLICATE_WS_COUNT = 3;

const WebSocketEvents = {
	ServerConnected: '1010',
	DuplicateConnection: '1020',
	MaintenanceCompleted: '73',
	StatisticsMessage: '1202',
	PongEvent: '1202'
};

let pingTimer = null;

function startPing(socket) {
	stopPing();

	pingTimer = setInterval(() => {
		if (socket.current) {
			socket.current.send('ping');
		}
	}, SOCKET_PING_INTERVAL);
}

function stopPing() {
	if (pingTimer) {
		clearInterval(pingTimer);

		pingTimer = null;
	}
}

function onSocketOpen(event) {
	debugMessage('[CHAT] [onSocketOpen] online', event);
}

function onSocketError(event) {
	debugMessage('[CHAT] [onSocketError] offline', event);
}

function onSocketMessage(event, socket, messageHandler) {
	debugMessage('[CHAT] [onSocketMessage] online');

	if (!event.data) {
		return;
	}

	try {
		const msg = JSON.parse(event.data);

		if (msg.context && msg.context.eventId === WebSocketEvents.StatisticsMessage) {
			// ignore statistics messages
			return;
		}

		debugMessage('[CHAT] [onSocketMessage]', msg);

		const eventId = msg && msg.context && msg.context.eventId;

		if (eventId === WebSocketEvents.ServerConnected) {
			debugMessage('[CHAT] [onSocketMessage] ServerConnected');

			startPing(socket);

			const { chat } = store.getState();

			store.dispatch(
				setSocketMeta({
					count: 0,
					isTerminated: false
				})
			);

			if (chat.isDuplicate) {
				if (chat.progress === 'acceptedByAgent') {
					store.dispatch(getPreviousMessages());
				}

				store.dispatch(
					setDuplicate({
						isDuplicate: false
					})
				);
			}
		} else if (eventId === WebSocketEvents.DuplicateConnection) {
			debugMessage('[CHAT] [onSocketMessage] DuplicateConnection');

			const { chat } = store.getState();

			store.dispatch(
				setSocketMeta({
					count: (chat.socketMeta.count += 1)
				})
			);

			if (!chat.isDuplicate) {
				store.dispatch(
					setDuplicate({
						isDuplicate: true
					})
				);
			}
		} else if (eventId === WebSocketEvents.MaintenanceCompleted) {
			debugMessage('[CHAT] [onSocketMessage] MaintenanceCompleted');

			store.dispatch(
				getAuthMetadata({
					socket: socket.current
				})
			);
		}

		if (msg && msg.payLoad) {
			messageHandler.current.handleMessage(msg.payLoad, msg.context);
		}
	} catch (error) {
		debugMessage('[CHAT] [onSocketMessage]: error parsing message', error);
	}
}

function onSocketClose(event, socket, setReconnect) {
	if (console) {
		// workaround when window is shutting down but ie decides to go ahead and fire events
		debugMessage('[CHAT] [OnSocketClose], close code == ' + event.code);
	}

	const { chat } = store.getState();

	debugMessage('[CHAT] [onSocketClose]: offline');

	stopPing();

	socket.current = null;

	if (chat.socketMeta.isTerminated) {
		return;
	}

	if (event && event.code === SOCKET_NO_STATUS_RECIEVED_CODE) {
		return;
	}

	if (event && event.code === SOCKET_ABNORMAL_CLOSURE_CODE) {
		return;
	}

	if (chat.socketMeta.count > MAX_DUPLICATE_WS_COUNT) {
		store.dispatch(
			setDuplicate({
				isDuplicate: true
			})
		);

		debugMessage('[CHAT] [onSocketClose]: duplicate chat', chat.socketMeta.count);
	}

	setTimeout(() => {
		setReconnect(true);
	}, 3 * 1000);

	debugMessage('[CHAT] [onSocketClose]: reconnect', chat.socketMeta.count);
}

const useChatWebSocket = () => {
	const ws = useRef(null);
	const [reconnect, setReconnect] = useState(false);
	const messageHandler = useRef(new MessageHandler());
	const url = useSelector((state) => state.chat.session.urls.socket);
	const campaigns = useSelector((state) => state.chat.session.campaigns);

	const terminateSocket = useCallback(() => {
		if (ws.current) {
			stopPing();

			store.dispatch(
				setSocketMeta({
					isTerminated: true
				})
			);

			ws.current.close();
		}
	}, []);

	useEffect(() => {
		if ((url && campaigns.length) || (url && reconnect)) {
			setReconnect(false);

			ws.current = new WebSocket(url);
			ws.current.onopen = (event) => onSocketOpen(event);
			ws.current.onclose = (event) => onSocketClose(event, ws, setReconnect);
			ws.current.onerror = (event) => onSocketError(event);
			ws.current.onmessage = (event) => onSocketMessage(event, ws, messageHandler);
		}

		return () => {
			terminateSocket();
		};
	}, [campaigns.length, reconnect, terminateSocket, url]);

	return { socket: ws.current, terminate: terminateSocket, reconnect: setReconnect };
};

export default useChatWebSocket;
