/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import { useState } from 'react';
import { useExportDrillDown } from '../../../../../containers/Reports/DrillDownTable/Incidents';
import { getDateTime, getLocaleByUserPreferenceTimeZone } from '../../utils';
import { DateTime } from 'luxon';
import { getLocaleByTimeZone } from '@portal-reporting/report-ui-templates/dist/timeZoneLocaleMap';

const apiUrl = '/portal/incidentStatistics/aggregationDrillDownData?source=NRS&limit=20&page=0';
const method = 'POST';

function getIncidentSeverityFilter(fromChart) {
	const severity = fromChart.severityLevels;
	let severities;
	if (severity && severity[0] && 'allseverity' !== severity[0].toLowerCase()) {
		severities = severity;
	} else {
		severities = ['HIGH', 'MEDIUM', 'LOW'];
	}

	/*if (fromChart.eventCategory === 'Reconnaissance') {
		severities = severities.filter((s) => s !== 'LOW');
	}*/
	return severities;
}

function getTicketMetricsStartTime(fromChart) {
	const timeZoneLocale = getLocaleByUserPreferenceTimeZone();
	const rptStartTimeMomentObj = getDateTime(fromChart.endDate, { timeZoneLocale })
		.setZone(fromChart.timezone ? fromChart.timezone : 'UTC')
		.minus({ months: 12 });

	return rptStartTimeMomentObj.toISO();
}

function getEventIncidentTrendStartTime(fromChart) {
	const timezone = fromChart.timezone ? fromChart.timezone : 'UTC';
	let timeZoneLocale = getLocaleByTimeZone({ timeZoneName: timezone });
	let startTimeMomentObj;

	if (fromChart.sortTimestamp) {
		startTimeMomentObj = DateTime.fromFormat(fromChart.sortTimestamp, 'MMM yyyy', {
			locale: 'en-US',
			zone: timezone
		}).startOf('month');
	} else {
		startTimeMomentObj = getDateTime(fromChart.startDate, { timeZoneLocale });
	}

	return startTimeMomentObj.toISO();
}

function getStartTime(fromChart, rptStartTime) {
	const timezone = fromChart.timezone ? fromChart.timezone : 'UTC';
	let timeZoneLocale = getLocaleByTimeZone({ timeZoneName: timezone });
	let rptStartTimeMomentObj = getDateTime(rptStartTime, { timeZoneLocale });
	let startTimeMomentObj;

	if (fromChart.sortTimestamp) {
		startTimeMomentObj = DateTime.fromFormat(fromChart.sortTimestamp, 'MMM yyyy', {
			locale: 'en-US',
			zone: timezone
		}).startOf('month');
	} else {
		startTimeMomentObj = getDateTime(fromChart.startDate, { timeZoneLocale });
	}

	if (
		startTimeMomentObj.toMillis() < rptStartTimeMomentObj.toMillis() &&
		rptStartTimeMomentObj.toMillis() < DateTime.local().toMillis()
	) {
		return startTimeMomentObj.toISO();
	} else {
		return rptStartTimeMomentObj.toISO();
	}
}

function getEndTime(fromChart, rptEndTime) {
	const endTime = fromChart.endDate;
	const timezone = fromChart.timezone ? fromChart.timezone : 'UTC';
	let timeZoneLocale = getLocaleByTimeZone({ timeZoneName: timezone });
	let rptEndTimeMomentObj = getDateTime(rptEndTime, { timeZoneLocale });
	let endTimeMomentObj;

	if (fromChart.sortTimestamp) {
		endTimeMomentObj = DateTime.fromFormat(fromChart.sortTimestamp, 'MMM yyyy', {
			locale: 'en-US',
			zone: timezone
		}).endOf('month');
	} else {
		endTimeMomentObj = getDateTime(endTime, { timeZoneLocale });
	}

	if (endTimeMomentObj.toMillis() < rptEndTimeMomentObj.toMillis()) {
		return endTimeMomentObj.toISO();
	} else {
		return rptEndTime;
	}
}

function getLocations(clientLocationHrefs) {
	return clientLocationHrefs?.length > 0 && clientLocationHrefs[0] !== 'alllocations' ? clientLocationHrefs : [];
}

function getPayloadParameters(fromChart, reportName) {
	const drillDownFilters = {};
	const subRptType = fromChart.subReportType;

	if (fromChart.eventCategory) {
		drillDownFilters.attackType = fromChart.eventCategory;
	}

	if (fromChart.ticketStatus && fromChart.ticketStatus.length > 0) {
		drillDownFilters.ticketStatus = fromChart.ticketStatus;
	}

	if (fromChart.ticketType) {
		drillDownFilters.ticketType = fromChart.ticketType;
	}

	if (fromChart.ticketGroupType) {
		drillDownFilters.ticketGroupType = fromChart.ticketGroupType;
	}

	if (
		subRptType === 'TICKET_METRICS' &&
		fromChart.record?.eventCategory &&
		fromChart.record.eventCategory.includes('Yearly Average')
	) {
		drillDownFilters.startTime = getTicketMetricsStartTime(fromChart);
	} else if (
		subRptType === 'EVENT_INCIDENT_TREND' ||
		subRptType === 'ATTACK_TYPE_TREND' ||
		subRptType === 'INCIDENTS_BY_DEVICE_TYPE_TREND' ||
		subRptType === 'INCIDENT_SEVERITY_TREND'
	) {
		drillDownFilters.startTime = getEventIncidentTrendStartTime(fromChart);
	} else {
		drillDownFilters.startTime = getStartTime(fromChart, fromChart.startDate);
	}

	return {
		routingCategory: 'SRR_DRILL_DOWN',
		endTime: getEndTime(fromChart, fromChart.endDate),
		limit: 20,
		locations: getLocations(fromChart.clientLocationHrefs),
		page: 0,
		priorities: getIncidentSeverityFilter(fromChart),
		deviceTypes: fromChart.deviceTypes,
		fileName: getDownloadFileName(fromChart, reportName),
		...drillDownFilters
	};
}

function getDownloadFileName(fromChart, reportName) {
	const subReportType = fromChart.eventCategory ? '_' + fromChart.eventCategory : '';

	let fileName = '';
	if (reportName && subReportType) {
		fileName = reportName + subReportType + '_Ticket_List';
	}
	return fileName.replace(/ /g, '_');
}

const useServiceReviewDrillDown = (fromChart, reportName) => {
	const [requestPayload, setRequestPayload] = useState(getPayloadParameters(fromChart, reportName));
	const { exportDrillDownData } = useExportDrillDown();

	const downloadCsv = () => {
		exportDrillDownData(
			{
				...requestPayload
			},
			'csv'
		);
	};

	const downloadPdf = () => {
		exportDrillDownData(
			{
				...requestPayload
			},
			'pdf'
		);
	};

	return {
		apiUrl,
		method,
		requestPayload,
		setRequestPayload,
		downloadCsv,
		downloadPdf
	};
};

export { useServiceReviewDrillDown };
