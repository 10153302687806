/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { withStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

export const StyledTableCell = withStyles((theme) => ({
	root: {
		borderBottom: 0
	},
	head: {
		'whiteSpace': 'nowrap',
		'verticalAlign': 'bottom',
		'borderRight': `1px solid ${theme.palette.table.cell.body.border}`,
		'&:first-child': {
			borderLeft: `1px solid ${theme.palette.table.cell.head.background}`
		},
		'&:last-child': {
			borderRight: `1px solid ${theme.palette.table.cell.head.background}`
		},
		'&$sizeSmall': {
			fontSize: theme.typography.body2.fontSize,
			padding: theme.spacing(1, 2)
		}
	},
	body: {
		'verticalAlign': 'middle',
		'wordBreak': 'break-word',
		'wordWrap': 'break-word',
		'overflow': 'hidden',
		'&$sizeSmall': {
			fontSize: theme.typography.body2.fontSize,
			padding: 0,
			[theme.breakpoints.down('sm')]: {
				fontSize: 14
			}
		},
		[theme.breakpoints.up('md')]: {
			'borderRight': `1px solid ${theme.palette.table.cell.body.border}`,
			'&:first-child': {
				borderLeft: `1px solid ${theme.palette.table.cell.head.background}`
			},
			'&:last-child': {
				borderRight: `1px solid ${theme.palette.table.cell.head.background}`
			}
		}
	},
	sizeSmall: {}
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
	root: {
		'color': theme.palette.table.cell.body.color,
		'backgroundColor': theme.palette.table.cell.body.background,
		'&:nth-of-type(even)': {
			color: theme.palette.table.cell.striped.color,
			backgroundColor: theme.palette.table.cell.striped.background
		},
		'&$head': {
			color: theme.palette.table.cell.head.color,
			backgroundColor: theme.palette.table.cell.head.background
		},
		'&$hover:hover': {
			color: theme.palette.table.cell.hover.color,
			backgroundColor: theme.palette.table.cell.hover.background
		},
		'&$selected, &$selected:hover': {
			color: theme.palette.table.cell.selected.color,
			backgroundColor: theme.palette.table.cell.selected.background
		},
		[theme.breakpoints.up('md')]: {
			'&:last-child td': {
				'borderBottom': `1px solid ${theme.palette.table.cell.head.background}`,
				'&:first-child': {
					borderBottomLeftRadius: theme.spacing(1)
				},
				'&:last-child': {
					borderBottomRightRadius: theme.spacing(1)
				}
			}
		},
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			padding: theme.spacing(2)
		}
	},
	head: {},
	hover: {},
	selected: {}
}))(TableRow);

export const StyledTableSortLabel = withStyles((theme) => ({
	root: {
		'&:focus': {
			color: theme.palette.table.sort.color
		},
		'&:hover': {
			'color': theme.palette.table.sort.colorHover,
			'& $icon': {
				opacity: 1,
				color: theme.palette.table.sort.colorHover
			}
		},
		'&$active': {
			'color': theme.palette.table.sort.color,
			'&& $icon': {
				opacity: 1,
				color: theme.palette.table.sort.color
			}
		}
	},
	active: {},
	icon: {
		color: theme.palette.table.sort.color,
		fontSize: 18,
		marginRight: 4,
		marginLeft: 4,
		opacity: 0
	},
	iconDirectionDesc: {
		transform: 'none'
	},
	iconDirectionAsc: {
		transform: 'none'
	}
}))(TableSortLabel);

export const StyledTable = withStyles((theme) => ({
	root: {
		borderCollapse: 'separate',
		[theme.breakpoints.down('sm')]: {
			display: 'block'
		}
	}
}))(Table);

export const StyledTableBody = withStyles((theme) => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			display: 'block'
		}
	}
}))(TableBody);

export const StyledTableHead = withStyles((theme) => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
}))(TableHead);
