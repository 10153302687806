/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		...theme.typography.body2,
		'margin': 0,
		'width': '100%',
		'display': 'block',
		'minHeight': 'auto',
		'overflow': 'hidden',
		'whiteSpace': 'nowrap',
		'boxSizing': 'border-box',
		'padding': theme.spacing(1, 2),
		'color': theme.palette.dropdownItem.colorDisabled,
		'backgroundColor': theme.palette.dropdownItem.backgroundDisabled,
		'& + $title': {
			paddingTop: 0
		}
	}
}));

const DropdownTitle = ({ title }) => {
	const classes = useStyles();
	const titles = Array.isArray(title) ? title : [title];
	const { t } = useTranslation();

	return titles.map((text) => (
		<li key={text} className={classes.title}>
			{t(text)}
		</li>
	));
};

export default DropdownTitle;
