/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Portal } from '@material-ui/core';
import { hasAccess } from '../../utils/access';
import { ChatFrame } from './Views';

const Chat = ({ disablePortal, isStandaloneChat }) => {
	if (
		!hasAccess({
			toCustom: ({ features }) => {
				return (
					features['CHAT_WIDGET_ENABLED'] &&
					(features['CHAT_WIDGET_SAFELIST_ENABLED']
						? features['CHAT_WIDGET_ENABLED_CLIENT']
						: !features['CHAT_WIDGET_DISABLED_CLIENT'])
				);
			}
		}) ||
		!('WebSocket' in window)
	) {
		return null;
	}

	if (disablePortal) {
		return <ChatFrame isStandaloneChat={isStandaloneChat} />;
	}

	return (
		<Portal>
			<ChatFrame />
		</Portal>
	);
};

export default Chat;
