/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { toggleNav } from '../../../../state/actions/components/nav';

const useStyles = makeStyles((theme) => ({
	burgerMenuItem: {
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	burgerMenuButton: {
		border: `1px solid ${theme.palette.secondary.contrastText}`,
		minWidth: 0,
		padding: '4px 6px',
		fontSize: '1.5em'
	}
}));

const NavToggle = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isMenuOpen = useSelector((state) => state.nav.isOpen);

	return (
		<Grid item className={classes.burgerMenuItem}>
			<Button
				color="inherit"
				edge="start"
				className={classes.burgerMenuButton}
				onClick={() => dispatch(toggleNav({ isOpen: !isMenuOpen }))}
			>
				<FontAwesomeIcon icon={faBars} />
			</Button>
		</Grid>
	);
};

export default NavToggle;
