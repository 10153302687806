import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import '@portal-reporting/report-ui-templates/dist/styles/report-styles.css';
import 'highcharts/css/highcharts.css';
import { TopTalkersDrillDown } from './Events';
import { TopTalkers } from '@portal-reporting/report-ui-templates';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TimePeriodDialog, validateTimeRange } from '../EventAnalysis/Events/TimePeriodMessage';
import { makeStyles } from '@material-ui/core/styles';
import { updateDrillDownDataTableParams } from '../../../../state/actions/components/pivotdatatables';
import { drillDownId } from './Events/useTopTalkersDrillDown';

const useStyles = makeStyles(() => ({
	drillDown: {
		padding: '0.1in'
	}
}));

const getHeaderTitle = function (headerId, t) {
	switch (headerId) {
		case 'SRCIP':
			return t('reports:topTalkers.drillDown.sourceIPs');
		case 'DSTIP':
			return t('reports:topTalkers.drillDown.destinationIPs');
		case 'SRC_COUNTRY':
			return t('reports:topTalkers.drillDown.sourceCountries');
		case 'DST_COUNTRY':
			return t('reports:topTalkers.drillDown.destinationCountries');
		default:
			return headerId;
	}
};

const getActionHeaders = function (reportMetaData, t) {
	const actionType = reportMetaData?.actionType;

	const blocked = {
		id: 'Blocked',
		enableDrillDown: true,
		name: t('reports:topTalkers.drillDown.blocked'),
		filterBy: 'action'
	};

	const notBlocked = {
		id: 'NotBlocked',
		enableDrillDown: true,
		name: t('reports:topTalkers.drillDown.notBlocked'),
		filterBy: 'action'
	};

	const headers = [
		{
			id: reportMetaData.subReportType,
			name: getHeaderTitle(reportMetaData.subReportType, t)
		}
	];

	if (actionType?.toUpperCase() === 'BLOCKED') {
		headers.push(blocked);
	} else if (actionType?.toUpperCase() === 'NOT BLOCKED') {
		headers.push(notBlocked);
	} else {
		headers.push(blocked);
		headers.push(notBlocked);
		headers.push({
			id: 'Count',
			name: t('reports:topTalkers.drillDown.count')
		});
	}
	return headers;
};

const getDrillDownFilterValue = function (subReportType, record) {
	if ((subReportType === 'SRC_COUNTRY' || subReportType === 'DST_COUNTRY') && record['countryCode']) {
		return record['countryCode'];
	} else {
		return record['value'];
	}
};

function getPivotTableData(props, t) {
	const { data, reportMetaData = {} } = props;
	const { subReportType = 'SRCIP' } = reportMetaData;
	const pivotData = {
		headers: getActionHeaders(reportMetaData, t),
		tableHeaderModel: {
			rowGroupingHeader: subReportType,
			columnGroupingHeader: 'Count',
			rowGroupingHeaderType: 'STRING',
			columnGroupingHeaderType: 'STRING',
			otherGroupColumn: null
		},
		rowData: {
			elements: data?.aggregateData
				? data.aggregateData.map((r) => {
						return {
							[subReportType + 'Filter']: getDrillDownFilterValue(subReportType, r),
							Count: r['eventCount'],
							[subReportType]: r['value'],
							Blocked: r['blockedEventCount'],
							NotBlocked: r['notblockedEventCount']
						};
				  })
				: [],
			pagination: {
				totalPages: 1,
				currentPage: 1,
				numberOfElements: data?.aggregateData ? data.aggregateData.length : 0,
				limit: 1,
				hasMorePages: true
			}
		}
	};
	return pivotData;
}

const TopTalkersMain = (props) => {
	const classes = useStyles();
	const drillDownRef = React.useRef(null);
	const { data, locale, t, dataParams = {}, reportName = '' } = props;
	const [showTable, setShowTable] = useState(false);
	const [timeRangeMsg, setTimeRangeMsg] = useState({
		isValid: false,
		message: ''
	});
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pivotTableData, setPivotTableData] = useState({});
	const [parameters, setParameters] = useState({ reportId: dataParams.reportId });
	const dispatch = useDispatch();

	const renderDrillDownTable = (props) => {
		resetDrillDownParams();
		const updatedParams = { ...parameters, ...props.reportMetaData };
		if (props.data) {
			setPivotTableData(getPivotTableData(props, t));
		}
		loadParameters(updatedParams);
		updateShowHelpModal(updatedParams);
	};

	const updateShowHelpModal = (params) => {
		if (!timeRangeMsg.isValid) {
			const isValidTimeRange = validateTimeRange({
				startDate: params.startDate,
				t: t
			});
			setTimeRangeMsg(isValidTimeRange);
		}
	};

	const loadParameters = (params) => {
		setShowTable(false);
		setIsLoading(true);
		if (params.subReportType === 'SERVICES') {
			scrollToDDView();
		}
		axios
			.get('/portal/reports/executedReport?reportId=' + dataParams.reportId)
			.then((response) => {
				const res = response.data;
				setParameters({
					...res,
					...params
				});
				setShowTable(true);
			})
			.catch(() => {
				setShowTable(false);
				setIsError(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const scrollToDDView = () => {
		drillDownRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	const resetDrillDownParams = () => {
		dispatch(
			updateDrillDownDataTableParams({
				name: drillDownId,
				drillDownParams: {
					expandedRow: undefined,
					selectedColumn: undefined
				}
			})
		);
	};

	return (
		<>
			<TopTalkers
				data={data}
				t={t}
				dataParams={dataParams}
				locale={locale}
				renderDrillDownTable={renderDrillDownTable}
				reportName={props.reportName}
			></TopTalkers>
			{showTable && parameters.subReportType === 'SERVICES' && (
				<TimePeriodDialog
					open={!timeRangeMsg.isValid}
					onClose={() => {
						setTimeRangeMsg({
							...timeRangeMsg,
							isValid: true
						});
					}}
					message={timeRangeMsg.message}
				></TimePeriodDialog>
			)}
			<div ref={drillDownRef} className={classes.drillDown}>
				{showTable && !isLoading ? (
					<TopTalkersDrillDown
						fromChart={parameters}
						pivotTableData={pivotTableData}
						reportName={reportName}
						isError={isError}
					/>
				) : (
					isLoading && (
						<p>
							{t('events:summaryMessages.loadingPage')}
							<FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon>
						</p>
					)
				)}
			</div>
		</>
	);
};

export default TopTalkersMain;
