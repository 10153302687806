/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomIconButton = withStyles((theme) => ({
	root: {
		'color': theme.palette.grey[500],
		'&:hover': {
			color: theme.palette.action.active
		},
		'&$disabled': {
			color: theme.palette.action.disabledBackground
		}
	},
	disabled: {}
}))((props) => <IconButton color="secondary" {...props} />);

export default CustomIconButton;
