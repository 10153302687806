/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ClickAwayListener } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import NavMenu from './Menu';

const useStyles = makeStyles((theme) => ({
	navItem: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	navLink: {
		'color': theme.palette.headerNav.link,
		'display': 'block',
		'borderTop': '2px solid transparent',
		'borderBottom': '2px solid transparent',
		'padding': theme.spacing(3, 2),
		'textTransform': 'uppercase',
		'letterSpacing': '.033em',
		'borderRadius': 0,
		'fontSize': 10,
		'&:hover': {
			backgroundColor: 'transparent'
		},
		'&:focus svg': {
			color: theme.palette.headerNav.iconHover
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: 12
		}
	},
	navLinkActive: {
		'color': theme.palette.headerNav.linkHover,
		'backgroundColor': theme.palette.headerNav.linkBackgroundHover,
		'borderBottomColor': theme.palette.headerNav.linkBorderHover,
		'&:hover, &:focus': {
			color: theme.palette.headerNav.linkHover,
			backgroundColor: theme.palette.headerNav.linkBackgroundHover,
			borderBottomColor: theme.palette.headerNav.linkBorderHover
		}
	},
	navLinkIcon: {
		display: 'none',
		margin: `0 auto ${theme.spacing(1)}px auto`,
		fontSize: theme.spacing(4),
		color: theme.palette.headerNav.icon,
		transition: 'color .3s ease',
		[theme.breakpoints.up('lg')]: {
			display: 'block'
		}
	},
	navLinkIconActive: {
		color: theme.palette.headerNav.iconHover
	},
	navLinkText: {
		display: 'block',
		textAlign: 'center',
		lineHeight: 1.4,
		[theme.breakpoints.down('md')]: {
			maxWidth: 70,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden'
		}
	},
	navLinkTextSmall: {
		fontSize: 10,
		maxWidth: 120,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			maxWidth: 70
		}
	},
	navLinkTextIcon: {
		color: theme.palette.headerNav.icon,
		transition: 'color .3s ease'
	},
	navLinkTextIconActive: {
		color: theme.palette.headerNav.iconHover
	}
}));

const NavItem = ({ name, icon, routes }) => {
	const classes = useStyles();
	const buttonRef = useRef();
	const { t } = useTranslation();
	const pathname = useSelector((state) => state.router.location.pathname);
	const isNavSmall = useSelector((state) => state.nav.isSmall);
	const [navLinkEl, setNavLinkEl] = useState(false);
	const [navLinkActive, setNavLinkActive] = useState(false);

	function closeMenu() {
		setNavLinkEl(null);
	}

	function openMenu(event) {
		setNavLinkEl(event.currentTarget);
	}

	routes.forEach((item) => {
		if (!item) {
			return;
		}

		const match = matchPath(pathname, {
			path: item.path
		});

		if (match && !navLinkActive) {
			setNavLinkActive(true);
		}
	});

	return (
		<Grid item className={classes.navItem} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
			<ClickAwayListener onClickAway={closeMenu}>
				<Button
					disableRipple
					ref={buttonRef}
					color="inherit"
					onClick={(event) => {
						setNavLinkEl(navLinkEl ? false : event.currentTarget);
					}}
					className={classNames(classes.navLink, {
						[classes.navLinkActive]: navLinkActive
					})}
				>
					{icon && !isNavSmall ? (
						<FontAwesomeIcon
							icon={icon}
							className={classNames(classes.navLinkIcon, {
								[classes.navLinkIconActive]: navLinkActive || navLinkEl
							})}
						/>
					) : null}
					<span
						className={classNames(classes.navLinkText, {
							[classes.navLinkTextSmall]: isNavSmall
						})}
					>
						{t(name)}{' '}
						<FontAwesomeIcon
							icon={faCaretDown}
							className={classNames(classes.navLinkTextIcon, {
								[classes.navLinkTextIconActive]: navLinkActive || navLinkEl
							})}
						/>
					</span>
				</Button>
			</ClickAwayListener>

			<NavMenu routes={routes} pathname={pathname} navLinkEl={buttonRef.current} isOpen={Boolean(navLinkEl)} />
		</Grid>
	);
};

export default NavItem;
