/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { footerRoutes } from '../../../../routes/components/footer';

import NavItem from '../../../Nav/Secondary/Item';
import QualtricsFeedback from '../../../Qualtrics/Feedback';

import navBackgroundImageDark from './media/background-dark.png';
import navBackgroundImageLight from './media/background-light.gif';

const navRoutes = footerRoutes();

const useStyles = makeStyles((theme) => ({
	nav: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(4),
		borderTop: `2px solid ${theme.palette.footerNav.border}`,
		background: `${theme.palette.footerNav.background} url(${
			theme.palette.type === 'dark' ? navBackgroundImageDark : navBackgroundImageLight
		}) no-repeat center bottom`,
		backgroundSize: 'auto 100%'
	},
	menu: {
		margin: 0,
		padding: 0,
		listStyle: 'none'
	},
	item: {
		maxWidth: 260,
		flex: '1 0 1px'
	},
	title: {
		color: theme.palette.footerNav.text,
		textTransform: 'uppercase',
		marginBottom: theme.spacing(1)
	},
	menuItemTitle: {
		fontSize: theme.typography.body2.fontSize,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingBottom: 0
	},
	menuItemTitleText: {
		color: theme.palette.footerNav.text,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: 'uppercase',
		display: 'block'
	},
	feedback: {
		'& a': {
			...theme.typography.body2,
			'color': theme.palette.footerNav.link,
			'textDecoration': 'none',
			'paddingBottom': theme.spacing(1),
			'display': 'inline-block',
			'&:hover, &:focus': {
				color: theme.palette.footerNav.linkHover
			}
		}
	}
}));

const NavTitle = ({ text }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Typography component="p" variant="h4" className={classes.title}>
			{t(text)}
		</Typography>
	);
};

const NavFeedback = () => {
	const classes = useStyles();

	return (
		<NavItem
			key="qualtrics-general-feedback-footer"
			useHeaderStyles={false}
			link={{
				renderItem() {
					return <QualtricsFeedback id="qualtrics-general-feedback-footer" className={classes.feedback} />;
				}
			}}
		/>
	);
};

const FooterNav = () => {
	const classes = useStyles();

	return (
		<nav className={classes.nav}>
			<Container maxWidth={false}>
				<Grid container spacing={4}>
					{navRoutes.map((route, i) => {
						if (!route) {
							return null;
						}

						const { name, routes, showFeedback } = route;
						const hasRoutes = routes && routes.length;

						return (
							<Grid item key={name || i} className={classes.item}>
								{name ? <NavTitle text={name} /> : null}

								{hasRoutes || showFeedback ? (
									<ul className={classes.menu}>
										{showFeedback ? <NavFeedback /> : null}

										{hasRoutes
											? routes.map((link, idx) => {
													if (link) {
														const menu = link.menu;
														// don't render nav item if its menu items are null
														if (menu && menu.items && link.menu.items.every((x) => !x)) {
															return null;
														}

														// Don't render nav item if it is a sub-page
														if (link.isSubMenu) {
															return null;
														}

														return (
															<NavItem
																key={link.name || idx}
																link={link}
																useHeaderStyles={false}
															/>
														);
													} else {
														return null;
													}
											  })
											: null}
									</ul>
								) : null}
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</nav>
	);
};

export default FooterNav;
