/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import {
	ADD_DATA_TABLE,
	UPDATE_DATA_TABLE_DATA,
	UPDATE_DATA_TABLE_PARAMS,
	UPDATE_DATA_TABLE_PROGRESS,
	UPDATE_DATA_TABLE_MENU,
	UPDATE_DATA_TABLE_SETTINGS,
	APPEND_DATA_TABLE_DATA,
	UPDATE_DATA_TABLE_HAS_MORE,
	MODIFY_DATA_TABLE_DATA,
	UPDATE_AUTO_REFRESH_DATA_TABLE_PARAMS
} from '../../actions/components/datatables';

export default function datatableReducer(state = {}, action) {
	switch (action.type) {
		case ADD_DATA_TABLE:
			return {
				...state,
				...action.payload
			};
		case UPDATE_DATA_TABLE_DATA:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					data: {
						...action.payload.data,
						elements: action.payload.data.elements,
						updated: action.payload.data.updated
					}
				}
			};
		case APPEND_DATA_TABLE_DATA:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					data: {
						...action.payload.data,
						elements: [...state[action.payload.name].data.elements, ...action.payload.data.elements],
						updated: action.payload.data.updated
					}
				}
			};
		case MODIFY_DATA_TABLE_DATA:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					data: {
						...action.payload.data,
						elements: action.payload.data.elements,
						updated: action.payload.data.updated
					}
				}
			};
		case UPDATE_DATA_TABLE_PARAMS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					params: {
						...state[action.payload.name].params,
						...action.payload.params
					}
				}
			};
		case UPDATE_AUTO_REFRESH_DATA_TABLE_PARAMS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					params: {
						...state[action.payload.name].params,
						...{ limit: state[action.payload.name].data.elements?.length || 50, offset: 0 }
					}
				}
			};
		case UPDATE_DATA_TABLE_HAS_MORE:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					hasMore: action.payload.hasMore
				}
			};
		case UPDATE_DATA_TABLE_PROGRESS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					isError: action.payload.isError,
					isLoading: action.payload.isLoading,
					isAppendLoading: action.payload.isAppendLoading,
					isMenuOpen: action.payload.isMenuOpen,
					isSettingsOpen: action.payload.isSettingsOpen
				}
			};
		case UPDATE_DATA_TABLE_MENU:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					isMenuOpen: action.payload.isMenuOpen
				}
			};
		case UPDATE_DATA_TABLE_SETTINGS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					settings: {
						...state[action.payload.name].settings,
						...action.payload.settings
					},
					isSettingsOpen: action.payload.isSettingsOpen
				}
			};
		default:
			return state;
	}
}
