/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	reset,
	createSession,
	setPosition,
	setSize,
	getAvailableCampaigns,
	setProgress,
	setOpen,
	setSession,
	getConversationInfo
} from '../../../../state/actions/components/chat';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import ChatFrameButton from './Button';

import ErrorView from '../Error';
import TransferView from '../Transfer';
import InitializeView from '../Initialize';
import InformationView from '../Information';
import QueueView from '../Queue';
import ConverstationView from '../Conversation';
import UnavailableView from '../Unavailable';
import TerminatedView from '../Terminated';
import EndChatModal from './Modals';

import useChatWebSocket from './socket';
import { debugMessage, useNetworkState } from '../../../../utils';

const INIT_RETRY_TIMER = 30000; // every 30 seconds
const REFETCH_CAMPAIGNS_TIMER = 15000; // every 15 seconds

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		zIndex: theme.zIndex.appBar
	},
	rootFixed: {
		right: theme.spacing(3),
		bottom: 0,
		position: 'fixed'
	},
	rootExpanded: {
		'width': '100%',
		'height': '100%',
		'& $frame': {
			'height': '100vh',
			'maxHeight': 'inherit',
			'& .sw-chat-frame-base': {
				border: 0
			}
		}
	},
	frame: {
		display: 'flex',
		position: 'relative',
		flexFlow: 'column nowrap',
		height: '80vh !important',
		maxHeight: 600,
		maxWidth: '90vw !important',
		borderBottom: 0
	},
	frameFull: {
		height: '100vh !important',
		width: 'auto !important',
		maxHeight: 'inherit',
		maxWidth: '100vw !important'
	},
	resizeHandle: {
		position: 'absolute',
		top: 39,
		left: 4,
		transform: 'rotate(45deg)',
		cursor: 'w-resize',
		width: 8,
		height: 8,
		lineHeight: 1
	}
}));

const mapStateToView = (progress) => {
	switch (progress) {
		case 'showError':
			return <ErrorView />;
		case 'showUnavailable':
			return <UnavailableView />;
		case 'showTerminated':
			return <TerminatedView />;
		case 'showQueue':
			return <QueueView />;
		case 'showTransfer':
			return <TransferView />;
		case 'showChatForm':
			return <InformationView />;
		case 'acceptedByAgent':
		case 'terminatedByAgent':
		case 'terminatedByClient':
			return <ConverstationView />;
		default:
			return <InitializeView />;
	}
};

const useChatFrame = () => {
	const canRun = useRef(true);
	const dispatch = useDispatch();
	const contactId = useSelector((state) => state.contact.id);
	const sessionContactId = useSelector((state) => state.chat.session.contactId);
	const isOpen = useSelector((state) => state.chat.isOpen);
	const progress = useSelector((state) => state.chat.progress);
	const campaigns = useSelector((state) => state.chat.session.campaigns);
	const tokenId = useSelector((state) => state.chat.session.tokenId);
	const sessionIsLoading = useSelector((state) => state.chat.session.isLoading);
	const [retry, setRetry] = useState(false);

	useEffect(() => {
		// creates a session
		if ((!tokenId && !sessionIsLoading && canRun.current) || retry) {
			debugMessage('[CHAT] [useChatFrame] Creating initial session.');

			canRun.current = false;
			setRetry(false);

			dispatch(createSession());
		}
	}, [dispatch, retry, sessionIsLoading, tokenId]);

	useEffect(() => {
		// resets session on contact change
		if (sessionContactId && sessionContactId !== contactId) {
			debugMessage('[CHAT] [useChatFrame] Contact id has changed, resetting state.');
			dispatch(reset());
		}
	}, [contactId, sessionContactId, dispatch]);

	useChatWebSocket();

	useEffect(() => {
		// resets session when campains become unavailable
		if (tokenId && !campaigns.length && !sessionIsLoading && canRun.current && isOpen === null) {
			debugMessage('[CHAT] [useChatFrame] No campaigns are available, resetting state.');
			dispatch(reset());
		}
	}, [campaigns.length, dispatch, sessionIsLoading, tokenId, isOpen]);

	useEffect(() => {
		// show user message when campains become unavailable and user has chat open
		if (tokenId && !campaigns.length && !sessionIsLoading && isOpen !== null) {
			debugMessage('[CHAT] [useChatFrame] No campaigns are available, showing user message.');
			dispatch(setOpen({ isOpen: true }));
			dispatch(
				setProgress({
					progress: 'showUnavailable'
				})
			);
		}
	}, [campaigns.length, dispatch, sessionIsLoading, tokenId, isOpen]);

	useEffect(() => {
		// periodically retries session creation when campaigns are unavailable
		const interval = setInterval(() => {
			debugMessage('[CHAT] [useChatFrame] Running session creation interval.');
			if (
				!campaigns.length &&
				!sessionIsLoading &&
				!canRun.current &&
				progress !== 'showUnavailable' &&
				progress !== 'showError'
			) {
				debugMessage('[CHAT] [useChatFrame] No campaigns are available, retrying session creation.');
				canRun.current = true;
				setRetry(true);
			}
			// reset session loading state after a minute, in instance it was interrupted.
			if (sessionIsLoading && sessionIsLoading + 60000 < new Date().getTime()) {
				debugMessage('[CHAT] [useChatFrame] Stale session loading state, resetting.');
				dispatch(
					setSession({
						isLoading: false
					})
				);
				canRun.current = true;
				setRetry(true);
			}
		}, INIT_RETRY_TIMER);

		return () => clearInterval(interval);
	}, [dispatch, campaigns.length, sessionIsLoading, tokenId, progress]);

	useEffect(() => {
		// periodically rechecks available campaigns
		const interval = setInterval(() => {
			debugMessage('[CHAT] [useChatFrame] Running campaign check interval.');
			if (
				tokenId &&
				campaigns.length &&
				!sessionIsLoading &&
				(!progress || ['showChatForm', 'showQueue'].includes(progress))
			) {
				debugMessage('[CHAT] [useChatFrame] Rechecking available campaigns.');
				dispatch(getAvailableCampaigns());
			}
		}, REFETCH_CAMPAIGNS_TIMER);

		return () => clearInterval(interval);
	}, [dispatch, sessionIsLoading, tokenId, progress, campaigns.length]);

	return {
		campaigns
	};
};

const ChatFrame = ({ isStandaloneChat }) => {
	const nodeRef = useRef();
	const dispatch = useDispatch();
	const classes = useStyles();
	const isOnline = useNetworkState();
	const { campaigns } = useChatFrame();
	const isOpen = useSelector((state) => state.chat.isOpen) || isStandaloneChat;
	const progress = useSelector((state) => state.chat.progress);
	const position = useSelector((state) => state.chat.position);
	const size = useSelector((state) => state.chat.size);
	const defaultSize = isStandaloneChat ? { w: 320, h: 550 } : size;
	const defaultPosition = isStandaloneChat ? { x: 0, y: 0 } : position;

	useEffect(() => {
		if (['acceptedByAgent', 'showQueue', 'showTransfer'].includes(progress) && isOnline) {
			dispatch(getConversationInfo());
		}
	}, [dispatch, progress, isOnline]);

	const saveWidgetPosition = (event, { x }) => {
		dispatch(setPosition({ x, y: 0 }));
	};

	const saveWidgetSize = (event, { size }) => {
		dispatch(
			setSize({
				w: parseInt(size.width, 10),
				h: parseInt(size.height, 10)
			})
		);
	};

	return (
		<Draggable
			axis="x"
			bounds="parent"
			handle=".sw-chat-drag-handle"
			disabled={isStandaloneChat}
			onStop={saveWidgetPosition}
			defaultPosition={defaultPosition}
		>
			<Collapse
				in={isOpen}
				collapsedHeight={40}
				className={classNames('sw-chat', classes.root, {
					[classes.rootFixed]: !isStandaloneChat,
					[classes.rootExpanded]: isStandaloneChat
				})}
			>
				<ResizableBox
					axis="x"
					width={defaultSize.w}
					height={defaultSize.h}
					minConstraints={[300, 500]}
					className={classNames(classes.frame, { [classes.frameFull]: isStandaloneChat })}
					resizeHandles={['w']}
					onResizeStop={saveWidgetSize}
					handle={
						isStandaloneChat || !progress || progress === 'showUnavailable'
							? null
							: () => (
									<div className={classes.resizeHandle}>
										<FontAwesomeIcon icon={faAngleLeft} />
									</div>
							  )
					}
				>
					<div ref={nodeRef} className={classNames(classes.frame, { [classes.frameFull]: isStandaloneChat })}>
						{isStandaloneChat ? null : <ChatFrameButton disabled={!campaigns.length && !isOpen} />}
						{mapStateToView(progress)}
						<EndChatModal container={nodeRef} isStandaloneChat={isStandaloneChat} />
					</div>
				</ResizableBox>
			</Collapse>
		</Draggable>
	);
};

export default ChatFrame;
