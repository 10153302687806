import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getEventURL } from '../../../../../pages/Reports/View/TopTalkers/Events/util';

export const getEventsTableColumns = function ({ t, clientId, severityClasses, classes, params }) {
	const reportType = params?.reportType?.[0];

	const columns = [
		{
			headerTitle: t('events:headers.dateTime'),
			id: 'eventTimeStamp',
			accessor: 'eventTimeStamp',
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.eventId'),
			id: 'eventId',
			accessor: 'eventId',
			Cell: (e) => {
				const number = e.value;
				if (number) {
					const href = getEventURL(e.row?.original, clientId);
					return (
						<a href={href} className={classes.downloadLink} target="_blank" rel="noreferrer">
							{number}
						</a>
					);
				} else {
					return <></>;
				}
			},
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.severity'),
			id: 'severity',
			accessor: (row) => {
				if (!row) {
					return null;
				}

				return row.severity?.toLowerCase();
			},
			Cell: (e) => {
				if (e.value) {
					const severity = e.value.toString();
					return (
						<>
							<FontAwesomeIcon
								title={t('events:severities.' + severity)}
								className={severityClasses[severity]}
								icon={faCircle}
							></FontAwesomeIcon>
							<span className={severityClasses.label}>{t('events:severities.' + severity)}</span>
						</>
					);
				} else {
					return <></>;
				}
			},
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.eventSummary'),
			id: 'eventSummary',
			accessor: 'eventSummary',
			background: 'red',
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.category'),
			id: 'category',
			accessor: 'category',
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.action'),
			id: 'action',
			accessor: 'action',
			className: classes.cell
		},
		{
			headerTitle: t('events:headers.device'),
			id: 'device',
			accessor: 'device',
			className: classes.cell
		}
	];

	if (reportType === 'SRC_COUNTRY' || reportType === 'DST_COUNTRY') {
		columns.push({
			headerTitle: t('events:headers.srcIpCountry'),
			id: 'srcIpCountry',
			accessor: 'srcIpCountry',
			className: classes.cell,
			hidden: true
		});
		columns.push({
			headerTitle: t('events:headers.dstIpCountry'),
			id: 'dstIpCountry',
			accessor: 'dstIpCountry',
			className: classes.cell
		});
	} else {
		columns.push({
			headerTitle: t('events:headers.sourceIp'),
			id: 'source',
			accessor: 'source',
			className: classes.cell
		});
		columns.push({
			headerTitle: t('events:headers.destIp'),
			id: 'destination',
			accessor: 'destination',
			className: classes.cell
		});
		if (reportType === 'SERVICES') {
			columns.push({
				headerTitle: t('events:headers.serviceWithPort'),
				id: 'serviceWithPort',
				accessor: (row) => {
					if (!row) {
						return null;
					}

					return params.dstPort;
				},
				className: classes.cell
			});
		}
	}
	columns.push({
		headerTitle: t('events:headers.count'),
		id: 'count',
		accessor: 'count',
		className: classes.cell
	});
	return columns;
};
