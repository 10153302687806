/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import routeList from '../bootstrap';
import { hasRouteAccess } from '../../utils/access';
import { commonRoutes, commonBarRoutes, documentsMenuRoutes } from './common';

/**
 * Footer navigation menu routes.
 *
 * @param {Object} options - Options for rendering menu routes.
 * @returns {Array} A list of configured routes.
 */
export const footerRoutes = () => [
	...commonRoutes,
	{
		routes: [...commonBarRoutes(), ...hasRouteAccess([documentsMenuRoutes(), routeList.other.browserSupport])],
		showFeedback: true
	}
];
