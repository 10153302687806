/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React, { useRef, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/Inputs/Button';
import SelectCustom from '../../../components/Inputs/Formik/Select';
import TextFieldCustom from '../../../components/Inputs/Formik/TextField';
import axios from 'axios';
import { showAlert } from '../../../state/actions/components/alerts';
import { fetchDataTableData } from '../../../state/actions/components/datatables';
import { hideModal } from '../../../state/actions/components/modals';
import ReviewBlock from './ReviewBlock';
import { AlertCustom } from '../../../components/Alert';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.grey[500],
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(3)
	},
	dialogContent: {
		maxHeight: 420
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	},
	field: {
		marginTop: theme.spacing(3)
	}
}));

const EditNetworkModal = ({ isOpen, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const { row } = data;

	const [requestState, setRequestState] = useState({
		isError: false,
		isLoading: false
	});

	const [reviewEdits, setReviewEdits] = useState(false);

	const [is412Error, setIs412Error] = useState(false);

	const networkOptions = [
		{ value: 'NAT', label: 'NAT' },
		{ value: 'DMZ', label: 'DMZ' },
		{ value: 'Internal', label: 'Internal' },
		{ value: 'Public', label: 'Public' }
	];

	const formRef = useRef();

	function ipv4_6(message) {
		const regExpIp = /(^\s*((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/;

		return (
			this.transform((initialValue) => {
				const values = initialValue.split('/');
				const range = values[1];

				/** Check for CIDR
				 * If CIDR (range) fails, pass through full initialValue to trigger error message.
				 * Otherwise pass IP (values[0]) for further validation.
				 */
				if (!!range && (Number.isNaN(range * 1) || range < 1 || range > 32)) {
					return initialValue;
				}
				return values[0];
			})
				/* Check IP against regexp */
				.matches(regExpIp, {
					message,
					excludeEmptyString: true
				})
				.test('ip', message, (value) => {
					return !value || value !== undefined || value.trim() !== '';
				})
		);
	}
	Yup.addMethod(Yup.string, 'ipv4_6', ipv4_6);

	const InitialValues = useMemo(
		() => ({
			networkType: `${row.values['network-summary-type']}`,
			networkIP: `${row.values['network-summary-ip-address']}`,
			networkDescription: `${row.values['network-summary-description']}`,
			allValid: false,
			networkStatus: `${row.original['networkStatus']}`
		}),
		[row]
	);

	const ValidationSchema = Yup.object().shape({
		networkIP: Yup.string()
			.required(t('modals.editNetwork.messages.required', { field: t('modals.editNetwork.cidrRange') }))
			.ipv4_6(t('modals.editNetwork.messages.invalidCIDRRange')),
		networkDescription: Yup.string().required(
			t('modals.editNetwork.messages.required', { field: t('modals.editNetwork.description') })
		),
		allValid: Yup.mixed().test('changed', (value, values) => {
			const allValid =
				values.from[0].value.networkDescription === InitialValues.networkDescription &&
				values.from[0].value.networkIP === InitialValues.networkIP &&
				values.from[0].value.networkType === InitialValues.networkType;
			return !allValid;
		})
	});

	function handleClose() {
		const refreshView = reviewEdits;

		setReviewEdits(false);
		setIs412Error(false);

		setRequestState({
			isError: false
		});

		if (refreshView) {
			dispatch(
				fetchDataTableData({
					name: 'ADMINISTRATION_NETWORKS'
				})
			);
		}

		dispatch(hideModal({ name: 'EDIT_NETWORK' }));
	}

	function handleContinue() {
		setReviewEdits(true);
	}

	function handleSubmit() {
		const params = {
			networkType: formRef.current.values.networkType.toUpperCase(),
			networkAddress: formRef.current.values.networkIP,
			description: formRef.current.values.networkDescription
		};

		setRequestState({
			isError: false,
			isLoading: true
		});

		const url = `/portal/administration/networks/edit/${row.original.networkId}`;
		const method = 'PUT';

		return axios({
			url,
			method,
			data: params
		})
			.then(() => {
				setRequestState({
					isError: false,
					isLoading: false
				});

				dispatch(
					showAlert({
						id: `EDIT_NETWORKS_SUCCESS_${new Date().getTime()}`,
						data: {
							severity: 'success',
							message: [
								'common:modals.editNetwork.messages.success',
								{
									name: `${formRef.current.values.networkType}, ${formRef.current.values.networkIP}, ${formRef.current.values.networkDescription}`
								}
							]
						}
					})
				);

				handleClose();
			})
			.catch(({ response }) => {
				if (response && response.status) {
					if (response.status === 412) {
						setIs412Error(true);
					}
				}

				setRequestState({
					isError: true,
					isLoading: false
				});
			});
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="edit-network-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<Formik
				onSubmit={handleSubmit}
				validationSchema={ValidationSchema}
				initialValues={InitialValues}
				initialErrors={{
					networkType: ''
				}}
				innerRef={formRef}
			>
				{({ submitForm, isSubmitting, isValid, values }) => (
					<Form autoComplete="off">
						<DialogTitle id="edit-network-modal" disableTypography>
							<Typography variant="h2">
								<Box fontWeight="fontWeightRegular">{t('modals.editNetwork.title')}</Box>
								<IconButton
									size="small"
									aria-label="close"
									onClick={handleClose}
									className={classes.closeButton}
								>
									<FontAwesomeIcon icon={faTimes} size="1x" />
								</IconButton>
							</Typography>
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContent}>
							{requestState.isError ? (
								<AlertCustom severity="error" variant="outlined" gutterBottom>
									{is412Error
										? t('modals.editNetwork.messages.error412')
										: t('modals.editNetwork.messages.error')}
								</AlertCustom>
							) : null}

							{!reviewEdits ? (
								<>
									<Box mb={2}>
										<Typography>{t('modals.editNetwork.messages.singleHostNote')}</Typography>
									</Box>

									<Field
										component={SelectCustom}
										name={'networkType'}
										label={`${t('modals.editNetwork.type')}:`}
										options={networkOptions}
										className={classes.field}
									/>

									<Field
										component={TextFieldCustom}
										name={'networkIP'}
										label={`${t('modals.editNetwork.cidrRange')}:`}
										className={classes.field}
									/>

									<Field
										fullWidth
										component={TextFieldCustom}
										multiline
										rows={6}
										name={'networkDescription'}
										label={`${t('modals.editNetwork.description')}:`}
										className={classes.field}
									/>
								</>
							) : (
								<>
									<Box mb={2}>
										<Typography>{t('modals.editNetwork.review.instruction')}</Typography>
									</Box>

									<ReviewBlock
										label={t('modals.editNetwork.review.originalValues')}
										data={{
											type: row.values['network-summary-type'],
											ip: row.values['network-summary-ip-address'],
											description: row.values['network-summary-description']
										}}
									/>
									<ReviewBlock
										label={t('modals.editNetwork.review.newValues')}
										data={{
											type: values.networkType,
											ip: values.networkIP,
											description: values.networkDescription
										}}
									/>

									{/**Inline formatting from translation requires default copy as template.
									 * This is replaced at runtime.
									 */}
									<Trans i18nKey="common:modals.editNetwork.review.clickSubmit">
										Click <strong>Submit</strong> to edit the network.
									</Trans>
									<Box mb={2}>
										<Trans i18nKey="common:modals.editNetwork.review.clickCancel">
											Click <strong>Cancel</strong> to cancel the edit.
										</Trans>
									</Box>
								</>
							)}
						</DialogContent>

						<DialogActions className={classes.dialogActions}>
							{is412Error ? (
								<Button onClick={handleClose}>{t('buttons.close')}</Button>
							) : (
								<Button
									onClick={handleClose}
									variant="outlined"
									color="default"
									disabled={requestState.isLoading}
								>
									{t('buttons.cancel')}
								</Button>
							)}

							{!reviewEdits ? (
								<Button onClick={handleContinue} disabled={!isValid}>
									{t('buttons.continue')}
								</Button>
							) : null}

							{reviewEdits && !is412Error ? (
								<Button onClick={submitForm} isLoading={isSubmitting} disabled={!isValid}>
									{t('buttons.submit')}
								</Button>
							) : null}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default EditNetworkModal;
