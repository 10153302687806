import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const styles = (theme) => ({
	'& > .fa': {
		margin: theme.spacing(2)
	},
	'root': {
		margin: 0,
		padding: theme.spacing(2),
		paddingLeft: 16
	},
	'noHeader': {
		margin: 0,
		padding: 0
	},
	'closeButton': {
		'position': 'absolute',
		'cursor': 'pointer',
		'right': theme.spacing(2),
		'top': theme.spacing(2),
		'fontSize': '1rem',
		'opacity': 0.6,
		'color': theme.palette.grey[500],
		'&:hover': {
			color: '#181818',
			opacity: 0.4
		}
	}
});

const CustomDialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle className={children ? classes.root : classes.noHeader} {...other}>
			{children && <Typography variant="h3">{children}</Typography>}
			<FontAwesomeIcon className={classes.closeButton} icon={faTimes} onClick={onClose} />
		</MuiDialogTitle>
	);
});

const CloseButton = withStyles((theme) => ({
	root: {
		'backgroundColor': theme.palette.cancelButton.backgroundColor,
		'color': theme.palette.cancelButton.color,
		'border': '1px solid',
		'borderColor': theme.palette.cancelButton.borderColor,
		'fontSize': '15px',
		'borderRadius': '4px',
		'lineHeight': '22px',
		'padding': '4px 12px',
		'marginRight': '5px',
		'&:hover': {
			textDecoration: 'none',
			backgroundColor: theme.palette.cancelButton.hover.backgroundColor,
			color: theme.palette.cancelButton.hover.color
		}
	}
}))(Button);

const DialogContent = withStyles((theme) => ({
	root: {
		padding: '5px 15px'
	},
	dividers: {
		borderTop: '1px solid ' + theme.palette.reports.modal.dividers.borderColor,
		borderBottom: '1px solid ' + theme.palette.reports.modal.dividers.borderColor
	}
}))(MuiDialogContent);

const DialogFooter = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		background: theme.palette.reports.modal.footer.color
	}
}))(DialogActions);

export { CustomDialogTitle, CloseButton, DialogContent, DialogFooter };
