/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { meta } from '../../../../../state/bootstrap';

const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.footerBar.text
	}
}));

const InfoBox = () => {
	const classes = useStyles();
	const { t } = useTranslation('common');

	const text = [
		t('legal.copyright', {
			currentYear
		})
	];

	if (meta && meta.buildRevision && meta.hostName && meta.buildVersion) {
		text.push(t('app.info', meta));
	}

	return (
		<Typography variant="body2" className={classes.root}>
			{text.join(' ')}
		</Typography>
	);
};

export default InfoBox;
