/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Reports from '../../../../../utils/reports';
import { useExportDrillDown } from './useExportDrillDown';
import { getDownloadFileName, getGroupingOptions, getTopServicesAggregationsSearchFormModel } from './util';

const apiUrl = '/portal/reportsdrilldown/getTopTalkersEventAggregateData?limit=10&page=1';
const method = 'POST';
const drillDownId = 'REPORTS_TOP_TALKERS_DRILLDOWN';
const showPagination = false;
const useTopTalkersDrillDown = (fromChart, reportName) => {
	const { t } = useTranslation(['events', 'reports', 'dashboard']);
	const groupingOptions = getGroupingOptions(t, fromChart.subReportType);
	const [requestPayload, setRequestPayload] = useState({
		...getTopServicesAggregationsSearchFormModel(fromChart),
		rowGrouping: groupingOptions[0].id
	});

	const { exportDrillDownData } = useExportDrillDown();

	// download
	const downloadPdf = (rowGrouping) => {
		exportDrillDownData(
			{
				...requestPayload,
				rowGrouping: rowGrouping || requestPayload.rowGrouping
			},
			'pdf',
			getDownloadFileName(rowGrouping || requestPayload.rowGrouping, requestPayload.dstPort, reportName)
		);
	};

	const downloadCsv = (rowGrouping) => {
		exportDrillDownData(
			{
				...requestPayload,
				rowGrouping: rowGrouping || requestPayload.rowGrouping
			},
			'csv',
			getDownloadFileName(rowGrouping || requestPayload.rowGrouping, requestPayload.dstPort, reportName)
		);
	};

	return {
		apiUrl,
		method,
		requestPayload,
		setRequestPayload,
		groupingOptions,
		defaultGroupingOption: groupingOptions[0],
		downloadPdf,
		downloadCsv,
		fetchDataResponseFormatter: Reports.DrillDown.fetchDataResponseFormatter,
		drillDownId,
		showPagination,
		drillDownParams: fromChart
	};
};
useTopTalkersDrillDown.propTypes = Reports.DrillDown.hookPropTypes;
export { useTopTalkersDrillDown, drillDownId };
