/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Button } from '@material-ui/core';
import LoaderCircular from '../../Loaders/Circular';

function ButtonInput(props) {
	const {
		children,
		disabled,
		isLoading,
		color = 'secondary',
		variant = 'contained',
		loaderSize = 26,
		...other
	} = props;

	return (
		<Button disabled={disabled || isLoading} color={color} variant={variant} {...other}>
			{isLoading ? <LoaderCircular size={loaderSize} /> : children}
		</Button>
	);
}

export default ButtonInput;
