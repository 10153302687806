/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, MenuList } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: {
		padding: '5px 8px 5px 10px',
		borderBottomLeftRadius: '0',
		borderBottomRightRadius: '0',
		border: `1px solid ${theme.palette.table.cell.head.dropdown.border}`,
		borderBottom: 'none'
	},
	dropdownIcon: {
		marginLeft: theme.spacing(1)
	},
	dropdownMenuContainer: {
		border: `1px solid ${theme.palette.table.cell.head.dropdown.border}`
	},
	dropdownMenuItem: {
		'&&:hover': {
			backgroundColor: theme.palette.common.blue,
			color: theme.palette.common.white
		}
	},
	popper: {
		zIndex: '1'
	}
}));

export const ExportControl = ({ exportFormats, exportFunction }) => {
	const classes = useStyles();
	const { t } = useTranslation(['datatables']);

	const buttonRef = createRef();
	const [elRef, setElRef] = useState(null);

	const handleMenuItemClick = (option) => {
		exportFunction(option);

		setElRef(null);
	};

	const handleToggle = () => {
		setElRef(elRef ? null : buttonRef.current);
	};

	const handleClose = () => {
		setElRef(null);
	};

	return (
		<>
			<Button
				color="default"
				size="small"
				variant="contained"
				ref={buttonRef}
				className={classes.button}
				aria-expanded={elRef ? 'true' : undefined}
				aria-haspopup="menu"
				onClick={handleToggle}
			>
				{t('controls.download')}
				<FontAwesomeIcon icon={faChevronDown} className={classes.dropdownIcon} />
			</Button>
			<Popper
				className={classes.popper}
				open={Boolean(elRef)}
				anchorEl={elRef}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper className={classes.dropdownMenuContainer}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{exportFormats.map((option) => (
										<MenuItem
											title={option.tooltip}
											className={classes.dropdownMenuItem}
											key={option.format}
											onClick={() => handleMenuItemClick(option.format)}
										>
											{option.format}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
