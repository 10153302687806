/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import axios from 'axios';
import { batch } from 'react-redux';

export const ADD_DATA_TABLE = 'ADD_DATA_TABLE';

export const UPDATE_DATA_TABLE_DATA = 'UPDATE_DATA_TABLE_DATA';
export const APPEND_DATA_TABLE_DATA = 'APPEND_DATA_TABLE_DATA';
export const MODIFY_DATA_TABLE_DATA = 'MODIFY_DATA_TABLE_DATA';
export const UPDATE_DATA_TABLE_PARAMS = 'UPDATE_DATA_TABLE_PARAMS';
export const UPDATE_AUTO_REFRESH_DATA_TABLE_PARAMS = 'UPDATE_AUTO_REFRESH_DATA_TABLE_PARAMS';
export const UPDATE_DATA_TABLE_HAS_MORE = 'UPDATE_DATA_TABLE_HAS_MORE';
export const UPDATE_DATA_TABLE_PROGRESS = 'UPDATE_DATA_TABLE_PROGRESS';
export const UPDATE_DATA_TABLE_MENU = 'UPDATE_DATA_TABLE_MENU';
export const UPDATE_DATA_TABLE_SETTINGS = 'UPDATE_DATA_TABLE_SETTINGS';

const defaultDataTableOptions = {
	url: null,
	method: 'GET',
	data: { elements: [] },
	filters: [],
	params: {
		limit: 50,
		page: 1
	},
	settings: {},
	isError: false,
	isLoading: true,
	isAppendLoading: false,
	isMenuOpen: false,
	isSettingsOpen: false,
	hasMore: true
};

export const addDataTable = ({ name, options = {} }) => ({
	type: ADD_DATA_TABLE,
	payload: {
		[name]: {
			...defaultDataTableOptions,
			...options
		}
	}
});

export const updateDataTableData = ({ name, data = {} }) => ({
	type: UPDATE_DATA_TABLE_DATA,
	payload: {
		name,
		data
	}
});

export const appendDataTableData = ({ name, data = {} }) => ({
	type: APPEND_DATA_TABLE_DATA,
	payload: {
		name,
		data
	}
});

export const modifyDataTableData = ({ name, data = {} }) => ({
	type: MODIFY_DATA_TABLE_DATA,
	payload: {
		name,
		data
	}
});

export const updateDataTableParams = ({ name, params = {} }) => ({
	type: UPDATE_DATA_TABLE_PARAMS,
	payload: {
		name,
		params
	}
});

export const updateAutoRefreshDataTableParams = ({ name, params = {} }) => ({
	type: UPDATE_AUTO_REFRESH_DATA_TABLE_PARAMS,
	payload: {
		name,
		params
	}
});

export const updateDataTableHasMore = ({ name, hasMore }) => ({
	type: UPDATE_DATA_TABLE_HAS_MORE,
	payload: {
		name,
		hasMore
	}
});

export const updateDataTableProgress = ({ name, isError, isLoading, isAppendLoading = false }) => ({
	type: UPDATE_DATA_TABLE_PROGRESS,
	payload: {
		name,
		isError,
		isLoading,
		isAppendLoading: isAppendLoading,
		isMenuOpen: false,
		isSettingsOpen: false
	}
});

export const updateDataTableMenu = ({ name, isMenuOpen }) => ({
	type: UPDATE_DATA_TABLE_MENU,
	payload: {
		name,
		isMenuOpen
	}
});

export const updateDataTableSettings = ({ name, settings, isSettingsOpen }) => ({
	type: UPDATE_DATA_TABLE_SETTINGS,
	payload: {
		name,
		settings,
		isSettingsOpen
	}
});

export function createDataTable({ name, options, initialData, dataProperty, dataCallbackFn }) {
	return (dispatch) => {
		dispatch(addDataTable({ name, options }));
		if (initialData) {
			dispatch(createTableWithPredefinedData({ name, initialData }));
		} else {
			dispatch(fetchDataTableData({ name, dataProperty, dataCallbackFn }));
		}
	};
}

export function updateDataTableProgressByAction({ name, action, loading, isError = false }) {
	return (dispatch) => {
		if (action !== 'APPEND' && action !== 'MODIFY') {
			dispatch(
				updateDataTableProgress({
					name,
					isError: isError,
					isLoading: loading,
					isAppendLoading: false
				})
			);
		} else if (action === 'APPEND') {
			dispatch(
				updateDataTableProgress({
					name,
					isError: isError,
					isLoading: false,
					isAppendLoading: loading
				})
			);
		}
	};
}

export function fetchDataTableData({ name, action, dataProperty, dataCallbackFn }) {
	return (dispatch, getState) => {
		const { datatables } = getState();
		const { url, params = {}, method = 'GET' } = datatables[name] || {};

		dispatch(updateDataTableProgressByAction({ name, action, loading: true }));

		let axiosConfig;

		if (method === 'POST') {
			axiosConfig = {
				url,
				method,
				data: params
			};
		} else {
			axiosConfig = {
				url,
				method,
				params
			};
		}
		axios(axiosConfig)
			.then(({ data }) => {
				if (Array.isArray(data)) {
					data = {
						elements: data
					};
				} else if (dataProperty && Array.isArray(data[dataProperty])) {
					data = {
						...data,
						elements: data[dataProperty]
					};
				}

				if (!data?.elements) {
					data = {
						...data,
						elements: []
					};
				}

				data.updated = new Date().getTime();
				batch(() => {
					switch (action) {
						case 'APPEND':
							dispatch(
								appendDataTableData({
									name,
									data
								})
							);
							break;
						case 'MODIFY':
							dispatch(
								modifyDataTableData({
									name,
									data
								})
							);
							break;
						default:
							dispatch(
								updateDataTableData({
									name,
									data
								})
							);
							break;
					}
					dispatch(
						updateDataTableHasMore({
							name,
							hasMore: params.limit ? data.elements.length === params.limit : true
						})
					);
					dispatch(updateDataTableProgressByAction({ name, action, loading: false, isError: false }));
				});

				if (dataCallbackFn) {
					dataCallbackFn(data);
				}
			})
			.catch(() => {
				dispatch(updateDataTableProgressByAction({ name, action, loading: false, isError: true }));
			});
	};
}

export function createTableWithPredefinedData({ name, initialData }) {
	return (dispatch) => {
		dispatch(
			updateDataTableProgress({
				name,
				isError: false,
				isLoading: true
			})
		);

		const data = initialData;

		return batch(() => {
			dispatch(
				updateDataTableData({
					name,
					data
				})
			);
			dispatch(
				updateDataTableProgress({
					name,
					isError: false,
					isLoading: false
				})
			);
		});
	};
}
