/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { AppRoutes } from '../../routes';
import useLuxon from '../../utils/useLuxon';
import GlobalAlertRoot, { useGlobalAlertRoot } from '../Alerts';
import GlobalModalRoot, { useGlobalModalRoot } from '../Modals';
import { useQualtrics } from '../../components/Qualtrics';
import Chat from '../../components/Chat';
import { useVendorAccessChecks } from '../../components/Chat/vendorAccessCheck';
import { useCurrentTheme } from '../../components/Themes';
import LoaderPage from '../../components/Loaders/Page';

import Content from '../Content';
import Footer from '../Footer';
import Header from '../Header';
import { useDispatch } from 'react-redux';
import { changeLanguage, changeTheme } from '../../state/actions/app/preferences';
import { useTranslation } from 'react-i18next';

import { config } from '@fortawesome/fontawesome-svg-core';

/**
 * Renders main application shell.
 *
 * @param {Object} context - Redux context.
 * @param {Object} history - History context.
 *
 * @returns {React.Component}
 */
const App = ({ context, history }) => {
	const theme = useCurrentTheme();

	config.autoAddCss = false;

	useLuxon();
	useQualtrics();
	useGlobalAlertRoot();
	useGlobalModalRoot();
	useVendorAccessChecks();

	return (
		<ThemeProvider theme={theme}>
			<ConnectedRouter history={history} context={context}>
				<HelmetProvider>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<Header />
						<Suspense fallback={<section />}>
							<GlobalAlertRoot />
						</Suspense>
						<Content>
							<Suspense fallback={<LoaderPage />}>
								<AppRoutes />
								<GlobalModalRoot />
								<Chat />
							</Suspense>
						</Content>
						<Footer />
					</MuiPickersUtilsProvider>
				</HelmetProvider>
			</ConnectedRouter>
		</ThemeProvider>
	);
};

App.propTypes = {
	context: PropTypes.any.isRequired,
	history: PropTypes.any.isRequired
};

const AppLegacyHoC = ({ children }) => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation('common');

	config.familyPrefix = 'legacy-app-fa';
	config.replacementClass = 'legacy-app-inline-fa';
	config.autoAddCss = false;

	window.appChangeTheme = (theme) => {
		dispatch(
			changeTheme({
				theme
			})
		);
	};

	window.appChangeLanguage = (language) => {
		dispatch(
			changeLanguage({
				language,
				i18n
			})
		);
	};

	return children;
};

/**
 * Renders legacy application shell.
 *
 * @param {Object} context - Redux context.
 * @param {Object} history - History context.
 *
 * @returns {React.Component}
 */
const AppLegacy = ({ context, history, isStandaloneChat }) => {
	const theme = useCurrentTheme();

	useLuxon();

	return (
		<ThemeProvider theme={theme}>
			<ConnectedRouter history={history} context={context}>
				<HelmetProvider>
					<MuiPickersUtilsProvider utils={LuxonUtils}>
						<Suspense fallback={<div />}>
							<AppLegacyHoC>
								<Chat disablePortal={true} isStandaloneChat={isStandaloneChat} />
							</AppLegacyHoC>
						</Suspense>
					</MuiPickersUtilsProvider>
				</HelmetProvider>
			</ConnectedRouter>
		</ThemeProvider>
	);
};

AppLegacy.propTypes = {
	context: PropTypes.any.isRequired,
	history: PropTypes.any.isRequired
};

export { App, AppLegacy };
