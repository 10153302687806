/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import CustomSelect from '../../Select';
import { fieldToSelect } from 'formik-material-ui';

const Select = ({ onBlur, onChange, ...props }) => {
	const fieldToSelectProps = fieldToSelect(props);

	const handleBlur = (...args) => {
		if (onBlur) {
			onBlur(...args);
		}

		if (fieldToSelectProps.onBlur) {
			fieldToSelectProps.onBlur(...args);
		}
	};

	const handleChange = (...args) => {
		if (onChange) {
			onChange(...args);
		}

		if (fieldToSelectProps.onChange) {
			fieldToSelectProps.onChange(...args);
		}
	};

	const formikUpdateValue = (value) => {
		props.form.setFieldValue(props.field.name, value);
	};

	return (
		<CustomSelect
			className={props.className}
			{...fieldToSelectProps}
			onBlur={handleBlur}
			onChange={handleChange}
			formikUpdateValue={formikUpdateValue}
		/>
	);
};

export default Select;
