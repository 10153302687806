/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { fetchDataCubeData } from '../../state/actions/components/datacube';
import * as yup from 'yup';
import matchSorter, { rankings } from 'match-sorter';
import { hasRoleAccess } from '../../utils/access';

const mdash = '\u2014\u2014\u2014';

// TODO : replace with numeraljs or other library
export function kFormatter(num, abbreviation = 'k') {
	const value =
		Math.abs(num) > 999
			? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + abbreviation
			: Math.sign(num) * Math.abs(num);

	return `${value}`;
}

export const useIsExpanded = (nodes, checkParent) => {
	const expanded = useSelector((state) => state.datacube.expanded);
	const isExpanded = useMemo(
		() =>
			!!Object.keys(expanded).filter(
				(expandedKey) =>
					nodes.filter((node) => generateId(checkParent ? node.parent : node) === expandedKey).length
			).length,
		[checkParent, expanded, nodes]
	);

	return isExpanded;
};

export function useInitialData({ id, validateFn, type }) {
	const dispatch = useDispatch();
	const canFetch = useRef(true);
	const data = useSelector((state) => state.datacube.data);
	const hasData = Object.keys(data).length;

	const isValidTicket = id.match(validateFn);
	const ticketId = isValidTicket ? id : null;

	const fetchData = useCallback(() => {
		if (isValidTicket) {
			dispatch(
				fetchDataCubeData({
					url: `/portal/relational-view/${type}/${ticketId}`
				})
			);
		}
	}, [dispatch, isValidTicket, ticketId, type]);

	useEffect(() => {
		if (!isValidTicket) {
			canFetch.current = false;
		}

		if (canFetch.current && !hasData) {
			canFetch.current = false;
			fetchData();
		}
		//eslint-disable-next-line
	}, []);

	return { fetchData, ticketId };
}

export function toggleNodes(nodes, id) {
	const list = { ...nodes };

	Object.keys(list).forEach((v) => {
		if (id.indexOf(v) === -1) {
			delete list[v];
		}
	});

	return list;
}

export function generateId(node, result = []) {
	if (!node) {
		return null;
	}

	if (node.parent) {
		generateId(node.parent, result);
	}

	result.push(node.data && node.data.id);

	return result.join('_');
}

export const processI18nTitle = (title) => (t) => t(title);

export const processDataValues = {
	ipAddresses: ({ ipAddresses }) => {
		// TODO : How to handle empty values?
		if (!ipAddresses) {
			return mdash;
		}

		return ipAddresses.filter(Boolean).join(', ');
	},
	categorization: ({ categorization }) => {
		// TODO : How to handle empty values?
		if (!(categorization && Object.values(categorization).filter(Boolean).length)) {
			return mdash;
		}

		return Object.values(categorization).filter(Boolean).join(' > ');
	},
	status: ({ status }, t) => {
		// TODO : How to handle empty values?
		if (!status) {
			return mdash;
		}

		const options = {
			NEW: 'common:statuses.new',
			QUEUED: 'common:statuses.queued',
			ACTIVE: 'common:statuses.active',
			PENDING: 'common:statuses.pending',
			RESOLVED: 'common:statuses.resolved',
			CLOSED: 'common:statuses.closed'
		};

		return t(options[status.toUpperCase()]);
	},
	severity: ({ severity }, t) => {
		// TODO : How to handle empty values?
		if (!severity) {
			return mdash;
		}

		const options = {
			LOW: 'common:severity.low',
			MEDIUM: 'common:severity.medium',
			HIGH: 'common:severity.high'
		};

		return t(options[severity.toUpperCase()]);
	},
	healthStatus: ({ healthStatus = 'UNKNOWN' }, t) => {
		const options = {
			GOOD: 'common:healthStatus.good',
			BAD: 'common:healthStatus.bad',
			UNKNOWN: 'common:healthStatus.unknown',
			WARNING: 'common:healthStatus.warning'
		};

		return t(options[healthStatus.toUpperCase()]);
	},
	createdTS: ({ createdTS }, t) => {
		// TODO : How to handle empty values?
		if (!createdTS) {
			return mdash;
		}

		const date = new Date(createdTS);

		return t('common:luxon', {
			date: {
				value: date,
				format: 'D tt z'
			}
		});
	},
	lastUpdated: ({ lastUpdated }, t) => {
		// TODO : How to handle empty values?
		if (!lastUpdated) {
			return mdash;
		}

		const date = new Date(lastUpdated);

		return t('common:luxon', {
			date: {
				value: date,
				format: 'D t'
			}
		});
	},
	detected: ({ detected }, t) => {
		// TODO : How to handle empty values?
		if (!detected) {
			return mdash;
		}

		const date = new Date(detected);

		return t('common:luxon', {
			date: {
				value: date,
				format: 'D t'
			}
		});
	},
	cvssScore: ({ cvssScore }) => {
		// TODO : How to handle empty values?
		if (!cvssScore) {
			return '—';
		}

		return parseFloat(cvssScore, 10).toFixed(2);
	}
};

export const processDataText = (keys, seperator = '; ') => (data, t) => {
	if (!(keys && keys.length)) {
		return null;
	}

	return keys
		.map((key) => {
			const mutateFn = processDataValues[key];

			if (mutateFn) {
				return mutateFn(data, t);
			}

			return data[key] === 0 ? `${data[key]}` : !data[key] ? (key !== 'spawned' ? mdash : '') : data[key];
		})
		.filter(Boolean)
		.join(seperator);
};

export const processHeaders = (headers) => (t) => {
	return headers.map((header) => t(header)).join(' | ');
};

export const processPageLink = (page) => (data) => {
	switch (page.type) {
		case 'EVENT':
			return page.path &&
				data[page.key] &&
				data[page.keyLocationID] &&
				data[page.keyClientID] &&
				data[page.keyCreatedTS]
				? `${page.path}${data[page.key]}?locationId=${data[page.keyLocationID]}&clientId=${
						data[page.keyClientID]
				  }&createdTimestamp=${data[page.keyCreatedTS]}`
				: null;
		case 'VULNERABILITY':
			return page.path && data[page.key]
				? `${page.path}${data[page.key]}`
				: page.pathAlt && data[page.keyAlt]
				? `${page.pathAlt}${data[page.keyAlt]}`
				: null;
		case 'ASSET':
			return page.managedAssetPath &&
				data[page.keyDeviceId] &&
				hasRoleAccess({
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				})
				? `${page.managedAssetPath}${data[page.keyDeviceId]}?unmanagedAssetId=${data[page.key]}`
				: page.unmanagedAssetPath && data[page.key] && data[page.keyClientLocationId]
				? `${page.unmanagedAssetPath}${data[page.keyClientLocationId]}-${data[page.key]}`
				: null;
		default:
			return page.path && data[page.key] ? `${page.path}${data[page.key]}` : null;
	}
};

export const headers = {
	EVENTS: [
		'common:datacube.group.header.severity',
		'common:datacube.group.header.eventId',
		'common:datacube.group.header.createdTS',
		'common:datacube.group.header.description'
	],
	USERS: [],
	ASSETS: [
		'common:datacube.group.header.healthStatus',
		'common:datacube.group.header.hostName',
		'common:datacube.group.header.ipAddresses'
	],
	INCIDENTS: [
		'common:datacube.group.header.severity',
		'common:datacube.group.header.incidentId',
		'common:datacube.group.header.lastUpdated',
		'common:datacube.group.header.categorization',
		'common:datacube.group.header.description'
	],
	RELATEDTICKETS: [],
	CHANGEREQUESTS: [
		'common:datacube.group.header.changeId',
		'common:datacube.group.header.lastUpdated',
		'common:datacube.group.header.description'
	],
	SERVICEREQUESTS: [
		'common:datacube.group.header.serviceId',
		'common:datacube.group.header.status',
		'common:datacube.group.header.description'
	],
	VULNERABILITIES: [
		'common:datacube.group.header.severity',
		'common:datacube.group.header.cveId',
		'common:datacube.group.header.description'
	]
};

export const filters = {
	id: {
		name: 'id',
		type: 'Text',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.id' }]
			});
		},
		options: {}
	},
	severity: {
		name: 'severity',
		type: 'CheckBoxGroup',
		label: 'common:datacube.group.header.severity',
		filterFn: (nodes, filterValue = []) => {
			let result = [];

			filterValue.forEach((value) => {
				result.push(
					matchSorter(nodes, value, {
						keys: [{ threshold: rankings.CONTAINS, key: 'data.info.severity' }]
					})
				);
			});

			return result.flat();
		},
		options: [
			{
				label: 'common:severity.low',
				value: 'LOW'
			},
			{
				label: 'common:severity.medium',
				value: 'MEDIUM'
			},
			{
				label: 'common:severity.high',
				value: 'HIGH'
			}
		]
	},
	description: {
		name: 'description',
		type: 'Text',
		label: 'common:datacube.group.header.description',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.description' }]
			});
		},
		options: {}
	},
	createdTS: {
		name: 'createdTS',
		type: 'TimePeriod',
		label: 'common:datacube.group.header.createdTS',
		filterFn: (nodes, filterValue) => {
			return nodes.filter((node) => {
				const filterCreatedTS = DateTime.local().minus({ days: filterValue }).toMillis();
				return node.data.info.createdTS >= filterCreatedTS;
			});
		},
		options: [
			{
				label: 'common:timePeriods.last24Hours',
				value: 1
			},
			{
				label: 'common:timePeriods.last7Days',
				value: 7
			},
			{
				label: 'common:timePeriods.last15Days',
				value: 15
			},
			{
				label: 'common:timePeriods.last30Days',
				value: 30
			},
			{
				label: 'common:timePeriods.last60Days',
				value: 60
			},
			{
				label: 'common:timePeriods.last90Days',
				value: 90
			}
		]
	},
	sourceIp: {
		name: 'sourceIp',
		type: 'Text',
		label: 'common:datacube.group.header.sourceIp',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.sourceIp' }]
			});
		},
		options: {}
	},
	deviceIp: {
		name: 'deviceIp',
		type: 'Text',
		label: 'common:datacube.group.header.deviceIp',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.deviceIp' }]
			});
		},
		options: {}
	},
	deviceType: {
		name: 'deviceType',
		type: 'SelectList',
		label: 'common:datacube.group.header.deviceType',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.deviceType' }]
			});
		},
		options: {}
	},
	eventId: {
		name: 'eventId',
		type: 'Text',
		label: 'common:datacube.group.header.eventId',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.id' }]
			});
		},
		options: {}
	},
	ipAddresses: {
		name: 'ipAddresses',
		type: 'Text',
		label: 'common:datacube.group.header.ipAddresses',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.ipAddresses' }]
			});
		},
		options: {}
	},
	hostName: {
		name: 'hostName',
		type: 'Text',
		label: 'common:datacube.group.header.hostName',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.hostName' }]
			});
		},
		options: {}
	},
	serviceId: {
		name: 'id',
		type: 'Text',
		label: 'common:datacube.group.header.serviceId',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.id' }]
			});
		},
		options: {}
	},
	asset: {
		name: 'asset',
		type: 'Text',
		label: 'common:datacube.group.header.asset',
		filterFn: (nodes, filterValue) => {
			return matchSorter(nodes, filterValue, {
				keys: [{ threshold: rankings.CONTAINS, key: 'data.info.asset' }]
			});
		},
		options: {}
	}
};

export const filterForms = {
	EVENTS: {
		fieldsSorting: [filters.severity, filters.createdTS],
		fieldsFilters: [filters.severity, filters.deviceType, filters.sourceIp, filters.deviceIp, filters.eventId],
		initialValues: {
			severity: filters.severity.options.map((severity) => severity.value),
			sourceIp: '',
			deviceIp: '',
			deviceType: '',
			eventId: '',
			createdTS: '',
			sortBy: filters.severity.name,
			sortOrder: 'asc',
			secondOrderSort: {
				firstOrder: filters.severity.name,
				secondOrder: filters.createdTS.name
			}
		},
		validationSchema: yup.object().shape({
			severity: yup.string(),
			sourceIp: yup.string(),
			deviceIp: yup.string(),
			deviceType: yup.string(),
			eventId: yup.string(),
			createdTS: yup.string()
		})
	},
	VULNERABILITIES: {
		fieldsSorting: [filters.severity],
		fieldsFilters: [filters.severity],
		initialValues: {
			severity: filters.severity.options.map((severity) => severity.value),
			sortBy: filters.severity.name,
			sortOrder: 'asc'
		},
		validationSchema: yup.object().shape({
			severity: yup.string()
		})
	},
	ASSETS: {
		fieldsSorting: [filters.hostName, filters.ipAddresses],
		initialValues: {
			hostName: '',
			ipAddresses: '',
			sortBy: filters.hostName.name,
			sortOrder: 'asc'
		},
		validationSchema: yup.object().shape({
			hostName: yup.string(),
			ipAddresses: yup.string(),
			sortBy: yup.string(),
			sortOrder: yup.string()
		})
	},
	SERVICEREQUESTS: {
		fieldsSorting: [filters.serviceId],
		fieldsFilters: [filters.serviceId, filters.asset],
		initialValues: {
			id: '',
			asset: '',
			sortBy: filters.serviceId.name,
			sortOrder: 'asc'
		},
		validationSchema: yup.object().shape({
			id: yup.string(),
			asset: yup.string(),
			sortBy: yup.string(),
			sortOrder: yup.string()
		})
	}
};

export const filterNodeList = (nodes, appliedFilters) => {
	Object.keys(appliedFilters).forEach((appliedFilter) => {
		const filter = filters[appliedFilter];

		if (!filter) {
			return;
		}

		const filterFn = filter.filterFn;
		const filterValue = appliedFilters[appliedFilter];

		if (filterFn && filterValue) {
			nodes = filterFn(nodes, filterValue);
		}
	});

	return nodes;
};

export const sortNodeList = (nodes, value, order) => {
	return nodes.sort((a, b) => {
		const aValue = `${a.data.info[value]}`;
		const bValue = `${b.data.info[value]}`;

		if (order === 'asc' && aValue !== '') {
			return aValue.localeCompare(bValue);
		} else {
			return bValue.localeCompare(aValue);
		}
	});
};

export const sortNodeListSecondOrder = (nodes, valuePrimary, valueSecondary, order) => {
	return nodes.sort((a, b) => {
		const aValuePrimary = `${a.data.info[valuePrimary]}`;
		const bValuePrimary = `${b.data.info[valuePrimary]}`;
		const aValueSecondary = `${a.data.info[valueSecondary]}`;
		const bValueSecondary = `${b.data.info[valueSecondary]}`;

		if (order === 'asc') {
			if (aValuePrimary !== bValuePrimary) {
				return aValuePrimary.localeCompare(bValuePrimary);
			}
			return bValueSecondary.localeCompare(aValueSecondary);
		} else {
			if (aValuePrimary !== bValuePrimary) {
				return bValuePrimary.localeCompare(aValuePrimary);
			}
			return bValueSecondary.localeCompare(aValueSecondary);
		}
	});
};

const groupTypes = {
	EVENTS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.events',
		headers: headers.EVENTS,
		isGroup: true,
		minWidth: 320
	},
	USERS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.users',
		headers: headers.USERS,
		isGroup: true,
		minWidth: 320
	},
	ASSETS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.assets',
		headers: headers.ASSETS,
		isGroup: true,
		minWidth: 320
	},
	INCIDENTS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.incidents',
		headers: headers.INCIDENTS,
		isGroup: true,
		minWidth: 320
	},
	RELATEDTICKETS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.relatedTickets',
		headers: headers.RELATEDTICKETS,
		isGroup: true,
		minWidth: 320
	},
	CHANGEREQUESTS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.changeRequests',
		headers: headers.CHANGEREQUESTS,
		isGroup: true,
		minWidth: 320
	},
	SERVICEREQUESTS: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.serviceRequests',
		headers: headers.SERVICEREQUESTS,
		isGroup: true,
		minWidth: 420
	},
	VULNERABILITIES: {
		icon: 'GROUP',
		title: 'common:datacube.group.label.vulnerabilities',
		headers: headers.VULNERABILITIES,
		isGroup: true,
		minWidth: 320
	}
};

export const labels = {
	eventId: 'common:datacube.group.header.eventId',
	severity: 'common:datacube.group.header.severity',
	description: 'common:datacube.group.header.description',
	createdTS: 'common:datacube.group.header.createdTS',
	userId: 'common:datacube.group.header.userId',
	name: 'common:datacube.group.header.name',
	healthStatus: 'common:datacube.group.header.healthStatus',
	hostName: 'common:datacube.group.header.hostName',
	ipAddresses: 'common:datacube.group.header.ipAddresses',
	incidentId: 'common:datacube.group.header.incidentId',
	lastUpdated: 'common:datacube.group.header.lastUpdated',
	categorization: 'common:datacube.group.header.categorization',
	changeId: 'common:datacube.group.header.changeId',
	serviceId: 'common:datacube.group.header.serviceId',
	id: 'common:datacube.group.header.serviceId',
	vulnerabilityId: 'common:datacube.group.header.vulnerabilityId',
	cveId: 'common:datacube.group.header.cveId',
	assetCount: 'common:datacube.group.header.assetCount',
	cvssScore: 'common:datacube.group.header.cvssScore',
	occurrences: 'common:datacube.group.header.occurrences',
	deviceIp: 'common:datacube.group.header.deviceIp',
	deviceType: 'common:datacube.group.header.deviceType',
	sourceIp: 'common:datacube.group.header.sourceIp',
	destinationIp: 'common:datacube.group.header.destinationIp',
	status: 'common:datacube.group.header.status',
	detected: 'common:datacube.group.header.detected',
	detectedBy: 'common:datacube.group.header.detectedBy',
	detectionTechnique: 'common:datacube.group.header.detectionTechnique',
	asset: 'common:datacube.group.header.asset',
	spawned: 'common:datacube.group.header.spawned'
};

const itemTypes = {
	EVENT: {
		icon: 'SEVERITY',
		label: 'common:datacube.item.label.event',
		title: ['id', 'createdTS'],
		titleShort: ['id'],
		description: ['description'],
		headers: headers.EVENTS,
		isItem: true,
		type: 'EVENT',
		labels: {
			id: labels.eventId,
			severity: labels.severity,
			description: labels.description,
			occurrences: labels.occurrences,
			createdTS: labels.createdTS,
			deviceIp: labels.deviceIp,
			deviceType: labels.deviceType,
			sourceIp: labels.sourceIp,
			destinationIp: labels.destinationIp
		},
		page: {
			type: 'EVENT',
			path: '/portal/events/realTimeEvents#eventDetails/',
			key: 'id',
			keyClientID: 'clientId',
			keyLocationID: 'locationId',
			keyCreatedTS: 'createdTS'
		}
	},
	USER: {
		icon: 'USER',
		label: 'common:datacube.item.label.user',
		title: ['name'],
		titleShort: ['name'],
		description: [],
		headers: headers.USERS,
		isItem: true,
		labels: {
			id: labels.userId,
			name: labels.name
		},
		page: {}
	},
	ASSET: {
		icon: 'HEALTHSTATUS',
		label: 'common:datacube.item.label.asset',
		pageURI: '/portal/assets/details/asset/unmanaged/',
		title: ['hostName', 'ipAddresses'],
		titleShort: ['hostName'],
		description: [],
		headers: headers.ASSETS,
		isItem: true,
		labels: {
			healthStatus: labels.healthStatus,
			hostName: labels.hostName,
			ipAddresses: labels.ipAddresses
		},
		page: {
			type: 'ASSET',
			unmanagedAssetPath: '/portal/assets/details/asset/unmanaged/',
			managedAssetPath: '/portal/assets/details/asset/managed/',
			key: 'id',
			keyDeviceId: 'deviceId',
			keyClientLocationId: 'clientLocationId'
		}
	},
	INCIDENT: {
		icon: 'SEVERITY',
		label: 'common:datacube.item.label.incident',
		title: ['id', 'lastUpdated', 'categorization'],
		titleShort: ['id'],
		description: ['description'],
		headers: headers.INCIDENTS,
		isItem: true,
		labels: {
			id: labels.incidentId,
			severity: labels.severity,
			description: labels.description,
			lastUpdated: labels.lastUpdated,
			categorization: labels.categorization,
			status: labels.status,
			detected: labels.detected,
			detectedBy: labels.detectedBy,
			detectionTechnique: labels.detectionTechnique
		},
		page: {
			path: '/portal/incidents/',
			key: 'id'
		}
	},
	CHANGEREQUEST: {
		icon: 'CHANGEREQUEST',
		label: 'common:datacube.item.label.changeRequest',
		title: ['id', 'lastUpdated'],
		titleShort: ['id'],
		description: ['description'],
		headers: headers.CHANGEREQUESTS,
		isItem: true,
		labels: {
			id: labels.changeId,
			description: labels.description,
			lastUpdated: labels.lastUpdated
		},
		page: {
			path: '/portal/servicerequests/',
			key: 'id'
		}
	},
	SERVICEREQUEST: {
		icon: 'SERVICEREQUEST',
		label: 'common:datacube.item.label.serviceRequest',
		title: ['id', 'status'],
		titleShort: ['id'],
		description: ['subject'],
		headers: headers.SERVICEREQUESTS,
		isItem: true,
		labels: {
			id: labels.serviceId,
			subject: labels.description,
			status: labels.status,
			asset: labels.asset,
			spawned: labels.spawned
		},
		page: {
			path: '/portal/servicerequests/',
			key: 'id'
		}
	},
	VULNERABILITY: {
		icon: 'SEVERITY',
		label: 'common:datacube.item.label.cve',
		title: ['cveId'],
		titleShort: ['cveId'],
		description: ['description'],
		headers: headers.VULNERABILITIES,
		isItem: true,
		labels: {
			cveId: labels.cveId,
			assetCount: labels.assetCount,
			description: labels.description,
			cvssScore: labels.cvssScore
		},
		page: {
			type: 'VULNERABILITY',
			path: '/portal/intel/vulnerability/',
			key: 'vulnId',
			pathAlt: 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=',
			keyAlt: 'cveId'
		}
	}
};

export function useSortFilterMutation(id, type, nodes) {
	const [nodeList, setNodeList] = useState(nodes);
	const filterData = useSelector((state) => state.datacube.filters[id]);
	const filterForm = filterForms[type];
	const filterList = (filterData && filterData.values) || (filterForm && filterForm.initialValues);

	useEffect(() => {
		if (filterList) {
			let result = filterNodeList(nodes, filterList);
			if (filterList.secondOrderSort && filterList.sortBy === filterList.secondOrderSort.firstOrder) {
				result = sortNodeListSecondOrder(
					result,
					filterList.secondOrderSort.firstOrder,
					filterList.secondOrderSort.secondOrder,
					filterList.sortOrder
				);
			} else if (filterList.sortBy) {
				result = sortNodeList(result, filterList.sortBy, filterList.sortOrder);
			}

			setNodeList(result);
		}
	}, [filterList, nodes]);

	return nodeList;
}

export function useGetDevices(nodes) {
	return [...new Set(nodes.map((node) => node.data.info.deviceType))];
}

export const definitions = { ...groupTypes, ...itemTypes };

export const nodeInfoShapes = {
	EVENT: {
		info: yup.object()
	},
	USER: {
		info: yup.object()
	},
	ASSET: {
		info: yup.object()
	},
	INCIDENT: {
		info: yup.object()
	},
	CHANGEREQUEST: {
		info: yup.object()
	},
	SERVICEREQUEST: {
		info: yup.object()
	},
	VULNERABILITY: {
		info: yup.object()
	}
};

export const nodeSchema = yup.object().shape({
	id: yup.string().required(),
	url: yup.mixed().nullable(),
	type: yup
		.mixed()
		.oneOf([...Object.keys(groupTypes), ...Object.keys(itemTypes)])
		.required(),
	info: yup.object().when('type', (type, schema) => (nodeInfoShapes[type] ? nodeInfoShapes[type].info : schema)),
	children: yup.array()
});
