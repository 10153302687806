/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { all, get, post, spread } from 'axios';
import { useSelector } from 'react-redux';
import { hasFeatureAccess, checkRouteIfPublic } from '../../utils/access';

const SUCCESS_STATUS_CODES = [200, 201, 202, 203, 204, 205, 206];

const VENDOR_DOMAINS = [
	'https://app.five9.com/appsvcs/',
	'https://app-scl.five9.com/appsvcs/',
	'https://app-atl.five9.com/appsvcs/'
];

const VENDOR_DOMAIN_TO_PREFERENCE = {
	'https://app.five9.com/appsvcs/': 'CHAT_FIVE9COM',
	'https://app-atl.five9.com/appsvcs/': 'CHAT_FIVE9ATLCOM',
	'https://app-scl.five9.com/appsvcs/': 'CHAT_FIVE9SCLCOM'
};

const VENDOR_ACCESS_CHECKS_COOKIE = 'VENDOR_ACCESS_CHECKS_COOKIE';
const VENDOR_ACCESS_CHECKS_COMPLETE_COOKIE = 'VENDOR_ACCESS_CHECKS_COMPLETE_COOKIE';
const STORE_STATUS_API = '/portal/preferences/contact-preferences?preference-name=';

function storeCookie(name, value) {
	document.cookie = `${name}=${value}; path=/`;
}

function getCookieValue(name) {
	var value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');

	return value ? value.pop() : '';
}

function formatValues(value) {
	let result = [];

	if (value) {
		result = value.split(', ').map((entry) => {
			const [url, response] = entry.split(' status: ');

			return { url, response };
		});
	}

	return result;
}

function storeStatus(status) {
	let requests = [];

	status.map((statusObj) => {
		if (statusObj && statusObj.url && statusObj.response) {
			requests.push(
				post(STORE_STATUS_API + VENDOR_DOMAIN_TO_PREFERENCE[statusObj.url], {
					preferenceValue: statusObj.response
				})
			);
		}
	});

	Promise.all(requests).then(
		function () {
			storeCookie(VENDOR_ACCESS_CHECKS_COMPLETE_COOKIE, 'complete');
		},
		function () {
			storeCookie(VENDOR_ACCESS_CHECKS_COMPLETE_COOKIE, 'incomplete');
		}
	);
}

export const useVendorAccessChecks = () => {
	const isPublicRoute = checkRouteIfPublic();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const sessionDomains = formatValues(getCookieValue(VENDOR_ACCESS_CHECKS_COOKIE));
	const isChecksDone = sessionDomains.length === VENDOR_DOMAINS.length;
	const isChecksStored = getCookieValue(VENDOR_ACCESS_CHECKS_COMPLETE_COOKIE);
	const isFeatureEnabled = hasFeatureAccess({ list: ['CHAT_HEALTH_CHECK_ENABLED'] });
	const canRun = isFeatureEnabled && isAuthenticated && !isPublicRoute;

	useEffect(() => {
		if (canRun && isChecksDone && isChecksStored !== 'complete') {
			// store incomplete checks in database
			storeStatus(sessionDomains);
		}
	}, [canRun, isChecksDone, isChecksStored, sessionDomains]);

	useEffect(() => {
		if (canRun && !isChecksDone) {
			const vendorRequests = VENDOR_DOMAINS.map((domain) =>
				get(domain, {
					timeout: 5000,
					headers: null,
					withCredentials: false,
					validateStatus: function (status) {
						return status === 401;
					}
				})
					.then((resp) => resp)
					.catch((err) => err)
			);

			all(vendorRequests).then(
				spread((...responses) => {
					const cookieValue = responses
						.map((item) => {
							let response = '';
							const url = item.config.url;

							if (item.isAxiosError) {
								let statusCode = 0;
								if (item.response && item.response.status) {
									statusCode = item.response.status;
								}
								response = `ERROR${statusCode}`;
							} else {
								response =
									(item.data && item.data.five9ExceptionDetail) ||
									SUCCESS_STATUS_CODES.indexOf(item.status) > -1
										? `OK`
										: 'ERROR' +
										  (item.status != null && item.status != undefined ? item.status : '');
							}

							return `${url} status: ${response}`;
						})
						.join(', ');
					// store performed checks in session cookie
					storeCookie(VENDOR_ACCESS_CHECKS_COOKIE, cookieValue);
					// store performed checks in database
					storeStatus(formatValues(cookieValue));
				})
			);
		}
	}, [canRun, isChecksDone]);
};
