/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccess } from '../../../utils/access';
import { showAlert } from '../../../state/actions/components/alerts';

export const useTimeZoneUnavailableAlerts = () => {
	const dispatch = useDispatch();
	const isTimeZoneUnavailable = useSelector((state) => state.other.isTimeZoneUnavailable);

	useEffect(() => {
		if (hasAccess() && isTimeZoneUnavailable) {
			dispatch(
				showAlert({
					id: 'TIME_ZONE_DOWN_ALERT',
					data: {
						severity: 'warning',
						message: 'common:alerts.serviceDown.timeZone'
					}
				})
			);
		}
	}, [dispatch, isTimeZoneUnavailable]);
};
