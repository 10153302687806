/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { put } from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

import { AlertCustom } from '../../../components/Alert';
import { hideAlert } from '../../../state/actions/components/alerts';
import { ClickAwayListener } from '@material-ui/core';
import Dropdown from '../../../components/Inputs/Dropdown';
import DropdownItem from '../../../components/Inputs/Dropdown/Item';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
	link: {
		color: 'inherit',
		whiteSpace: 'nowrap'
	},
	header: {
		paddingRight: 10
	},
	headerIcon: {
		marginRight: '5px'
	},
	dropdownLink: {
		minWidth: '115px',
		marginLeft: 'auto',
		fontSize: '15px'
	},
	fontAwesomeIcon: {
		marginLeft: 5
	},
	alert: {
		'& .MuiAlert-message': {
			lineHeight: '18px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			fontSize: '15px'
		}
	},
	contentWrapper: {
		marginRight: '5px'
	}
}));

const UrgentAlerts = ({ id, data }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = React.useState(false);

	const { alerts, config } = data;

	function getIcon() {
		if (config.icon) {
			return <FontAwesomeIcon size="1x" icon={config.icon} className={classes.headerIcon} />;
		}
	}

	function getHostDownMessage() {
		const url = '/portal/healthtickets';

		return (
			<Trans i18nKey={config.messageText}>
				<a rel="noopener noreferrer" className={classes.link} onClick={handleLinkDismiss} href={url} />
			</Trans>
		);
	}

	function handleLinkDismiss(event) {
		event.preventDefault();

		put(`/portal/alerts/dismiss?type=${config.name}`);

		localStorage.setItem(
			'urgent-alerts-host-down',
			JSON.stringify({ id: 'host-down', showHostDownTickets: true, displayHostDownAlertOnRedirect: false })
		);

		window.location = event.currentTarget.href;
	}

	function getReleaseMessage() {
		const url = alerts[0].targetUrl;
		const date = new Date(alerts[0].releaseDate);

		return (
			<Trans
				i18nKey={config.messageText}
				values={{
					date: t('common:luxon', {
						date: {
							value: date,
							format: 'LLLL yyyy'
						}
					})
				}}
			>
				<a
					target="_blank"
					rel="noopener noreferrer"
					onClick={handleClick}
					className={classes.link}
					href={url}
				/>
			</Trans>
		);
	}

	function getMessage() {
		if (config.name === 'RELEASE') {
			return getReleaseMessage();
		} else if (config.name === 'CTA_HOSTDOWN') {
			return getHostDownMessage();
		} else {
			return alerts[0].message;
		}
	}

	function getSelectOptions() {
		if (!config.snoozingOptions) {
			return;
		}

		return (
			<React.Fragment>
				<Link
					className={classes.dropdownLink}
					component="button"
					variant="body2"
					onClick={handleClick}
					aria-haspopup="true"
					aria-controls="urgent-alert-dropdown-list"
				>
					{t('common:alerts.urgent.snoozing.remindMe')}
					<FontAwesomeIcon className={classes.fontAwesomeIcon} icon={faChevronCircleDown} />
				</Link>

				<Dropdown
					id="urgent-alert-dropdown-list"
					placement="bottom"
					isOpen={open}
					anchorEl={anchorEl}
					hasArrow={false}
				>
					<ClickAwayListener onClickAway={closeDropdown}>
						<div>
							{config.snoozingOptions.map((option, index) => (
								<DropdownItem key={index} button={true} value={option} onClick={handleChange}>
									{t('common:alerts.urgent.snoozing.options.' + option)}
								</DropdownItem>
							))}
						</div>
					</ClickAwayListener>
				</Dropdown>
			</React.Fragment>
		);
	}

	function handleClose() {
		dispatch(hideAlert({ id }));
	}

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
	}

	function closeDropdown() {
		setOpen(false);
	}

	function handleChange(event) {
		event.preventDefault();

		const timeRange = event.target.value;
		let action = 'dismiss';
		const id = alerts[0].id;

		let params;
		if (timeRange !== 'dismiss') {
			params = `timeRange=${timeRange}`;
			action = 'snooze';
		}

		if (config.name === 'CTA_HOSTDOWN') {
			// dismiss action for CTA_HOSTDOWN alert is handled separately via a link
			put(`/portal/alerts/snooze?type=${config.name}&${params}`);
		} else {
			put(`/portal/alerts/${id}/${action}${params ? '?' + params : ''}`);
		}

		closeDropdown();
		handleClose();
	}

	return (
		<div>
			<AlertCustom
				className={classes.alert}
				variant={config.variant}
				severity={config.severity}
				icon={false}
				noSideBorders
				key={id}
			>
				<div className={classes.contentWrapper}>
					<strong className={classes.header}>
						{getIcon()}
						{config.name === 'URGENT' ? alerts[0].header : t(config.headerText)}
					</strong>
					{getMessage()}
				</div>
				{getSelectOptions()}
			</AlertCustom>
		</div>
	);
};

UrgentAlerts.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired
};

export default UrgentAlerts;
