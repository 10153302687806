/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../Inputs/Button';
import { reset, createSession } from '../../../../../state/actions/components/chat';

const useStyles = makeStyles((theme) => ({
	button: {
		color: theme.palette.action.active,
		padding: theme.spacing(1)
	}
}));

const ConverstationViewEnd = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const chat = useSelector((state) => state.chat);
	const progress = useSelector((state) => state.chat.progress);
	const [isLoading, setIsLoading] = useState(false);

	const handleCloseWindow = () => {
		dispatch(reset());

		if (window.isStandaloneChat) {
			window.close();
		}
	};

	const handleStartNew = () => {
		setIsLoading(true);

		dispatch(
			reset({
				isOpen: !window.isStandaloneChat,
				session: {
					...chat.session,
					isStandalone: window.isStandaloneChat,
					wasQuestionShown: false,
					wasGeneratedTicketShown: false
				}
			})
		);
		dispatch(createSession());
	};

	let message = '';

	if (progress === 'terminatedByAgent') {
		message = t('common:chat.conversation.agentEndChat');
	}

	if (progress === 'terminatedByClient') {
		message = t('common:chat.conversation.customerEndChat');
	}

	return (
		<div className="sw-chat-view-conversation-footer-end">
			<Typography variant="h4">{message}</Typography>
			<Typography>
				<Button variant="text" className={classes.button} onClick={handleStartNew} disabled={isLoading}>
					{t('common:chat.buttons.startNew')}
				</Button>
				{t('common:chat.labels.or')}
				<Button variant="text" className={classes.button} onClick={handleCloseWindow} disabled={isLoading}>
					{t('common:chat.buttons.closeWindow')}
				</Button>
			</Typography>
		</div>
	);
};

export default ConverstationViewEnd;
