import React, { useMemo } from 'react';
import DataTable from '../../../../components/DataTable';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getFormattedDateTimeByLocale } from '../../../../pages/Reports/View/utils';
import { severityStyles } from '../../../../pages/Reports/View/Executive/Express';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { post } from 'axios';
import { getQueryString } from '../../../../pages/Dashboards/Dashboard/useExportDrillDown';
import { downloadFile } from '../../../../utils';

const DATA_TABLE_NAME = 'PIVOT_DRILL_DOWN_INCIDENTS_TABLE';

export const useDrillDownStyles = makeStyles((theme) => ({
	downloadLink: {
		'color': theme.palette.link.color,
		'textDecoration': 'underline',
		'cursor': 'pointer',
		'&:hover': {
			color: theme.palette.link.colorHover
		},
		'transition': theme.transitions.create('color')
	},
	message: {
		marginBottom: theme.spacing(1)
	},
	cell: {
		backgroundColor: theme.palette.table.cell.drillDownCell.background
	}
}));

export const useExportDrillDown = () => {
	let isLoading = false;
	let isError = null;

	const exportDrillDownData = (filter, fileType) => {
		const axiosOptions = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			responseType: 'arraybuffer'
		};
		post(
			'/portal/incidentStatistics/exportDrillDownIncidents',
			getQueryString({
				searchFormJson: JSON.stringify(filter),
				csrfToken: window?.APP_DATA?.meta?.csrfToken,
				type: fileType
			}),
			axiosOptions
		)
			.then((response) => {
				isLoading = false;
				downloadFile(response);
			})
			.catch((e) => {
				isLoading = false;
				isError = e;
			});
	};
	return { isLoading, isError, exportDrillDownData };
};

export function getIncidentsDrillDownFilters(pivotDataTable) {
	const { params = {}, drillDownParams = {}, tableHeaderModel = {} } = pivotDataTable || {};

	const rowGrouping = params?.rowGrouping;
	const selectedRowGrouping = drillDownParams.expandedRow ? drillDownParams.expandedRow[rowGrouping] : null;
	return {
		url: '/portal/incidentStatistics/aggregationDrillDownData?source=NRS',
		method: 'POST',
		params: {
			startTime: params.startTime,
			endTime: params.endTime,
			rowGrouping: params.rowGrouping,
			locations: params.locations,
			fileName: params.eventCategoryName,
			limit: 20,
			page: 0,
			drillDownFilters: {
				categorization:
					selectedRowGrouping === 'All Other Incident Categories'
						? tableHeaderModel.otherGroupColumn
						: selectedRowGrouping
			}
		}
	};
}

export const IncidentsDataTable = (props) => {
	const { t } = useTranslation(['incidents']);
	const classes = useDrillDownStyles();

	const severityClasses = severityStyles();

	const pivotDataTable = useSelector((state) => state.pivotdatatables[props.name]);
	const drillDownFilters = useMemo(() => {
		return getIncidentsDrillDownFilters(pivotDataTable);
	}, [pivotDataTable]);

	const incidentDataTable = useSelector((state) => state.datatables[DATA_TABLE_NAME]);
	const { data = {}, params = {}, isLoading = false } = incidentDataTable || {};

	const columns = useMemo(
		() => [
			{
				headerTitle: t('incidents:summary.number'),
				id: 'number',
				accessor: 'number',
				Cell: (e) => {
					const number = e.value;
					const href = '/portal/incidents/' + number;
					if (number) {
						return (
							<a href={href} className={classes.downloadLink} target="_blank" rel="noreferrer">
								{number}
							</a>
						);
					} else {
						return <></>;
					}
				},
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.deviceType'),
				id: 'deviceType',
				accessor: 'deviceType',
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.categorization'),
				id: 'categorization',
				accessor: 'categorization',
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.severity'),
				id: 'severity',
				accessor: (row) => {
					if (!row) {
						return null;
					}

					return row.priority?.toLowerCase();
				},
				Cell: (e) => {
					if (e.value) {
						const severity = e.value.toString();
						return (
							<>
								<FontAwesomeIcon
									title={t('incidents:severities.' + severity)}
									className={severityClasses[severity]}
									icon={faCircle}
								></FontAwesomeIcon>
								<span className={severityClasses.label}>{t('incidents:severities.' + severity)}</span>
							</>
						);
					} else {
						return <></>;
					}
				},
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.summary'),
				id: 'summary',
				accessor: 'summary',
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.created'),
				id: 'createdDateFormat',
				accessor: (data) => {
					if (!data) {
						return null;
					}

					return getFormattedDateTimeByLocale(data.createdTimestamp, { timeFormat: 'hh:mm a' });
				},
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.closeCode'),
				id: 'closeCode',
				accessor: 'closeCode',
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.closedBy'),
				id: 'closedBy',
				accessor: 'closedBy',
				className: classes.cell
			},
			{
				headerTitle: t('incidents:incidentStatistics.table.clientsAssignedEmployee'),
				id: 'assignedEmployee',
				accessor: 'assignedEmployee',
				className: classes.cell
			}
		],
		[t, severityClasses, classes]
	);

	const { exportDrillDownData } = useExportDrillDown();

	const infoMessage = useMemo(() => {
		const pagination = data?.pagination || {};
		const currentPage = pagination?.currentPage - 1;
		const pageStart = pagination?.limit * currentPage + 1;
		let pageEnd = pagination?.limit * (currentPage + 1);
		if (pageEnd > pagination?.numberOfElements) {
			pageEnd = pagination?.numberOfElements;
		}
		const details = {
			total: data?.pagination?.numberOfElements,
			pageStart: pageStart,
			pageEnd: pageEnd
		};

		return !isLoading ? (
			<div className={classes.message}>
				{t('incidents:incidentStatistics.nrs.showingIncidentsMsg', details)}
				<a
					className={classes.downloadLink}
					onClick={() => {
						exportDrillDownData(params, 'csv');
					}}
					target="_blank"
					rel="noreferrer"
				>
					{t('incidents:incidentStatistics.nrs.downloadAllIncidents', details)}
				</a>
			</div>
		) : null;
	}, [t, classes, data, exportDrillDownData, params, isLoading]);

	const processPaginationFn = (pagination) => {
		pagination = {
			...pagination,
			count: pagination.totalPages,
			current: pagination.currentPage - 1
		};

		return pagination;
	};

	return (
		<>
			<DataTable
				title="Incidents"
				name={DATA_TABLE_NAME}
				columns={columns}
				options={drillDownFilters}
				processDataFn={(data) => data}
				nameSpace={['incidents']}
				emptyMessage={'incidents:summaryMessages.empty'}
				errorMessage={'incidents:summaryMessages.error'}
				loadingMessage={'incidents:summaryMessages.loading'}
				canSelectAll={false}
				showPagination={true}
				processPaginationFn={processPaginationFn}
				serverSidePagination={true}
				canToggleColumns={false}
				infoMessage={infoMessage}
				paginationType={'DROPDOWN'}
			/>
		</>
	);
};
