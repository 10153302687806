/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { TableCell } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export const useStyle = makeStyles((theme) => ({
	tableBodyCell: {
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(2),
		'backgroundColor': 'inherit',
		'color': 'inherit',
		'fontSize': 13,
		'lineHeight': '22px',
		'border': 0,
		'borderRightWidth': 1,
		'borderRightColor': theme.palette.table.cell.body.border,
		'borderRightStyle': 'solid',
		'&:last-child': {
			borderRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			'&[data-header]&:before': {
				content: 'attr(data-header) ":\\00A0"',
				fontWeight: theme.typography.fontWeightBold,
				whiteSpace: 'nowrap'
			},
			'width': '100% !important',
			'display': 'block',
			'maxWidth': '100% !important'
		}
	},
	link: {
		textDecoration: 'underline',
		color: theme.palette.link.color,
		cursor: 'pointer'
	},
	highlightAggrCell: {
		backgroundColor: theme.palette.table.cell.highlight.background,
		border: '1px solid ' + theme.palette.table.cell.highlight.border,
		color: theme.palette.table.cell.highlight.color,
		fontWeight: 700
	}
}));
export const PivotDataTableCell = function ({ cell, handleDrillDown, highlight, selectedColumn, ...otherProps }) {
	const classes = useStyle();
	const showDrillDownLink = cell.column?.enableDrillDown && parseInt(cell?.value) > 0;
	return (
		<TableCell
			size={'small'}
			className={classNames(classes.tableBodyCell, {
				[classes.highlightAggrCell]: showDrillDownLink && highlight && selectedColumn?.id === cell.column?.id
			})}
			data-header={otherProps.column.headerTitle}
		>
			{showDrillDownLink && !(highlight && selectedColumn?.id === cell.column?.id) ? (
				<span
					className={classes.link}
					onClick={() => {
						if (handleDrillDown) {
							handleDrillDown(cell);
						}
					}}
				>
					{cell?.value}
				</span>
			) : (
				cell?.value
			)}
		</TableCell>
	);
};
