import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../Inputs/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataTableParams } from '../../../../../state/actions/components/datatables';

const useStyles = makeStyles((theme) => ({
	applyButton: {
		'marginLeft': 8,
		'height': 32,
		'backgroundColor': theme.palette.cancelButton.backgroundColor,
		'borderColor': theme.palette.cancelButton.borderColor,
		'color': theme.palette.primary.light,
		'border': '1px solid',
		'&:hover': {
			color: theme.palette.cancelButton.hover.color,
			backgroundColor: theme.palette.cancelButton.hover.backgroundColor
		},
		'padding': '4px 12px 4px 12px'
	},
	filterByLabel: {
		fontSize: 15,
		marginRight: 10,
		paddingTop: 5
	},
	searchBarDiv: {
		display: 'flex',
		marginTop: '25px'
	},
	closeButton: {
		color: theme.palette.card.color
	},
	adornment: {
		height: '100%',
		backgroundColor: theme.palette.filterbar.filterbarHead.clearBtn.backgroundColor
	}
}));

const CustomOutlinedInput = withStyles((theme) => ({
	root: {
		height: 32,
		paddingRight: 1,
		borderColor: theme.palette.autocomplete.border,
		backgroundColor: theme.palette.autocomplete.background,
		border: '1px solid',
		color: theme.palette.headerBar.searchInput
	},
	focused: {
		border: '1px solid',
		borderColor: theme.palette.filterbar.filterbarHead.focused.borderColor
	},
	notchedOutline: {
		border: 0
	}
}))(OutlinedInput);

const GlobalFilter = ({ name, showFilter, updateMyData }) => {
	const { t } = useTranslation('datatables');
	const classes = useStyles();

	const dispatch = useDispatch();
	const tableState = useSelector((state) => state.datatables[name]);
	const [searchValue, setSearchValue] = useState(tableState?.params?.filteringName || '');

	const handleClick = () => {
		const params = {
			...tableState?.params,
			offset: 0,
			limit: 50,
			filteringName: searchValue
		};
		dispatch(updateDataTableParams({ name, params }));
		updateMyData();
	};

	const handeClearClick = () => {
		setSearchValue('');
		const params = {
			...tableState?.params,
			offset: 0,
			filteringName: null,
			limit: 50
		};
		dispatch(updateDataTableParams({ name, params }));
		updateMyData();
	};

	return (
		<div>
			{showFilter ? (
				<div className={classes.searchBarDiv}>
					<div>
						<Box>
							<Typography className={classes.filterByLabel}>
								{t('datatables:filterBar.filterBy') + ':'}
							</Typography>
						</Box>
					</div>
					<div>
						<CustomOutlinedInput
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
							}}
							variant="outlined"
							name="searchBar"
							type="text"
							placeholder={t('datatables:filterBar.containsText')}
							autoComplete="off"
							inputProps={{
								style: {
									paddingLeft: 5
								}
							}}
							endAdornment={
								<InputAdornment position="end" className={classes.adornment}>
									<IconButton
										size="small"
										aria-label="close"
										className={classes.closeButton}
										onClick={handeClearClick}
									>
										<FontAwesomeIcon icon={faTimesCircle} size="1x" />
									</IconButton>
								</InputAdornment>
							}
						/>
					</div>
					<div>
						<Button className={classes.applyButton} onClick={handleClick}>
							{t('filterBar.buttons.apply')}
						</Button>
					</div>
				</div>
			) : null}
		</div>
	);
};
export default GlobalFilter;
