/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../../../components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faArrowCircleLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedDateTimeByLocale, getLocaleByUserPreferenceTimeZone } from '../../utils';
import EventDetails from './EventDetails';

export const severityStyles = makeStyles((theme) => ({
	label: {
		paddingLeft: '5px'
	},
	low: {
		color: theme.palette.severity.low
	},
	medium: {
		color: theme.palette.severity.medium
	},
	high: {
		color: theme.palette.severity.high
	},
	noUnderlineLink: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	}
}));

const useStyles = makeStyles((theme) => ({
	backTop: {
		marginBottom: '15px'
	},
	backLink: {
		fontWeight: 700,
		marginLeft: '5px',
		marginRight: '5px'
	},
	backTopLinkIcon: {
		marginTop: '2px',
		fontSize: '15px',
		color: theme.palette.link.color
	},
	backLinkIcon: {
		fontSize: '16px',
		color: theme.palette.link.color
	},
	backLinkText: {
		'color': theme.palette.link.color,
		'textDecoration': 'underline',
		'cursor': 'pointer',
		'&:hover': {
			color: theme.palette.link.colorHover
		},
		'transition': theme.transitions.create('color')
	},
	selectedChartHeader: {
		marginBottom: '-20px'
	}
}));

function getEventURL(selectedEvent, clientId) {
	const eventURL =
		'/portal/events/event#details/' +
		selectedEvent.eventId +
		'?locationId=' +
		selectedEvent.locationID +
		'&createdTimestamp=' +
		selectedEvent.eventTime +
		'&clientId=' +
		clientId +
		'&source=REPORTING';

	return eventURL;
}

const EventsDataTable = ({ parameters, updateReportView, loadingExpressPayload }) => {
	const { t } = useTranslation('events');
	const severityClasses = severityStyles();
	const classes = useStyles();
	const [view, setView] = useState('table');
	const [selectedEventDetails, setSelectedEventDetails] = useState({});
	const appData = window.APP_DATA || {};
	const clientId = appData.client.id;

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [view]);

	const BackLinks = () => {
		return (
			<>
				<div className={classes.backTop}>
					<FontAwesomeIcon className={classes.backLinkIcon} icon={faArrowCircleLeft}></FontAwesomeIcon>
					<span className={classes.backLink}>
						<a
							className={classes.backLinkText}
							onClick={() => {
								if (updateReportView) {
									updateReportView({
										type: 'main'
									});
								}
							}}
						>
							{t('reports:links.backToExecSum')}
						</a>
					</span>
					{<BackToEventsTable></BackToEventsTable>}
				</div>
			</>
		);
	};

	const BackToEventsTable = () => {
		var drilledFilterValueToDisplay = '';
		if (parameters.chartType === 'Attack_Summary_Chart' || parameters.chartType === 'Top_Attacked_Ports_Chart') {
			drilledFilterValueToDisplay = parameters.drilledFilterValueToDisplay;
		} else {
			drilledFilterValueToDisplay = parameters.drilledFilterValue;
		}
		return (
			<>
				/
				<span className={classes.backLink}>
					{view === 'single' ? (
						<>
							<a
								className={classes.backLinkText}
								onClick={() => {
									setView('table');
								}}
							>
								{t('reports:chartTypes.' + parameters.chartType) + ': ' + drilledFilterValueToDisplay}
							</a>{' '}
							/<span className={classes.backLink}>{selectedEventDetails?.eventId}</span>
						</>
					) : (
						t('reports:chartTypes.' + parameters.chartType) + ': ' + drilledFilterValueToDisplay
					)}
				</span>
			</>
		);
	};

	const timeZoneLocale = getLocaleByUserPreferenceTimeZone();

	const columns = useMemo(
		() => [
			{
				headerTitle: t('events:headers.time'),
				id: 'time',
				accessor: (data) => {
					if (!data) {
						return null;
					}

					return getFormattedDateTimeByLocale(data.eventTime, { timeZoneLocale: timeZoneLocale });
				},
				Cell: (e) => {
					const time = e.value.toString();
					if (time) {
						return <b>{time}</b>;
					} else {
						return <></>;
					}
				},
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.severity'),
				id: 'severity',
				accessor: (row) => {
					if (!row) {
						return null;
					}

					return row.severity.toLowerCase();
				},
				Cell: (e) => {
					const severity = e.value.toString();
					if (severity) {
						return (
							<a
								href={getEventURL(e.row.original, clientId)}
								onClick={(event) => {
									event.preventDefault();
								}}
								className={severityClasses.noUnderlineLink}
							>
								<FontAwesomeIcon
									title={t('events:severities.' + severity)}
									className={severityClasses[severity]}
									icon={faCircle}
								></FontAwesomeIcon>
								<span className={severityClasses.label}>{t('events:severities.' + severity)}</span>
							</a>
						);
					} else {
						return <></>;
					}
				},
				escapeLeftClick: true
			},
			{
				headerTitle: t('events:headers.dev'),
				id: 'deviceIp',
				accessor: 'deviceIP',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.deviceDesc'),
				id: 'deviceDesc',
				accessor: 'deviceDesc',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.eventId'),
				id: 'eventId',
				accessor: 'eventId',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.event_summary'),
				id: 'eventSummary',
				accessor: 'eventSummary',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.action'),
				id: 'action',
				accessor: 'action',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.ticketId'),
				id: 'ticketId',
				accessor: 'ticketId',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.sourceIp'),
				id: 'sourcIp',
				accessor: 'source',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.sourcePort'),
				id: 'sourcePort',
				accessor: 'sourcePort',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.destinIp'),
				id: 'destinationIp',
				accessor: 'destination',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.destinPort'),
				id: 'destinationPort',
				accessor: 'destinationPort',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.logTime'),
				id: 'logTimeStamp',
				accessor: (data) => {
					if (!data) {
						return null;
					}

					return getFormattedDateTimeByLocale(data.logTimeStamp);
				},
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.ipProtocol'),
				id: 'ipProtocol',
				accessor: 'ipProtocolNum',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			},
			{
				headerTitle: t('events:headers.count'),
				id: 'occurences',
				accessor: 'occurrence',
				escapeLeftClick: true,
				cellLinkHref: (row) => {
					return getEventURL(row, clientId);
				}
			}
		],
		[t, severityClasses, clientId, timeZoneLocale]
	);

	const options = useMemo(
		() => ({
			url: '/portal/reports/expressDrillDownEvents?page=1&source=NRS',
			method: 'POST',
			params: {
				...parameters,
				limit: 100,
				page: 0
			}
		}),
		[parameters]
	);

	const exportTable = (format) => {
		window.location.href = `/portal/reports/expressDrillDownEvents/export?type=${format}`;
	};

	const processPaginationFn = (pagination) => {
		pagination = {
			...pagination,
			count: pagination.totalPages,
			current: pagination.currentPage - 1
		};

		return pagination;
	};

	const handleSelectSingleRowClick = (event, row) => {
		setSelectedEventDetails({ ...row.original });
		setView('single');
	};

	return (
		<>
			{loadingExpressPayload ? (
				<p>
					{t('events:summaryMessages.loadingPage')}
					<FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon>
				</p>
			) : (
				<>
					<BackLinks></BackLinks>
					{view === 'single' ? (
						<>
							<EventDetails selectedEvent={selectedEventDetails}></EventDetails>
						</>
					) : (
						<DataTable
							title="Events"
							name="EXPRESS_DRILL_DOWN_EVENTS"
							columns={columns}
							options={options}
							processDataFn={(data) => data}
							emptyMessage="events:summaryMessages.empty"
							errorMessage="events:summaryMessages.error"
							loadingMessage="events:summaryMessages.loading"
							canSelectAll={false}
							serverSidePagination={true}
							exportFormats={[
								{ format: 'XML' },
								{ format: 'CSV' },
								{ format: 'JSON' },
								{ format: 'PDF' }
							]}
							exportFunction={exportTable}
							processPaginationFn={processPaginationFn}
							canSelectSingleRow={true}
							hiddenColumns={['deviceDesc', 'ipProtocol']}
							handleSelectSingleRowClick={handleSelectSingleRowClick}
						/>
					)}
				</>
			)}
		</>
	);
};

export default EventsDataTable;
