/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { post } from 'axios';
import { downloadFile } from '../../../utils';

export function getQueryString(data = {}) {
	return Object.entries(data)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');
}

export const useExportDrillDown = () => {
	let isLoading = false;
	let isError = null;
	const exportDrillDownData = (filter, fileType = 'pdf') => {
		isLoading = true;
		const axiosOptions = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			responseType: 'arraybuffer'
		};
		switch (fileType) {
			case 'pdf':
				post(
					'/portal/reportsdrilldown/exportEventAnalysisStatsAggrData',
					getQueryString({
						searchFormJson: JSON.stringify(filter),
						csrfToken: window?.APP_PAGE_DATA?.csrfToken,
						type: fileType
					}),
					axiosOptions
				)
					.then((response) => {
						isLoading = false;
						downloadFile(response);
					})
					.catch((e) => {
						isLoading = false;
						isError = e;
					});
				break;
			case 'csv':
				post(
					'/portal/events/eventsStreamDownload',
					getQueryString({
						eventSearchFormJson: JSON.stringify(filter),
						csrfToken: window?.APP_PAGE_DATA?.csrfToken,
						downloadContentType: fileType
					}),
					axiosOptions
				)
					.then((response) => {
						isLoading = false;
						downloadFile(response);
					})
					.catch((e) => {
						isLoading = false;
						isError = e;
					});
		}
	};
	return { isLoading, isError, exportDrillDownData };
};
