/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateDataTableParams } from '../../../../state/actions/components/datatables';
import { Box } from '@material-ui/core';
import { Pagination } from '../../../Pagination';

export const TablePagination = ({
	name,
	tablePagination,
	pageSize,
	isLoading,
	gotoPage,
	setPageSize,
	updateMyData,
	hasPaginationData,
	paginationType
}) => {
	const dispatch = useDispatch();

	return (
		<Box pt={2} pb={2} display="flex" alignItems="center" justifyContent="center">
			<Pagination
				isLoading={isLoading}
				data={{ totalPages: tablePagination.count, currentPage: tablePagination.current + 1 }}
				handleChange={(event, page) => {
					const currentPage = page - 1;
					tablePagination.current = currentPage;
					gotoPage(currentPage);
					dispatch(updateDataTableParams({ name, params: { page: currentPage } }));

					if (hasPaginationData) {
						updateMyData();
					}
				}}
				rowsPerPage={pageSize}
				count={tablePagination.count}
				page={tablePagination.current}
				onChangePage={(event, newPage) => {
					gotoPage(newPage);
				}}
				onChangeRowsPerPage={(event) => {
					setPageSize(parseInt(event.target.value, 10));
				}}
				type={paginationType}
			/>
		</Box>
	);
};
