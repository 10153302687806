/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import routeList from '../bootstrap';
import { hasRoleAccess, hasRouteAccess } from '../../utils/access';
import {
	faBook,
	faChartLine,
	faCogs,
	faDesktop,
	faExclamationTriangle,
	faFileAlt,
	faFlag,
	faLightbulb,
	faServer
} from '@fortawesome/free-solid-svg-icons';
import { store } from '../../state';
import { showModal } from '../../state/actions/components/modals';

import ThemeSwitch from '../../components/Themes/Switch';
import DropdownTitle from '../../components/Inputs/Dropdown/Title';

/**
 * Common main navigation menu routes.
 */
export const commonRoutes = hasRouteAccess([
	{
		name: 'common:navigation.dashboards',
		icon: faChartLine,
		routes: Object.values(routeList.dashboards),
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.incidents',
		icon: faExclamationTriangle,
		routes: [routeList.incidents.incidents, routeList.incidents.statistics],
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.events',
		icon: faFlag,
		routes: Object.values(routeList.events),
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'NGP ENGINEER'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.reports',
		icon: faFileAlt,
		routes: [
			routeList.reports.executedNRS,
			routeList.reports.scheduled,
			routeList.reports.templates,
			//routeList.reports.create,
			routeList.reports.createNew,
			routeList.reports.acknowledge
		],
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.assets',
		icon: faDesktop,
		routes: [
			routeList.assets.managed,
			routeList.assets.unmanaged,
			routeList.assets.healthTickets,
			routeList.assets.provisioningLibrary,
			routeList.assets.efdConfiguration
		],
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'SCAN_SSO_EXPOSURES'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.service',
		icon: faCogs,
		routes: Object.values(routeList.service),
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
				options: {
					hasAny: true
				}
			}
		}
	},
	{
		name: 'common:navigation.intelligence',
		icon: faLightbulb,
		routes: Object.values(routeList.intelligence),
		accessOptions: {
			toCustom: ({ features }) => {
				return (
					features['TIEW'] ||
					hasRoleAccess({
						list: [
							'ADMIN',
							'ANALYST',
							'AUDITOR',
							'INFRASTRUCTURE',
							'SECURITY',
							'THREAT INTELLIGENCE ANALYST'
						],
						options: {
							hasAny: true
						}
					})
				);
			}
		}
	},
	{
		name: 'common:navigation.orchestration',
		icon: faBook,
		routes: Object.values(routeList.orchestration),
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
				options: {
					hasAny: true
				}
			},
			toPrivileges: {
				list: ['ORCHESTRATION']
			}
		}
	},
	{
		name: 'common:navigation.administration',
		icon: faServer,
		routes: Object.values(routeList.administration),
		accessOptions: {
			toRoles: {
				list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
				options: {
					hasAny: true
				}
			}
		}
	}
]);

export const definePreferencesItems = (options) => {
	const hasPreferencesNotificationsAccess = hasRouteAccess(routeList.preferences.notifications);
	const hasPreferencesTokenAccess = hasRouteAccess(routeList.preferences.token);

	if (options.showPreferencesItems && (hasPreferencesNotificationsAccess || hasPreferencesTokenAccess)) {
		return {
			name: 'common:pages.preferences.preferences.title',
			displayOnly: true,
			routes: {
				preferences: hasRouteAccess(routeList.preferences.preferences),
				notifications: hasPreferencesNotificationsAccess,
				token: hasPreferencesTokenAccess
			}
		};
	} else {
		return hasRouteAccess(routeList.preferences.preferences);
	}
};

export const accountMenuRoutes = (options) => ({
	name: 'common:navigation.account',
	menu: {
		title: options.username,
		items: [
			...hasRouteAccess(options.showMultiClientOptions()),
			routeList.other.idm,
			definePreferencesItems(options),
			...hasRouteAccess(options.showEngineerOptions())
		]
	},
	accessOptions: {
		toAuth: ({ isAuthenticated }) => isAuthenticated
	}
});

export const documentsMenuRoutes = () => ({
	name: 'common:navigation.legalDocuments',
	offsetTop: true,
	menu: {
		items: hasRouteAccess(Object.values(routeList.documents.gdpr))
	},
	accessOptions: {
		toFeatures: {
			list: ['GDPR_SPLASH_SCREEN']
		}
	}
});

export const resourcesMenuRoutes = (options) => ({
	name: 'common:navigation.resources',
	menu: {
		items: hasRouteAccess([
			routeList.other.carbonBlack,
			routeList.other.kenna,
			{
				name: 'common:navigation.logRetention',
				onClick: options.showLogRetention,
				accessOptions: {
					toCustom: ({ other }) => {
						return other.hasLogDevices;
					},
					toRoles: {
						list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
						options: {
							hasAny: true
						}
					}
				}
			},
			routeList.other.nessus,
			{
				name: 'common:pages.other.qualys.title',
				onClick: options.showQualysSubscription,
				accessOptions: {
					toCustom: ({ other }) => {
						return other.hasQualysSubscription;
					}
				}
			},
			routeList.other.cloudSecurity,
			routeList.other.redCloak
		])
	}
});

export const toolsMenuRoutes = () => ({
	name: 'common:navigation.tools',
	menu: {
		items: hasRouteAccess([routeList.tools.filterSets, routeList.tools.manageLibrary])
	},
	accessOptions: {
		toAuth: ({ isAuthenticated }) => {
			return isAuthenticated;
		},
		toRoles: {
			list: ['FILTERSETADMIN', 'CTU', 'ENTERPRISE_BRAND_SURVEILLANCE'],
			options: {
				hasAny: true,
				andHas: ['NGP ENGINEER']
			}
		}
	}
});

/**
 * Common bar navigation menu routes.
 * @param {Object} options - Options to customize these routes
 * @returns {Array}
 */
export const commonBarOptions = (options) =>
	hasRouteAccess([
		routeList.other.learningCenter,
		routeList.other.help,
		resourcesMenuRoutes(options),
		routeList.other.contactUs,
		toolsMenuRoutes(options),
		accountMenuRoutes(options),
		{
			renderItem: options.showThemeSwitch
		},
		routeList.auth.logout
	]);

export const commonBarRoutes = ({ showThemeSwitch = false, showPreferencesItems = false } = {}) => {
	const { auth, client, contact } = store.getState();
	const { isLoggedIn, isEngineer, isMultiClient } = auth;

	const clientName = client.name;
	const contactName = contact.name;
	const contactEmail = contact.email;
	const contactSourceEmail = contact.sourceEmail;

	let username = [];

	if (contactName) {
		username.push(contactName);
	}

	if (contactEmail) {
		username.push(contactEmail);
	}

	if (clientName && isMultiClient && isLoggedIn) {
		username.push(clientName);
	}

	return commonBarOptions({
		username,
		showThemeSwitch: showThemeSwitch
			? function showThemeSwitch() {
					return <ThemeSwitch />;
			  }
			: null,
		showPreferencesItems: showPreferencesItems,
		showEngineerOptions() {
			return isEngineer
				? [
						{
							renderItem: function engineerMenu() {
								return (
									<DropdownTitle
										key={'common:labels.engineer'}
										title={['common:labels.engineer', contactSourceEmail]}
									/>
								);
							}
						},
						{
							name: 'common:buttons.switch',
							path: '/portal/j_spring_security_exit_user'
						}
				  ]
				: [];
		},
		showMultiClientOptions() {
			return isMultiClient
				? [
						{
							name: 'common:buttons.multiClientSwitch',
							path: '/portal/j_spring_security_exit_user'
						}
				  ]
				: [];
		},
		showLogRetention(event) {
			event.preventDefault();

			store.dispatch(
				showModal({
					name: 'LOG_RETENTION'
				})
			);
		},
		showQualysSubscription(event) {
			event.preventDefault();

			store.dispatch(
				showModal({
					name: 'QUALYS_SUBSCRIPTION'
				})
			);
		}
	});
};
