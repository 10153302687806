import React, { useMemo } from 'react';
import DataTable from '../../../../components/DataTable';
import { severityStyles } from '../../../../pages/Reports/View/Executive/Express';
import { useTranslation } from 'react-i18next';
import { useDrillDownStyles } from '../Incidents';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../../../utils/dashboard';
import { fetchDataTableData, updateDataTableParams } from '../../../../state/actions/components/datatables';
import {
	getSelectedEventsDrillDownFilter,
	getRowGroupingFilter,
	useExportDrillDown
} from '../../../../pages/Reports/View/TopTalkers/Events/util';
import { getEventsTableColumns } from './Columns';
import {
	TimePeriodDialog,
	validateTimeRange
} from '../../../../pages/Reports/View/EventAnalysis/Events/TimePeriodMessage';
import { updateDrillDownDataTableMsgOptions } from '../../../../state/actions/components/pivotdatatables';

const DATA_TABLE_NAME = 'PIVOT_DRILL_DOWN_EVENTS_TABLE';
const EVENTS_DATA_TABLE_PROPERTY = 'securityEventOutputRecord';

const getEventSearchParams = (requestPayload) => {
	const dateFormat = 'MM/dd/yyyy HH:mm:ss';
	return {
		action: 'shallowSearch',
		advancedParameters: '',
		aggregationFields: [],
		assets: '',
		clientHref: window.APP_DATA?.client?.href,
		deviceTypes: requestPayload?.deviceTypes?.join(','),
		eventClasses: requestPayload?.eventClasses?.join(','),
		eedParameters: '',
		startDateTime: Dashboard.DrillDown.getDateInUserTimeZone(requestPayload.startTime, dateFormat),
		endDateTime: Dashboard.DrillDown.getDateInUserTimeZone(requestPayload.endTime, dateFormat),
		fileName: '',
		groupByFields: [],
		locations: requestPayload?.locations?.join(','),
		orderByFields: [],
		source: 'TOP_TALKERS_AGGR_DRILL_DOWN',
		threatLevels: requestPayload?.threatLevels?.join(','),
		serviceLevels: requestPayload?.serviceLevels?.join(','),
		assetTags: requestPayload?.assetTags?.join(','),
		timePeriod: 'Custom....',
		uuid: ''
	};
};

function getEventsDrillDownFilters(pivotDataTable, drillDownOptions) {
	const { params = {}, drillDownParams = {}, tableHeaderModel = {} } = pivotDataTable || {};

	const selectedRowGrouping = getRowGroupingFilter({
		drillDownParams,
		tableHeaderModel
	});

	const selectedFilters = getSelectedEventsDrillDownFilter({
		rowGroupingHeaderId: tableHeaderModel?.rowGroupingHeader,
		rowGroupingValue: selectedRowGrouping?.filter,
		rowGroupingText: selectedRowGrouping?.text,
		columnGroupingValue: drillDownParams.selectedColumn?.id,
		params: drillDownOptions?.params,
		reportName: drillDownOptions?.reportName,
		otherGroupColumnValue: tableHeaderModel?.otherGroupColumn
	});

	return {
		url: '/portal/events/search',
		method: 'POST',
		params: {
			...getEventSearchParams(params),
			...selectedFilters
		},
		fileName: selectedFilters.fileName
	};
}

export const TopTalkerEventsDataTable = (props) => {
	const { t } = useTranslation(['events', 'reports']);
	const classes = useDrillDownStyles();
	const severityClasses = severityStyles();
	const clientId = window.APP_DATA?.client?.id;
	const { drillDownOptions = {}, name } = props;

	const pivotDataTable = useSelector((state) => state.pivotdatatables[name]);
	const { msgOptions = {} } = pivotDataTable || {};

	const eventsDrillDownOptions = useMemo(
		() => ({
			...getEventsDrillDownFilters(pivotDataTable, drillDownOptions)
		}),
		[pivotDataTable, drillDownOptions]
	);

	const eventsDataTable = useSelector((state) => state.datatables[DATA_TABLE_NAME]);
	const { data = {}, params = {}, isLoading = false } = eventsDataTable || {};

	const dispatch = useDispatch();

	const dataCallbackFn = (response) => {
		const uuid = response.uuid;
		if (uuid) {
			dispatch(
				updateDataTableParams({
					name: DATA_TABLE_NAME,
					params: {
						...getEventsDrillDownFilters(pivotDataTable, drillDownOptions).params,
						uuid: uuid,
						action: 'deeperSearch'
					}
				})
			);

			dispatch(
				fetchDataTableData({
					name: DATA_TABLE_NAME,
					action: 'APPEND',
					dataProperty: EVENTS_DATA_TABLE_PROPERTY
				})
			);
		}
	};

	const columns = useMemo(
		() =>
			getEventsTableColumns({
				t,
				classes,
				clientId,
				severityClasses,
				params: drillDownOptions?.params
			}),
		[t, classes, clientId, severityClasses, drillDownOptions]
	);

	const { exportDrillDownData } = useExportDrillDown();

	const TimePeriodWarning = (props) => {
		return (
			<TimePeriodDialog
				open={!props.timeRangeMsg.isValid}
				onClose={() => {
					dispatch(
						updateDrillDownDataTableMsgOptions({
							name,
							msgOptions: { showWarning: false, shown: true }
						})
					);
				}}
				message={props.timeRangeMsg.message}
			></TimePeriodDialog>
		);
	};

	const infoMessage = useMemo(() => {
		const limit = 50;
		let msgKey, downloadMsgKey;
		if (data?.elements?.length >= limit) {
			msgKey = 'events:text.nrs.mostRecentEvents';
			downloadMsgKey = 'events:text.downloadFullEventsList';
		} else {
			msgKey = 'events:text.nrs.eventsMessage';
			downloadMsgKey = 'events:text.downloadEventsList';
		}
		return !isLoading ? (
			<div className={classes.message}>
				{t(msgKey, { limit: data?.elements?.length })}
				<a
					className={classes.downloadLink}
					onClick={() => {
						exportDrillDownData(params, 'csv');
					}}
					target="_blank"
					rel="noreferrer"
				>
					{t(downloadMsgKey)}
				</a>
			</div>
		) : null;
	}, [t, classes, data, exportDrillDownData, params, isLoading]);

	const timeRangeMsg = validateTimeRange({
		startDate: drillDownOptions?.params?.startDate,
		t: t
	});

	return (
		<>
			{drillDownOptions?.params?.subReportType !== 'SERVICES' &&
			msgOptions?.showWarning &&
			!timeRangeMsg.isValid ? (
				<TimePeriodWarning timeRangeMsg={timeRangeMsg}></TimePeriodWarning>
			) : (
				<DataTable
					title="Events"
					nameSpace={['events']}
					name={DATA_TABLE_NAME}
					columns={columns}
					options={eventsDrillDownOptions}
					processDataFn={(data) => data}
					emptyMessage={'events:summaryMessages.empty'}
					errorMessage={'events:summaryMessages.error'}
					loadingMessage={'events:summaryMessages.loading'}
					canSelectAll={false}
					showPagination={false}
					serverSidePagination={false}
					canToggleColumns={false}
					dataProperty={EVENTS_DATA_TABLE_PROPERTY}
					infoMessage={infoMessage}
					dataCallbackFn={dataCallbackFn}
				/>
			)}
		</>
	);
};
