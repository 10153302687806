/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormLabel-root': {
			'margin-top': theme.spacing(2),
			'margin-bottom': theme.spacing(1),
			'color': theme.palette.accordion.color
		}
	},

	deviceRegistrationDownloadsModalOpenSourceInfo: {
		fontSize: '11.25px',
		lineHeight: 'normal',
		margin: '0 5px 11px '
	},

	footer: {
		backgroundColor: theme.palette.download.modal.footer.backgroundColor
	},

	header: {
		fontSize: '26.25px',
		fontWeight: 'normal'
	},

	title: {
		padding: '10px 18px'
	},

	strongClass: {
		fontWeight: '700',
		marginTop: '1.5em'
	},

	paragraph: {
		lineHeight: '22px',
		fontSize: '15px',
		fontFamily: 'Arial, sans-serif',
		padding: '2px 0px'
	},

	small: {
		fontSize: '85%'
	},

	closeButton: {
		padding: '0px',
		top: '5px',
		color: theme.palette.grey[500],
		float: 'right'
	},

	btnClose: {
		'border': '1px solid',
		'fontSize': '15px',
		'borderRadius': '4px',
		'lineHeight': '22px',
		'backgroundColor': theme.palette.download.modal.footer.closeButton.backgroundColor,
		'borderColor': theme.palette.download.modal.footer.closeButton.borderColor,
		'color': theme.palette.download.modal.footer.closeButton.color,
		'&:hover': {
			backgroundColor: theme.palette.download.modal.footer.closeButton.hover.backgroundColor,
			color: theme.palette.download.modal.footer.closeButton.hover.color
		}
	},
	btnDownload: {
		'&:disabled': {
			border: '1px solid',
			backgroundColor: theme.palette.download.modal.footer.downloadButton.disabled.backgroundColor,
			borderColor: theme.palette.download.modal.footer.downloadButton.disabled.borderColor,
			color: theme.palette.download.modal.footer.downloadButton.disabled.color
		},
		'&:hover': {
			border: '1px solid',
			backgroundColor: theme.palette.download.modal.footer.downloadButton.hover.backgroundColor,
			borderColor: theme.palette.download.modal.footer.downloadButton.hover.borderColor,
			color: theme.palette.download.modal.footer.downloadButton.hover.color
		},
		'border': '1px solid',
		'fontSize': '15px',
		'borderRadius': '4px',
		'lineHeight': '22px',
		'backgroundColor': theme.palette.download.modal.footer.downloadButton.backgroundColor,
		'borderColor': theme.palette.download.modal.footer.downloadButton.borderColor,
		'color': theme.palette.download.modal.footer.downloadButton.color
	},

	checkBox: {
		float: 'left',
		marginRight: '5px'
	},

	dialogContent: {
		padding: '2px 15px 8px'
	},

	deviceRegistrationDownloadsModalCheckbox: {
		marginTop: '2em',
		fontSize: '80%'
	}
}));
