/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

export function downloadFile(response = {}) {
	const contentDisposition = response.headers['content-disposition'];
	const downloadedFileType = response.headers['content-type'];
	if (contentDisposition && downloadedFileType) {
		const fileName = contentDisposition.split('filename=')[1].replace(/"/g, '');
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// IE variant
			window.navigator.msSaveOrOpenBlob(
				new Blob([response.data], {
					type: downloadedFileType
				}),
				fileName
			);
		} else {
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: downloadedFileType
				})
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}
	}
}
