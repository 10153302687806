/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { matchPath } from 'react-router';
import { store } from '../state';
import { bootstrapPublicRoutes } from '../routes/bootstrap';

/**
 * Filters the specified list against data source.
 *
 * @param {Object} data - Data source.
 * @param {Array} list - List of items to check against data source.
 *
 * @returns {Array} Final list of matching list items.
 */
export const filter = (data, list) => {
	return list.filter((item) => data[item.toUpperCase()]);
};

/**
 * Checks if given data source contains specified items.
 *
 * @param {Object} data - Data source to check against.
 * @param {Array} list - List of items to validate.
 * @param {Object} options - Options to configure checks.
 *
 * @returns {Boolean} Whether it passed the test.
 */
export const check = ({ data = {}, list = [], options = { hasAny: false, andHas: false, orHas: false } }) => {
	if (options.andHas) {
		const andHasResult = filter(data, options.andHas);
		if (options.andHas.length !== andHasResult.length) {
			return false;
		}
	}

	if (options.orHas) {
		const orHasResult = filter(data, options.orHas);
		if (orHasResult.length) {
			return true;
		}
	}

	const listResult = filter(data, list);

	if (!listResult.length || (!options.hasAny && listResult.length !== list.length)) {
		return false;
	}

	return true;
};

/**
 * Checks if user has access based on requested roles.
 *
 * @param {Array} list - A list of roles to check.
 * @param {Array} options.orHas - List of roles to create `or` condition.
 * @param {Array} options.andHas - List of roles to create `and` condition.
 * @param {Boolean} options.hasAny - Whether to check for any matching role within the `list`.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasRoleAccess = ({ list, options }) => {
	const { roles } = store.getState();

	return check({ data: roles, list, options });
};

/**
 * Checks if user has access based on requested features.
 *
 * @param {Array} list - A list of features to check.
 * @param {Array} options.orHas - List of features to create `or` condition.
 * @param {Array} options.andHas - List of features to create `and` condition.
 * @param {Boolean} options.hasAny - Whether to check for any matching feature within the `list`.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasFeatureAccess = ({ list, options }) => {
	const { features } = store.getState();

	return check({ data: features, list, options });
};

/**
 * Checks if user has access based on requested privileges.
 *
 * @param {Array} list - A list of privileges to check.
 * @param {Array} options.orHas - List of privileges to create `or` condition.
 * @param {Array} options.andHas - List of privileges to create `and` condition.
 * @param {Boolean} options.hasAny - Whether to check for any matching privilege within the `list`.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasPrivilegeAccess = ({ list, options }) => {
	const { privileges } = store.getState();

	return check({ data: privileges, list, options });
};

/**
 * Checks if user has access based on auth state.
 *
 * @param {Function} toAuth - Custom access check method.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasAuthAccess = (toAuth) => {
	const { auth } = store.getState();

	if (typeof toAuth === 'function') {
		return toAuth(auth);
	}

	return auth.isLoggedIn;
};

/**
 * Checks if user has access based on custom state checks.
 *
 * @param {Function} toCustom - Custom state check method.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasCustomAccess = (toCustom) => {
	const state = store.getState();

	if (typeof toCustom === 'function') {
		return toCustom(state);
	}

	return false;
};

/**
 * Checks if user has access based on requested roles, features, and privileges.
 *
 * @param {hasRoleAccess} toRoles - Roles to check.
 * @param {hasCustomAccess} toCustom - Custom state to check.
 * @param {hasFeatureAccess} toFeatures - Features to check.
 * @param {hasPrivilegeAccess} toPrivileges - Privileges to check.
 *
 * @returns {Boolean} Whether all checks have passed.
 */
export const hasAccess = ({ toAuth, toCustom, toRoles, toFeatures, toPrivileges } = {}) => {
	// check against auth state
	if (!hasAuthAccess(toAuth)) {
		// checks failed, stop here.
		return false;
	}

	// check against requested roles
	if (toRoles && !hasRoleAccess(toRoles)) {
		// checks failed, stop here.
		return false;
	}

	// check against requested roles
	if (toFeatures && !hasFeatureAccess(toFeatures)) {
		// checks failed, stop here.
		return false;
	}

	// check against requested roles
	if (toPrivileges && !hasPrivilegeAccess(toPrivileges)) {
		// checks failed, stop here.
		return false;
	}

	// check against custom checks
	if (toCustom && !hasCustomAccess(toCustom)) {
		// checks failed, stop here.
		return false;
	}

	return true;
};

/**
 * Checks if user has access to the route based on provided accessOptions.
 *
 * @param {Object|Array} route - A route or list of routes to check.
 * @param {Object} accessOptions - Access options to check against.
 *
 * @returns {route|null} Original provided route or null.
 */
export const hasRouteAccess = (route) => {
	if (Array.isArray(route)) {
		return route.map((entry) => hasRouteAccess(entry));
	}

	if (!route || !hasAccess(route.accessOptions)) {
		return null;
	}

	if (route.routes) {
		if (route.routes.constructor === Array) {
			route.routes.forEach((value, key) => {
				route.routes[key] = hasRouteAccess(value);
			});
		}

		if (route.routes.constructor === Object) {
			for (let [key, value] of Object.entries(route.routes)) {
				route.routes[key] = hasRouteAccess(value);
			}
		}
	}

	return route;
};

/**
 * Checks if given route is a public page.
 *
 * @param {String} [path] - A path to check, otherwise current location is used.
 *
 * @returns {Boolean} Whether path is a public route.
 */
export const checkRouteIfPublic = (path = null) => {
	const { auth, router } = store.getState();

	return (
		!auth.isAuthenticated ||
		bootstrapPublicRoutes.find(
			(pathname) =>
				!!matchPath(pathname, {
					path: path || router.location.pathname,
					exact: true,
					strict: true
				})
		)
	);
};
