/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { createRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox, ClickAwayListener, FormGroup, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import Link from '../../../../Link';
import Dropdown from '../../../../Inputs/Dropdown';

export const StyledCheckbox = withStyles((theme) => ({
	root: {
		'padding': theme.spacing(0),
		'color': theme.palette.text.primary,
		'&$checked': {
			color: theme.palette.action.active
		},
		'&$disabled': {
			color: theme.palette.text.secondary
		}
	},
	checked: {},
	disabled: {}
}))(Checkbox);

export const StyledFormControlLabel = withStyles((theme) => ({
	root: {
		'color': theme.palette.text.primary,
		'marginLeft': 0,
		'marginRight': 0,
		'&$disabled': {
			color: theme.palette.text.secondary
		}
	},
	label: {
		'fontSize': theme.typography.body2.fontSize,
		'paddingLeft': theme.spacing(1),
		'&$disabled': {
			color: theme.palette.text.secondary
		}
	},
	disabled: {}
}))(FormControlLabel);

const useStyles = makeStyles((theme) => ({
	form: {
		padding: theme.spacing(2)
	},
	formLabel: {
		'&:not(:last-child)': {
			marginBottom: theme.spacing(1)
		}
	},
	toggle: {
		'width': 40,
		'height': 40,
		'textAlign': 'center',
		'display': 'flex',
		'justifyContent': 'center',
		'alignItems': 'center',
		'color': theme.palette.text.primary,
		'padding': theme.spacing(2),
		'margin': '0 auto',
		'&:not($toggleDisabled):hover $toggleIcon, &:not($toggleDisabled):focus $toggleIcon': {
			color: theme.palette.link.colorHover
		},
		'&$toggleDisabled': {
			cursor: 'default',
			color: theme.palette.text.secondary
		},
		[theme.breakpoints.down('sm')]: {
			width: 30,
			height: 30,
			padding: theme.spacing(1)
		}
	},
	toggleDisabled: {},
	toggleIcon: {}
}));

export const ToggleColumns = ({ columns, settingsColumns, isLoading }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const buttonRef = createRef();
	const [elRef, setElRef] = useState(null);

	const isDisabled = Boolean(isLoading);
	const isMenuOpen = Boolean(elRef) && !isDisabled;

	return (
		<>
			<Link
				ref={buttonRef}
				href="#"
				underline="none"
				title={t('datatables:controls.toggleColumns.button')}
				disabled={isDisabled}
				className={classNames(classes.toggle, {
					[classes.toggleDisabled]: isDisabled
				})}
				onClick={(event) => {
					event.preventDefault();

					if (isDisabled) {
						return;
					}

					setElRef(elRef ? null : buttonRef.current);
				}}
			>
				<FontAwesomeIcon icon={faCog} className={classes.toggleIcon} />
			</Link>
			<Dropdown
				anchorEl={elRef}
				isOpen={isMenuOpen}
				placement="bottom"
				PaperProps={{
					component: 'div',
					square: false
				}}
			>
				<ClickAwayListener
					onClickAway={() => {
						setElRef(null);
					}}
				>
					<FormGroup className={classes.form}>
						{columns
							.filter(({ id }) => !settingsColumns.includes(id))
							.map((column) => (
								<StyledFormControlLabel
									key={column.id}
									disabled={
										isDisabled ||
										(columns.filter((col) => col.isVisible).length == 1 && column.isVisible)
									}
									control={<StyledCheckbox {...column.getToggleHiddenProps()} />}
									label={t(column.headerTitle)}
									className={classes.formLabel}
								/>
							))}
					</FormGroup>
				</ClickAwayListener>
			</Dropdown>
		</>
	);
};
