/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { Duration } from 'luxon';
import DOMPurify from 'dompurify';
import { find as linkifyJsFind } from 'linkifyjs';
import MessageSound from './message.mp3';
import { store } from '../../../../../state';
import { debugMessage } from '../../../../../utils';
import {
	addAgent,
	removeAgent,
	addMessage,
	sendMessage,
	setProgress,
	setSession,
	setEWT,
	setAgentTyping,
	acknowledgeAgentMessage
} from '../../../../../state/actions/components/chat';

const getMessageId = () => {
	return 'client.' + Date.now();
};

const getEWT = (content) => {
	let result = null;

	if (!content) {
		return result;
	}

	const hours =
		content.indexOf(' hours') !== -1
			? content.split(' hours')[0]
			: content.indexOf(' hour') !== -1
			? content.split(' hour')[0]
			: 0;

	const minutes =
		content.indexOf(' minutes') !== -1
			? content.split(' minutes')[0]
			: content.indexOf(' minute') !== -1
			? content.split(' minute')[0]
			: 0;

	const seconds =
		content.indexOf(' seconds') !== -1
			? content.split(' seconds')[0]
			: content.indexOf(' second') !== -1
			? content.split(' second')[0]
			: 0;

	try {
		result = Duration.fromObject({
			hours,
			minutes,
			seconds
		}).toFormat('m');
	} catch (error) {
		debugMessage('[CHAT] [getEWT]', error);

		return result;
	}

	return parseInt(result, 10);
};

const MessageTypes = {
	MSG_BROWSER_NOT_SUPPORTED: -100,

	RESULT_SUCCESS: 1,
	RESULT_ERROR: -1,

	TRANSFER_TO_GROUP: 1751,
	TRANSFERT_TO_AGENT: 1752,
	AGENT_TRANSFER_AGENT: 1753,

	INTERACTION_TERMINATED: 19507,

	MSG_CHAT_KEEP_ALIVE: 17000,
	MSG_CHAT_CLIENT_REQUEST: 17001,
	MSG_CHAT_CLIENT_MESSAGE: 17002,
	MSG_CHAT_CLIENT_TERMINATE: 17003,
	MSG_CHAT_CLIENT_MESSAGE_RECEIVED: 17004,
	MSG_CHAT_CLIENT_TYPING: 17005,
	// MSG_CHAT_CLIENT_TRANSFER_TO_GROUP:   17007,
	MSG_NO_SERVICE: 17008,

	CUSTOMER_CONTACT_UPDATE: 19608,

	MSG_CHAT_AGENT_ACCEPT: 18000,
	MSG_CHAT_AGENT_MESSAGE: 18001,
	MSG_CHAT_AGENT_TERMINATE: 18002,
	MSG_CHAT_AGENT_MESSAGE_TO_AGENT: 18004,
	MSG_CHAT_AGENT_TYPING: 18005,
	MSG_CHAT_AGENT_MESSAGE_RECEIVED: 18007,
	MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT: 18008,
	MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT: 18009,

	MSG_CHAT_SIGHTCALL_ESCALATION: 18012,
	MSG_CHAT_SIGHTCALL_VIDEO_ACTIVATED: 18013,
	MSG_CHAT_SIGHTCALL_VIDEO_TERMINATED: 18014,
	MSG_CHAT_SIGHTCALL_CANCELED: 18015,

	MSG_CHAT_AGENT_CONFERENCE_AGENT: 18020,
	MSG_CHAT_AGENT_COMFORT_MESSAGE: 18021,
	MSG_CHAT_AUTO_CLOSE: 18022,

	MSG_TEXT: 1,
	MSG_HTML: 2,
	MSG_VOICE: 3,
	MSG_VIDEO: 4,
	MSG_FILE: 5,

	STATE_PENDING: 1,
	STATE_DELIVERED: 2,
	STATE_TYPING: 3,
	STATE_DELETING: 4,

	FROM_AGENT: 1,
	FROM_CLIENT: 2,
	FROM_SERVER: 3,
	FROM_TYPING: 4,

	CHAT_STATE_ACTIVE: 1,
	CHAT_STATE_TEMINATED: 2,

	IN: 1,
	OUT: 2,

	getDescription: function (messageId) {
		switch (messageId) {
			case this.MSG_CHAT_CLIENT_REQUEST:
				return 'ChatClientRequest';
			case this.MSG_CHAT_CLIENT_MESSAGE:
				return 'ChatClientMessage';
			case this.MSG_CHAT_CLIENT_TERMINATE:
				return 'ChatClientTerminate';
			case this.MSG_CHAT_CLIENT_MESSAGE_RECEIVED:
				return 'ChatClientMessageReceived';
			case this.MSG_CHAT_CLIENT_TYPING:
				return 'ChatClientTyping';
			case this.MSG_CHAT_AGENT_ACCEPT:
				return 'ChatAgentAccept';
			case this.MSG_CHAT_AGENT_MESSAGE:
				return 'ChatAgentMessage';
			case this.MSG_CHAT_AGENT_TERMINATE:
				return 'ChatAgentTerminate';
			case this.MSG_CHAT_AGENT_MESSAGE_TO_AGENT:
				return 'ChatAgentToAgent';
			case this.MSG_CHAT_AGENT_TYPING:
				return 'ChatAgentTyping';
			case this.MSG_CHAT_AGENT_MESSAGE_RECEIVED:
				return 'ChatAgentMessageReceived';
			case this.MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT:
				return 'AddAgentToConference';
			case this.MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT:
				return 'RemoveAgentFromConference';
			case this.MSG_CHAT_AGENT_COMFORT_MESSAGE:
				return 'ChatAgentComfortMessage';
			case this.MSG_CHAT_AUTO_CLOSE:
				return 'ChatAutoCloseWarning';

			default:
				return 'Unknown message id [' + messageId + ']';
		}
	}
};

class ChatMessage {
	constructor({
		id,
		messageId,
		messageType,
		fromType,
		content,
		originatorType,
		displayName,
		contentType,
		removeUserNames,
		timestamp
	}) {
		this._id = id;
		this._messageId = messageId;
		this._messageType = messageType;
		this._content = content;
		this._fromType = fromType;
		this._originatorType = originatorType;
		this._displayName = displayName;
		this._contentType = contentType;
		this._removeUserNames = removeUserNames;
		this._timestamp = timestamp;
	}

	get messageId() {
		return this._messageId;
	}

	get originatorType() {
		return this._originatorType;
	}

	get displayName() {
		return this._displayName;
	}

	get contentType() {
		return this._contentType;
	}

	get fromType() {
		return this._fromType;
	}

	get isInboundMessage() {
		return this._fromType === MessageTypes.IN;
	}

	get isOutboundMessage() {
		return this._fromType === MessageTypes.OUT;
	}

	get hasToSendAcknowledgement() {
		return (
			(this._messageType === MessageTypes.MSG_CHAT_CLIENT_MESSAGE ||
				this._messageType === MessageTypes.MSG_CHAT_AGENT_MESSAGE ||
				this._messageType === MessageTypes.MSG_CHAT_AGENT_MESSAGE_TO_AGENT) &&
			this._fromType === MessageTypes.IN
		);
	}

	get content() {
		if (this._messageType === MessageTypes.MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT) {
			return {
				i18n: 'common:chat.conversation.agentLeft',
				data: {
					agentName: this._removeUserNames[0]
				}
			};
		}

		if (typeof this._content === 'string') {
			const links = linkifyJsFind(this._content);

			if (links.length) {
				return {
					message: this._content,
					links
				};
			}
		}

		return this._content || '';
	}

	get messageTime() {
		return new Date(this._timestamp);
	}

	get isGRNotification() {
		return this._id === '5001' && this._messageId === '5001';
	}
}
class ChatMessageHandler {
	handleMessage(payload) {
		switch (payload.messageId) {
			case MessageTypes.MSG_CHAT_CLIENT_REQUEST:
				this.handleClientChatRequestMessage(payload);
				return;

			case MessageTypes.MSG_CHAT_KEEP_ALIVE:
			case MessageTypes.MSG_CHAT_CLIENT_MESSAGE:
			case MessageTypes.MSG_CHAT_CLIENT_MESSAGE_RECEIVED:
			case MessageTypes.MSG_CHAT_CLIENT_TYPING:
				this.handleConfirmationMessageFromServer(payload);
				return;

			case MessageTypes.INTERACTION_TERMINATED:
				debugMessage('[CHAT] MessageTypes.INTERACTION_TERMINATED');
				return;

			case MessageTypes.MSG_CHAT_CLIENT_TERMINATE:
				this.handleChatTerminatedByClient(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_TERMINATE:
				this.handleChatTerminatedByAgent(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_ACCEPT:
				this.handleChatAcceptedByAgent(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_MESSAGE:
			case MessageTypes.MSG_CHAT_AGENT_COMFORT_MESSAGE:
			case MessageTypes.MSG_CHAT_AUTO_CLOSE:
				this.handleChatMessageFromAgent(payload);
				return;

			case MessageTypes.MSG_CHAT_SIGHTCALL_ESCALATION:
				debugMessage('[CHAT] Video message type not supported');
				return;

			case MessageTypes.MSG_CHAT_SIGHTCALL_CANCELED:
				debugMessage('[CHAT] Video message type not supported');
				return;

			case MessageTypes.MSG_CHAT_AGENT_TYPING:
				this.handleAgentTypingMessage(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_MESSAGE_RECEIVED:
				this.handleChatMessageAcknowledgement(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT:
				this.handleAddAgentToChat(payload);
				return;

			case MessageTypes.MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT:
				this.handleRemoveAgentFromChat(payload);
				return;

			case MessageTypes.MSG_NO_SERVICE:
				// eslint-disable-next-line no-undef
				this.handleNoServiceMessage(payload);
				return;

			case MessageTypes.TRANSFER_TO_GROUP:
				this.handleTransferToGroupMessage(payload);
				return;

			case MessageTypes.TRANSFERT_TO_AGENT:
				this.handleTransferToAgentMessage(payload);
				return;

			case MessageTypes.AGENT_TRANSFER_AGENT:
				this.handlAgentTransferMessage(payload);
				return;
		}
	}

	handleAddAgentToChat(payload) {
		debugMessage('[CHAT] [handleAddAgentToChat]', payload);

		const interaction = JSON.parse(payload.interaction);

		for (let i = 0; i < interaction.addUserIds.length; i++) {
			store.dispatch(
				addAgent({
					id: interaction.addUserIds[i],
					name: interaction.addUserNames[i]
				})
			);

			store.dispatch(
				addMessage({
					id: interaction.id,
					messageId: interaction.messageId,
					messageType: payload.messageId,
					fromType: MessageTypes.IN,
					content: {
						i18n: 'common:chat.conversation.agentJoined',
						data: {
							agentName: interaction.addUserNames[i]
						}
					},
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_AGENT,
					displayName: interaction.displayName,
					timestamp: Date.now()
				})
			);
		}
	}

	handleRemoveAgentFromChat(payload) {
		debugMessage('[CHAT] [handleRemoveAgentFromChat]', payload);

		const interaction = JSON.parse(payload.interaction);

		for (let i = 0; i < interaction.userIds.length; i++) {
			store.dispatch(
				removeAgent({
					id: interaction.removeUserIds[i]
				})
			);
		}

		store.dispatch(
			addMessage({
				id: interaction.id,
				messageId: interaction.messageId,
				messageType: payload.messageId,
				fromType: MessageTypes.IN,
				content: interaction.content,
				contentType: MessageTypes.MSG_TEXT,
				originatorType: MessageTypes.FROM_AGENT,
				displayName: interaction.displayName,
				removeUserNames: interaction.removeUserNames,
				timestamp: Date.now()
			})
		);
	}

	handleAgentTypingMessage(payload) {
		debugMessage('[CHAT] [handleAgentTypingMessage]', payload);

		window.clearTimeout(this.typingTimeout);

		store.dispatch(setAgentTyping(true));

		this._typingTimeout = window.setTimeout(() => {
			store.dispatch(setAgentTyping(false));
		}, 5 * 1000);
	}

	handleChatAcceptedByAgent(payload) {
		debugMessage('[CHAT] [handleChatAcceptedByAgent]', payload);

		const { chat } = store.getState();

		const CHAT_BOT_OWNER_ID = '-8000';
		const interaction = JSON.parse(payload.interaction);

		if (typeof interaction.ownerId != 'string') {
			interaction.ownerId = interaction.ownerId.toString();
		}

		store.dispatch(
			setSession({
				owner: {
					id: interaction.ownerId,
					name: interaction.displayName
				},
				isChatBot: interaction.ownerId === CHAT_BOT_OWNER_ID
			})
		);

		store.dispatch(
			setProgress({
				progress: 'acceptedByAgent'
			})
		);

		// send the ticketId message
		if (chat.form.isGeneratedTicket && !chat.session.wasGeneratedTicketShown) {
			store.dispatch(
				addMessage({
					id: interaction.id,
					messageId: `system.${Date.now()}`,
					messageType: payload.messageId,
					fromType: MessageTypes.IN,
					content: {
						i18n: 'common:chat.conversation.ticket',
						data: {
							ticketNumber: `${chat.form.ticketId}`
						},
						components: [{ component: 'createServiceRequestLink', data: chat.form.ticketId }]
					},
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_SERVER,
					displayName: 'System',
					timestamp: Date.now()
				})
			);
			store.dispatch(
				setSession({
					wasGeneratedTicketShown: true
				})
			);
		}

		if (chat.form.question && !chat.session.wasQuestionShown) {
			store.dispatch(
				sendMessage({
					content: chat.form.question
				})
			);
			store.dispatch(
				setSession({
					wasQuestionShown: true
				})
			);
		}

		store.dispatch(
			addMessage({
				id: interaction.id,
				messageId: interaction.messageId,
				messageType: payload.messageId,
				fromType: MessageTypes.IN,
				content: {
					i18n: 'common:chat.conversation.messageTo',
					data: {
						agentName: interaction.displayName
					}
				},
				contentType: MessageTypes.MSG_TEXT,
				originatorType: MessageTypes.FROM_AGENT,
				displayName: 'System',
				timestamp: Date.now()
			})
		);
	}

	handleChatMessageFromAgent(payload) {
		debugMessage('[CHAT] [handleChatMessageFromAgent]', payload);
		const { chat } = store.getState();

		let playSoundIfEnabled = false;
		const interaction = JSON.parse(payload.interaction);

		if (interaction.content.indexOf('[[[SIGHTCALL:') !== -1) {
			// gChatEventHandler.requestVideoEscalation(payload, interaction);
			playSoundIfEnabled = true;
		} else if (interaction.content.indexOf('[[[SIGHTCALL-VIDEO-ACTIVATED]]]') !== -1) {
			// gChatEventHandler.videoActivated(payload, interaction);
		} else if (interaction.content.indexOf('[[[SIGHTCALL-VIDEO-TERMINATED]]]') !== -1) {
			// gChatEventHandler.videoTerminated(payload, interaction);
		} else if (interaction.content.indexOf('[[[SIGHTCALL-CANCELED]]]') !== -1) {
			// gChatEventHandler.videoCanceled(payload, interaction);
		} else {
			playSoundIfEnabled = true;

			store.dispatch(
				addMessage({
					id: interaction.id,
					messageId: interaction.messageId,
					messageType: payload.messageId,
					fromType: MessageTypes.IN,
					content: DOMPurify.sanitize(interaction.content, { RETURN_DOM: true }).innerHTML,
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_AGENT,
					displayName: interaction.displayName,
					timestamp: Date.now()
				})
			);
		}

		if (window.Audio && playSoundIfEnabled && chat.preferences.sound) {
			// buffers automatically when created
			const snd = new window.Audio(MessageSound);

			snd.play();
		}

		window.clearTimeout(this.typingTimeout);
		store.dispatch(setAgentTyping(false));
	}

	handleChatTerminatedByAgent(payload) {
		debugMessage('[CHAT] [handleChatTerminatedByAgent]', payload);

		store.dispatch(
			setProgress({
				progress: 'terminatedByAgent'
			})
		);

		if (payload.interaction) {
			const interaction = JSON.parse(payload.interaction);

			store.dispatch(
				addMessage({
					id: interaction.id,
					messageId: interaction.messageId,
					messageType: payload.messageId,
					fromType: MessageTypes.IN,
					content: DOMPurify.sanitize(interaction.content),
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_AGENT,
					displayName: interaction.displayName,
					timestamp: Date.now()
				})
			);
		}
	}

	handleChatTerminatedByClient(payload) {
		debugMessage('[CHAT] [handleChatTerminatedByClient]', payload);

		store.dispatch(
			setProgress({
				progress: 'terminatedByClient'
			})
		);

		if (payload.interaction) {
			const interaction = JSON.parse(payload.interaction);

			store.dispatch(
				addMessage({
					id: payload.interactionId,
					messageId: interaction.messageId,
					messageType: payload.messageId,
					fromType: MessageTypes.IN,
					content: DOMPurify.sanitize(interaction.content),
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_CLIENT,
					displayName: interaction.displayName,
					timestamp: Date.now()
				})
			);
		}
	}

	handleClientChatRequestMessage(payload) {
		debugMessage('[CHAT] [handleClientChatRequestMessage]', payload);

		const { chat } = store.getState();

		if (payload.status === MessageTypes.RESULT_SUCCESS) {
			store.dispatch(
				setSession({
					id: payload.interactionId
				})
			);

			store.dispatch(
				setProgress({
					progress: chat.progress === 'showTransfer' ? 'showTransfer' : 'showQueue'
				})
			);

			const interaction = JSON.parse(payload.interaction);

			if (interaction.content) {
				if (interaction.displayName === 'System Message~~EWT~~') {
					store.dispatch(
						setEWT({
							content: DOMPurify.sanitize(interaction.content),
							timestamp: interaction.timestamp
						})
					);
				} else {
					store.dispatch(
						addMessage({
							id: interaction.id,
							messageId: interaction.messageId,
							messageType: payload.messageId,
							fromType: MessageTypes.IN,
							content: DOMPurify.sanitize(interaction.content),
							contentType: MessageTypes.MSG_TEXT,
							originatorType: MessageTypes.FROM_SERVER,
							displayName: interaction.displayName,
							timestamp: Date.now()
						})
					);
				}
			}

			return;
		}

		this.handleMessageError(payload);
	}

	handleTransferToGroupMessage(payload) {
		debugMessage('[CHAT] [handleTransferToGroupMessage]', payload);

		store.dispatch(
			setProgress({
				progress: 'showTransfer'
			})
		);

		if (payload.groupName) {
			if (payload.groupId && typeof payload.groupId != 'string') {
				payload.groupId = payload.groupId.toString();
			}

			store.dispatch(
				addMessage({
					id: payload.interactionId,
					messageId: `system.${Date.now()}`,
					messageType: payload.msgType,
					fromType: MessageTypes.IN,
					content: {
						i18n: 'common:chat.conversation.transferGroup'
					},
					contentType: MessageTypes.MSG_TEXT,
					originatorType: MessageTypes.FROM_SERVER,
					displayName: 'System',
					timestamp: Date.now()
				})
			);
		}
	}

	handleTransferToAgentMessage(payload) {
		debugMessage('[CHAT] [handleTransferToAgentMessage]', payload);
	}

	handlAgentTransferMessage(payload) {
		debugMessage('[CHAT] [handlAgentTransferMessage]', payload);

		const { chat } = store.getState();

		const agentFrom = chat.session.owner;
		const toName = typeof payload.toUserName === 'string' ? payload.toUserName : payload.interaction.lockByName;
		const agentTo = {
			id: payload.interaction.workedById,
			name: toName
		};

		store.dispatch(setSession({ owner: agentTo }));

		let agentFromId = payload.fromUserId;
		let agentToId = payload.toUserId;

		if (agentFromId && typeof agentFromId != 'string') {
			agentFromId = agentFromId.toString();
		}
		if (agentToId && typeof agentToId != 'string') {
			agentToId = agentToId.toString();
		}

		store.dispatch(
			addMessage({
				id: payload.id,
				messageId: `system.${Date.now()}`,
				messageType: payload.msgType,
				fromType: MessageTypes.IN,
				content: {
					i18n: 'common:chat.conversation.messageFrom',
					data: {
						fromAgentName: agentFrom.name,
						toAgentName: agentTo.name
					}
				},
				contentType: MessageTypes.MSG_TEXT,
				originatorType: MessageTypes.FROM_AGENT,
				ownerId: agentFromId,
				displayName: agentFrom.name,
				timestamp: Date.now()
			})
		);

		store.dispatch(
			addMessage({
				id: payload.id,
				messageId: `system.${Date.now()}`,
				messageType: payload.msgType,
				fromType: MessageTypes.IN,
				content: {
					i18n: 'common:chat.conversation.messageTo',
					data: {
						agentName: agentTo.name
					}
				},
				contentType: MessageTypes.MSG_TEXT,
				originatorType: MessageTypes.FROM_AGENT,
				ownerId: agentToId,
				displayName: agentTo.name,
				timestamp: Date.now()
			})
		);
	}

	handleMessageError(payload) {
		debugMessage('[CHAT] [handleMessageError]', payload);

		store.dispatch(
			setProgress({
				progress: 'showError'
			})
		);
	}

	handleConfirmationMessageFromServer(payload) {
		debugMessage('[CHAT] [handleConfirmationMessageFromServer]', payload);
	}

	handleChatMessageAcknowledgement(payload) {
		debugMessage('[CHAT] [handleChatMessageAcknowledgement]', payload);

		store.dispatch(
			acknowledgeAgentMessage({
				messageId: payload.messageId
			})
		);
	}
}

class MessageHandler {
	constructor() {
		this._chatMessageHandler = new ChatMessageHandler();
	}

	handleMessage(payload, context) {
		payload = payload || {};

		// correct formatting because Freedom API and AgentConsole send messages differently
		if (payload.messageId === undefined) {
			// Consoles were built with the expectation messageId would always be set in the payload
			payload.messageId = context.messageId;
		}

		if (typeof payload.messageId === 'string') {
			try {
				payload.messageId = parseInt(payload.messageId, 10);
			} catch (err) {
				// eslint-disable-next-line no-empty
			}
		}

		if (!payload.messageId) {
			return;
		}

		payload.desc = MessageTypes.getDescription(payload.messageId);

		switch (payload.messageId) {
			case MessageTypes.TRANSFER_TO_GROUP:
			case MessageTypes.TRANSFERT_TO_AGENT:
			case MessageTypes.AGENT_TRANSFER_AGENT:
			case MessageTypes.INTERACTION_TERMINATED:
			case MessageTypes.MSG_CHAT_CLIENT_REQUEST:
			case MessageTypes.MSG_CHAT_CLIENT_MESSAGE:
			case MessageTypes.MSG_CHAT_CLIENT_TERMINATE:
			case MessageTypes.MSG_CHAT_CLIENT_MESSAGE_RECEIVED:
			case MessageTypes.MSG_CHAT_CLIENT_TYPING:
			case MessageTypes.MSG_CHAT_AGENT_ACCEPT:
			case MessageTypes.MSG_CHAT_AGENT_MESSAGE:
			case MessageTypes.MSG_CHAT_AGENT_TERMINATE:
			case MessageTypes.MSG_CHAT_AGENT_MESSAGE_TO_AGENT:
			case MessageTypes.MSG_CHAT_AGENT_TYPING:
			case MessageTypes.MSG_CHAT_AGENT_MESSAGE_RECEIVED:
			case MessageTypes.MSG_CHAT_AGENT_REMOVE_AGENT_FROM_CHAT:
			case MessageTypes.MSG_CHAT_AGENT_ADD_AGENT_TO_CHAT:
			case MessageTypes.MSG_CHAT_AGENT_COMFORT_MESSAGE:
			case MessageTypes.MSG_CHAT_AUTO_CLOSE:
			case MessageTypes.MSG_CHAT_SIGHTCALL_ESCALATION:
			case MessageTypes.MSG_CHAT_SIGHTCALL_VIDEO_ACTIVATED:
			case MessageTypes.MSG_CHAT_SIGHTCALL_VIDEO_TERMINATED:
			case MessageTypes.MSG_CHAT_SIGHTCALL_CANCELED:
			case MessageTypes.MSG_NO_SERVICE:
				this._chatMessageHandler.handleMessage(payload);
				return;

			case MessageTypes.MSG_BROWSER_NOT_SUPPORTED:
				debugMessage('[CHAT] Need to handle error!');
				return;

			default:
				try {
					debugMessage('[CHAT] Unknown message type payLoad', payload);
					// eslint-disable-next-line no-empty
				} catch (err) {}
				return;
		}
	}
}

export default ChatMessage;

export { MessageTypes, MessageHandler, getMessageId, getEWT };
