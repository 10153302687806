/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
	getDateTime,
	getFormattedDateTimeByLocale,
	getLocaleByUserPreferenceTimeZone
} from '../../../pages/Reports/View/utils';
import PivotTableHeader from '../DrillDownTable/TableHeader';
import DataTable from '../../../components/DataTable';
import { getSRRTicketsTableColumns } from './Columns';
import { severityStyles } from '../../../pages/Reports/View/Executive/Express';
import { useDrillDownStyles } from '../DrillDownTable/Incidents';
import { DateTime } from 'luxon';

const DATA_TABLE_NAME = 'SRR_TICKETS_DRILL_DOWN_TABLE';

function getAggregationTableSubTitle(requestPayload) {
	const options = { timeFormat: 'HH:mm ZZZZ', timeZoneLocale: getLocaleByUserPreferenceTimeZone() };

	return (
		getFormattedDateTimeByLocale(requestPayload.startTime, options) +
		' - ' +
		getFormattedDateTimeByLocale(requestPayload.endTime, options)
	);
}

function getErrorMsg(requestPayload) {
	const twoYearsFromNow = new DateTime.local().minus({ years: 2 });
	const startTime = getDateTime(requestPayload.startTime, {});
	if (startTime.toMillis() < twoYearsFromNow.toMillis()) {
		return 'incidents:summaryMessages.invalidTimeRange';
	} else {
		return 'incidents:summaryMessages.error';
	}
}

const ServiceReviewDrillDownContainer = ({ apiUrl, requestPayload, method, downloadCsv, downloadPdf }) => {
	const { t } = useTranslation(['dashboard', 'incidents']);
	const classes = useDrillDownStyles();
	const severityClasses = severityStyles();

	const downloadMenu = useMemo(
		() => [
			{
				title: t('dashboard:drilldown.common.download'),
				subMenus: [
					{
						title: t('dashboard:drilldown.common.csv'),
						onClick: () => {
							downloadCsv();
						}
					},
					{
						title: t('dashboard:drilldown.common.pdf'),
						onClick: () => {
							downloadPdf();
						}
					}
				]
			}
		],
		[downloadCsv, downloadPdf, t]
	);

	const options = useMemo(() => {
		return {
			url: apiUrl,
			params: requestPayload,
			method
		};
	}, [apiUrl, requestPayload, method]);

	const columns = useMemo(
		() =>
			getSRRTicketsTableColumns({
				t,
				classes,
				severityClasses,
				params: requestPayload
			}),
		[t, classes, severityClasses, requestPayload]
	);

	const processPaginationFn = (pagination) => {
		pagination = {
			...pagination,
			count: pagination.totalPages,
			current: pagination.currentPage - 1
		};

		return pagination;
	};

	return (
		<>
			<Box display="flex" flexGrow={1}>
				<PivotTableHeader
					menus={downloadMenu}
					headingText={t('incidents:ticketList')}
					subHeadingText={getAggregationTableSubTitle(requestPayload)}
				/>
			</Box>
			<Box marginTop={2} marginBottom={4} marginLeft={1} marginRight={1}>
				<DataTable
					title="Incidents"
					name={DATA_TABLE_NAME}
					columns={columns}
					options={options}
					processDataFn={(data) => data}
					nameSpace={['incidents']}
					emptyMessage={'incidents:summaryMessages.empty'}
					errorMessage={getErrorMsg(requestPayload)}
					loadingMessage={'incidents:summaryMessages.loading'}
					canSelectAll={false}
					showPagination={true}
					processPaginationFn={processPaginationFn}
					serverSidePagination={true}
					canToggleColumns={false}
					paginationType={'DROPDOWN'}
				/>
			</Box>
		</>
	);
};

ServiceReviewDrillDownContainer.propTypes = {
	apiUrl: PropTypes.string,
	requestPayload: PropTypes.object,
	method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
	downloadCsv: PropTypes.func,
	downloadPdf: PropTypes.func
};

export default ServiceReviewDrillDownContainer;
