import React, { useEffect } from 'react';
import '@portal-reporting/report-ui-templates/dist/styles/report-styles.css';
import 'highcharts/css/highcharts.css';
import { Trans, useTranslation } from 'react-i18next';
import {
	AssetList,
	ChangeManagementSummaryDetail,
	Compliance,
	DeviceEventTrend,
	PortalActivity,
	SecurityEvent,
	TicketSummary,
	UserManagement
} from '@portal-reporting/report-ui-templates';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ExecutiveMain } from './Executive';
import EventAnalysisMain from './EventAnalysis';
import { showModal } from '../../../state/actions/components/modals';
import axios from 'axios';
import TopTalkersMain from './TopTalkers';
import ARMReportMain from './ARMTickets';
import { meta, urls } from '../../../state/bootstrap';
import ServiceReviewMain from './ServiceReview';
import ReportPDFViewContainer from '../PDFViewer';

const EXECUTED_REPORT_URL = '/portal/reports/executedReport';

const useStyles = makeStyles(() => ({
	reportName: {
		position: 'relative',
		display: 'block',
		padding: 'inherit',
		fontFamily: 'Arial, sans-serif',
		fontSize: 15,
		paddingLeft: 0,
		fontWeight: 'bold',
		paddingBottom: 24
	},
	helpText: {
		fontSize: '12.75px',
		paddingBottom: 20
	}
}));

const MapReportTypeToView = ({ reportType, ...others }) => {
	switch (reportType) {
		case 'complianceboard':
			return <Compliance {...others} />;
		case 'eventanalysisstats':
			return <EventAnalysisMain {...others} />;
		case 'execsum':
			return <ExecutiveMain {...others} />;
		case 'secevents':
			return <SecurityEvent {...others} />;
		case 'changemanagementsummaryanddetail':
			return <ChangeManagementSummaryDetail {...others} />;
		case 'toptalkers':
			return <TopTalkersMain {...others} />;
		case 'armticketreport':
			return <ARMReportMain {...others} />;
		case 'usermanagement':
			return <UserManagement {...others} />;
		case 'deviceeventtrend':
			return <DeviceEventTrend {...others} />;
		case 'portalactivity':
			return <PortalActivity {...others} />;
		case 'assetreport':
			return <AssetList {...others} />;
		case 'servicereview':
			return <ServiceReviewMain {...others} />;
		case 'ticketsum':
			return <TicketSummary {...others} />;
		default:
			return <div>In Progress...</div>;
	}
};

const ReportContainer = ({ dataCallbackFn }) => {
	const { i18n } = useTranslation('reportsUI');
	const { t } = useTranslation(['reports', 'events', 'reportsUI']);
	const viewParams = useSelector((state) => state.reports.viewParams);
	const reportDataInputParams = {
		reportId: viewParams.reportId,
		reportType: viewParams.reportType,
		section: 'all',
		page: 1,
		pageSize: 10
	};
	const classes = useStyles();
	const dispatch = useDispatch();
	const appData = window.APP_DATA || {};
	const isEngineer = appData?.auth?.isEngineer;
	const staticUrl = `${urls?.cdn}/portal/static` + (meta.staticVersion ? `/${meta.staticVersion}` : '');

	useEffect(() => {
		if (viewParams.showAckModal) {
			const data = {
				submitFn: (rows, modalUtils) => {
					modalUtils.setLoading(true);
					axios(EXECUTED_REPORT_URL, {
						method: 'PUT',
						data: {
							acknowledged: true
						},
						params: {
							reportIds: viewParams.reportId,
							reportName: viewParams.reportName
						}
					})
						.then(() => {
							modalUtils.setLoading(false);
							dataCallbackFn();
						})
						.catch(() => {
							dataCallbackFn();
						});
					modalUtils.handleClose();
				},
				title: t('reports:modals.ackModal.title'),
				body: (
					<Trans i18nKey="reports:modals.ackModal.body">
						<b />
					</Trans>
				),
				disableSubmitButton: isEngineer,
				cancelButton: t('reports:modals.ackModal.buttons.ackLater'),
				submitButton: t('reports:modals.ackModal.buttons.ackNow')
			};
			dispatch(showModal({ name: 'REPORTS_GENERIC_MODAL', data: data }));
		}
	}, [dispatch, t, viewParams, isEngineer, dataCallbackFn]);

	useEffect(() => {
		!viewParams.isViewed &&
			axios(EXECUTED_REPORT_URL, {
				method: 'PUT',
				data: {
					isViewed: true
				},
				params: {
					reportIds: viewParams.reportId
				}
			});
	}, [viewParams]);

	return (
		<>
			<div className={classes.reportName}> {viewParams?.reportName}</div>
			{viewParams.isOnlyIhub ? (
				<ReportPDFViewContainer viewParams={viewParams} t={t} locale={i18n.language} />
			) : (
				<div>
					<div className={classes.helpText}> {t('reports:viewReport.helpText')}</div>
					{viewParams.reportType && (
						<MapReportTypeToView
							reportType={viewParams.reportType}
							reportName={viewParams.reportName}
							dataParams={reportDataInputParams}
							t={i18n.getFixedT(null, 'reportsUI')}
							locale={i18n.language}
							expressDrillDownLimit={5000}
							staticUrl={staticUrl}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default ReportContainer;
