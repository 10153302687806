/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Fade, List } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCustomAngleDoubleLeft, faCustomAngleDoubleRight } from '../../../Icons/Angle';
import MenuList from './MenuList';
import MenuListTabs from './MenuListTabs';

const useStyles = makeStyles((theme) => ({
	drawer: {
		'flex': '0 1 auto',
		'position': 'relative',
		'zIndex': 10,
		'&:not($drawerStatic)': {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	},
	drawerOpen: {
		width: 280,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		width: 45,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	drawerStatic: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 'auto',
			border: 0,
			position: 'relative'
		}
	},
	drawerPaper: {
		overflowX: 'hidden',
		position: 'absolute',
		padding: theme.spacing(9, 3, 3, 3),
		backgroundColor: theme.palette.sidebar.background,
		borderColor: theme.palette.sidebar.border
	},
	drawerPaperStatic: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 'auto',
			borderRight: 0,
			borderBottom: `1px solid ${theme.palette.sidebar.border}`,
			position: 'relative',
			padding: theme.spacing(3, 1, 3, 1)
		}
	},
	drawerToggle: {
		'display': 'inline-block',
		'position': 'absolute',
		'top': theme.spacing(4),
		'right': theme.spacing(2),
		'padding': theme.spacing(0, 1),
		'color': theme.palette.sidebar.icon,
		'transition': theme.transitions.create('color'),
		'&:hover, &:focus': {
			color: theme.palette.sidebar.iconHover
		}
	},
	drawerToggleStatic: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	drawerToggleLeft: {
		right: 'inherit',
		left: theme.spacing(2.5)
	}
}));

const Sidebar = ({ navRoutes, tabRoutes, isStatic }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [open, setOpen] = useState(true);
	const [peek, setPeek] = useState(false);

	function handleDrawer(event) {
		event.preventDefault();

		event.currentTarget.blur();

		setOpen(!open);

		setPeek(false);
	}

	return (
		<Drawer
			open={open}
			variant="permanent"
			onMouseEnter={() => {
				if (!open && !peek) {
					setPeek(true);
				}
			}}
			onMouseLeave={() => {
				if (peek) {
					setPeek(false);
				}
			}}
			className={classNames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
				[classes.drawerStatic]: isStatic
			})}
			classes={{
				paper: classNames(classes.drawerPaper, {
					[classes.drawerOpen]: open || peek,
					[classes.drawerClose]: !open && !peek,
					[classes.drawerPaperStatic]: isStatic
				})
			}}
		>
			<a
				href="#"
				onClick={handleDrawer}
				className={classNames(classes.drawerToggle, {
					[classes.drawerToggleLeft]: !open && peek,
					[classes.drawerToggleStatic]: isStatic
				})}
				title={open ? t('common:buttons.collapse') : t('common:buttons.expand')}
			>
				<FontAwesomeIcon size="lg" icon={open ? faCustomAngleDoubleLeft : faCustomAngleDoubleRight} />
			</a>

			<Fade in={open || peek}>
				<List
					disablePadding
					component="nav"
					// "nav", and "nav-tabs" class names are used by e2e automation tests
					className={classNames('nav', {
						'nav-tabs': Boolean(tabRoutes)
					})}
				>
					{tabRoutes ? <MenuListTabs routes={tabRoutes} /> : null}
					{navRoutes ? <MenuList routes={navRoutes} /> : null}
				</List>
			</Fade>
		</Drawer>
	);
};

export default Sidebar;
