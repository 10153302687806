/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, List, useMediaQuery } from '@material-ui/core';
import { languages } from '../../../../../utils/i18n';
import { hasAccess } from '../../../../../utils/access';
import { toggleNav } from '../../../../../state/actions/components/nav';
import { changeLanguage } from '../../../../../state/actions/app/preferences';
import { headerResponsiveRoutes } from '../../../../../routes/components/header';

import Item from '../../../../Nav/Responsive/Item';
import QualtricsFeedback from '../../../../Qualtrics/Feedback';

import SearchBar from '../../Bar/Search';
import SelectInput from '../../../../Inputs/Select';
import ThemeSwitch from '../../../../Themes/Switch';

const navRoutes = headerResponsiveRoutes();

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: '100%',
		flexShrink: 0
	},
	drawerPaper: {
		width: '80%',
		border: 0,
		backgroundColor: theme.palette.headerNavResp.background,
		overflow: 'hidden'
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 3),
		justifyContent: 'flex-start',
		minHeight: theme.spacing(13),
		backgroundColor: theme.palette.headerBar.background,
		borderTop: `5px solid ${theme.palette.headerBar.background}`
	},
	list: {
		height: '100%',
		overflowY: 'auto',
		WebkitOverflowScrolling: 'touch'
	},
	itemTertiary: {
		'color': theme.palette.primary.light,
		'padding': theme.spacing(2.4),
		'fontSize': theme.typography.body2.fontSize,
		'&:last-child': {
			paddingTop: 0
		}
	},
	feedback: {
		'& a': {
			display: 'block',
			textDecoration: 'none',
			padding: theme.spacing(2.4),
			color: theme.palette.headerNavResp.secondary.level1.link,
			backgroundColor: theme.palette.headerNavResp.secondary.level1.background,
			borderBottom: `1px solid ${theme.palette.headerNavResp.secondary.level1.border}`,
			fontWeight: 700
		}
	}
}));

const HeaderNavResponsive = () => {
	const { t, i18n } = useTranslation('common');
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.preferences.language);
	const isLanguageLoading = useSelector((state) => state.preferences.isLoading);
	const isMenuOpen = useSelector((state) => state.nav.isOpen);
	const hasBasicAccess = hasAccess();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const currentYear = new Date().getFullYear();

	function closeNav() {
		dispatch(toggleNav({ isOpen: false }));
	}

	function handleLanguageChange(event) {
		dispatch(
			changeLanguage({
				language: event.target.value,
				i18n
			})
		);
	}

	useEffect(() => {
		// Handle Qualtrics Updates
		if (window.QSI && window.QSI.API) {
			window.QSI.API.load();
		}

		return function cleanup() {
			if (window.QSI && window.QSI.API) {
				window.QSI.API.unload();
			}
		};
		//eslint-disable-next-line
	}, [isMenuOpen, matches]);

	if (matches && isMenuOpen) {
		closeNav();
	}

	return (
		<Drawer
			anchor="right"
			open={isMenuOpen}
			onClose={closeNav}
			className={classes.drawer}
			elevation={0}
			variant="temporary"
			classes={{
				paper: classes.drawerPaper
			}}
			BackdropProps={{
				invisible: true
			}}
		>
			<div className={classes.drawerHeader}>
				{hasBasicAccess ? <SearchBar keepOpen={true} size="large" /> : null}
			</div>

			<List disablePadding className={classes.list}>
				{navRoutes.primary.map((route, idx) =>
					route ? <Item key={route.name || idx} route={route} isPrimary /> : null
				)}

				<Item
					key="qualtrics-general-feedback-mobile"
					route={{
						name: 'qualtrics-general-feedback-mobile',
						renderItem() {
							return (
								<QualtricsFeedback
									id="qualtrics-general-feedback-mobile"
									className={classes.feedback}
								/>
							);
						}
					}}
					isSecondary
				/>

				{navRoutes.secondary.map((route, idx) => {
					const menu = route && route.menu;
					if (menu && menu.items && menu.items.every((x) => !x)) {
						return null;
					}
					return route ? <Item key={route.name || idx} route={route} isSecondary /> : null;
				})}

				{hasBasicAccess ? (
					<li className={classes.itemTertiary}>
						<ThemeSwitch variant="dark" />
					</li>
				) : null}

				<li className={classes.itemTertiary}>
					<SelectInput
						label={t('labels.language')}
						value={language}
						options={languages}
						disabled={isLanguageLoading}
						onChange={handleLanguageChange}
					/>
				</li>

				<li className={classes.itemTertiary}>
					{t('legal.copyright', {
						currentYear
					})}
				</li>
			</List>
		</Drawer>
	);
};

export default HeaderNavResponsive;
