/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useTopTalkersDrillDown } from './useTopTalkersDrillDown';
import React from 'react';
import TopTalkersDrillDownContainer from '../../../../../containers/Reports/TopTalkersDrillDown';

/**
 * @param {object} fromChart - Data returned from Chart
 * @returns {JSX.Element}
 */
export const TopTalkersDrillDown = ({ fromChart, pivotTableData, reportName = '' }) => {
	const drillDownProps = useTopTalkersDrillDown(fromChart, reportName);
	return (
		<TopTalkersDrillDownContainer
			fromChart={fromChart}
			pivotTableData={pivotTableData}
			reportName={reportName}
			{...drillDownProps}
		/>
	);
};
