/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { TableCell, TableSortLabel } from '@material-ui/core';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
	tableHeadCell: {
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(2),
		'backgroundColor': theme.palette.table.cell.head.background,
		'color': theme.palette.table.cell.head.color,
		'fontSize': 13,
		'fontWeight': 700,
		'lineHeight': '22px',
		'borderRightWidth': 1,
		'borderRightColor': theme.palette.table.cell.body.border,
		'borderRightStyle': 'solid',
		'&:last-child': {
			borderRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	tableSortLabel: {
		'&:hover': {
			color: theme.palette.table.cell.head.color
		},
		'&:focus': {
			color: theme.palette.table.cell.head.color
		},
		'& svg': {
			color: `${theme.palette.table.cell.head.color} !important`
		}
	}
}));

export const PivotDataTableHeadCell = ({ column, onClick }) => {
	const classes = useStyle();
	return (
		<TableCell size={'small'} className={classes.tableHeadCell} onClick={onClick}>
			<TableSortLabel
				active={column.isSorted}
				direction={column.isSortedDesc ? 'desc' : 'asc'}
				hideSortIcon={true}
				className={classes.tableSortLabel}
			>
				{column?.headerTitle}
			</TableSortLabel>
		</TableCell>
	);
};
