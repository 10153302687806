/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { settingsColumns } from '../Controls';
import { ToggleColumns } from '../Controls/ToggleColumns';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'none',
		alignItems: 'center',
		position: 'relative',
		padding: theme.spacing(1, 2),
		color: theme.palette.table.cell.head.color,
		backgroundColor: theme.palette.table.cell.head.background,
		[theme.breakpoints.down('sm')]: {
			display: 'flex'
		}
	},
	title: {
		flexGrow: 1,
		textAlign: 'center',
		fontWeight: theme.typography.fontWeightBold
	},
	controls: {
		flexShrink: 0,
		paddingLeft: theme.spacing(2),
		borderLeft: `1px solid ${theme.palette.text.secondary}`
	}
}));

export const TableToolbar = ({ title, columns, isLoading, canToggleColumns }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			{title ? (
				<Typography variant="body1" component="p" className={classes.title}>
					{t(title)}
				</Typography>
			) : null}
			{canToggleColumns ? (
				<div className={classes.controls}>
					<ToggleColumns
						columns={columns}
						settingsColumns={settingsColumns}
						isLoading={isLoading}
						size="small"
					/>
				</div>
			) : null}
		</div>
	);
};
