/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const showAlert = ({ id, type = '', data = {} }) => ({
	type: SHOW_ALERT,
	payload: {
		id,
		type,
		data,
		show: true
	}
});

export const hideAlert = ({ id }) => ({
	type: HIDE_ALERT,
	payload: {
		id,
		show: false
	}
});
