/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faExclamationTriangle,
	faExclamationCircle,
	faCheckCircle,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

const useAlertStyles = makeStyles((theme) => ({
	/* Styles applied to the root element. */
	root: {
		...theme.typography.body2,
		'borderRadius': 0,
		'backgroundColor': 'transparent',
		'display': 'flex',
		'padding': '5px 15px',
		'& .MuiLink-button:hover': {
			textDecoration: 'none'
		}
	},
	/* Styles applied to the root element if `variant="standard"` and `color="success"`. */
	standardSuccess: {
		'color': theme.palette.status.success.color,
		'backgroundColor': theme.palette.status.success.background,
		'& $icon': {
			color: theme.palette.status.success.color
		}
	},
	/* Styles applied to the root element if `variant="standard"` and `color="info"`. */
	standardInfo: {
		'color': theme.palette.status.info.color,
		'backgroundColor': theme.palette.status.info.background,
		'& $icon': {
			color: theme.palette.status.info.color
		}
	},
	/* Styles applied to the root element if `variant="standard"` and `color="warning"`. */
	standardWarning: {
		'color': theme.palette.status.warning.color,
		'backgroundColor': theme.palette.status.warning.background,
		'& $icon': {
			color: theme.palette.status.warning.color
		}
	},
	/* Styles applied to the root element if `variant="standard"` and `color="error"`. */
	standardError: {
		'color': theme.palette.status.error.color,
		'backgroundColor': theme.palette.status.error.background,
		'& $icon': {
			color: theme.palette.status.error.color
		}
	},
	/* Styles applied to the root element if `variant="outlined"` and `color="success"`. */
	outlinedSuccess: {
		'color': theme.palette.status.success.color,
		'border': `1px solid ${theme.palette.status.success.border}`,
		'backgroundColor': theme.palette.status.success.background,
		'& $icon': {
			color: theme.palette.status.success.color
		}
	},
	/* Styles applied to the root element if `variant="outlined"` and `color="info"`. */
	outlinedInfo: {
		'color': theme.palette.status.info.color,
		'border': `1px solid ${theme.palette.status.info.border}`,
		'backgroundColor': theme.palette.status.info.background,
		'& $icon': {
			color: theme.palette.status.info.color
		},
		'& a, & .MuiLink-button': {
			'color': theme.palette.status.info.outlined.link,
			'&:hover': {
				color: theme.palette.link.colorHover
			}
		}
	},
	/* Styles applied to the root element if `variant="outlined"` and `color="warning"`. */
	outlinedWarning: {
		'color': theme.palette.status.warning.color,
		'border': `1px solid ${theme.palette.status.warning.border}`,
		'backgroundColor': theme.palette.status.warning.background,
		'& $icon': {
			color: theme.palette.status.warning.color
		}
	},
	/* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
	outlinedError: {
		'color': theme.palette.status.error.color,
		'border': `1px solid ${theme.palette.status.error.border}`,
		'backgroundColor': theme.palette.status.error.background,
		'& $icon': {
			color: theme.palette.status.error.color
		}
	},
	/* Styles applied to the root element if `variant="filled"` and `color="success"`. */
	filledSuccess: {
		color: '#fff',
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.status.success.border
	},
	/* Styles applied to the root element if `variant="filled"` and `color="info"`. */
	filledInfo: {
		color: '#fff',
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.status.info.border
	},
	/* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
	filledWarning: {
		color: '#fff',
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: theme.palette.status.warning.border
	},
	/* Styles applied to the root element if `variant="filled"` and `color="error"`. */
	filledError: {
		'color': '#fff',
		'fontWeight': theme.typography.fontWeightMedium,
		'backgroundColor': theme.palette.status.error.border,
		'& a, & .MuiLink-button': {
			'color': '#fff',
			'&:hover': {
				color: '#fff4cf'
			}
		},
		'& .MuiIconButton-root:hover': {
			color: '#d1d1d1'
		}
	},
	/* Styles applied to the icon wrapper element. */
	icon: {
		marginRight: 6,
		padding: '4px 0',
		display: 'flex',
		fontSize: 22,
		opacity: 1
	},
	/* Styles applied to the message wrapper element. */
	message: {
		padding: '5px 0',
		width: '100%'
	},
	/* Styles applied to the action wrapper element if `action` is provided. */
	action: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		paddingLeft: 15,
		marginRight: -5
	},
	removeSideBorders: {
		borderLeft: 0,
		borderRight: 0
	},
	gutterBottomClass: {
		marginBottom: theme.spacing(2)
	},
	gutterTopClass: {
		marginTop: theme.spacing(2)
	}
}));

const AlertCustom = (props) => {
	const { noSideBorders, icon, gutterTop, gutterBottom, ...other } = props;
	const { removeSideBorders, gutterBottomClass, gutterTopClass, ...classes } = useAlertStyles();

	const iconMap = {
		info: faInfoCircle,
		error: faExclamationCircle,
		success: faCheckCircle,
		warning: faExclamationTriangle
	};
	const displayIcon = icon ? (
		icon
	) : iconMap[props.severity] ? (
		<FontAwesomeIcon size="sm" icon={iconMap[props.severity]} />
	) : (
		<FontAwesomeIcon size="sm" icon={faInfoCircle} />
	);

	return (
		<Alert
			className={classNames({
				[removeSideBorders]: noSideBorders,
				[gutterBottomClass]: gutterBottom,
				[gutterTopClass]: gutterTop
			})}
			classes={classes}
			icon={icon ? icon : displayIcon}
			{...other}
		/>
	);
};

AlertCustom.defaultProps = {
	noSideBorders: false,
	gutterBottom: false,
	gutterTop: false
};

AlertCustom.propTypes = {
	noSideBorders: PropTypes.bool,
	gutterBottom: PropTypes.bool,
	gutterTop: PropTypes.bool
};

export { AlertCustom };

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 2)
	},
	error: {
		color: theme.palette.status.error.color,
		border: `1px solid ${theme.palette.status.error.border}`,
		backgroundColor: theme.palette.status.error.background
	},
	info: {
		color: theme.palette.status.info.color,
		border: `1px solid ${theme.palette.status.info.border}`,
		backgroundColor: theme.palette.status.info.background
	},
	success: {
		color: theme.palette.status.success.color,
		border: `1px solid ${theme.palette.status.success.border}`,
		backgroundColor: theme.palette.status.success.background
	},
	warning: {
		color: theme.palette.status.warning.color,
		border: `1px solid ${theme.palette.status.warning.border}`,
		backgroundColor: theme.palette.status.warning.background
	},
	gutterBottom: {
		marginBottom: theme.spacing(2)
	},
	gutterTop: {
		marginTop: theme.spacing(2)
	}
}));

function AlertComponent(props) {
	const classes = useStyles();
	const { children, elevation = 0, variant, gutterBottom, gutterTop, ...other } = props;

	return (
		<SnackbarContent
			elevation={elevation}
			className={classNames(classes.root, classes[variant], {
				[classes.gutterBottom]: gutterBottom,
				[classes.gutterTop]: gutterTop
			})}
			{...other}
		>
			{children}
		</SnackbarContent>
	);
}

AlertComponent.propTypes = {
	className: PropTypes.string,
	elevation: PropTypes.number,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
	gutterBottom: PropTypes.bool,
	gutterTop: PropTypes.bool
};

export default AlertComponent;
