/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */

/**
 * Custom icon for use with FontAwesomeIcon.
 */
export const faCustomAngleDoubleRight = {
	prefix: 'fas',
	iconName: 'customAngleDoubleRight',
	icon: [
		8, // viewBox width
		15, // viewBox height
		[],
		'f0000', // default color
		'M4.98 8.036c0 0.067-0.033 0.142-0.084 0.193l-3.901 3.901c-0.050 0.050-0.126 0.084-0.193 0.084s-0.142-0.033-0.193-0.084l-0.419-0.419c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l3.29-3.29-3.29-3.29c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l0.419-0.419c0.050-0.050 0.126-0.084 0.193-0.084s0.142 0.033 0.193 0.084l3.901 3.901c0.050 0.050 0.084 0.126 0.084 0.193zM8.195 8.036c0 0.067-0.033 0.142-0.084 0.193l-3.901 3.901c-0.050 0.050-0.126 0.084-0.193 0.084s-0.142-0.033-0.193-0.084l-0.419-0.419c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l3.29-3.29-3.29-3.29c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l0.419-0.419c0.050-0.050 0.126-0.084 0.193-0.084s0.142 0.033 0.193 0.084l3.901 3.901c0.050 0.050 0.084 0.126 0.084 0.193z' // SVG path d value
	]
};

/**
 * Custom icon for use with FontAwesomeIcon.
 */
export const faCustomAngleDoubleLeft = {
	prefix: 'fas',
	iconName: 'customAngleDoubleLeft',
	icon: [
		9, // viewBox width
		15, // viewBox height
		[],
		'f0000', // default color
		'M5.248 11.518c0 0.067-0.033 0.142-0.084 0.193l-0.419 0.419c-0.050 0.050-0.126 0.084-0.193 0.084s-0.142-0.033-0.193-0.084l-3.901-3.901c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l3.901-3.901c0.050-0.050 0.126-0.084 0.193-0.084s0.142 0.033 0.193 0.084l0.419 0.419c0.050 0.050 0.084 0.126 0.084 0.193s-0.033 0.142-0.084 0.193l-3.29 3.29 3.29 3.29c0.050 0.050 0.084 0.126 0.084 0.193zM8.463 11.518c0 0.067-0.033 0.142-0.084 0.193l-0.419 0.419c-0.050 0.050-0.126 0.084-0.193 0.084s-0.142-0.033-0.193-0.084l-3.901-3.901c-0.050-0.050-0.084-0.126-0.084-0.193s0.033-0.142 0.084-0.193l3.901-3.901c0.050-0.050 0.126-0.084 0.193-0.084s0.142 0.033 0.193 0.084l0.419 0.419c0.050 0.050 0.084 0.126 0.084 0.193s-0.033 0.142-0.084 0.193l-3.29 3.29 3.29 3.29c0.050 0.050 0.084 0.126 0.084 0.193z' // SVG path d value
	]
};
