/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../Inputs/Dropdown';
import DropdownItem from '../../../../Inputs/Dropdown/Item';
import DropdownTitle from '../../../../Inputs/Dropdown/Title';

const getHref = (path) => (Array.isArray(path) ? path[0] : path ? path : '#');

const NavMenu = ({ menu, elRef, isOpen, closeMenu, placement }) => {
	const { t } = useTranslation('common');
	const { items, title } = menu;

	const menuItems = items.filter((item) => {
		return !!item;
	});

	return (
		<Dropdown anchorEl={elRef} isOpen={isOpen} placement={placement}>
			{title && <DropdownTitle title={title} />}
			{menuItems.map((item) =>
				item.renderItem ? (
					item.renderItem()
				) : (
					<DropdownItem
						key={item.name}
						href={getHref(item.path)}
						onClick={(event) => {
							if (item.onClick) {
								item.onClick(event);
							}

							closeMenu();
						}}
						{...(item.isExternal && {
							target: '_blank',
							rel: 'noopener'
						})}
					>
						{t(item.name)}
					</DropdownItem>
				)
			)}
		</Dropdown>
	);
};

export default NavMenu;
