/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { checkRouteIfPublic } from '../../utils/access';
import $ from 'jquery';

/**
 * Qualtrics tracker script.
 *
 * You probably shouldn't edit this file...
 */

/* eslint-disable */
var QualtricsTracker = function (e, h, f, g) {
	this.get = function (a) {
		for (var a = a + '=', c = document.cookie.split(';'), b = 0, e = c.length; b < e; b++) {
			for (var d = c[b]; ' ' == d.charAt(0); ) d = d.substring(1, d.length);
			if (0 == d.indexOf(a)) return d.substring(a.length, d.length);
		}
		return null;
	};
	this.set = function (a, c) {
		var b = '',
			b = new Date();
		b.setTime(b.getTime() + 6048e5);
		b = '; expires=' + b.toGMTString();
		document.cookie = a + '=' + c + b + '; path=/; ';
	};
	this.check = function () {
		var a = this.get(f);
		if (a) a = a.split(':');
		else if (100 != e)
			'v' == h && (e = Math.random() >= e / 100 ? 0 : 100), (a = [h, e, 0]), this.set(f, a.join(':'));
		else return !0;
		var c = a[1];
		if (100 == c) return !0;
		switch (a[0]) {
			case 'v':
				return !1;
			case 'r':
				return (c = a[2] % Math.floor(100 / c)), a[2]++, this.set(f, a.join(':')), !c;
		}
		return !0;
	};
	this.go = function () {
		if (this.check()) {
			var a = document.createElement('script');
			a.type = 'text/javascript';
			a.src = g;
			document.body && document.body.appendChild(a);
		}
	};
	this.start = function () {
		var t = this;
		'complete' !== document.readyState
			? window.addEventListener
				? window.addEventListener(
						'load',
						function () {
							t.go();
						},
						!1
				  )
				: window.attachEvent &&
				  window.attachEvent('onload', function () {
						t.go();
				  })
			: t.go();

		const fixCSP = function () {
			$('.QSILink')
				.attr('href', '#')
				.on('click', function (e) {
					e.preventDefault();
					return false;
				});
		};

		setTimeout(fixCSP, 4000);
	};
};
/* eslint-enable */

export const initQualtrics = () => {
	try {
		new QualtricsTracker(
			100,
			'r',
			'QSI_S_ZN_50yBFpkMWDw146F',
			// add `&Q_DEBUG` locally to enable debug panel
			'https://zn50ybfpkmwdw146f-secureworks.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_50yBFpkMWDw146F&Q_LOC=' +
				encodeURIComponent(window.location.href)
		).start();
	} catch (i) {
		throw new Error(i);
	}
};

export const useQualtrics = () => {
	const clientId = useSelector((state) => state.client.id);
	const contactId = useSelector((state) => state.contact.id);
	const isPublicRoute = checkRouteIfPublic();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	useEffect(() => {
		if (isAuthenticated && !isPublicRoute) {
			// These are used by Qualtrics tracker
			window.portal = window.portal || {
				contactId: contactId,
				contactClientId: clientId
			};

			if (!window.QSI) {
				initQualtrics();
			}
		}
	}, [clientId, contactId, isAuthenticated, isPublicRoute]);
};
