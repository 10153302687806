/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, MenuList } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: {
		'padding': '2px 10px 2px 10px',
		'border': `1px solid ${theme.palette.table.cell.head.dropdown.border}`,
		'backgroundColor': theme.palette.common.blueDark,
		'fontSize': '15px',
		'&&:hover': {
			backgroundColor: theme.palette.common.blue
		},
		'color': theme.palette.common.white
	},
	dropdownIcon: {
		marginLeft: theme.spacing(1)
	},
	dropdownMenuContainer: {
		border: `1px solid ${theme.palette.table.cell.head.dropdown.border}`
	},
	dropdownMenuItem: {
		'&&:hover': {
			backgroundColor: theme.palette.common.blue,
			color: theme.palette.common.white
		}
	},
	popper: {
		zIndex: '1'
	}
}));

export const ExportControl = ({ exportFormats, exportFunction }) => {
	const classes = useStyles();
	const { t } = useTranslation(['events']);

	const buttonRef = createRef();
	const [elRef, setElRef] = useState(null);

	const handleMenuItemClick = (option) => {
		exportFunction(option);

		setElRef(null);
	};

	const handleToggle = () => {
		setElRef(elRef ? null : buttonRef.current);
	};

	const handleClose = () => {
		setElRef(null);
	};

	return (
		<>
			<Button
				color="default"
				size="small"
				variant="contained"
				ref={buttonRef}
				className={classes.button}
				aria-expanded={elRef ? 'true' : undefined}
				aria-haspopup="menu"
				onClick={handleToggle}
			>
				{t('labels.export')}
				<FontAwesomeIcon icon={faCaretDown} className={classes.dropdownIcon} />
			</Button>
			<Popper
				className={classes.popper}
				open={Boolean(elRef)}
				anchorEl={elRef}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper className={classes.dropdownMenuContainer}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{exportFormats.map((option) => (
										<MenuItem
											className={classes.dropdownMenuItem}
											key={option}
											onClick={() => handleMenuItemClick(option)}
										>
											{t('links.' + option)}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
