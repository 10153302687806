/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { get } from 'axios';
import { batch } from 'react-redux';
import { definitions, nodeSchema } from '../../../containers/DataCube/utils';

export const UPDATE_DATACUBE_DATA = 'UPDATE_DATACUBE_DATA';
export const UPDATE_DATACUBE_STATUS = 'UPDATE_DATACUBE_STATUS';
export const UPDATE_DATACUBE_FILTERS = 'UPDATE_DATACUBE_FILTERS';
export const RESET_DATACUBE_FILTERS = 'RESET_DATACUBE_FILTERS';
export const UPDATE_DATACUBE_EXPANDED = 'UPDATE_DATACUBE_EXPANDED';
export const UPDATE_DATACUBE_SUMMARY = 'UPDATE_DATACUBE_SUMMARY';
export const UPDATE_DATACUBE_ROTATION = 'UPDATE_DATACUBE_ROTATION';
export const UPDATE_DATACUBE_DOWNLOAD_PATH = 'UPDATE_DATACUBE_DOWNLOAD_PATH';

/**
 * Mutates a copy of current `data` state by inserting new `children` data
 * at specified `id` level.
 *
 * @param {Array} data - Current data within the store.
 * @param {Array} children - New data to update specific entry.
 * @param {String} id - ID where to insert new data children.
 */
const updateData = (data, children, id) => {
	if (data && data.length) {
		data.forEach((node) => {
			if (node.id === id) {
				node.children = children;
			} else if (node.children) {
				updateData(node.children, children, id);
			}
		});
	}
};

const formatDataItem = async (item) => {
	const error = await nodeSchema
		.validate(item)
		.then(() => false)
		.catch((err) => err);

	if (error) {
		if (console && console.warn) {
			console.warn(`"${item.id}" node was removed; ${error.message}`);
		}
		// mark entry for removal
		return null;
	}

	if (item.children) {
		item.children = await formatData(item.children);
	}

	return { ...item, options: definitions[item.type] };
};

/**
 * Creates a new copy of `data` with extended static options
 * for each data item.
 *
 * @param {Array} data - Data to format.
 * @returns {Array} Data with extended options.
 */
const formatData = async (data) =>
	Promise.all(data.map((item) => formatDataItem(item))).then((data) => data.filter(Boolean));

/**
 * An action creator to update data state.
 *
 * @param {Object} config - Configuration options.
 * @param {Object} config.data - New data value.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeData = ({ data = {} }) => ({
	type: UPDATE_DATACUBE_DATA,
	payload: {
		data
	}
});

/**
 * An action creator to update fetch status state.
 *
 * @param {Object} config - Configuration options.
 * @param {String} config.id - New data value.
 * @param {Boolean} config.isError - Whether fetch returned error state.
 * @param {Boolean} config.isLoaded - Whether fetch successfully finished.
 * @param {Boolean} config.isLoading - Whether fetch is in progress state.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeStatus = ({ id, isError, isLoaded, isLoading }) => ({
	type: UPDATE_DATACUBE_STATUS,
	payload: {
		[id]: {
			isError,
			isLoaded,
			isLoading
		}
	}
});

/**
 * An action creator to update applied filters.
 *
 * @param {Object} config - Configuration options.
 * @param {String} config.id - Node group's ID.
 * @param {Object} config.options - Applied filter options.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeFilters = ({ id, ...state }) => ({
	type: UPDATE_DATACUBE_FILTERS,
	payload: {
		id,
		state
	}
});

/**
 * An action creator to reset applied filters.
 *
 * @param {Object} config - Configuration options.
 * @param {String} config.id - Node group's ID.
 *
 * @returns {Object} A composed action creator.
 */
export const resetDataCubeFilters = ({ id, ...state }) => ({
	type: RESET_DATACUBE_FILTERS,
	payload: {
		id,
		state
	}
});

/**
 * An action creator to update expanded state.
 *
 * @param {Object} config - Configuration options.
 * @param {Object} config.expanded - New expanded node entry.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeExpanded = ({ expanded }) => ({
	type: UPDATE_DATACUBE_EXPANDED,
	payload: {
		expanded
	}
});

/**
 * An action creator to update summary state.
 *
 * @param {Object} config - Configuration options.
 * @param {Object} config.summary - New summary node to display.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeSummary = ({ summary }) => ({
	type: UPDATE_DATACUBE_SUMMARY,
	payload: {
		summary
	}
});

/**
 * An action creator to circle rotation state.
 *
 * @param {Object} config - Configuration options.
 * @param {Number} config.rotateAngle - New rotation angle.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDataCubeRotation = ({ rotateAngle }) => ({
	type: UPDATE_DATACUBE_ROTATION,
	payload: {
		rotateAngle
	}
});

/**
 * An action creator to update the download path state.
 *
 * @param {Object} config - Configuration options.
 * @param {Object} config.downloadPath - new download path.
 *
 * @returns {Object} A composed action creator.
 */
export const updateDownloadPath = ({ downloadPath }) => ({
	type: UPDATE_DATACUBE_DOWNLOAD_PATH,
	payload: {
		downloadPath
	}
});

/**
 * A thunk action creator to fetch initial data set.
 *
 * @param {Object} config - Configuration options.
 * @param {String} config.url - A URL to fetch data from.
 *
 * @returns {Promise} A thunk function.
 */
export function fetchDataCubeData({ url }) {
	// `dispatch` and `getState` arguments
	// are passed on by redux-thunk midleware.
	return (dispatch) => {
		batch(() => {
			dispatch(
				updateDataCubeStatus({
					id: 'root',
					isError: false,
					isLoaded: false,
					isLoading: true
				})
			);
			updateDataCubeExpanded({ expanded: {} });
			updateDataCubeSummary({ summary: null });
		});

		// returns promise to allow us to call
		// .then() on async dispatch result.
		return get(url)
			.then(({ data }) =>
				formatData([data]).then((data) => {
					const result = data[0];
					const hasData = !!result;

					return batch(() => {
						dispatch(
							updateDataCubeData({
								data: result
							})
						);
						dispatch(
							updateDataCubeStatus({
								id: 'root',
								isError: !hasData,
								isLoaded: hasData,
								isLoading: false
							})
						);
					});
				})
			)
			.catch((error) => {
				if (console && console.warn) {
					// capture errors to assist debugging,
					// without breaking UI.
					console.warn(error);
				}

				return dispatch(
					updateDataCubeStatus({
						id: 'root',
						isError: true,
						isLoaded: false,
						isLoading: false
					})
				);
			});
	};
}

/**
 * A thunk action creator to fetch nested data sets.
 * Mutates `data` collection in place of a given `id` with fetched results.
 *
 * @param {Object} config - Configuration options.
 * @param {String} config.id - An identifier within initial data set.
 * @param {String} config.url - A URL to fetch data from.
 *
 * @returns {Promise} A thunk function.
 */
export function fetchDataCubeFurtherData({ id, url }) {
	// `dispatch` and `getState` arguments
	// are passed on by redux-thunk midleware.
	return (dispatch, getState) => {
		dispatch(
			updateDataCubeStatus({
				id,
				isError: false,
				isLoaded: false,
				isLoading: true
			})
		);
		// returns promise to allow us to call
		// .then() on async dispatch result.
		return get(url)
			.then(({ data }) =>
				formatData(data.children).then((children) => {
					const { datacube } = getState();
					const newDataCopy = { ...datacube.data };

					updateData(newDataCopy.children, children, id);

					batch(() => {
						dispatch(updateDataCubeData({ data: newDataCopy }));
						dispatch(
							updateDataCubeStatus({
								id,
								isError: false,
								isLoaded: true,
								isLoading: false
							})
						);
					});
				})
			)
			.catch((error) => {
				if (console && console.warn) {
					// capture errors to assist debugging,
					// without breaking UI.
					console.warn(error);
				}

				return dispatch(
					updateDataCubeStatus({
						id,
						isError: true,
						isLoaded: false,
						isLoading: false
					})
				);
			});
	};
}
