/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviousMessages } from '../../../../state/actions/components/chat';
import { BaseView, BaseViewContent, BaseViewFooter, BaseViewHeader } from '../Base';

import ConverstationViewEnd from './End';
import ConverstationViewForm from './Form';
import ConverstationViewMessages from './Messages';

const ConverstationView = () => {
	const progress = useSelector((state) => state.chat.progress);
	const dispatch = useDispatch();

	useEffect(() => {
		if (progress === 'acceptedByAgent') {
			dispatch(getPreviousMessages());
		}
	}, [dispatch, progress]);

	return (
		<BaseView className="sw-chat-view-conversation">
			<BaseViewHeader />
			<BaseViewContent>
				<ConverstationViewMessages />
			</BaseViewContent>
			<BaseViewFooter>
				{progress === 'acceptedByAgent' ? <ConverstationViewForm /> : <ConverstationViewEnd />}
			</BaseViewFooter>
		</BaseView>
	);
};

export default ConverstationView;
