/**
 * Classification': '//SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import darkTheme from '../Dark';
import lightTheme from '../Light';

/**
 * Returns theme based on user preferences.
 *
 * @returns {Object} Current theme
 */
const useCurrentTheme = () => {
	const themeValue = useSelector((state) => state.preferences.theme.preferenceValue);

	return useMemo(() => (themeValue === 'dark-theme' ? darkTheme : lightTheme), [themeValue]);
};

export default useCurrentTheme;
