/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { SHOW_MODAL, HIDE_MODAL } from '../../actions/components/modals';

const initialState = {
	queue: {},
	limit: 1
};

export default function modalsReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				...state,
				queue: {
					...state.queue,
					[action.payload.name]: {
						...state.queue[action.payload.name],
						data: action.payload.data,
						name: action.payload.name,
						show: action.payload.show,
						priority: action.payload.priority
					}
				}
			};
		case HIDE_MODAL:
			return {
				...state,
				queue: {
					...state.queue,
					[action.payload.name]: {
						...state.queue[action.payload.name],
						...action.payload
					}
				}
			};
		default:
			return state;
	}
}
