/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
	headerMenuRoot: {
		display: 'flex'
	},
	paper: {
		marginRight: theme.spacing(2)
	},
	levelOneMenu: {
		display: 'inline-block',
		marginLeft: theme.spacing(2)
	},
	link: {
		fontSize: 15,
		lineHeight: '22px',
		color: theme.palette.link.color
	},
	subMenuItem: {
		'borderBottom': `1px solid ${theme.palette.widgetDrillDown.menu.border}`,
		'padding': 12,
		'textAlign': 'center',
		'lineHeight': '22px',
		'width': 'auto',
		'display': 'block',
		'&:hover': {
			backgroundColor: theme.palette.widgetDrillDown.menu.hoverColor,
			color: theme.palette.common.white
		}
	},
	subMenuList: {
		border: `1px solid ${theme.palette.widgetDrillDown.menu.border}`,
		paddingTop: 0,
		paddingBottom: 0,
		marginTop: theme.spacing(1)
	},
	subMenuListArrow: {
		width: '3em',
		height: '3em',
		position: 'absolute',
		fontSize: 4,
		top: -7,
		transform: 'rotate(45deg)',
		left: '40%',
		borderLeft: `1px solid ${theme.palette.widgetDrillDown.menu.border}`,
		borderTop: `1px solid ${theme.palette.widgetDrillDown.menu.border}`
	},
	dropDownSubMenuList: {
		'paddingTop': theme.spacing(0),
		'minWidth': 160,
		'paddingBottom': theme.spacing(1),
		'marginTop': '5px',
		'border': '1px solid #b5b5b5',
		'color': 'inherit !important',
		'borderColor': theme.palette.table.cell.head.dropdown.border,
		'borderRadius': theme.spacing(1),
		'& + span': {
			'&:before': {
				borderBottomColor: `${theme.palette.table.cell.head.dropdown.title.background} !important`
			}
		}
	},
	dropDownArrow: {
		width: '2em',
		height: '2em',
		position: 'absolute',
		fontSize: 6,
		top: -5,
		right: 5,
		transform: 'rotate(45deg)',
		borderLeft: `1px solid ${theme.palette.table.cell.head.dropdown.title.background}`,
		borderTop: `1px solid ${theme.palette.table.cell.head.dropdown.title.background}`,
		borderWidth: '6px'
	},
	dropDownSubMenuItem: {
		'paddingTop': 3,
		'paddingBottom': 3,
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(1),
		'color': theme.palette.table.cell.head.dropdown.dropdownItem.color,
		'&:hover': {
			color: theme.palette.common.white,
			backgroundColor: `${theme.palette.table.cell.head.dropdown.dropdownItem.backgroundActive} !important`
		}
	},
	dropdownHeading: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.table.cell.head.dropdown.title.background,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		paddingTop: '2px',
		marginBottom: 4,
		textTransform: 'uppercase'
	},
	dropdownHeadingBtn: {
		fontSize: 'inherit'
	}
}));

function TableHeaderMenus({ menus, children, viewType = 'basic', placement = 'bottom' }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [opens, setOpens] = React.useState(new Array(menus.length));
	const anchorRefs = React.useRef({});

	const handleOpen = (index) => {
		const opensCopy = new Array(menus.length);
		opensCopy[index] = true;
		setOpens(opensCopy);
	};

	const handleClose = (event, index) => {
		if (anchorRefs.current && anchorRefs.current[index] && anchorRefs.current[index].contains(event.target)) {
			return;
		}

		opens[index] = false;
		setOpens([...opens]);
	};

	return (
		<div className={classes.headerMenuRoot}>
			{menus.map((menu, index) => {
				const actions = {};
				if (viewType === 'advanced') {
					actions.onClick = () => {
						handleOpen(index);
					};
				} else {
					actions.onMouseEnter = () => {
						handleOpen(index);
					};
				}

				return (
					<div
						key={'menu_' + index}
						className={classes.levelOneMenu}
						onMouseLeave={() => {
							handleClose(index);
						}}
					>
						<Link
							className={classes.link}
							ref={(element) => (anchorRefs.current[index] = element)}
							component="button"
							variant="body2"
							aria-controls={opens[index] ? 'menu-list-grow' + index : undefined}
							aria-haspopup="true"
							{...actions}
						>
							{children ? children : menu.title}
						</Link>
						{menu.subMenus.length && (
							<Popper
								open={opens[index] ? true : false}
								anchorEl={anchorRefs.current[index]}
								role={undefined}
								transition
								disablePortal
								placement={placement}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
										}}
									>
										<Paper>
											<ClickAwayListener
												onClickAway={(event) => {
													handleClose(event, index);
												}}
											>
												{viewType === 'basic' ? (
													<MenuList
														autoFocusItem={opens[index]}
														id={'menu-list-grow' + index}
														className={classes.subMenuList}
													>
														<span className={classes.subMenuListArrow} />
														{menu.subMenus.map((subMenu, subMenuIndex) => {
															return (
																<MenuItem
																	title={subMenu.tooltip}
																	key={'subMenu_' + subMenuIndex}
																	onClick={(event) => {
																		handleClose(event, index);
																		subMenu.onClick && subMenu.onClick();
																	}}
																	className={classes.subMenuItem}
																>
																	{subMenu.title}
																</MenuItem>
															);
														})}
													</MenuList>
												) : (
													<MenuList
														autoFocusItem={opens[index]}
														id={'menu-list-grow' + index}
														className={classes.dropDownSubMenuList}
													>
														<span className={classes.dropDownArrow} />
														<Box pb={0.3} className={classes.dropdownHeading}>
															<Typography variant={'body1'} component={'span'}>
																{t('events:links.download')}
															</Typography>
															<IconButton
																aria-label="Close"
																component="span"
																size={'small'}
																color={'inherit'}
																onClick={(event) => {
																	handleClose(event, index);
																}}
																className={classes.dropdownHeadingBtn}
															>
																<FontAwesomeIcon icon={faTimes} />
															</IconButton>
														</Box>
														{menu.subMenus.map((subMenu, subMenuIndex) => {
															return (
																<MenuItem
																	title={subMenu.tooltip}
																	key={'subMenu_' + subMenuIndex}
																	onClick={(event) => {
																		handleClose(event, index);
																		subMenu.onClick && subMenu.onClick();
																	}}
																	className={classes.dropDownSubMenuItem}
																>
																	{subMenu.title}
																</MenuItem>
															);
														})}
													</MenuList>
												)}
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						)}
					</div>
				);
			})}
		</div>
	);
}
export default TableHeaderMenus;
