/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { get } from 'axios';
import { batch } from 'react-redux';

export const ADD_WIDGET = 'ADD_WIDGET';

export const UPDATE_WIDGET_DATA = 'UPDATE_WIDGET_DATA';
export const UPDATE_WIDGET_PARAMS = 'UPDATE_WIDGET_PARAMS';
export const UPDATE_WIDGET_PROGRESS = 'UPDATE_WIDGET_PROGRESS';
export const UPDATE_WIDGET_MENU = 'UPDATE_WIDGET_MENU';
export const UPDATE_WIDGET_SETTINGS = 'UPDATE_WIDGET_SETTINGS';

const defaultWidgetOptions = {
	url: null,
	data: {},
	params: {},
	settings: {},
	isError: false,
	isLoading: true,
	isMenuOpen: false,
	isSettingsOpen: false
};

export const addWidget = ({ name, options = {} }) => ({
	type: ADD_WIDGET,
	payload: {
		[name]: {
			...defaultWidgetOptions,
			...options
		}
	}
});

export const updateWidgetData = ({ name, data = {} }) => ({
	type: UPDATE_WIDGET_DATA,
	payload: {
		name,
		data
	}
});

export const updateWidgetParams = ({ name, params = {} }) => ({
	type: UPDATE_WIDGET_PARAMS,
	payload: {
		name,
		params
	}
});

export const updateWidgetProgress = ({ name, isError, isLoading }) => ({
	type: UPDATE_WIDGET_PROGRESS,
	payload: {
		name,
		isError,
		isLoading,
		isMenuOpen: false,
		isSettingsOpen: false
	}
});

export const updateWidgetMenu = ({ name, isMenuOpen }) => ({
	type: UPDATE_WIDGET_MENU,
	payload: {
		name,
		isMenuOpen
	}
});

export const updateWidgetSettings = ({ name, settings, isSettingsOpen }) => ({
	type: UPDATE_WIDGET_SETTINGS,
	payload: {
		name,
		settings,
		isSettingsOpen
	}
});

export function createWidget({ name, options }) {
	return (dispatch) => {
		dispatch(addWidget({ name, options }));
		dispatch(fetchWidgetData({ name }));
	};
}

export function fetchWidgetData({ name }) {
	return (dispatch, getState) => {
		const { widgets } = getState();
		const { url, params = {}, settings = {} } = widgets[name] || {};

		if (settings.timeRange) {
			const fromDate = new Date();
			const toDate = fromDate.toISOString();

			fromDate.setDate(fromDate.getDate() - settings.timeRange);

			params.toDate = toDate;
			params.fromDate = fromDate.toISOString();
		}

		dispatch(
			updateWidgetProgress({
				name,
				isError: false,
				isLoading: true
			})
		);

		return get(url, { params })
			.then(({ data }) => {
				if (Array.isArray(data)) {
					data = {
						elements: data
					};
				}

				batch(() => {
					dispatch(
						updateWidgetData({
							name,
							data: data
						})
					);
					dispatch(
						updateWidgetProgress({
							name,
							isError: false,
							isLoading: false
						})
					);
				});
			})
			.catch((error) => {
				dispatch(
					updateWidgetProgress({
						name,
						isError: true,
						isLoading: false
					})
				);
				throw new Error(error.message);
			});
	};
}
