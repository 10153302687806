/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */

/**
 * Bootstrap data from server.
 */
const APP_DATA = window.APP_DATA || {};
const APP_DATA_ACCESS = APP_DATA.access || {};

const auth = {
	...{
		request: null,
		isError: false,
		isAuthenticated: false,
		isMultiClient: false,
		isEngineer: false,
		isLoggedIn: false,
		isFFIEC: false
	},
	...APP_DATA.auth
};

const client = {
	...{
		id: null,
		name: null,
		href: null,
		locationRefs: null
	},
	...APP_DATA.client
};

const contact = {
	...{
		id: null,
		name: null,
		href: null,
		email: null,
		sourceEmail: null
	},
	...APP_DATA.contact
};

const preferences = {
	...{
		language: 'en_US',
		timeZone: 'UTC',
		hasTimeZone: false,
		theme: {
			contactPreferenceId: null,
			preferenceId: null,
			preferenceName: null,
			preferenceValue: 'light-theme'
		}
	},
	...APP_DATA.preferences
};

const features = { ...APP_DATA_ACCESS.features };
const privileges = { ...APP_DATA_ACCESS.privileges };
const roles = { ...APP_DATA_ACCESS.roles };

const languages = [
	{
		label: 'English (US)',
		value: 'en_US'
	},
	{
		label: 'Español',
		value: 'es_ES'
	},
	{
		label: '日本語',
		value: 'ja_JP'
	}
];

const meta = {
	...{
		buildRevision: null,
		buildVersion: new Date().toJSON().slice(0, 10).replace(/-/g, '.'),
		csrfToken: null,
		hostName: null,
		sessionLimit: 3300
	},
	...APP_DATA.meta
};

const urls = {
	...{
		cdn: '',
		kenna: null,
		nessus: null,
		redCloak: null,
		sabaCloud: null,
		carbonBlack: null,
		cloudSecurity: null,
		legal: {
			terms: null,
			readiness: null,
			subprocessor: null
		},
		datacubeSurvey: {
			href: null,
			hrefText: null
		}
	},
	...APP_DATA.urls
};

const other = {
	...{
		isTimeZoneUnavailable: false,
		hasLogDevices: false,
		hasTiewAlertsEnabled: false,
		hasKennaSubscription: false,
		hasNessusSubscription: false,
		hasQualysSubscription: false,
		scanningSubscriptionQualysData: [],
		chatTenant: ''
	},
	...APP_DATA.other
};

/**
 * Compiled bootstrap data with defaults.
 */
export { auth, client, contact, features, languages, preferences, privileges, roles, meta, urls, other };
