/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { createRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import NavMenu from './Menu';

const useHeadStyles = makeStyles((theme) => ({
	item: {
		'marginLeft': theme.spacing(4),
		'&:first-child': {
			marginLeft: 0
		}
	},
	itemOffset: {
		marginLeft: theme.spacing(8)
	},
	link: {
		...theme.typography.body2,
		'fontSize': 10,
		'display': 'inline-block',
		'paddingLeft': 0,
		'paddingRight': 0,
		'paddingTop': theme.spacing(1),
		'paddingBottom': theme.spacing(1),
		'color': theme.palette.headerBar.link,
		[theme.breakpoints.up('lg')]: {
			fontSize: 13
		},
		'&:hover': {
			color: theme.palette.headerBar.link
		}
	},
	linkActive: {
		textDecoration: 'underline'
	},
	box: {
		display: 'inline-block',
		position: 'relative'
	},
	icon: {
		color: theme.palette.headerBar.icon,
		transition: 'color .3s ease'
	},
	iconActive: {
		color: theme.palette.headerBar.iconHover
	}
}));

const useFootStyles = makeStyles((theme) => ({
	item: {
		'marginBottom': theme.spacing(0.4),
		'&:last-child': {
			marginBottom: 0
		}
	},
	itemOffset: {
		marginTop: theme.spacing(2)
	},
	link: {
		'color': theme.palette.footerNav.link,
		'fontSize': theme.typography.h6.fontSize,
		'display': 'inline-block',
		'paddingTop': theme.spacing(1),
		'paddingBottom': theme.spacing(1),
		'&:hover, &:focus': {
			color: theme.palette.footerNav.linkHover
		}
	},
	linkActive: {
		color: theme.palette.footerNav.linkHover
	},
	box: {
		display: 'inline-block',
		position: 'relative'
	},
	icon: {
		color: theme.palette.footerNav.icon,
		transition: 'color .3s ease'
	},
	iconActive: {
		color: theme.palette.footerNav.iconHover
	}
}));

const NavItem = ({ link, useHeaderStyles }) => {
	const headStyles = useHeadStyles();
	const footStyles = useFootStyles();
	const classes = useHeaderStyles ? headStyles : footStyles;
	const placement = useHeaderStyles ? 'bottom' : 'top';
	const linkRef = createRef();
	const { t } = useTranslation('common');
	const [elRef, setElRef] = useState(null);
	const { name, path, menu, isExternal, offsetTop, onClick, renderItem } = link;
	const hasMenu = !!menu;
	const isActive = Boolean(elRef);
	const href = Array.isArray(path) ? path[0] : path ? path : '#';

	if (renderItem) {
		return (
			<Grid
				item
				component="li"
				className={classNames(classes.item, {
					[classes.itemOffset]: offsetTop
				})}
			>
				{renderItem()}
			</Grid>
		);
	}

	if (!name) {
		return null;
	}

	function closeMenu() {
		setElRef(null);
	}

	function openMenu(event) {
		event.preventDefault();

		setElRef(linkRef.current);
	}

	function toggleMenu(event) {
		event.preventDefault();

		if (elRef) {
			closeMenu();
		} else {
			openMenu(event);
		}
	}

	function handleClick(event) {
		if (hasMenu) {
			toggleMenu(event);
		}

		if (onClick) {
			onClick(event);
		}
	}

	if (menu && menu.items && link.menu.items.every((x) => !x)) {
		return null;
	}

	return (
		<Grid
			item
			component="li"
			className={classNames(classes.item, {
				[classes.itemOffset]: offsetTop
			})}
		>
			<div
				className={classes.box}
				{...(hasMenu && {
					onMouseEnter: openMenu,
					onMouseLeave: closeMenu
				})}
			>
				<Link
					ref={linkRef}
					href={href}
					onClick={handleClick}
					className={classNames(classes.link, isActive && classes.linkActive)}
					{...(hasMenu && { 'aria-haspopup': true })}
					{...(isExternal && { target: '_blank', rel: 'noopener' })}
				>
					{t(name)}{' '}
					{hasMenu && (
						<FontAwesomeIcon
							icon={faCaretDown}
							className={classNames(classes.icon, isActive && classes.iconActive)}
						/>
					)}
				</Link>
				{hasMenu && isActive && (
					<NavMenu menu={menu} elRef={elRef} closeMenu={closeMenu} isOpen={isActive} placement={placement} />
				)}
			</div>
		</Grid>
	);
};

export default NavItem;
