/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare, faTimes, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ToggleColumns } from './ToggleColumns';
import CustomCheckbox from '../../../Inputs/Checkbox';
import CustomIconButton from '../../../Inputs/IconButton';

export const settingsColumns = ['row-select-toggle', 'row-expand-toggle', 'column-visibility-toggle'];

export const tableControlHooks = ({
	columns,
	canSelectRows,
	canExpandRows,
	canToggleColumns,
	isLoading,
	onEdit,
	onDelete,
	canSelectAll,
	canSelectMultipleRows
}) => {
	return [
		canSelectRows
			? {
					id: 'row-select-toggle',
					width: '3%',
					noPadding: true,
					groupByBoundary: true,
					Header({ getToggleAllRowsSelectedProps }) {
						const { t } = useTranslation();
						const { checked, ...props } = getToggleAllRowsSelectedProps();

						return canSelectAll ? (
							<CustomCheckbox
								size="small"
								disabled={isLoading}
								{...props}
								checked={checked}
								title={
									checked
										? t('datatables:controls.selectRows.deselectAll')
										: t('datatables:controls.selectRows.selectAll')
								}
								style={{ padding: 4, margin: 10, fontSize: 12 }}
								icon={<FontAwesomeIcon icon={faTimes} />}
								checkedIcon={<FontAwesomeIcon icon={faTimes} />}
								indeterminateIcon={<FontAwesomeIcon icon={faTimes} />}
							/>
						) : (
							''
						);
					},
					stopPropagationField: true,
					Cell({ row }) {
						const { t } = useTranslation();
						const { checked, ...props } = row.getToggleRowSelectedProps();

						return canSelectMultipleRows ? (
							<CustomCheckbox
								size="small"
								disabled={isLoading}
								{...props}
								checked={checked}
								title={
									checked
										? t('datatables:controls.selectRows.deselectOne')
										: t('datatables:controls.selectRows.selectOne')
								}
								style={{ padding: 0, marginLeft: 3, fontSize: 12 }}
								icon={<FontAwesomeIcon icon={faCheck} />}
								checkedIcon={<FontAwesomeIcon icon={faCheck} />}
								indeterminateIcon={<FontAwesomeIcon icon={faCheck} />}
							/>
						) : (
							''
						);
					}
			  }
			: null,
		canExpandRows
			? {
					id: 'row-expand-toggle',
					width: '40px',
					minWidth: '40px',
					maxWidth: '40px',
					noPadding: true,
					Header() {
						return '';
					},
					Cell({ row }) {
						return row.canExpand ? (
							<span {...row.getToggleRowExpandedProps()}>
								{row.isExpanded ? (
									<FontAwesomeIcon size="1x" icon={faMinusSquare} />
								) : (
									<FontAwesomeIcon size="1x" icon={faPlusSquare} />
								)}
							</span>
						) : null;
					}
			  }
			: null,
		...columns,
		canToggleColumns
			? {
					id: 'column-visibility-toggle',
					width: onEdit && onDelete ? '90px' : '40px',
					noPadding: true,
					Cell({ row }) {
						return !row.original.isCloudNetwork && !row.original.tenantId ? (
							<div style={{ textAlign: 'center' }}>
								{onEdit && (
									<CustomIconButton
										disabled={isLoading}
										size="small"
										style={{ margin: '0 8px 0 0' }}
										onClick={() => {
											onEdit(row);
										}}
									>
										<FontAwesomeIcon
											icon={faEdit}
											transform={{
												x: 0,
												y: 0
											}}
										/>
									</CustomIconButton>
								)}
								{onDelete && (
									<CustomIconButton
										disabled={isLoading}
										size="small"
										style={{ margin: '0 0 0 8px' }}
										onClick={() => {
											onDelete(row);
										}}
									>
										<FontAwesomeIcon
											icon={faTimes}
											transform={{
												x: 0,
												y: 0
											}}
										/>
									</CustomIconButton>
								)}
							</div>
						) : null;
					},
					Header() {
						return (
							<ToggleColumns columns={columns} settingsColumns={settingsColumns} isLoading={isLoading} />
						);
					}
			  }
			: null
	].filter(Boolean);
};
