import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '../../../components/Inputs/Button';
import { hideModal } from '../../../state/actions/components/modals';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.grey[500],
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(3)
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	},
	field: {
		marginTop: theme.spacing(3)
	},
	dialog: {
		alignItems: 'start'
	},
	dialogContent: {
		color: theme.palette.text.primary
	},
	cancelButton: {
		'backgroundColor': theme.palette.cancelButton.backgroundColor,
		'borderColor': theme.palette.cancelButton.borderColor,
		'color': theme.palette.primary.light,
		'border': '1px solid',
		'&:hover': {
			color: theme.palette.cancelButton.hover.color,
			backgroundColor: theme.palette.cancelButton.hover.backgroundColor,
			borderColor: theme.palette.cancelButton.borderColor
		}
	},
	submitButton: {
		'&:disabled': {
			backgroundColor: theme.palette.input.disabledBackground,
			cursor: 'not-allowed'
		},
		'&:hover': {
			backgroundColor: theme.palette.reports.button.hoverBackgroundColor
		},
		'color': theme.palette.common.white,
		'backgroundColor': theme.palette.reports.button.backgroundColor
	},
	errorAlert: {
		marginTop: 20,
		backgroundColor: theme.palette.reports.errorMessage.background,
		padding: '8px 35px 8px 14px',
		borderRadius: 4,
		color: theme.palette.reports.errorMessage.color,
		lineHeight: 1.35
	}
}));

const CustomDialog = withStyles(() => ({
	root: {
		alignItems: 'start'
	},
	scrollPaper: {
		alignItems: 'flex-start'
	}
}))(Dialog);

const ReportsGenericModal = ({ isOpen, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState({ show: false, message: '' });

	const handleClose = () => {
		setErrorMessage({ show: false, message: '' });
		dispatch(hideModal({ name: 'REPORTS_GENERIC_MODAL' }));
	};

	const handleSubmit = (rows) => {
		const modalUtils = {
			handleClose: handleClose,
			setLoading: setLoading,
			setErrorMessage: setErrorMessage
		};
		data.submitFn(rows, modalUtils);
	};

	return (
		<CustomDialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="reports-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle id="view-parameters-modal" disableTypography>
				<Typography variant="h2">
					<Box fontWeight="fontWeightRegular">{data.title}</Box>
					<IconButton size="small" aria-label="close" onClick={handleClose} className={classes.closeButton}>
						<FontAwesomeIcon icon={faTimes} size="1x" />
					</IconButton>
				</Typography>
			</DialogTitle>

			<DialogContent dividers className={classes.dialogContent}>
				<Typography>{data.body}</Typography>
				{errorMessage.show && <div className={classes.errorAlert}>{errorMessage.message}</div>}
			</DialogContent>

			<DialogActions className={classes.dialogActions}>
				{isLoading && <FontAwesomeIcon icon={faSpinner} spin={true} className={classes.spinner} />}
				{data.cancelButton && (
					<Button onClick={handleClose} variant="outlined" className={classes.cancelButton}>
						{data.cancelButton}
					</Button>
				)}
				{data.submitButton && (
					<Button
						onClick={() => handleSubmit(data?.rows)}
						variant="outlined"
						color="default"
						className={classes.submitButton}
						disabled={data.disableSubmitButton || isLoading}
					>
						{data.submitButton}
					</Button>
				)}
			</DialogActions>
		</CustomDialog>
	);
};

export default ReportsGenericModal;
