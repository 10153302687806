/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PivotDataTable from '../../../components/PivotDataTable';
import { usePivotDataTable } from '../../../components/PivotDataTable/usePivotDataTable';
import PropTypes from 'prop-types';
import { getFormattedDateTimeByLocale, getLocaleByUserPreferenceTimeZone } from '../../../pages/Reports/View/utils';
import PivotTableHeader from '../DrillDownTable/TableHeader';

function getAggregationTableTitle(groupingsBy, t) {
	if (groupingsBy === 'SRCIP') {
		return t('reports:topTalkers.topServices.drillDown.sourceIPs');
	} else if (groupingsBy === 'DSTIP') {
		return t('reports:topTalkers.topServices.drillDown.destinationIPs');
	} else {
		return '';
	}
}

function getAggregationTableSubTitle(requestPayload) {
	const timeZoneLocale = getLocaleByUserPreferenceTimeZone();

	const options = { timeFormat: 'HH:mm ZZZZ', timeZoneLocale: timeZoneLocale };

	return (
		getFormattedDateTimeByLocale(requestPayload.startTime, options) +
		' - ' +
		getFormattedDateTimeByLocale(requestPayload.endTime, options)
	);
}

const TopTalkersDrillDownContainer = ({
	apiUrl,
	requestPayload,
	method,
	downloadPdf,
	downloadCsv,
	groupingOptions,
	defaultGroupingOption,
	fetchDataResponseFormatter,
	drillDownId,
	showPagination = true,
	rowGroupingWidth = '40%',
	enableDrillDown = true,
	pivotTableData,
	drillDownParams = {},
	reportName = ''
}) => {
	const { t } = useTranslation(['reports', 'dashboard']);
	const [selectedRowGroupingOption, setSelectedRowGroupingOption] = useState(defaultGroupingOption);
	const downloadMenu = useMemo(
		() => [
			{
				title: t('dashboard:drilldown.common.download'),
				subMenus: [
					{
						title: t('dashboard:drilldown.common.csv'),
						tooltip: t('dashboard:drilldown.events.downloadAggregateCounts'),
						onClick: () => {
							downloadCsv(selectedRowGroupingOption?.id);
						}
					},
					{
						title: t('dashboard:drilldown.common.pdf'),
						tooltip: t('dashboard:drilldown.events.downloadAggregateCounts'),
						onClick: () => {
							downloadPdf(selectedRowGroupingOption?.id);
						}
					}
				]
			}
		],
		[downloadPdf, downloadCsv, t, selectedRowGroupingOption]
	);

	const options = useMemo(() => {
		return {
			url: apiUrl,
			params: requestPayload,
			method
		};
	}, [apiUrl, requestPayload, method]);
	const {
		data,
		columns,
		settings,
		isError,
		isLoading,
		skipResetRef,
		fetchData,
		updateParams,
		pageSize
	} = usePivotDataTable({
		name: drillDownId,
		initialData: requestPayload.reportType === 'SERVICES' ? undefined : pivotTableData,
		options: options,
		fetchDataResponseFormatter: (data, params) => {
			return fetchDataResponseFormatter(
				data,
				groupingOptions,
				onGroupingOptionClick,
				params,
				t,
				rowGroupingWidth,
				enableDrillDown
			);
		},
		fetchFromService: requestPayload.reportType === 'SERVICES',
		updateColumnsOnFetch: true,
		updateSettingOnFetch: true
	});

	const onGroupingOptionClick = (option) => {
		updateParams({ rowGrouping: option.id, page: 1 });
		setSelectedRowGroupingOption(option);
		fetchData();
	};

	return (
		<>
			{requestPayload.reportType === 'SERVICES' && (
				<Box display="flex" flexGrow={1}>
					<PivotTableHeader
						menus={downloadMenu}
						headingText={getAggregationTableTitle(selectedRowGroupingOption?.id, t)}
						subHeadingText={getAggregationTableSubTitle(requestPayload)}
					/>
				</Box>
			)}
			<Box marginTop={2} marginBottom={4} marginLeft={1} marginRight={1}>
				<PivotDataTable
					showPagination={showPagination}
					hasPaginationData={true}
					columns={columns ? columns : []}
					data={data.elements ? data.elements : []}
					isError={isError}
					isLoading={isLoading}
					skipResetRef={skipResetRef}
					name={drillDownId}
					errorMsg={'dashboard:drilldown.common.tableMsg.error'}
					emptyMsg={'dashboard:drilldown.common.tableMsg.empty'}
					loadingMsg={'dashboard:drilldown.common.tableMsg.loading'}
					fetchData={fetchData}
					pageChange={(page) => {
						updateParams({ page });
					}}
					totalPages={settings?.totalPages}
					pageSize={pageSize}
					drillDownType={'TOP_TALKER_EVENTS'}
					drillDownOptions={{ params: drillDownParams, reportName: reportName }}
					showWarning={true}
				/>
			</Box>
		</>
	);
};

TopTalkersDrillDownContainer.propTypes = {
	apiUrl: PropTypes.string,
	requestPayload: PropTypes.object,
	method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
	downloadPdf: PropTypes.func,
	downloadCsv: PropTypes.func,
	groupingOptions: PropTypes.array,
	defaultGroupingOption: PropTypes.object,
	fetchDataResponseFormatter: PropTypes.func,
	drillDownId: PropTypes.string,
	showPagination: PropTypes.bool,
	rowGroupingWidth: PropTypes.string,
	enableDrillDown: PropTypes.bool,
	pivotTableData: PropTypes.object,
	reportName: PropTypes.string,
	drillDownParams: PropTypes.object
};

export default TopTalkersDrillDownContainer;
