/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 *
 * Wrapper on CustomCheckbox to provide a label
 */

import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '../Checkbox';

const useStyles = makeStyles((theme) => ({
	checkboxRoot: {
		padding: theme.spacing(1)
	}
}));

const CheckboxLabelled = (props) => {
	const { t } = useTranslation();
	const { disabled, labelPlacement, name } = props;
	const classes = useStyles();

	const checkboxProps = { ...props };
	delete checkboxProps.labelPlacement;

	return (
		<FormControlLabel
			control={
				<CustomCheckbox className={classes.checkboxRoot} size="small" disabled={disabled} {...checkboxProps} />
			}
			label={t(name)}
			labelPlacement={labelPlacement}
			title={t(name)}
		/>
	);
};

export default CheckboxLabelled;
