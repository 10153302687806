/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Switch } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { changeTheme } from '../../../state/actions/app/preferences';

const Switcher = withStyles((theme) => ({
	root: {
		width: 32,
		height: 24,
		padding: 8
	},
	switchBase: {
		'padding': 6,
		'color': theme.palette.primary.light,
		'&$checked': {
			'color': theme.palette.primary.light,
			'transform': 'translateX(9px)',
			'& + $track': {
				opacity: 1,
				color: theme.palette.primary.light,
				backgroundColor: '#fff'
			}
		},
		'&:hover $thumb': {
			background: '#5f58ff'
		},
		'&.MuiIconButton-root:hover': {
			background: 'transparent'
		}
	},
	thumb: {
		width: 11,
		height: 11,
		boxShadow: 'none',
		backgroundColor: theme.palette.common.blue
	},
	track: {
		opacity: 1,
		backgroundColor: '#fff'
	},
	checked: {}
}))(Switch);

const useStyles = makeStyles(() => ({
	label: {
		height: '100%',
		width: 'fit-content',
		cursor: 'pointer',
		fontSize: 'inherit',
		marginBottom: 0
	},
	lightLabel: {
		'&:hover, &:focus': {
			'& $item': {
				color: '#777'
			}
		}
	},
	darkLabel: {
		'&:hover, &:focus': {
			'& $item': {
				color: '#777'
			}
		}
	},
	item: {},
	lightItem: {
		fontSize: '13px',
		color: '#b5b5b5'
	},
	darkItem: {
		fontSize: '17px',
		color: '#777'
	},
	lightIcon: {
		'&:hover': {
			color: '#efefef'
		}
	},
	darkIcon: {
		'&:hover': {
			color: '#5f58ff'
		}
	},
	lightTrack: {
		border: '1px solid #fff'
	},
	darkTrack: {
		border: '1px solid #777'
	}
}));

const ThemeSwitch = ({ variant }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const themeValue = useSelector((state) => state.preferences.theme.preferenceValue);
	const isDarkTheme = themeValue === 'dark-theme';

	return (
		<Grid
			component="label"
			container
			alignItems="center"
			className={classNames(classes.label, classes[variant + 'Label'])}
		>
			<Grid item className={classNames(classes.item, classes[variant + 'Item'])}>
				<FontAwesomeIcon icon={faSun} className={classNames(classes[variant + 'Icon'])} />
			</Grid>
			<Grid item>
				<Switcher
					classes={{
						root: classes.root,
						switchBase: classes.switchBase,
						thumb: classes.thumb,
						track: classNames(classes.track, classes[variant + 'Track']),
						checked: classes.checked
					}}
					checked={isDarkTheme}
					onChange={() =>
						dispatch(
							changeTheme({
								theme: isDarkTheme ? 'light-theme' : 'dark-theme'
							})
						)
					}
					value="dark-theme"
				/>
			</Grid>
			<Grid item className={classNames(classes.item, classes[variant + 'Item'])}>
				<FontAwesomeIcon icon={faMoon} className={classNames(classes[variant + 'Icon'])} />
			</Grid>
		</Grid>
	);
};

ThemeSwitch.defaultProps = {
	variant: 'light'
};

ThemeSwitch.propTypes = {
	variant: PropTypes.oneOf(['dark', 'light'])
};

export default ThemeSwitch;
