/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { INCREMENT_SESSION_COUNTER } from '../../actions/components/session';

const initialState = {
	counter: 0
};

export default function sessionReducer(state = initialState, action) {
	switch (action.type) {
		case INCREMENT_SESSION_COUNTER:
			return {
				...state,
				counter: state.counter + 1
			};
		default:
			return state;
	}
}
