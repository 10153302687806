/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { makeStyles } from '@material-ui/core/styles';

export const usePivotTableStyles = makeStyles((theme) => ({
	pivotDataTableRoot: {},
	table: {
		borderCollapse: 'separate',
		borderSpacing: 0,
		borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
		border: `1px solid ${theme.palette.table.borderColor}`
	},
	tableHead: {},
	tableHeadRow: {},

	tableBody: {},
	tableBodyRow: {
		'color': theme.palette.table.cell.body.color,
		'backgroundColor': theme.palette.table.cell.body.background,
		'&:last-child td': {
			'&:first-child': {
				borderBottomLeftRadius: theme.spacing(1)
			},
			'&:last-child': {
				borderBottomRightRadius: theme.spacing(1)
			}
		}
	},
	tableBodyRowStriped: {
		'&:nth-of-type(even)': {
			color: theme.palette.table.cell.striped.color,
			backgroundColor: theme.palette.table.cell.striped.background
		}
	},
	pivotTablePagination: {
		'alignItems': 'center',
		'display': 'flex',
		'alignSelf': 'center',
		'flexDirection': 'column',
		'margin': theme.spacing(2),
		'& ul': {
			'& li:first-child': {
				'& button': {
					margin: 0,
					borderTopLeftRadius: theme.spacing(1),
					borderBottomLeftRadius: theme.spacing(1)
				}
			},
			'& li:last-child': {
				'& button': {
					margin: 0,
					borderTopRightRadius: theme.spacing(1),
					borderBottomRightRadius: theme.spacing(1)
				}
			},
			'& li': {
				'& button': {
					'margin': 0,
					'borderRadius': 0,
					'height': 28,
					'width': 28,
					'color': theme.palette.pagination.color,
					'backgroundColor': theme.palette.pagination.backgroundColor,
					'borderColor': `${theme.palette.pagination.borderColor} !important`,
					'&:hover': {
						backgroundColor: theme.palette.pagination.backgroundHover,
						color: theme.palette.pagination.colorHover
					},
					'&[aria-current="true"]': {
						backgroundColor: theme.palette.pagination.activeBackground,
						color: theme.palette.pagination.activeColor
					}
				}
			}
		}
	},
	drillDownPanel: {
		backgroundColor: theme.palette.table.cell.drillDownCell.background,
		color: theme.palette.table.cell.drillDownCell.color,
		padding: theme.spacing(2),
		marginLeft: '-16px',
		marginRight: '-16px'
	},
	closeDrillDown: {
		float: 'right',
		cursor: 'pointer'
	},
	drillDownToolBar: {
		paddingBottom: theme.spacing(2)
	}
}));
