/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Radio, FormControlLabel } from '@material-ui/core';

const StyledRadio = withStyles((theme) => ({
	root: {
		'padding': 1,
		'color': theme.palette.text.secondary,
		'&$checked': {
			color: theme.palette.action.active
		},
		'&$disabled': {
			color: theme.palette.text.secondary
		}
	},
	checked: {},
	disabled: {}
}))((props) => <Radio color="default" {...props} />);

export const StyledFormControlLabel = withStyles((theme) => ({
	root: {
		'color': theme.palette.text.primary,
		'marginLeft': 0,
		'marginRight': 0,
		'&$disabled': {
			color: theme.palette.text.secondary
		},
		'& + $root': {
			marginTop: theme.spacing(1)
		}
	},
	label: {
		'paddingLeft': theme.spacing(1.5),
		'&$disabled': {
			color: theme.palette.text.secondary
		}
	},
	disabled: {}
}))(FormControlLabel);

const RadioWithLabel = (props) => {
	return <StyledFormControlLabel control={<StyledRadio disabled={props.disabled} size="small" />} {...props} />;
};

export default RadioWithLabel;
