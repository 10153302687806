/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LoaderCircular = withStyles((theme) => ({
	root: {
		color: theme.palette.action.active
	}
}))(CircularProgress);

export default LoaderCircular;
