/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import axios from 'axios';
import { batch } from 'react-redux';

export const ADD_PIVOT_DATA_TABLE = 'ADD_PIVOT_DATA_TABLE';
export const UPDATE_PIVOT_DATA_TABLE_DATA = 'UPDATE_PIVOT_DATA_TABLE_DATA';
export const UPDATE_PIVOT_DATA_TABLE_COLUMNS = 'UPDATE_PIVOT_DATA_TABLE_COLUMNS';
export const UPDATE_PIVOT_DATA_TABLE_PARAMS = 'UPDATE_PIVOT_DATA_TABLE_PARAMS';
export const UPDATE_PIVOT_DATA_TABLE_PROGRESS = 'UPDATE_PIVOT_DATA_TABLE_PROGRESS';
export const UPDATE_PIVOT_DATA_TABLE_SETTINGS = 'UPDATE_PIVOT_DATA_TABLE_SETTINGS';
export const UPDATE_PIVOT_DATA_TABLE_HEADER_MODEL = 'UPDATE_PIVOT_DATA_TABLE_HEADER_MODEL';
export const UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_PARAMS = 'UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_PARAMS';
export const UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_MSG_OPTIONS = 'UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_MSG_OPTIONS';

const defaultPivotDataTableOptions = {
	url: null,
	data: {},
	columns: [],
	filters: [],
	params: {
		limit: 10,
		page: 1
	},
	settings: {},
	isError: false,
	isLoading: true,
	tableHeaderModel: {},
	drillDownParams: {},
	msgOptions: {
		showWarning: false
	}
};

export const addPivotDataTable = ({ name, options = {} }) => ({
	type: ADD_PIVOT_DATA_TABLE,
	payload: {
		[name]: {
			...defaultPivotDataTableOptions,
			...options
		}
	}
});

export const updatePivotDataTableData = ({ name, data = {} }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_DATA,
	payload: {
		name,
		data
	}
});

export const updatePivotDataTableColumns = ({ name, columns = [] }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_COLUMNS,
	payload: {
		name,
		columns
	}
});
export const updatePivotDataTableParams = ({ name, params = {} }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_PARAMS,
	payload: {
		name,
		params
	}
});

export const updatePivotDataTableProgress = ({ name, isError, isLoading }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_PROGRESS,
	payload: {
		name,
		isError,
		isLoading,
		isMenuOpen: false,
		isSettingsOpen: false
	}
});

export const updatePivotDataTableSettings = ({ name, settings }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_SETTINGS,
	payload: {
		name,
		settings
	}
});

export const updateDrillDownDataTableParams = ({ name, drillDownParams = {} }) => ({
	type: UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_PARAMS,
	payload: {
		name,
		drillDownParams
	}
});

export const updateDrillDownDataTableMsgOptions = ({ name, msgOptions = {} }) => ({
	type: UPDATE_PIVOT_DRILL_DOWN_DATA_TABLE_MSG_OPTIONS,
	payload: {
		name,
		msgOptions
	}
});

export const updatePivotDataTableHeaderModel = ({ name, tableHeaderModel = {} }) => ({
	type: UPDATE_PIVOT_DATA_TABLE_HEADER_MODEL,
	payload: {
		name,
		tableHeaderModel
	}
});

export function createPivotDataTable({
	name,
	options,
	initialData,
	fetchDataResponseFormatter = defaultDataFormatter,
	updateColumnsOnFetch = false,
	updateSettingOnFetch = false
}) {
	return (dispatch) => {
		dispatch(addPivotDataTable({ name, options }));
		if (initialData) {
			dispatch(
				createTableWithPredefinedData({
					name,
					initialData,
					fetchDataResponseFormatter
				})
			);
		} else {
			dispatch(
				fetchPivotDataTableData({
					name,
					fetchDataResponseFormatter,
					updateColumnsOnFetch,
					updateSettingOnFetch
				})
			);
		}
	};
}

const defaultDataFormatter = (data) => {
	return { data: { elements: Array.isArray(data) ? data : [] }, columns: [], settings: {} };
};

export function fetchPivotDataTableData({
	name,
	fetchDataResponseFormatter = defaultDataFormatter,
	updateColumnsOnFetch = false,
	updateSettingOnFetch = false
}) {
	return (dispatch, getState) => {
		const { pivotdatatables } = getState();
		const { url, params = {}, method = 'POST' } = pivotdatatables[name] || {};
		if (url) {
			dispatch(
				updatePivotDataTableProgress({
					name,
					isError: false,
					isLoading: true
				})
			);

			return axios({
				url,
				method,
				data: params
			})
				.then(({ data }) => {
					const tableHeaderModel = data.tableHeaderModel;

					const formattedResponseData = fetchDataResponseFormatter(data, params);
					data = formattedResponseData.data;
					data.updated = Date.now();

					batch(() => {
						dispatch(
							updatePivotDataTableData({
								name,
								data
							})
						);

						dispatch(
							updatePivotDataTableHeaderModel({
								name,
								tableHeaderModel
							})
						);

						if (updateColumnsOnFetch) {
							dispatch(
								updatePivotDataTableColumns({
									name,
									columns: formattedResponseData.columns
								})
							);
						}
						if (updateSettingOnFetch) {
							dispatch(
								updatePivotDataTableSettings({
									name,
									settings: formattedResponseData.settings
								})
							);
						}
						dispatch(
							updatePivotDataTableProgress({
								name,
								isError: false,
								isLoading: false
							})
						);
					});
				})
				.catch(() => {
					dispatch(
						updatePivotDataTableProgress({
							name,
							isError: true,
							isLoading: false
						})
					);
				});
		}
	};
}

export function createTableWithPredefinedData({
	name,
	initialData,
	fetchDataResponseFormatter = defaultDataFormatter
}) {
	return (dispatch) => {
		dispatch(
			updatePivotDataTableProgress({
				name,
				isError: false,
				isLoading: true
			})
		);

		let data = initialData;
		const params = {};

		const tableHeaderModel = data.tableHeaderModel;

		const formattedResponseData = fetchDataResponseFormatter(data, params);
		data = formattedResponseData.data;
		data.updated = Date.now();

		return batch(() => {
			dispatch(
				updatePivotDataTableData({
					name,
					data
				})
			);
			dispatch(
				updatePivotDataTableHeaderModel({
					name,
					tableHeaderModel
				})
			);

			dispatch(
				updatePivotDataTableColumns({
					name,
					columns: formattedResponseData.columns
				})
			);

			dispatch(
				updatePivotDataTableSettings({
					name,
					settings: formattedResponseData.settings
				})
			);

			dispatch(
				updatePivotDataTableProgress({
					name,
					isError: false,
					isLoading: false
				})
			);
		});
	};
}
