/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { post } from 'axios';
import { meta } from '../../../state/bootstrap';
import { hideModal } from '../../../state/actions/components/modals';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../components/Inputs/Button';
import { Box, Dialog, IconButton, Typography, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const DialogTitle = withStyles((theme) => ({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
}))((props) => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h2">
				<Box fontWeight="fontWeightRegular">{children}</Box>
			</Typography>
			{onClose ? (
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<FontAwesomeIcon icon={faTimesCircle} />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const TokenRenewalModal = ({ isOpen }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setDisabled] = useState(false);

	const handleClose = () => {
		dispatch(
			hideModal({
				name: 'TOKEN_RENEWAL'
			})
		);
	};

	const handleSubmit = () => {
		const params = { csrfToken: meta.csrfToken };
		post('/portal/preferences/token', params)
			.catch(() => {
				setDisabled(false);
			})
			.then(() => {
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
				location.reload();
			});
		setIsLoading(true);
		setDisabled(true);
	};

	return (
		<div>
			<Dialog
				fullWidth
				disableEscapeKeyDown
				open={isOpen}
				onClose={handleClose}
				maxWidth="sm"
				aria-labelledby="confirm-new-token-modal"
			>
				<DialogTitle id="confirm-new-token-modal" onClose={handleClose}>
					{t('pages.preferences.token.dialogTitle')}
				</DialogTitle>

				<DialogContent dividers>
					<Typography gutterBottom>{t('pages.preferences.token.dialogBody')}</Typography>
				</DialogContent>

				<DialogActions>
					<Button disabled={isDisabled} color="primary" onClick={handleClose}>
						{t('buttons.no')}
					</Button>

					<Button isLoading={isLoading} disabled={isDisabled} onClick={handleSubmit}>
						{t('buttons.yes')}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default TokenRenewalModal;
