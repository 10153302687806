/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import {
	ADD_WIDGET,
	UPDATE_WIDGET_DATA,
	UPDATE_WIDGET_PARAMS,
	UPDATE_WIDGET_PROGRESS,
	UPDATE_WIDGET_MENU,
	UPDATE_WIDGET_SETTINGS
} from '../../actions/components/widgets';

export default function widgetsReducer(state = {}, action) {
	switch (action.type) {
		case ADD_WIDGET:
			return {
				...state,
				...action.payload
			};
		case UPDATE_WIDGET_DATA:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					data: action.payload.data
				}
			};
		case UPDATE_WIDGET_PARAMS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					params: {
						...state[action.payload.name].params,
						...action.payload.params
					}
				}
			};
		case UPDATE_WIDGET_PROGRESS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					isError: action.payload.isError,
					isLoading: action.payload.isLoading,
					isMenuOpen: action.payload.isMenuOpen,
					isSettingsOpen: action.payload.isSettingsOpen
				}
			};
		case UPDATE_WIDGET_MENU:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					isMenuOpen: action.payload.isMenuOpen
				}
			};
		case UPDATE_WIDGET_SETTINGS:
			return {
				...state,
				[action.payload.name]: {
					...state[action.payload.name],
					settings: {
						...state[action.payload.name].settings,
						...action.payload.settings
					},
					isSettingsOpen: action.payload.isSettingsOpen
				}
			};
		default:
			return state;
	}
}
