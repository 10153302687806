/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import React, { useEffect, useMemo } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Collapse, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { usePivotTableStyles } from './Table/Styles';
import { PivotDataTableHeadCell } from './Table/Cells/Head/Default';
import { PivotDataTableCell } from './Table/Cells/Body/Default';
import { useTable, usePagination, useSortBy } from 'react-table';
import { PivotTableMessage } from './Table/Messages';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePrevious } from '../../utils';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
	updateDrillDownDataTableMsgOptions,
	updateDrillDownDataTableParams
} from '../../state/actions/components/pivotdatatables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MapDrillDownView } from './DrillDown';

/* *
 * Table component capable of rendering table with column and row data provided to it.
 * Table head cell and table body cell templates can be easily overridden by passing relevant information in 'columns'.
 * Note that this table is not coupled with any backend service. All data will come as input to this component. If you are planning to fetch data from
 * server API and show it in a table check 'usePivotDataTable' hook once. It can help you to do that.
 * Refer react-table documentation (https://react-table.tanstack.com/) for more details.
 * Note: Pivotal features are yet to be added to this component.
 * @param {boolean} showPagination - If this is set to true then you must set totalPages, pageSize, hasPaginationData, pageChange
 * */
const PivotDataTable = ({
	name,
	columns,
	data,
	isLoading,
	isError,
	errorMsg,
	loadingMsg,
	emptyMsg,
	skipResetRef,
	fetchData,
	totalPages,
	pageSize,
	showPagination = false,
	hasPaginationData = false,
	pageChange,
	tableStyleStriped = true,
	drillDownType,
	drillDownOptions,
	showWarning
}) => {
	// default cell templates
	const defaultColumn = useMemo(
		() => ({
			Cell: PivotDataTableCell,
			Header: PivotDataTableHeadCell
		}),
		[]
	);

	const dispatch = useDispatch();
	const dataTable = useSelector((state) => state.pivotdatatables[name]);
	const { params = {}, drillDownParams = {}, msgOptions = {} } = dataTable || {};

	const handleDrillDown = (cell) => {
		const updatedDrillDownParams = {
			expandedRow: cell.row.original,
			selectedColumn: { id: cell.column.id, filterBy: cell.column.filterBy }
		};

		batch(() => {
			if (showWarning && !msgOptions?.shown) {
				const updatedMsgOptions = { showWarning: true, shown: true };
				dispatch(updateDrillDownDataTableMsgOptions({ name, msgOptions: updatedMsgOptions }));
			}
			dispatch(updateDrillDownDataTableParams({ name, drillDownParams: updatedDrillDownParams }));
		});
	};

	const closeDrillDown = () => {
		const updatedDrillDownParams = { expandedRow: null };
		dispatch(updateDrillDownDataTableParams({ name, drillDownParams: updatedDrillDownParams }));
	};

	const classes = usePivotTableStyles();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageOptions,
		gotoPage,
		state: { pageIndex },
		setPageSize
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			autoResetPage: !skipResetRef?.current,
			initialState: {
				pageSize: pageSize ? pageSize : 10,
				pageIndex: 0
			}
		},
		useSortBy,
		usePagination
	);

	const prevPageSize = usePrevious(pageSize);
	// updating pageSize in table as well.
	useEffect(() => {
		if (pageSize !== prevPageSize) {
			setPageSize(pageSize);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize, prevPageSize]);

	const showDrillDownTableRow = (row) => {
		return (
			row?.original &&
			drillDownParams.expandedRow &&
			row.original[params.rowGrouping] === drillDownParams.expandedRow[params.rowGrouping]
		);
	};

	return (
		<>
			<TableContainer className={classes.pivotDataTableRoot}>
				<Table className={classes.table} {...getTableProps()}>
					<TableHead className={classes.tableHead}>
						{headerGroups.map((headerGroup) => {
							const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
							return (
								<TableRow key={key} className={classes.tableHeadRow} {...headerGroupProps}>
									{headerGroup.headers.map((column) => {
										const headerProps = column.getHeaderProps(column.getSortByToggleProps());
										return column.render('Header', headerProps);
									})}
								</TableRow>
							);
						})}
					</TableHead>
					<TableBody className={classes.tableBody} {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							const showDrillDownDetails = showDrillDownTableRow(row);
							const { key, ...rowProps } = row.getRowProps();
							return (
								<React.Fragment key={'row' + key}>
									<TableRow
										key={key}
										className={classNames(classes.tableBodyRow, {
											[classes.tableBodyRowStriped]: tableStyleStriped
										})}
										{...rowProps}
									>
										{row.cells.map((cell) => {
											const cellProps = cell.getCellProps();
											return cell.render('Cell', {
												...cellProps,
												handleDrillDown: handleDrillDown,
												highlight: showDrillDownDetails,
												selectedColumn: drillDownParams.selectedColumn
											});
										})}
									</TableRow>
									{showDrillDownDetails && (
										<>
											<TableRow key={'empty_row_' + key}></TableRow>
											<TableRow key={'drill_down_' + key}>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={columns?.length}
												>
													<Collapse in={showDrillDownDetails} timeout="auto" unmountOnExit>
														<Box className={classes.drillDownPanel}>
															<span
																className={classes.closeDrillDown}
																onClick={closeDrillDown}
															>
																<FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
															</span>
															<MapDrillDownView
																drillDownType={drillDownType}
																drillDownOptions={drillDownOptions}
																name={name}
															/>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										</>
									)}
								</React.Fragment>
							);
						})}
						{isError ? (
							<PivotTableMessage message={errorMsg} isError numberOfColumns={columns?.length} />
						) : null}
						{data?.length === 0 && !isError && !isLoading ? (
							<PivotTableMessage message={emptyMsg} isEmpty numberOfColumns={columns?.length} />
						) : null}
						{isLoading ? (
							<PivotTableMessage message={loadingMsg} isLoading numberOfColumns={columns?.length} />
						) : null}
					</TableBody>
				</Table>
			</TableContainer>
			{showPagination ? (
				<div>
					<Pagination
						count={totalPages ? totalPages : pageOptions.length}
						className={classes.pivotTablePagination}
						defaultValue={pageIndex + 1}
						onChange={(event, page) => {
							pageChange(page);
							gotoPage(page - 1);
							if (hasPaginationData) {
								fetchData();
							}
						}}
						variant="outlined"
						shape="rounded"
						color="primary"
					/>
				</div>
			) : null}
		</>
	);
};

PivotDataTable.defaultProps = {
	drillDownType: null,
	drillDownOptions: {},
	showWarning: false
};

PivotDataTable.propTypes = {
	name: PropTypes.string,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
			headerTitle: PropTypes.string.isRequired,
			Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
			Cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
			enableDrillDown: PropTypes.bool
		})
	).isRequired,
	data: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	isError: PropTypes.bool,
	errorMsg: PropTypes.string,
	loadingMsg: PropTypes.string,
	emptyMsg: PropTypes.string,
	skipResetRef: PropTypes.shape({
		current: PropTypes.bool.isRequired
	}).isRequired,
	fetchData: PropTypes.func,
	totalPages: PropTypes.number,
	pageSize: PropTypes.number,
	showPagination: PropTypes.bool,
	hasPaginationData: PropTypes.bool,
	pageChange: PropTypes.func,
	tableStyleStriped: PropTypes.bool,
	drillDownType: PropTypes.string,
	drillDownOptions: PropTypes.object,
	showWarning: PropTypes.bool
};
export default PivotDataTable;
