/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { Paper, Typography, Collapse } from '@material-ui/core';
import Button from '../../../Inputs/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { reset, toggleSound, toggleModal } from '../../../../state/actions/components/chat';
import { makeStyles } from '@material-ui/core/styles';
import { useNetworkState } from '../../../../utils';
import { faCustomWifiOff } from '../../../Icons/WiFi';

const useChatFrameBaseStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.chat.frame.border}`,
		backgroundColor: theme.palette.chat.frame.background,
		flexGrow: 1,
		display: 'flex',
		flexFlow: 'column nowrap',
		height: '100%',
		overflow: 'hidden'
	}
}));

const BaseView = ({ className, children }) => {
	const classes = useChatFrameBaseStyles();

	return (
		<Paper square elevation={10} className={classNames('sw-chat-frame-base', classes.root, className)}>
			{children}
		</Paper>
	);
};

const useChatFrameHeaderStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		width: '100%',
		padding: 5,
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default
	},
	boxLeft: {
		flexGrow: 1
	},
	boxRight: {
		flexShrink: 0
	},
	iconButton: {
		minWidth: 35,
		color: theme.palette.action.active
	},
	textButton: {
		'textTransform': 'uppercase',
		'fontSize': theme.typography.h6.fontSize,
		'fontWeight': theme.typography.fontWeightBold,
		'color': theme.palette.text.secondary,
		'&.Mui-disabled': {
			color: theme.palette.text.secondary
		}
	},
	offline: {
		flexGrow: 1,
		width: '100%',
		display: 'flex',
		color: theme.palette.error.main,
		alignItems: 'center',
		justifyContent: 'center'
	},
	offlineIcon: {
		fontSize: 18,
		width: 18
	},
	offlineText: {
		fontSize: 12,
		lineHeight: 1.1,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: theme.spacing(1)
	}
}));

const BaseViewHeader = ({ children }) => {
	const { t } = useTranslation();
	const classes = useChatFrameHeaderStyles();
	const dispatch = useDispatch();
	const isOnline = useNetworkState();
	const progress = useSelector((state) => state.chat.progress);
	const hasSound = useSelector((state) => state.chat.preferences.sound);
	const showOffline = !isOnline && ['acceptedByAgent'].includes(progress);
	const showClose = ['terminatedByAgent', 'terminatedByClient'].includes(progress);
	const showTerminate = ['acceptedByAgent', 'showQueue', 'showTransfer'].includes(progress);
	const showSound = !(showClose || ['showChatForm', 'showError'].includes(progress));

	const handleSoundClick = () => {
		dispatch(
			toggleSound({
				isEnabled: !hasSound
			})
		);
	};

	const handleEndClick = () => {
		dispatch(
			toggleModal({
				name: 'end',
				value: true
			})
		);
	};

	const handleCloseClick = () => {
		dispatch(reset());

		if (window.isStandaloneChat) {
			window.close();
		}
	};

	return (
		<div className={classNames('sw-chat-frame-header', classes.root)}>
			<div className={classNames('sw-chat-frame-header-sound', classes.boxLeft)}>
				{children}
				{showSound ? (
					<Button
						variant="text"
						aria-label="sound"
						title={t(hasSound ? 'common:chat.buttons.mute' : 'common:chat.buttons.unmute')}
						className={classes.iconButton}
						onClick={handleSoundClick}
					>
						<FontAwesomeIcon icon={hasSound ? faBell : faBellSlash} />
					</Button>
				) : null}
			</div>

			{showTerminate ? (
				<div className={classNames('sw-chat-frame-header-terminate', classes.boxRight)}>
					<Button
						variant="text"
						disabled={showOffline}
						className={classes.textButton}
						onClick={handleEndClick}
					>
						{t('common:chat.buttons.end')}
					</Button>
				</div>
			) : null}

			{showClose ? (
				<div className={classNames('sw-chat-frame-header-close', classes.boxRight)}>
					<Button variant="text" className={classes.textButton} onClick={handleCloseClick}>
						{t('common:chat.buttons.close')}
					</Button>
				</div>
			) : null}

			<Collapse in={showOffline} className={classes.offline}>
				<div className={classNames('sw-chat-frame-header-offline', classes.offline)}>
					<FontAwesomeIcon className={classes.offlineIcon} icon={faCustomWifiOff} />
					<Typography className={classes.offlineText}>{t('common:chat.error.offline')}</Typography>
				</div>
			</Collapse>
		</div>
	);
};

const useChatFrameContentStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		padding: theme.spacing(3),
		overflow: 'auto',
		position: 'relative',
		minHeight: 200
	}
}));

const BaseViewContent = ({ children }) => {
	const classes = useChatFrameContentStyles();

	return <div className={classNames('sw-chat-frame-content', classes.root)}>{children}</div>;
};

const useChatFrameFooterStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		width: '100%',
		padding: theme.spacing(3),
		textAlign: 'center',
		backgroundColor: theme.palette.background.default,
		boxShadow:
			'0px 5px 6px -3px rgba(0,0,0,0.2), 0px 9px 12px 1px rgba(0,0,0,0.2), 0px 3px 16px 2px rgba(0,0,0,0.2)'
	}
}));

const BaseViewFooter = ({ children }) => {
	const classes = useChatFrameFooterStyles();

	return <div className={classNames('sw-chat-frame-footer', classes.root)}>{children}</div>;
};

export { BaseView, BaseViewContent, BaseViewFooter, BaseViewHeader };
