/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useServiceReviewDrillDown } from './useServiceReviewDrillDown';
import React from 'react';
import ServiceReviewDrillDownContainer from '../../../../../containers/Reports/ServiceReviewDrillDown';

/**
 * @param {object} fromChart - Data returned from Chart
 * @returns {JSX.Element}
 */
export const ServiceReviewDrillDown = ({ fromChart, reportName }) => {
	const drillDownProps = useServiceReviewDrillDown(fromChart, reportName);
	return <ServiceReviewDrillDownContainer fromChart={fromChart} {...drillDownProps} />;
};
