/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = ({ name, data = {}, priority = -1 }) => ({
	type: SHOW_MODAL,
	payload: {
		data,
		name,
		show: true,
		priority
	}
});

export const hideModal = ({ name }) => ({
	type: HIDE_MODAL,
	payload: {
		name,
		show: false
	}
});
