import React from 'react';
import { IncidentsDataTable } from '../../../containers/Reports/DrillDownTable/Incidents';
import { EventsDataTable } from '../../../containers/Reports/DrillDownTable/Events';
import { TopTalkerEventsDataTable } from '../../../containers/Reports/DrillDownTable/TopTalkerEvents';

export const MapDrillDownView = ({ drillDownType, ...others }) => {
	switch (drillDownType) {
		case 'INCIDENTS':
			return <IncidentsDataTable {...others} />;
		case 'EVENTS':
			return <EventsDataTable {...others} />;
		case 'TOP_TALKER_EVENTS':
			return <TopTalkerEventsDataTable {...others} />;
		default:
			return <div></div>;
	}
};
