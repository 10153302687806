/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import SidebarOption from '../SidebarOption';

const MenuList = ({ routes }) => {
	const currentPath = useSelector((state) => state.router.location.pathname);

	function checkIfActive(path) {
		return !!matchPath(path, {
			path: currentPath,
			exact: true,
			strict: true
		});
	}

	return routes.map((route) => {
		if (!route) {
			return null;
		}

		const { name, description, path, isActive, isSubMenu, routes } = route;

		const activeAtCurrentPath = checkIfActive(path);

		if (isSubMenu && !isActive && !activeAtCurrentPath) {
			return null;
		}

		const subMenus = [];
		if (routes) {
			routes.map((route) => {
				for (const subMenu in route) {
					subMenus.push(route[subMenu]);
				}
			});
		}

		/*	If has subMenu, and subMenu is activeAtCurrentPath,
			as parent of active element, this element shows active also */
		const activeSubMenu = subMenus.filter((subMenu) => {
			return subMenu && subMenu.path ? checkIfActive(subMenu.path) : null;
		});
		const isParentOfActive = activeSubMenu.length > 0;

		if (!activeAtCurrentPath) {
			return (
				<SidebarOption
					description={description}
					isActive={isParentOfActive}
					key={name}
					name={name}
					path={path}
				/>
			);
		} else {
			return (
				<SidebarOption
					description={description}
					isActive={activeAtCurrentPath}
					key={name}
					name={name}
					path={path}
					isSubMenu={isSubMenu}
				>
					{subMenus.length &&
						subMenus.map((subMenu) => {
							if (!subMenu) {
								return;
							}
							return (
								<SidebarOption
									description={subMenu.description}
									isActive={subMenu.isActive}
									skipNameTranslation={subMenu.skipNameTranslation}
									key={subMenu.name}
									name={subMenu.name}
									path={subMenu.path}
									isSubMenu={true}
								/>
							);
						})}
				</SidebarOption>
			);
		}
	});
};

export default MenuList;
