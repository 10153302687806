/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import Menu from './Menu';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block'
	},
	icon: {
		float: 'right'
	},
	link: {
		display: 'block',
		textDecoration: 'none',
		padding: theme.spacing(2.4),
		borderBottom: '1px solid transparent'
	},
	linkLevel1: {
		fontWeight: 700,
		fontSize: theme.typography.body1.fontSize
	},
	linkLevel1Primary: {
		color: theme.palette.headerNav.link,
		backgroundColor: theme.palette.headerNav.background,
		borderBottomColor: theme.palette.headerBar.background
	},
	linkLevel1Secondary: {
		color: theme.palette.headerNavResp.secondary.level1.link,
		backgroundColor: theme.palette.headerNavResp.secondary.level1.background,
		borderBottomColor: theme.palette.headerNavResp.secondary.level1.border
	}
}));

const Item = ({ route, isPrimary, isSecondary }) => {
	const classes = useStyles();
	const { t } = useTranslation('common');
	const [isOpen, setIsOpen] = useState(false);
	const pathname = useSelector((state) => state.router.location.pathname);
	const { name, path, menu, routes } = route;
	const dropDown = menu || (routes && { items: routes });
	const href = Array.isArray(path) ? path[0] : path ? path : '#';

	useEffect(() => {
		if (routes) {
			routes.forEach((item) => {
				if (!item || !item.path) {
					return null;
				}
				if (matchPath(pathname, { path: item.path })) {
					setIsOpen(true);
				}
			});
		}
	}, [pathname, routes]);

	if (route.renderItem) {
		return (
			<li key={name} className={classes.item}>
				{route.renderItem()}
			</li>
		);
	}

	if (!name) {
		return null;
	}

	return (
		<li
			key={name}
			className={classNames(classes.item, {
				[classes.linkLevel1Primary]: isPrimary,
				[classes.linkLevel1Secondary]: isSecondary
			})}
		>
			<a
				href={href}
				className={classNames(classes.link, classes.linkLevel1, {
					[classes.linkLevel1Primary]: isPrimary,
					[classes.linkLevel1Secondary]: isSecondary
				})}
				{...(dropDown && {
					onClick: (event) => {
						event.preventDefault();
						setIsOpen(!isOpen);
					}
				})}
			>
				{t(name)} {dropDown ? <FontAwesomeIcon icon={faAngleDown} className={classes.icon} /> : null}
			</a>
			{dropDown ? <Menu {...dropDown} isOpen={isOpen} isPrimary={isPrimary} isSecondary={isSecondary} /> : null}
		</li>
	);
};

export default Item;
