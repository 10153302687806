/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, useScrollTrigger } from '@material-ui/core';
import { toggleSmallNav } from '../../../state/actions/components/nav';

import HeaderBar from './Bar';
import HeaderNav from './Nav';

const useStyles = makeStyles((theme) => ({
	appBar: {
		color: theme.palette.header.color,
		backgroundColor: theme.palette.header.background,
		flex: '0 1 auto',
		transform: 'translateX(0%)'
	},
	appBarAnimated: {
		top: 0,
		left: 0,
		right: 0,
		zIndex: theme.zIndex.appBar,
		position: 'fixed',
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarPinned: {
		transform: `translateY(-38px)`,
		[theme.breakpoints.down('sm')]: {
			transform: `translateY(0%)`
		}
	},
	appBarUnPinned: {
		transform: `translateY(0%)`
	},
	appBarShift: {
		padding: '0 !important',
		transform: 'translateX(-80%)',
		transition: theme.transitions.create(['transform'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	appBarPlaceholder: {
		paddingTop: 65,
		pointerEvents: 'none',
		userSelect: 'none',
		[theme.breakpoints.up('md')]: {
			paddingTop: 82
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: 107
		},
		[theme.breakpoints.up('xl')]: {
			paddingTop: 110
		}
	}
}));

const pinThreshold = 300;

const PrivateHeader = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isPinned = useScrollTrigger({ threshold: pinThreshold });
	const isMenuOpen = useSelector((state) => state.nav.isOpen);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const appBarRef = useRef();

	useEffect(() => {
		dispatch(
			toggleSmallNav({
				isSmall: isPinned
			})
		);
	}, [dispatch, isPinned]);

	return (
		<>
			<AppBar
				ref={appBarRef}
				role="banner"
				position="fixed"
				elevation={0}
				className={classNames('sw-header', classes.appBar, classes.appBarAnimated, {
					[classes.appBarShift]: isMenuOpen,
					[classes.appBarUnPinned]: !isPinned,
					[classes.appBarPinned]: isPinned
				})}
			>
				{isAuthenticated && <HeaderBar />}
				<HeaderNav />
			</AppBar>
			<div className={classes.appBarPlaceholder} />
		</>
	);
};

export default PrivateHeader;
