/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import Menu from '../../Menu';

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block'
	},
	icon: {
		marginRight: theme.spacing(1)
	},
	link: {
		display: 'block',
		textDecoration: 'none',
		padding: theme.spacing(2.4),
		borderBottom: '1px solid transparent'
	},
	linkLevel2: {
		fontWeight: 400,
		fontSize: theme.typography.body2.fontSize
	},
	linkLevelNested: {
		paddingLeft: theme.spacing(7.2)
	},
	linkLevel2Primary: {
		color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.headerBar.background,
		borderBottomColor: theme.palette.headerNav.background
	},
	linkLevel2Secondary: {
		color: theme.palette.headerNavResp.secondary.level2.link,
		backgroundColor: theme.palette.headerNavResp.secondary.level2.background,
		borderBottomColor: theme.palette.headerNavResp.secondary.level2.border
	},
	linkActive: {
		'color': theme.palette.headerNav.linkActive,
		'backgroundColor': theme.palette.headerNav.linkBackgroundActive,
		'borderBottomColor': theme.palette.headerBar.background,
		'transition': theme.transitions.create(['color', 'background-color']),
		'&:hover, &:focus': {
			color: theme.palette.headerNav.linkActive,
			backgroundColor: theme.palette.headerNav.linkBackgroundActive
		}
	}
}));

const Item = ({ route, isPrimary, isSecondary, isNested, useLongName }) => {
	const classes = useStyles();
	const { t } = useTranslation('common');

	const MakeItem = (routeObj) => {
		const { name, nameLong, path, renderItem, displayOnly } = routeObj;
		const pathname = useSelector((state) => state.router.location.pathname);
		const isItemActive = matchPath(pathname, { path, exact: true });
		const href = Array.isArray(path) ? path[0] : path ? path : '#';

		if (routeObj.isSubMenu) {
			return null;
		}

		if (renderItem) {
			return renderItem();
		}

		return (
			<li
				key={name}
				className={classNames(classes.item, {
					[classes.linkLevel2Primary]: isPrimary,
					[classes.linkLevel2Secondary]: isSecondary
				})}
			>
				{!displayOnly ? (
					<a
						href={href}
						className={classNames(classes.link, classes.linkLevel2, {
							[classes.linkLevelNested]: isNested,
							[classes.linkLevel2Primary]: isPrimary,
							[classes.linkLevel2Secondary]: isSecondary,
							[classes.linkActive]: Boolean(isItemActive)
						})}
					>
						<FontAwesomeIcon icon={faCaretRight} className={classes.icon} />
						{useLongName ? t(nameLong ? nameLong : name) : t(name)}
					</a>
				) : (
					<span
						className={classNames(classes.link, classes.linkLevel2, {
							[classes.linkLevel2Secondary]: isSecondary
						})}
					>
						{t(name)}
					</span>
				)}
				{route.routes ? (
					<Menu
						items={Object.values(routeObj.routes)}
						isOpen={true}
						isNested
						isPrimary={isPrimary}
						isSecondary={isSecondary}
						useLongName={true}
					/>
				) : null}
			</li>
		);
	};

	/**
	 * Fix for react-router / React sub menus.
	 * react-router requires sub-menus as Objects in an Array,
	 * which results in wrong {route} structure as required here.
	 * This is apparent where there is no direct access to {route.name}
	 * So, we deconstruct the passed {route} Object into the required form.
	 */
	if (!route.name) {
		const routeArr = Object.values(route);

		return (
			<>
				{routeArr.map(function (routeObj) {
					return MakeItem(routeObj);
				})}
			</>
		);
	} else {
		return MakeItem(route);
	}
};

export default Item;
