/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { TOGGLE_NAV, TOGGLE_SMALL_NAV } from '../../actions/components/nav';

const initialState = {
	isOpen: false,
	isSmall: false
};

export default function navReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NAV:
			return {
				...state,
				isOpen: action.payload.isOpen
			};
		case TOGGLE_SMALL_NAV:
			return {
				...state,
				isSmall: action.payload.isSmall
			};
		default:
			return state;
	}
}
