/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Box } from '@material-ui/core';
import LoaderCircular from '../Circular';

const LoaderPage = ({ size = 36 }) => (
	<Box textAlign="center" mt={2} mb={2}>
		<LoaderCircular size={size} />
	</Box>
);

export default LoaderPage;
