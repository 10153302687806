/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../components/Inputs/Button';
import TextFieldCustom from '../../../../components/Inputs/Formik/TextField';
import axios from 'axios';
import { hideModal } from '../../../../state/actions/components/modals';
import { faCaretDown, faCaretUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormikAutocomplete from '../../../../components/Inputs/Formik/Autocomplete';
import { fetchDataTableData } from '../../../../state/actions/components/datatables';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.grey[500],
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(3)
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	},
	field: {
		marginTop: theme.spacing(3)
	},
	reminder: {
		fontWeight: 'bold'
	},
	sharingOption: {
		cursor: 'pointer',
		fontWeight: 'bold',
		display: 'inline-block'
	},
	caret: {
		color: theme.palette.reports.arrow.color
	},
	addRecipientsModal: {
		overflow: 'hidden'
	},
	sharingOption2Options: {
		paddingLeft: 15
	},
	sharingOption1Options: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		flexWrap: 'wrap',
		paddingLeft: 15
	},
	dialogContent: {
		minHeight: '50vh',
		maxHeight: '50vh'
	},

	shareOtherOptions: {
		paddingTop: '5px'
	},

	shareOtherOptionsButtonGroup: {},
	hiddenField: {
		position: 'absolute',
		visibility: 'hidden'
	},
	activeButton: {
		'backgroundColor': theme.palette.reports.button.hoverBackgroundColor,
		'color': theme.palette.primary.contrastText,
		'marginLeft': 2,
		'&:hover': {
			backgroundColor: theme.palette.reports.button.hoverBackgroundColor,
			color: theme.palette.primary.contrastText
		}
	},
	formatButton: {
		'marginLeft': 2,
		'backgroundColor': theme.palette.cancelButton.backgroundColor,
		'borderColor': theme.palette.cancelButton.borderColor,
		'color': theme.palette.primary.light,
		'border': '1px solid',
		'&:hover': {
			backgroundColor: theme.palette.cancelButton.backgroundColor,
			borderColor: theme.palette.cancelButton.borderColor,
			color: theme.palette.primary.light
		}
	},
	reminderText: {
		marginLeft: 20,
		lineHeight: 2
	},
	warningPortalUser: {
		marginTop: 5,
		backgroundColor: theme.palette.status.warning.background,
		padding: '8px 35px 8px 14px',
		borderRadius: 4,
		color: theme.palette.status.warning.color,
		lineHeight: 1.35
	},
	errorEmails: {
		marginTop: 5,
		backgroundColor: theme.palette.reports.errorMessage.background,
		padding: '8px 35px 8px 14px',
		borderRadius: 4,
		color: theme.palette.reports.errorMessage.color,
		lineHeight: 1.35
	}
}));

const SHARING_CONTACTS_URL = '/portal/reports/sharingContactsList';

const emailAddressPattern = /^([\w.-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

const AddRecipientsModal = ({ isOpen, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('reports');
	const { row, dataCallbackFn } = data;
	const [isOpenSharingOption2, setIsOpenSharingOption2] = useState(false);
	const [isOpenSharingOption1, setIsOpenSharingOption1] = useState(false);
	const [sharingContacts, setSharingContacts] = useState([]);
	const [loadingContacts, setLoadingContacts] = useState(false);
	const [errorLoadingContacts, setErrorLoadingContacts] = useState(false);
	const [otherFormatOptions, setOtherFormatOptions] = useState();
	const [errorMessage, setErrormessage] = useState({ message: '', show: false });
	const [portalUserEmails, setPortalUserEmails] = useState([]);
	const [portalWarnUserEmails, setPortalWarnUserEmails] = useState([]);

	const validateExternalEmails = (value) => {
		let inValidEmails = [];
		let isEmptyEmail = false;
		let errorMsg;
		let externalEmailsText = value;
		if (value?.trim().length === 0) {
			setErrormessage({ message: '', show: false });
			return undefined;
		}
		const emails = externalEmailsText?.split(',');
		if (externalEmailsText?.length > 0) {
			for (let i = 0; i < emails?.length; i++) {
				let email = emails[i].trim();
				if (!emailAddressPattern.test(email)) {
					if (email.length === 0) {
						isEmptyEmail = true;
					} else {
						inValidEmails.push(email);
					}
				}
			}
			if (inValidEmails?.length > 0 || isEmptyEmail) {
				if (isEmptyEmail && inValidEmails?.length > 0) {
					errorMsg = inValidEmails.toString() + ' & ' + t('reports:errorMsgs.emptyEmailAddressError');
				} else if (isEmptyEmail) {
					errorMsg = t('reports:errorMsgs.emptyEmailAddressError');
				} else {
					errorMsg = inValidEmails.toString();
				}
				setErrormessage({ message: errorMsg, show: true });
				return true;
			} else {
				setErrormessage({ message: '', show: false });
				return undefined;
			}
		}
		return undefined;
	};

	const onExternalEmailsChange = () => {
		const emails = formRef.current.values.externalEmails?.split(',');
		let portalWarnEmails = emails?.filter((email) => portalUserEmails.includes(email.trim().toLowerCase()));
		setPortalWarnUserEmails(portalWarnEmails);
	};

	useEffect(() => {
		const currentContact = window.APP_DATA?.contact || {};
		const getSharingContacts = () => {
			setLoadingContacts(true);
			setErrorLoadingContacts(false);
			axios
				.get(SHARING_CONTACTS_URL, {
					params: {
						reportId: row.original.reportId,
						reportType: row.original.reportType
					}
				})
				.then(({ data }) => {
					let portalEmails = [];
					let contactsData = [];
					data.map((c) => {
						const href = c.contactRef.href;
						if (c.contactRef.href !== currentContact?.href) {
							portalEmails.push(c.email.trim().toLowerCase());
							contactsData.push({
								value: href.substring(href.lastIndexOf('/') + 1),
								label: c.name + ' / ' + c.email
							});
						}
					});
					setPortalUserEmails(portalEmails);
					setSharingContacts(contactsData);
					setLoadingContacts(false);
				})
				.catch(() => {
					setErrorLoadingContacts(true);
					setLoadingContacts(false);
				});
		};
		isOpen && getSharingContacts();
	}, [row, isOpen, setPortalUserEmails]);

	const formRef = useRef();

	const InitialValues = useMemo(
		() => ({
			contacts: [],
			displayOnExecutedReports: true,
			emailNotification: undefined,
			requestAck: undefined,
			pdfPassword: '',
			externalEmails: undefined
		}),
		[]
	);

	function handleClose() {
		setIsOpenSharingOption1(false);
		setIsOpenSharingOption2(false);
		setSharingContacts([]);
		setPortalWarnUserEmails([]);
		dispatch(hideModal({ name: 'ADD_RECIPIENTS_MODAL' }));
	}

	function handleSubmit(values) {
		const url = '/portal/reports/executedReport';
		axios(url, {
			method: 'PUT',
			data: {
				sharedUsers: values.contacts.map((contact) => contact.value),
				ackRequired: values.requestAck,
				notifyByEmail: values.emailNotification,
				emailAttachmentFormat:
					values.emailNotification && otherFormatOptions !== '' ? otherFormatOptions : null,
				externalEmailAddresses: values.externalEmails?.split(','),
				password: values.pdfPassword?.length > 0 ? values.pdfPassword : undefined
			},
			params: {
				reportName: row.original.reportName,
				isOnlyIhub: row.original.isOnlyIhub,
				reportIds: row.original.reportId,
				jobId: row.original.iHubJobId,
				reportType: row.original.reportType,
				reportFileId: row.original.iHubFileId,
				createdTimeInSec: parseInt(row.original.reportTime) / 1000,
				iHubFilePath: row.original.iHubFilePath
			}
		})
			.then(() => {
				dispatch(
					fetchDataTableData({ name: 'EXECUTED_REPORTS', action: 'MODIFY', dataCallbackFn: dataCallbackFn })
				);
				handleClose();
			})
			.catch(() => {
				dispatch(
					fetchDataTableData({ name: 'EXECUTED_REPORTS', action: 'MODIFY', dataCallbackFn: dataCallbackFn })
				);
				handleClose();
			});
	}

	const toggleSharingOption2 = () => {
		isOpenSharingOption2 ? setIsOpenSharingOption2(false) : setIsOpenSharingOption2(true);
	};
	const toggleSharingOption1 = () => {
		isOpenSharingOption1 ? setIsOpenSharingOption1(false) : setIsOpenSharingOption1(true);
	};

	const handleSharingOption = (option) => {
		option === otherFormatOptions ? setOtherFormatOptions('') : setOtherFormatOptions(option);
	};

	const checkRequiredFields = () => {
		if (formRef.current?.values.externalEmails?.length > 0) {
			return formRef.current?.values.pdfPassword?.length > 0;
		} else if (formRef.current?.values.contacts?.length > 0) {
			return true;
		}
	};

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="add-recipients-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<Formik onSubmit={handleSubmit} initialValues={InitialValues} innerRef={formRef}>
				{({ submitForm, isSubmitting, isValid }) => (
					<Form autoComplete="off">
						<DialogTitle id="add-recipients-modal" disableTypography>
							<Typography variant="h2">
								<Box fontWeight="fontWeightRegular">{t('modals.addRecipients.title')}</Box>
								<IconButton
									size="small"
									aria-label="close"
									onClick={handleClose}
									className={classes.closeButton}
								>
									<FontAwesomeIcon icon={faTimes} size="1x" />
								</IconButton>
							</Typography>
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContent}>
							<>
								<Box mb={1} onClick={toggleSharingOption1}>
									<Typography className={classes.sharingOption}>
										{t('modals.addRecipients.sharingOption1.message')}
									</Typography>
									<IconButton>
										{isOpenSharingOption1 ? (
											<FontAwesomeIcon icon={faCaretUp} className={classes.caret}>
												{' '}
											</FontAwesomeIcon>
										) : (
											<FontAwesomeIcon icon={faCaretDown} className={classes.caret}>
												{' '}
											</FontAwesomeIcon>
										)}
									</IconButton>
								</Box>
								{isOpenSharingOption1 && (
									<div className={classes.sharingOption1Options}>
										<div>
											<label htmlFor="contacts" className={classes.field}>
												{t('modals.addRecipients.sharingOption1.selectRecipients')}
												<Field
													fullWidth
													id="contacts"
													name="contacts"
													component={FormikAutocomplete}
													multiple
													options={sharingContacts || []}
													getOptionLabel={(option) => option.label}
													textFieldProps={
														formRef.current.values.contacts.length === 0 && {
															placeholder: errorLoadingContacts
																? t(
																		'modals.addRecipients.sharingOption1.errorLoadingContacts'
																  )
																: loadingContacts
																? t(
																		'modals.addRecipients.sharingOption1.loadingContacts'
																  )
																: t(
																		'modals.addRecipients.sharingOption1.selectContacts'
																  )
														}
													}
													disabled={errorLoadingContacts}
													disableCloseOnSelect
													filterSelectedOptions
													className={classes.field}
													noOptionsText={t(
														'modals.addRecipients.sharingOption1.noMatchesFound'
													)}
												/>
											</label>
										</div>
										<div>
											<Field
												type="checkbox"
												name="displayOnExecutedReports"
												id="displayOnExecutedReports"
												className={classes.field}
												disabled
											/>
											<label htmlFor="displayOnExecutedReports" className={classes.field}>
												{t('modals.addRecipients.sharingOption1.displayOnExecuted')}
											</label>
										</div>
										<div>
											<Field
												type="checkbox"
												name="emailNotification"
												id="emailNotification"
												className={classes.field}
												disabled={
													formRef.current && formRef.current.values.contacts.length === 0
												}
											/>

											<label htmlFor="emailNotification" className={classes.field}>
												{t('modals.addRecipients.sharingOption1.notifyByEmail')}
											</label>
											{formRef.current && formRef.current.values.emailNotification && (
												<div className={classes.shareOtherOptions}>
													<span className={classes.reminderText}>
														{t('modals.addRecipients.sharingOption1.reminderText')}
													</span>
													<span className={classes.shareOtherOptionsButtonGroup}>
														<Button
															size={'small'}
															variant="outlined"
															className={
																otherFormatOptions === 'pdf'
																	? classes.activeButton
																	: classes.formatButton
															}
															onClick={() => handleSharingOption('pdf')}
														>
															{t('modals.addRecipients.sharingOption1.formats.pdf')}
														</Button>
														<Button
															size={'small'}
															variant="outlined"
															className={
																otherFormatOptions === 'xml'
																	? classes.activeButton
																	: classes.formatButton
															}
															onClick={() => handleSharingOption('xml')}
														>
															{t('modals.addRecipients.sharingOption1.formats.xml')}
														</Button>
														<Button
															size={'small'}
															variant="outlined"
															className={
																otherFormatOptions === 'csv'
																	? classes.activeButton
																	: classes.formatButton
															}
															onClick={() => handleSharingOption('csv')}
														>
															{t('modals.addRecipients.sharingOption1.formats.csv')}
														</Button>
														<Button
															size={'small'}
															variant="outlined"
															className={
																otherFormatOptions === 'json'
																	? classes.activeButton
																	: classes.formatButton
															}
															onClick={() => handleSharingOption('json')}
														>
															{t('modals.addRecipients.sharingOption1.formats.json')}
														</Button>
													</span>
												</div>
											)}
										</div>
										<div>
											<label className={classes.field}>
												<Field
													type="checkbox"
													name="requestAck"
													id="requestAck"
													className={classes.field}
													disabled={
														formRef.current && formRef.current.values.contacts.length === 0
													}
												/>
												{t('modals.addRecipients.sharingOption1.requestAck')}
											</label>
										</div>
									</div>
								)}
								<Box mb={2} pb={1} onClick={toggleSharingOption2}>
									<Typography className={classes.sharingOption}>
										{' '}
										{t('modals.addRecipients.sharingOption2.message')}{' '}
									</Typography>
									<IconButton>
										{isOpenSharingOption2 ? (
											<FontAwesomeIcon icon={faCaretUp} className={classes.caret}>
												{' '}
											</FontAwesomeIcon>
										) : (
											<FontAwesomeIcon icon={faCaretDown} className={classes.caret}>
												{' '}
											</FontAwesomeIcon>
										)}
									</IconButton>
								</Box>
								{isOpenSharingOption2 && (
									<div className={classes.sharingOption2Options}>
										<Box mb={2}>
											<Typography>
												{' '}
												{t('modals.addRecipients.sharingOption2.instruction')}{' '}
											</Typography>
										</Box>
										<Box mb={2}>
											<Typography className={classes.reminder}>
												{' '}
												{t('modals.addRecipients.sharingOption2.reminder')}{' '}
											</Typography>
										</Box>
										<Field
											component={TextFieldCustom}
											name="pdfPassword"
											type="password"
											label={`${t('modals.addRecipients.sharingOption2.password')}:`}
											className={classes.field}
										/>
										<Field
											fullWidth
											component={TextFieldCustom}
											multiline
											rows={6}
											name={'externalEmails'}
											onBlur={onExternalEmailsChange}
											validate={validateExternalEmails}
											label={`${t('modals.addRecipients.sharingOption2.emailList')}:`}
											className={classes.field}
										/>
										{errorMessage.show && (
											<div className={classes.errorEmails}>
												<span>{t('modals.addRecipients.sharingOption2.errorEmails')}</span>
												{errorMessage.message}
											</div>
										)}
										{portalWarnUserEmails?.length > 0 && (
											<div className={classes.warningPortalUser}>
												<span>
													{t(
														'reports:modals.addRecipients.sharingOption2.emailListWarningPortalUser'
													)}
												</span>
												<span>{' ' + portalWarnUserEmails.join(',') + ' '}</span>
												<span>
													{t(
														'reports:modals.addRecipients.sharingOption2.emailListWarningInstruction'
													)}
												</span>
											</div>
										)}
									</div>
								)}
								{row.original.sharingInfoModels?.length > 0 && (
									<div>
										<Typography component={'span'}>
											{'(' + t('modals.addRecipients.previouslySent')}
										</Typography>
										{row.original.sharingInfoModels?.map((sharingInfoModel, i) => {
											return sharingInfoModel.isInternalUser ? (
												<Typography component={'span'} key={sharingInfoModel.contactHref}>
													{i === 0
														? ' ' + sharingInfoModel.contactName
														: ', ' + sharingInfoModel.contactName}
												</Typography>
											) : (
												<Typography component={'span'} key={sharingInfoModel.contactHref}>
													{i === 0
														? ' ' + sharingInfoModel.emailAddress
														: ', ' + sharingInfoModel.emailAddress}
												</Typography>
											);
										})}
										{')'}
									</div>
								)}
							</>
						</DialogContent>

						<DialogActions className={classes.dialogActions}>
							<Button onClick={handleClose} variant="outlined" color="default">
								{t('buttons.cancel')}
							</Button>

							<Button
								onClick={submitForm}
								isLoading={isSubmitting}
								disabled={!isValid || checkRequiredFields() !== true}
							>
								{t('buttons.submit')}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddRecipientsModal;
