/**
 * Classification': '//SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { createMuiTheme } from '@material-ui/core/styles';
import baseTheme from '../Base';

export default createMuiTheme({
	...baseTheme,
	themeName: 'Secureworks Dark',
	palette: {
		type: 'dark',
		common: { black: '#000', white: '#fff', blue: '#473eff', blueDark: '#2600b3' },
		primary: {
			light: '#2f3b53',
			main: '#232e42',
			dark: '#0f1523',
			contrastText: '#fff'
		},
		secondary: {
			light: '#9f9aff',
			main: '#473eff',
			dark: '#2600b3',
			contrastText: '#fff'
		},
		error: {
			light: '#ff463c',
			main: '#ce1126',
			dark: '#8e0b1b',
			contrastText: '#fff'
		},
		success: {
			light: '#40d67d',
			main: '#28bb64',
			dark: '#257545',
			contrastText: '#fff'
		},

		text: {
			primary: '#b5b5b5',
			secondary: '#969696',
			disabled: '#8092a4',
			hint: '#3C4D6F',
			icon: '#3C4D6F'
		},
		background: {
			paper: '#2F3B53',
			default: '#232E42',
			stripe: '#2F3B53',
			level2: '#3C4D6F',
			level1: '#0F1523'
		},
		card: {
			background: '#0F1523',
			border: '#0F1523',
			width: '288px',
			minHeight: '105px',
			outlineColor: '#8091AA',
			watermark: {
				opacity: 0.1
			},
			color: '#B5B5B5',
			status: {
				ok: '#388e3c',
				warn: '#ff9800'
			},
			tooltip: {
				backgroundColor: '#FFF',
				color: 'rgba(0, 0, 0, 0.87)',
				opacity: '0.8',
				fontSize: '14px'
			},
			modal: {
				btnClose: {
					background: '#9F9AFF',
					fontColor: '#232E42',
					btnHover: {
						background: '#9F9AFF',
						fontColor: '#fefefe'
					}
				}
			}
		},
		action: {
			active: '#9F9AFF',
			disabled: '#8092a4',
			disabledBackground: '#232E42',
			hover: '#3C4D6F',
			hoverOpacity: 0.1,
			selected: '#969696'
		},
		link: {
			color: '#9F9AFF',
			colorHover: '#fff',
			colorContrast: '#fff'
		},
		divider: '#0F1523',
		infoIcon: {
			color: '#d1cccc'
		},

		input: {
			color: '#d1d1d1',
			border: '#2d374f',
			background: '#0F1523',
			placeholder: '#b5b5b5',
			disabled: '#8092a4',
			disabledBackground: '#2f3b53'
		},

		skeleton: {
			background: '#232E42'
		},

		disabled: {
			color: '#8092a4',
			border: '#2f3b53',
			background: '#2f3b53'
		},
		healthStatus: {
			unknown: '#8092a4',
			warning: '#f28219',
			bad: '#cf1125',
			good: '#afc01a'
		},
		severity: {
			medium: '#f28219',
			high: '#cf1125',
			low: '#ffc711'
		},
		status: {
			error: {
				color: '#fff',
				border: '#ce1126',
				background: '#ce1126'
			},
			info: {
				color: '#181818',
				border: '#fff',
				background: '#d9d7ff',
				outlined: {
					link: '#2600b3'
				}
			},
			success: {
				color: '#181818',
				border: '#fff',
				background: '#a9edc5'
			},
			warning: {
				color: '#181818',
				border: '#fff',
				background: '#fff1c2'
			}
		},

		/* <Chat /> */
		chat: {
			frame: {
				border: '#473eff',
				background: '#192031'
			},
			message: {
				in: {
					color: '#fff',
					background: '#3D4D6F'
				},
				out: {
					color: '#EFEFEF',
					background: '#2E374F'
				}
			},
			divider: '#2E374F',
			button: {
				disabled: {
					color: '#8092a4',
					background: '#2f3b53'
				}
			}
		},

		/* <DataTable /> <PivotDataTable/>*/
		table: {
			borderColor: '#242F46',
			cell: {
				head: {
					color: '#b5b5b5',
					background: '#192031',
					dropdown: {
						border: '#192031',
						title: {
							background: '#192031'
						},
						dropdownItem: {
							color: '#9F9AFF',
							backgroundActive: '#3C4D6F'
						}
					}
				},
				body: {
					color: '#b5b5b5',
					background: '#2f3b53',
					border: '#2f3b53'
				},
				selected: {
					color: '#b5b5b5',
					background: '#1f623a'
				},
				striped: {
					color: '#b5b5b5',
					background: '#242f46'
				},
				hover: {
					color: '#b5b5b5',
					background: '#1f623a'
				},
				highlight: {
					background: '#277b49',
					border: '#28bb64',
					color: '#efefef'
				},
				drillDownCell: {
					background: '#0F1523',
					color: '#d1d1d1'
				}
			},
			sort: {
				color: '#9f9aff',
				colorHover: '#9f9aff'
			}
		},
		/* <ApexCharts /> */
		charts: {
			colors: ['#473eff', '#28bb64', '#ffc80f', '#d1007a', '#ff463c', '#969696']
		},
		/* <Accordion /> */
		accordion: {
			background: '#2F3B53',
			border: '#8092A4',
			color: '#b5b5b5'
		},
		accordionSummary: {
			background: '#242f46',
			border: '#8092A4',
			color: '#9f9aff',
			colorHover: '#b5b5b5'
		},
		accordionDetails: {
			background: '#2F3B53',
			color: '#b5b5b5'
		},
		/* <Autocomplete /> */
		autocomplete: {
			color: '#b5b5b5',
			colorError: '#ce1126',
			border: '#192031',
			borderError: '#ce1126',
			background: '#2d374f',
			colorDisabled: '#8092a4',
			borderDisabled: '#232E42',
			backgroundDisabled: '#232E42',
			chip: {
				background: '#2F3B53',
				borderColor: '#232e42',
				color: '#8092A4'
			}
		},
		/* <Dropdown /> */
		dropdown: {
			background: '#2F3B53',
			border: '#2F3B53',
			color: '#9F9AFF'
		},
		dropdownItem: {
			color: '#9F9AFF',
			divider: '#192031',
			colorHover: '#efefef',
			colorActive: '#9F9AFF',
			backgroundActive: '#3C4D6F',
			backgroundHover: '#3C4D6F',
			colorDisabled: '#8092a4',
			backgroundDisabled: '#232E42'
		},
		/* <Header /> */
		header: {
			background: '#232E42',
			color: '#CDD2D5'
		},
		headerBar: {
			background: '#232E42',
			color: '#CDD2D5',
			link: '#efefef',
			icon: '#8092A4',
			iconHover: '#b5b5b5',
			feedback: '#95A6B7',
			input: '#0F1523',
			searchInput: '#d1d1d1'
		},
		headerNav: {
			background: '#2F3B53',
			color: '#fff',
			link: '#efefef',
			linkHover: '#efefef',
			linkBackgroundHover: '#3C4D6F',
			linkBorderHover: '#232E42',
			linkActive: '#efefef',
			linkBackgroundActive: '#7470D1',
			icon: '#8092A4',
			iconHover: '#efefef'
		},
		headerNavResp: {
			background: '#efefef',
			secondary: {
				level1: {
					background: '#d1d1d1',
					border: '#777',
					link: '#3d3d3d'
				},
				level2: {
					background: '#efefef',
					border: '#b5b5b5',
					link: '#3d3d3d'
				}
			}
		},
		/* Content */
		content: {
			default: {
				background: '#232e42'
			},
			light: {
				background: '#232e42'
			}
		},
		/* <Footer /> */
		footer: {
			background: '#0F1523',
			text: '#8092A4'
		},
		footerBar: {
			background: '#0F1523',
			border: '#8092A4',
			text: '#8092A4',
			link: '#9F9AFF',
			linkHover: '#7470D1'
		},
		footerNav: {
			background: '#0F1523',
			border: '#473eff',
			text: '#8092A4',
			link: '#8092A4',
			linkHover: '#7470D1',
			icon: '#2F3B53',
			iconHover: '#7470D1'
		},
		/* <Sidebar /> */
		sidebar: {
			background: '#232E42',
			border: '#0F1523',
			link: '#9F9AFF',
			linkHover: '#fff',
			linkBackgroundHover: '#3C4D6F',
			linkActive: '#969696',
			linkBackgroundActive: '#2F3B53',
			icon: '#969696',
			iconHover: '#fff',
			badgeColor: '#969696',
			badgeBackground: '#0F1523',
			subMenu: {
				active: '#777'
			}
		},
		/* <Pagination /> <PivotDataTable/>*/
		pagination: {
			color: '#9F9AFF',
			colorHover: '#fff',
			background: '#192031',
			backgroundHover: '#2F3B53',
			borderColor: '#2F3B53',
			activeColor: '#8092A4',
			activeBackground: '#2F3B53',
			disabledColor: '#969696'
		},
		/* <Widgets /> */
		widget: {
			background: '#0F1523',
			divider: '#242F46',
			color: '#8092A4'
		},
		widgetHead: {
			background: '#2F3B53',
			color: '#b5b5b5'
		},
		widgetTable: {
			background: '#2F3B53',
			color: '#b5b5b5'
		},
		widgetTableHead: {
			background: '#0F1523',
			color: '#b5b5b5'
		},
		widgetTableBody: {
			background: '#0F1523',
			color: '#b5b5b5'
		},
		widgetChart: {
			background: '#2F3B53',
			color: '#b5b5b5'
		},
		widgetDrillDown: {
			closeButton: {
				color: '#fff'
			},
			menu: {
				hoverColor: '#9F9AFF',
				border: '#d1d1d1'
			}
		},
		datacube: {
			panel: {
				summary: {
					background: '#463df8'
				},
				detail: {
					background: '#101624',
					content: {
						background: '#101624'
					}
				},
				rowKey: '#768797',
				rowValue: '#b0b4b5'
			},
			timesCircle: '#657487',
			label: {
				disabled: {
					text: '#1a2739'
				},
				active: {
					text: '#e4e7eb'
				}
			},
			rootNodeItemAvatar: {
				border: '#fefefe',
				background: '#0f1523'
			},
			circle: {
				disabled: {
					fill: '#3c4f6f',
					stroke: '#1a2739',
					text: '#1a2739'
				},
				active: {
					stroke: '#fefefe',
					fill: '#0f1523',
					text: '#e4e7eb'
				},
				stroke: '#7276ba',
				fill: '#3c4d6a',
				text: '#7276ba'
			},
			groupIcon: {
				circle: {
					text: '#7276ba',
					fill: '#3c4f6f'
				},

				active: {
					circle: {
						fill: '#0f1523'
					},
					avatar: {
						border: '#fefefe'
					}
				},
				avatar: {
					border: '#7276ba'
				}
			},
			filters: {
				border: '#319C5C',
				icon: {
					active: '#fff',
					disabled: '#1a2739'
				},
				label: {
					text: '#9f9aff'
				}
			}
		},
		deviceRegistration: {
			credential: {
				status: {
					enabled: '#28bb64',
					disabled: '#ffc80f'
				}
			},
			configuration: {
				input: {
					warn: '#ff463c'
				}
			}
		},
		cancelButton: {
			backgroundColor: '#9f9aff',
			color: '#0f1523',
			borderColor: '#9f9aff',
			hover: {
				backgroundColor: '#9f9aff',
				color: '#fff'
			}
		},
		nextButton: {
			backgroundColor: '#473eff',
			color: '#777',
			borderColor: '#473eff',
			disabled: {
				backgroundColor: '#2f3b53',
				color: '#777',
				borderColor: '#2f3b53'
			},
			hover: {
				backgroundColor: '#9f9aff',
				borderColor: '#9f9aff'
			}
		},
		download: {
			modal: {
				footer: {
					backgroundColor: '#212a3e',
					closeButton: {
						fontSize: '15px',
						lineHeight: '22px',
						backgroundColor: '#9f9aff',
						color: '#0f1523',
						borderColor: '#9f9aff',
						hover: {
							backgroundColor: '#9f9aff',
							color: '#fff',
							borderColor: '#9f9aff'
						}
					},
					downloadButton: {
						color: '#fff',
						backgroundColor: '#473eff',
						borderColor: '#473eff',
						disabled: {
							backgroundColor: '#2f3b53',
							color: '#8092a4',
							borderColor: '#2f3b53'
						},
						hover: {
							backgroundColor: '#9f9aff',
							borderColor: '#9f9aff',
							color: '#fff'
						}
					}
				}
			},
			configurePage: {
				header: {
					downloadButton: {
						backgroundColor: '#9f9aff',
						color: '#0f1523',
						borderColor: '#9f9aff',
						hover: {
							borderColor: '#9f9aff',
							backgroundColor: '#9f9aff',
							color: '#fff'
						}
					}
				}
			}
		},
		copyButton: {
			backgroundColor: '#9f9aff',
			color: '#0f1523',
			borderColor: '#9f9aff',
			hover: {
				backgroundColor: '#9f9aff',
				color: '#fff'
			},
			disabled: {
				backgroundColor: '#2f3b53',
				color: '#777',
				borderColor: '#2f3b53'
			}
		},
		copyToClipboardIcon: {
			color: '#9f9aff'
		},
		filterSets: {
			color: '#fff',
			backgroundColor: '#28bb64'
		},
		reports: {
			errorMessage: {
				color: '#fff',
				border: '#ce1126',
				background: '#ce1126'
			},
			button: {
				backgroundColor: '#473eff',
				hoverBackgroundColor: '#9F9AFF'
			},
			sectionHeader: {
				color: '#D1D1D7'
			},
			arrow: {
				color: '#fff'
			},
			tabBorder: {
				default: {
					color: '#2F3B55'
				},
				active: {
					color: '#8091AA'
				}
			},
			tabsSeparator: {
				borderTopColor: '#181818',
				borderBottomColor: '#232E42'
			},
			tooltip: {
				color: '#b5b5b5',
				backgroundColor: '#495D84',
				border: '#232E42'
			},
			cancelButton: {
				background: '#9F9AFF',
				color: '#232E42',
				hover: {
					background: '#9f9aff',
					color: '#fff'
				}
			},
			label: {
				color: '#D1D1D1'
			},
			error: {
				color: '#ff463c'
			},
			action: {
				disabledBackground: '#2F3B53'
			},
			modal: {
				footer: {
					backgroundColor: '#212a3e'
				},
				dividers: {
					borderColor: '#0F1523'
				}
			},
			infoIcon: {
				color: '#b5b5b5'
			},
			greenCheck: {
				color: '#28bb64'
			}
		},
		newLabel: {
			color: '#3D3D3D',
			background: '#FCC911'
		},
		transferList: {
			infoContainer: '#acabab',
			newlyMoved: {
				backgroundColor: 'rgba(217, 215, 255, 0.53)',
				hover: '#d9d7ff'
			},
			ListItemEven: '#232e42',
			ListItemEvenHover: {
				hover: '#1b2333'
			},
			ListItemOddHover: {
				hover: '#1b2333'
			},
			textColor: '#ffffff',
			checkBox: '#ffffff'
		},
		logGroupRefresh: {
			color: '#ffffff',
			border: '1px solid #ffffff'
		},
		filterbar: {
			background: '#0f1523',
			filterbarHead: {
				background: '#257545',
				button: {
					hover: '#319c5c'
				},
				chipWrapperBg: '#232e42',
				chipWrapperBgEmpty: '#2f3b53',
				applyBtn: {
					color: '#8092a4',
					hover: '#3c4757'
				},
				clearBtn: {
					color: '#9f9aff',
					hover: '#473eff',
					expanded: {
						color: '#fff'
					},
					disabled: {
						color: '#8092a4'
					},
					backgroundColor: '#2E374F'
				},
				focused: {
					borderColor: '#473eff66'
				},
				placeholderColor: '#b5b5b5'
			},
			filterbarBody: {
				color: '#969696'
			},
			filterbarFooter: {
				background: '#3c4d6f',
				button: {
					background: '#473eff',
					border: '#9f9aff',
					color: '#fff',
					hover: '#9f9aff'
				}
			}
		}
	}
});
