/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { hideModal } from '../../../state/actions/components/modals';
import * as Yup from 'yup';
import { AlertCustom } from '../../../components/Alert';
import Button from '../../../components/Inputs/Button';
import SelectCustom from '../../../components/Inputs/Formik/Select';
import TextFieldCustom from '../../../components/Inputs/Formik/TextField';

const useStyles = makeStyles((theme) => ({
	field: {
		marginTop: theme.spacing(4)
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	}
}));

const AddNetworkModal = ({ isOpen }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('common');

	const [requestState, setRequestState] = useState({
		isError: false,
		isLoading: false
	});

	const ValidationSchema = Yup.object().shape({
		type: Yup.string().required(),
		cidr: Yup.string().required(),
		ipAddress: Yup.string().required(),
		description: Yup.string()
	});

	function handleClose() {
		dispatch(hideModal());
	}

	function handleSubmit() {
		setRequestState({
			isError: false,
			isLoading: true
		});
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="add-network-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<Formik
				onSubmit={handleSubmit}
				validationSchema={ValidationSchema}
				initialValues={{
					type: '',
					cidr: '',
					ipAddress: '',
					description: ''
				}}
				initialErrors={{
					type: '',
					cidr: '',
					ipAddress: '',
					description: ''
				}}
			>
				{({ submitForm, isSubmitting, isValid }) => (
					<Form autoComplete="off">
						<DialogTitle id="add-network-modal" disableTypography>
							<Typography component="h2" variant="h3">
								{t('modals.addNetwork.title')}
							</Typography>
						</DialogTitle>
						<DialogContent dividers>
							{requestState.isError ? (
								<AlertCustom severity="error" variant="outlined" gutterBottom>
									Add network error
								</AlertCustom>
							) : null}
							<Field
								fullWidth
								name="ipAddress"
								component={TextFieldCustom}
								label="IP Address"
								helperText="The IPv4 address associated with the network range"
							/>
							<Field
								fullWidth
								name="cidr"
								component={SelectCustom}
								options={[
									{ value: '1', label: '1 (128.0.0.0)' },
									{ value: '2', label: '2 (192.0.0.0)' },
									{ value: '3', label: '3 (224.0.0.0)' },
									{ value: '4', label: '4 (240.0.0.0)' },
									{ value: '5', label: '5 (248.0.0.0)' },
									{ value: '6', label: '6 (252.0.0.0)' },
									{ value: '7', label: '7 (254.0.0.0)' },
									{ value: '8', label: '8 (255.0.0.0)' },
									{ value: '9', label: '9 (255.128.0.0)' },
									{ value: '10', label: '10 (255.192.0.0)' },
									{ value: '11', label: '11 (255.224.0.0)' },
									{ value: '12', label: '12 (255.240.0.0)' },
									{ value: '13', label: '13 (255.248.0.0)' },
									{ value: '14', label: '14 (255.252.0.0)' },
									{ value: '15', label: '15 (255.254.0.0)' },
									{ value: '16', label: '16 (255.255.0.0)' },
									{ value: '17', label: '17 (255.255.128.0)' },
									{ value: '18', label: '18 (255.255.192.0)' },
									{ value: '19', label: '19 (255.255.224.0)' },
									{ value: '20', label: '20 (255.255.240.0)' },
									{ value: '21', label: '21 (255.255.248.0)' },
									{ value: '22', label: '22 (255.255.252.0)' },
									{ value: '23', label: '23 (255.255.254.0)' },
									{ value: '24', label: '24 (255.255.255.0)' },
									{ value: '25', label: '25 (255.255.255.128)' },
									{ value: '26', label: '26 (255.255.255.192)' },
									{ value: '27', label: '27 (255.255.255.224)' },
									{ value: '28', label: '28 (255.255.255.240)' },
									{ value: '29', label: '29 (255.255.255.248)' },
									{ value: '30', label: '30 (255.255.255.252)' },
									{ value: '31', label: '31 (255.255.255.254)' },
									{ value: '32', label: '32 (255.255.255.255)' }
								]}
								placeholder={t('common:buttons.select')}
								label="CIDR (Netmask)"
								helperText="Select the CIDR routing prefix for the network range"
								className={classes.field}
							/>
							<Field
								fullWidth
								name="type"
								component={SelectCustom}
								options={[
									{
										label: 'Public (Not Used Internally)',
										value: 'public'
									},
									{
										label: 'NAT',
										value: 'nat'
									},
									{
										label: 'Internal',
										value: 'internal'
									},
									{
										label: 'DMZ',
										value: 'dmz'
									}
								]}
								placeholder={t('common:buttons.select')}
								label="Type"
								className={classes.field}
								helperText="Select the type of network this range represents"
							/>
							<Field
								multiline
								fullWidth
								rows={3}
								name="description"
								component={TextFieldCustom}
								className={classes.field}
								label="Description"
								helperText="Free-form notes about or description of the network range"
							/>
						</DialogContent>

						<DialogActions className={classes.dialogActions}>
							<Button
								onClick={handleClose}
								variant="text"
								color="default"
								disabled={requestState.isLoading}
							>
								{t('buttons.cancel')}
							</Button>
							<Button onClick={submitForm} isLoading={isSubmitting} disabled={!isValid}>
								{t('buttons.submit')}
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddNetworkModal;
