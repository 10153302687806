/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import LinkComponent from '../../../Link';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	chatHelpLink: {
		color: theme.palette.action.active,
		display: 'block',
		paddingTop: theme.spacing(2)
	},
	infoIconStack: {
		'position': 'relative',
		'& svg': {
			position: 'absolute'
		}
	},
	infoIcon: {
		width: '0.2rem!important',
		left: '5px'
	},
	infoLink: {
		marginLeft: '1.25rem'
	}
}));

const ChatHelpLink = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const chatHelpUrl = `${window.APP_DATA.urls.cdn}/help/FAQ_-_Live_Chat.htm`;

	const openChatHelp = (e) => {
		e.preventDefault();

		window.open(chatHelpUrl);
	};

	return (
		<>
			<Tooltip title={t('common:chat.buttons.chatFaqTip')} placement="top">
				<LinkComponent
					className={classes.chatHelpLink}
					href={chatHelpUrl}
					onClick={openChatHelp}
					variant={'body2'}
					rel="noopener noreferrer"
				>
					<span className={classes.infoIconStack + ' chat-faq-icon'}>
						<FontAwesomeIcon icon={faCircle} className={classes.circleIcon + ' chat-faq-circle-icon'} />
						<FontAwesomeIcon icon={faInfo} className={classes.infoIcon + ' chat-faq-info-icon'} />
					</span>
					<span className={classes.infoLink}>{t('common:chat.buttons.chatFaq')}</span>
				</LinkComponent>
			</Tooltip>
		</>
	);
};

export default ChatHelpLink;
