import React, { useState } from 'react';
// import Highcharts from 'highcharts';
// import { theme } from '@portal-reporting/report-ui-templates/themes/highcharts-theme';
import axios from 'axios';
import { EventsDrillDown, Executive } from '@portal-reporting/report-ui-templates';
import '@portal-reporting/report-ui-templates/dist/styles/report-styles.css';
import 'highcharts/css/highcharts.css';
import { DrillDownMode } from './drilldown-mode';
import EventsDataTable from './Express';
import { buildQueryString } from '@portal-reporting/report-ui-templates/dist/utils';
import { EventReportChartType } from '@portal-reporting/report-ui-templates/dist/components/executive/enterprise/constants';

/*if (typeof Highcharts === 'object') {
	Highcharts.setOptions(theme);
}*/

export const REPORT_DATA_URL = '/portal/reports/reportData';

export const ExpressEventChartType = {
	EXECSUM_ATTACK_SUMMARY: 'Attack_Summary_Chart',
	EXECSUM_EVENT_ACTION_TREND: 'Event_Action_Trend',
	EXECSUM_ATTACK_EVENTS: 'Attack_Events_Chart',
	EXECSUM_INFORMATIONAL_EVENTS: 'Informational_Events_Chart',
	EXECSUM_TOP_ATTACKED_SERVICES: 'Top_Attacked_Ports_Chart',
	EXECSUM_TOP_ATTACKS: 'Top_Attacks_Chart'
};

const defaultEnterpriseEventsPayload = {
	eventReportType: 'EXECSUM_ATTACK_SUMMARY',
	reportSection: null,
	reportSectionData: null,
	granularity: null,
	startDate: null,
	endDate: null
};

const defaultExpressEventsParameters = {
	chartType: '',
	drilledFilterValue: '',
	drilledFilterValueToDisplay: '',
	payload: null,
	timeZone: '',
	numRowsToRetrieve: 1000,
	isRequiredRefresh: true,
	redirectedEventId: '',
	reportType: 'Executive Summary'
};

function getExpressEventsFilters(parameters) {
	const expressParameters = { ...defaultExpressEventsParameters };
	expressParameters.chartType = ExpressEventChartType[parameters.eventReportType];
	expressParameters.drilledFilterValue = parameters.reportSection;
	if (parameters.eventCategory) {
		expressParameters.drilledFilterValueToDisplay = parameters.eventCategory;
	} else {
		expressParameters.drilledFilterValueToDisplay = parameters.reportSection;
	}

	return expressParameters;
}

const ExecutiveMain = (props) => {
	const { data, locale, t, expressDrillDownLimit, dataParams } = props;
	const [reportViewType, setReportViewType] = useState('main');
	const [scrollPosition, setScrollPosition] = useState({ executiveSummary: 0 });
	const [drillDownMode, setDrillDownMode] = useState('Express');
	const [enterpriseEventsPayload, setEnterpriseEventsPayload] = useState({ ...defaultEnterpriseEventsPayload });
	const [expressEventsParameters, setExpressEventsParameters] = useState({ ...defaultExpressEventsParameters });
	const [loadingExpressPayload, setLoadingExpressPayload] = useState(false);
	const [breadCrumbLinks, setBreadCrumbLinks] = useState({});

	const handleDrillDownModeChange = (mode) => {
		setDrillDownMode(mode);
		setReportViewType('main');
	};

	const updateScrollPosition = (currentReportViewType) => {
		if (currentReportViewType === 'main') {
			setScrollPosition({ ...scrollPosition, executiveSummary: window.pageYOffset });
		}
	};

	const handleScrollPosition = () => {
		if (reportViewType === 'main') {
			window.scrollTo({
				top: scrollPosition.executiveSummary ? scrollPosition.executiveSummary : 0,
				behavior: 'smooth'
			});
		}
	};

	const updateReportView = (props) => {
		updateScrollPosition(reportViewType);

		const { type, payload } = props;
		const updatedPayload = {
			...enterpriseEventsPayload,
			...payload
		};

		if (updatedPayload?.granularity && type === 'drillDown') {
			setBreadCrumbLinks({
				...breadCrumbLinks,
				[updatedPayload?.granularity]: Object.assign({}, updatedPayload)
			});
		} else {
			setBreadCrumbLinks({});
		}

		setEnterpriseEventsPayload(updatedPayload);

		if (type === 'drillDown' && drillDownMode === 'Express' && updatedPayload.eventCount <= expressDrillDownLimit) {
			setLoadingExpressPayload(true);
			const expressParameters = getExpressEventsFilters({ ...updatedPayload });
			setExpressEventsParameters(expressParameters);

			updateExpressPayload(
				{
					startDate: new Date(updatedPayload.startDate).getTime(),
					endDate: new Date(updatedPayload.endDate).getTime(),
					sectionData: updatedPayload.reportSectionData,
					reportChart: EventReportChartType[updatedPayload.eventReportType]
				},
				expressParameters
			);
		}

		/*
		 * This report view type value is used to rerender the final state of the child view components
		 * once all other required filters are updated.
		 * */
		setReportViewType(type);
	};

	const updateExpressPayload = (parameters, expressParameters) => {
		const params = {
			...dataParams,
			queryString: buildQueryString(parameters),
			section: 'drilldownPayload'
		};
		axios
			.get(REPORT_DATA_URL, { params })
			.then((response) => {
				const res = response.data;
				setExpressEventsParameters({ ...expressParameters, payload: res?.payload });
				setLoadingExpressPayload(false);
			})
			.catch(() => {
				setLoadingExpressPayload(false);
			});
	};

	return (
		<>
			{reportViewType === 'main' && (
				<>
					<DrillDownMode
						t={t}
						expressDrillDownLimit={expressDrillDownLimit}
						drillDownMode={drillDownMode}
						handleDrillDownModeChange={handleDrillDownModeChange}
					/>
					<Executive
						data={data}
						t={t}
						dataParams={dataParams}
						locale={locale}
						updateReportView={updateReportView}
						handleScrollPosition={handleScrollPosition}
						reportName={props.reportName}
					/>
				</>
			)}
			{reportViewType === 'drillDown' &&
				(drillDownMode === 'Enterprise' || enterpriseEventsPayload.eventCount > expressDrillDownLimit) && (
					<EventsDrillDown
						dataParams={dataParams}
						payload={enterpriseEventsPayload}
						t={t}
						locale={locale}
						reportName={props.reportName}
						updateReportView={updateReportView}
						breadCrumbLinks={breadCrumbLinks}
					/>
				)}
			{reportViewType === 'drillDown' &&
				drillDownMode === 'Express' &&
				enterpriseEventsPayload.eventCount <= expressDrillDownLimit && (
					<EventsDataTable
						parameters={expressEventsParameters}
						updateReportView={updateReportView}
						loadingExpressPayload={loadingExpressPayload}
					/>
				)}
		</>
	);
};

export default ExecutiveMain;
