/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.text.secondary,
		[theme.breakpoints.down('sm')]: {
			display: ({ hideTitleInSmallView }) => (hideTitleInSmallView ? 'none' : 'block')
		}
	},
	heading: {
		color: theme.palette.text.primary
	},
	subHeading: {
		color: theme.palette.text.secondary,
		fontWeight: 400,
		marginLeft: theme.spacing(1)
	}
}));

const PageMeta = ({ title, customTitle, subtitle, description, variant = 'h1', hideTitleInSmallView = false }) => {
	const { t } = useTranslation();
	const classes = useStyles({ hideTitleInSmallView });

	const pageTitle = customTitle ? customTitle : title ? t(title) : null;
	const pageDescription = description ? t(description) : null;
	const pageSubtitle = subtitle ? t(subtitle) : null;

	if (!(pageTitle || pageDescription || pageSubtitle)) {
		return null;
	}

	return (
		<>
			{pageTitle ? (
				<>
					<Helmet>
						<title>
							{pageTitle} - {t('common:app.nameFull')}
						</title>
					</Helmet>

					<Typography
						className={classes.title}
						component="h1"
						gutterBottom
						noWrap={!!pageSubtitle}
						variant="h3"
					>
						<Typography className={classes.heading} component="span" variant={variant}>
							{pageTitle}
						</Typography>

						{pageSubtitle ? (
							<Typography className={classes.subHeading} component="span" variant="h3">
								{pageSubtitle}
							</Typography>
						) : null}
					</Typography>
				</>
			) : null}
			{pageDescription ? <Typography paragraph>{pageDescription}</Typography> : null}
		</>
	);
};

export default PageMeta;
