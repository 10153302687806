/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import ConverstationViewTyping from '../Typing';
import ConverstationViewMessage from '../Message';

const ConverstationViewMessages = () => {
	const bottomNodeRef = useRef();
	const isOpen = useSelector((state) => state.chat.isOpen);
	const messages = useSelector((state) => state.chat.messages);
	const agentTyping = useSelector((state) => state.chat.agentTyping);
	const values = Object.values(messages);
	const messageList = useMemo(() => values.sort((a, b) => a.timestamp > b.timestamp), [values]);

	useEffect(() => {
		if (values.length && bottomNodeRef.current && isOpen) {
			/**
			 * When new message is received, it scrolls the container
			 * to the bottom of the list.
			 */
			bottomNodeRef.current.scrollIntoView({
				behavior: 'smooth'
			});
		}
	}, [values.length, agentTyping, isOpen]);

	return (
		<>
			{messageList.map((message, index) => {
				if (!(message && message.content)) {
					return null;
				}

				return (
					<ConverstationViewMessage
						key={message.messageId}
						message={message}
						previousMessage={values[index - 1]}
					/>
				);
			})}
			<ConverstationViewTyping />
			<div ref={bottomNodeRef} />
		</>
	);
};

export default ConverstationViewMessages;
