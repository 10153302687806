import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getFormattedDateTimeByLocale } from '../../../../pages/Reports/View/utils';

export const getARMTicketsTableColumns = function ({ t, severityClasses, classes, params }) {
	const subReportType = params?.routingCategory;
	const columns = [
		{
			headerTitle: t('incidents:summary.number'),
			id: 'number',
			accessor: 'number',
			Cell: (e) => {
				const number = e.value;
				const href = '/portal/incidents/' + number;
				if (number) {
					return (
						<a href={href} className={classes.downloadLink} target="_blank" rel="noreferrer">
							{number}
						</a>
					);
				} else {
					return <></>;
				}
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.deviceType'),
			id: 'deviceType',
			accessor: 'deviceType'
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.categorization'),
			id: 'categorization',
			accessor: 'categorization'
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.severity'),
			id: 'severity',
			accessor: (row) => {
				if (!row) {
					return null;
				}

				return row.priority?.toLowerCase();
			},
			Cell: (e) => {
				if (e.value) {
					const severity = e.value.toString();
					return (
						<>
							<FontAwesomeIcon
								title={t('incidents:severities.' + severity)}
								className={severityClasses[severity]}
								icon={faCircle}
							></FontAwesomeIcon>
							<span className={severityClasses.label}>{t('incidents:severities.' + severity)}</span>
						</>
					);
				} else {
					return <></>;
				}
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.summary'),
			id: 'summary',
			accessor: 'summary'
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.created'),
			id: 'createdDateFormat',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.createdTimestamp, { timeFormat: 'hh:mm a' });
			}
		},
		{
			headerTitle: t('incidents:incidentStatistics.table.closeCode'),
			id: 'closeCode',
			accessor: 'closeCode'
		}
	];
	if (subReportType === 'AVERAGE_RESPONSE_TIME' || subReportType === 'TICKET_VOLUMES_SEVERITY_SLA') {
		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.closedBy'),
			id: 'closedBy',
			accessor: 'closedBy'
		});

		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.assignedEmployee'),
			id: 'assignedEmployee',
			accessor: 'assignedEmployee'
		});

		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.armQueuedDate'),
			id: 'armQueuedDate',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.armQueuedTimestamp, { timeFormat: 'hh:mm a' });
			}
		});

		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.armAssignmentDate'),
			id: 'armAssignmentDate',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return getFormattedDateTimeByLocale(data.armAssignedTimestamp, { timeFormat: 'hh:mm a' });
			}
		});

		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.armResponseTime'),
			id: 'armResponseTime',
			accessor: 'armResponseTime'
		});

		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.sloStatus'),
			id: 'sloStatus',
			accessor: (data) => {
				if (!data) {
					return null;
				}

				return data.sloStatus ? t('incidents:sloStatuses.' + data.sloStatus) : '';
			}
		});
	} else {
		columns.push({
			headerTitle: t('incidents:incidentStatistics.table.assignedEmployee'),
			id: 'assignedEmployee',
			accessor: 'assignedEmployee'
		});
	}
	return columns;
};
