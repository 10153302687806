/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import LanguageSwitch from './LanguageSwitch';
import InfoBox from './InfoBox';

const useStyles = makeStyles((theme) => ({
	bar: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.footerBar.border}`,
		backgroundColor: theme.palette.footerBar.background
	},
	skeleton: {
		backgroundColor: theme.palette.footerBar.background
	}
}));

const FooterBar = () => {
	const classes = useStyles();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<div className={classes.bar}>
			<Container maxWidth={false}>
				<Grid container alignItems="center">
					<Grid item xs>
						<Suspense
							fallback={
								<Skeleton
									width={320}
									height={18}
									variant="rect"
									classes={{
										root: classes.skeleton
									}}
								/>
							}
						>
							<InfoBox />
						</Suspense>
					</Grid>
					{isAuthenticated && (
						<Grid item>
							<Suspense
								fallback={
									<Skeleton
										width={110}
										height={30}
										variant="rect"
										classes={{
											root: classes.skeleton
										}}
									/>
								}
							>
								<LanguageSwitch />
							</Suspense>
						</Grid>
					)}
				</Grid>
			</Container>
		</div>
	);
};

export default FooterBar;
