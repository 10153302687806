/**
 * Classification': '//SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';

const QualtricsFeedback = ({ id, className, ...other }) => {
	return <div id={id} className={className} {...other} />;
};

export default QualtricsFeedback;
