/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { SHOW_ALERT, HIDE_ALERT } from '../../actions/components/alerts';

const initialState = {
	queue: {},
	limit: 99
};

export default function alertsReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_ALERT:
			return {
				...state,
				queue: {
					...state.queue,
					[action.payload.id]: {
						...state.queue[action.payload.id],
						id: action.payload.id,
						type: action.payload.type,
						data: action.payload.data,
						show: action.payload.show
					}
				}
			};
		case HIDE_ALERT:
			return {
				...state,
				queue: {
					...state.queue,
					[action.payload.id]: {
						...state.queue[action.payload.id],
						show: action.payload.show
					}
				}
			};
		default:
			return state;
	}
}
