import React from 'react';
import Autocomplete from './custom';
import { useLabelStyles, useInputStyles, useTagStyles } from '../../Autocomplete';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormikAutocomplete = ({ onBlur, onChange, textFieldProps, ...props }) => {
	const labelClasses = useLabelStyles();
	const inputClasses = useInputStyles();
	const tagClasses = useTagStyles();

	const {
		form: { setTouched, setFieldValue }
	} = props;
	const { error, ...field } = fieldToTextField(props);
	const { name } = field;

	const handleBlur = () => {
		setTouched({ [name]: true });
	};

	const handleChange = (_, value) => {
		setFieldValue(name, value);
	};

	return (
		<Autocomplete
			onBlur={onBlur ? onBlur : handleBlur}
			onChange={onChange ? onChange : handleChange}
			ChipProps={{
				deleteIcon: <FontAwesomeIcon icon={faTimes} size="1x" className={tagClasses.chipDeleteIcon} />
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					fullWidth={true}
					error={!!error}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						classes: inputClasses
					}}
					InputLabelProps={{
						...params.InputLabelProps,
						classes: labelClasses
					}}
					{...textFieldProps}
				/>
			)}
			{...props}
		/>
	);
};

export default FormikAutocomplete;
