/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '../../../../Inputs/Dropdown';
import DropdownItem from '../../../../Inputs/Dropdown/Item';
import { setProgress } from '../../../../../state/actions/components/reports';

const getHref = (path) => (Array.isArray(path) ? path[0] : path ? path : '#');

const EXECUTED_REPORTS_NRS_PATH = '/portal/reports/executed';

const NavMenu = ({ navLinkEl, routes, isOpen }) => {
	const { t } = useTranslation();
	const pathname = useSelector((state) => state.router.location.pathname);

	const dispatch = useDispatch();

	const handleOnNRSExecutedReportsClick = () => {
		dispatch(
			setProgress({
				progress: 'Executed'
			})
		);
	};

	return (
		<Dropdown
			anchorEl={navLinkEl}
			isOpen={isOpen}
			hasArrow={false}
			minWidth={200}
			menuPadding={[1, 0, 1, 0]}
			menuOffset={-3}
			shouldFade={false}
			placement="bottom"
		>
			{routes.map((item, idx) => {
				if (!item) {
					return null;
				}

				if (item.isSubMenu) {
					return null;
				}

				const match = matchPath(pathname, {
					path: item.path,
					exact: true
				});

				const otherOptions = {};

				if (
					item.path === EXECUTED_REPORTS_NRS_PATH &&
					window.location.href.includes(EXECUTED_REPORTS_NRS_PATH)
				) {
					otherOptions.onClick = handleOnNRSExecutedReportsClick;
					otherOptions.button = true;
				} else {
					otherOptions.href = getHref(item.path);
				}

				return (
					<DropdownItem
						key={item.name || idx}
						selected={Boolean(match)}
						{...(item.isExternal && {
							target: '_blank',
							rel: 'noopener'
						})}
						{...otherOptions}
					>
						{t(item.name)}
					</DropdownItem>
				);
			})}
		</Dropdown>
	);
};

export default NavMenu;
