/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';

import PrivateFooter from '../../components/Footers/Private';
import PublicFooter from '../../components/Footers/Public';
import { checkRouteIfPublic } from '../../utils/access';

const Footer = () => {
	const isPublicRoute = checkRouteIfPublic();

	return isPublicRoute ? <PublicFooter /> : <PrivateFooter />;
};

export default Footer;
