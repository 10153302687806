/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';

const LoaderSkeleton = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.skeleton.background
	},
	text: {
		marginTop: 0,
		marginBottom: 0
	}
}))(Skeleton);

export default LoaderSkeleton;
