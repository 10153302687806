/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Collapse, List } from '@material-ui/core';

import Item from './Item';
import Title from './Title';

const Menu = ({ title, items, isOpen, isNested, isPrimary, isSecondary, useLongName }) => {
	const theme = useTheme();

	return (
		<Collapse
			in={isOpen}
			timeout={{
				enter: theme.transitions.duration.enteringScreen,
				exit: theme.transitions.duration.leavingScreen
			}}
		>
			<List disablePadding>
				{title ? <Title title={title} /> : null}
				{items.map((item, idx) =>
					item ? (
						<Item
							key={item.name || idx}
							route={item}
							isPrimary={isPrimary}
							isSecondary={isSecondary}
							isNested={isNested}
							useLongName={useLongName}
						/>
					) : null
				)}
			</List>
		</Collapse>
	);
};

export default Menu;
