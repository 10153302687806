import { DateTime } from 'luxon';
import { preferences } from '../../../state/bootstrap';
import { getLocaleByTimeZone } from '@portal-reporting/report-ui-templates/dist/timeZoneLocaleMap';
import i18n from 'i18next';

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy HH:mm:ss ZZZZ';
const DEFAULT_LOCALE_DATE_FORMAT = 'MM/dd/yyyy';
const DEFAULT_LOCALE_TIME_FORMAT = 'HH:mm:ss ZZZZ';

export const getDateTime = (date, options = {}) => {
	let parsedTime;
	if (date && 'number' === typeof date) {
		parsedTime = DateTime.fromMillis(date);
	} else {
		parsedTime = DateTime.fromISO(date);
	}

	if (options && options.timeZoneLocale) {
		parsedTime = parsedTime.setLocale(options.timeZoneLocale);
	}

	return parsedTime;
};

export function getFormattedDateTime(dateTime, options = {}) {
	if (!dateTime) {
		return '';
	}
	const { dateFormat } = options;
	return getDateTime(dateTime, options).toFormat(dateFormat ? dateFormat : DEFAULT_DATE_FORMAT);
}

export function getFormattedDateTimeByLocale(dateTime, options = {}) {
	if (!dateTime) {
		return '';
	}

	let { dateFormat = DEFAULT_LOCALE_DATE_FORMAT, timeFormat = DEFAULT_LOCALE_TIME_FORMAT } = options;
	if (i18n.language === 'ja-JP') {
		dateFormat = 'yyyy/MM/dd';
	} else if (i18n.language === 'es-ES') {
		dateFormat = 'dd/MM/yyyy';
	}

	return getDateTime(dateTime, options).toFormat(dateFormat + ' ' + timeFormat);
}

export function getLocaleByUserPreferenceTimeZone() {
	const userPreferenceTimezone = preferences.hasTimeZone ? preferences.timeZone : 'UTC';
	return getLocaleByTimeZone({ timeZoneName: userPreferenceTimezone });
}
