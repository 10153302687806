/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import axios from 'axios';
import { batch } from 'react-redux';
import { urls } from '../../bootstrap';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_PROGRESS = 'AUTH_LOGOUT_PROGRESS';

const api = axios.create();

export function authLogout() {
	return (dispatch) => {
		dispatch(
			authLogoutProgress({
				isError: false,
				isLoading: true
			})
		);

		return api
			.get(urls.logout, {
				data: {},
				timeout: 5000,
				withCredentials: true,
				headers: {
					'Content-Type': 'text/plain'
				}
			})
			.then(() =>
				batch(() => {
					dispatch(authLogoutSuccess());
					dispatch(
						authLogoutProgress({
							isError: false,
							isLoading: false
						})
					);
				})
			)
			.catch((error) => {
				dispatch(
					authLogoutProgress({
						isError: true,
						isLoading: false
					})
				);
				throw new Error(error.message);
			});
	};
}

export const authLogoutSuccess = () => ({
	type: AUTH_LOGOUT_SUCCESS,
	payload: {
		isAuthenticated: false,
		isEngineer: false,
		isLoggedIn: false,
		isMultiClient: false,
		isFFIEC: false
	}
});

export const authLogoutProgress = ({ isError, isLoading }) => ({
	type: AUTH_LOGOUT_PROGRESS,
	payload: {
		isError,
		isLoading
	}
});
