/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { fade, withStyles } from '@material-ui/core/styles';
import { FormControl, InputBase, InputLabel, FormHelperText } from '@material-ui/core';
import { fieldToInputBase } from 'formik-material-ui';

const CustomFormControl = withStyles(() => ({
	root: {
		display: 'flex',
		width: '100%'
	}
}))(FormControl);

const CustomInputLabel = withStyles((theme) => ({
	root: {
		'color': theme.palette.text.primary,
		'fontSize': theme.typography.body2.fontSize,
		'fontWeight': theme.typography.fontWeightBold,
		'&$focused': {
			color: theme.palette.text.primary
		},
		'&$outlined$shrink': {
			transform: `translate(0, 0) scale(1)`
		}
	},
	shrink: {
		transform: `translate(0, 0) scale(1)`
	},
	focused: {},
	outlined: {}
}))(InputLabel);

const CustomInputBase = withStyles((theme) => ({
	root: {
		'lineHeight': 1,
		'label + &': {
			marginTop: theme.spacing(4)
		}
	},
	input: {
		'height': theme.typography.body1.fontSize,
		'position': 'relative',
		'padding': theme.spacing(2, 3),
		'color': theme.palette.input.color,
		'boxShadow': `inset 0 0 0 1px ${theme.palette.input.border}`,
		'backgroundColor': theme.palette.input.background,
		'borderRadius': theme.spacing(1),
		'fontSize': theme.typography.body1.fontSize,
		'transition': theme.transitions.create('box-shadow'),
		'lineHeight': 1,
		'&:focus': {
			borderRadius: theme.spacing(1),
			backgroundColor: theme.palette.input.background,
			boxShadow: `inset 0 0 0 1px ${theme.palette.common.blue}, 0 0 3px 1px ${fade(
				theme.palette.common.blue,
				0.3
			)}`
		},
		'&$disabled': {
			color: theme.palette.action.disabled,
			boxShadow: 'none',
			backgroundColor: theme.palette.action.disabledBackground
		}
	},
	multiline: {
		padding: 0
	},
	inputMultiline: {
		height: 'auto',
		overflow: 'auto',
		lineHeight: theme.typography.body1.lineHeight
	},
	disabled: {},
	error: {
		'& $input, & $input:focus': {
			boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}, 0 0 3px 1px ${fade(
				theme.palette.error.main,
				0.25
			)}`
		}
	}
}))(InputBase);

const CustomFormHelperText = withStyles((theme) => ({
	root: {
		'fontStyle': 'italic',
		'color': theme.palette.text.secondary,
		'&.absolute': {
			position: 'absolute',
			top: 0,
			right: 0,
			marginTop: 0,
			lineHeight: '13px'
		}
	}
}))(FormHelperText);

const TextField = (props) => {
	const {
		form: { errors, touched },
		field: { name }
	} = props;

	const {
		helperText,
		helperTextInline,
		className,
		onBlur,
		onChange,
		hideErrorText,
		highlightError,
		...inputProps
	} = props;

	const error = errors[name];
	const touch = touched[name];

	const hasError = !!(error && (touch || highlightError));

	const areaProps = {};

	if (helperText) {
		areaProps['aria-describedby'] = `${name}-help`;
	}

	if (error) {
		areaProps['aria-describedby'] = `${name}-error`;
	}

	const fieldToInputBaseProps = fieldToInputBase(inputProps);

	const handleBlur = (...args) => {
		if (onBlur) {
			onBlur(...args);
		}

		if (fieldToInputBaseProps.onBlur) {
			fieldToInputBaseProps.onBlur(...args);
		}
	};

	const handleChange = (...args) => {
		if (onChange) {
			onChange(...args);
		}

		if (fieldToInputBaseProps.onChange) {
			fieldToInputBaseProps.onChange(...args);
		}
	};

	return (
		<CustomFormControl className={className}>
			{props.label ? (
				<CustomInputLabel shrink htmlFor={name}>
					{props.label}
				</CustomInputLabel>
			) : null}

			<CustomInputBase
				id={name}
				{...areaProps}
				{...fieldToInputBaseProps}
				onBlur={handleBlur}
				onChange={handleChange}
				error={hasError}
			/>

			{hasError && !hideErrorText ? (
				<CustomFormHelperText error id={`${name}-error`}>
					{error}
				</CustomFormHelperText>
			) : null}
			{helperText ? (
				<CustomFormHelperText className={classNames({ absolute: helperTextInline })} id={`${name}-help`}>
					{helperText}
				</CustomFormHelperText>
			) : null}
		</CustomFormControl>
	);
};

export default TextField;
