/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { Box } from '@material-ui/core';
import { BaseView, BaseViewContent } from '../Base';
import LoaderCircular from '../../../Loaders/Circular';

const InitializeView = () => {
	return (
		<BaseView className="sw-chat-view-init">
			<BaseViewContent>
				<Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
					<LoaderCircular />
				</Box>
			</BaseViewContent>
		</BaseView>
	);
};

export default InitializeView;
