/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { IconButton } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen, setSession, popOutChat } from '../../../../../state/actions/components/chat';
import Button from '../../../../Inputs/Button';

const useStyles = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		alignSelf: 'flex-end'
	},
	button: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'flex-start',
		'fontSize': 20,
		'lineHeight': 1,
		'borderBottomLeftRadius': 0,
		'borderBottomRightRadius': 0,
		'height': 40,
		'minHeight': 40,
		'minWidth': 150,
		'borderTopLeftRadius': theme.spacing(1),
		'borderTopRightRadius': theme.spacing(1),
		'padding': theme.spacing(0, 3),
		'& span > *:first-child': {
			margin: 0,
			fontSize: 15
		},
		'&:disabled': {
			backgroundColor: theme.palette.chat.button.disabled.background,
			color: theme.palette.chat.button.disabled.color
		}
	},
	buttonExpanded: {
		'cursor': 'move',
		'color': theme.palette.secondary.contrastText,
		'backgroundColor': theme.palette.secondary.main,
		'&$disabled': {
			cursor: 'default'
		}
	},
	buttonLabel: {
		flexGrow: 1,
		userSelect: 'none'
	},
	iconButton: {
		'flexShrink': 0,
		'padding': theme.spacing(2),
		'marginRight': theme.spacing(-2),
		'width': 35,
		'&:hover': {
			backgroundColor: fade(theme.palette.secondary.dark, 0.2)
		}
	},
	iconExpand: {
		transform: 'rotate(45deg)'
	},
	disabled: {}
}));

const ChatFrameButton = ({ disabled }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const isOpen = useSelector((state) => state.chat.isOpen);
	const progress = useSelector((state) => state.chat.progress);
	const isNeverOpen = isOpen === null || progress === 'showUnavailable';
	const isStandalone = useSelector((state) => state.chat.session.isStandalone);
	const isDisabled = disabled || progress === 'showUnavailable';

	const handleToggle = () => {
		dispatch(
			setOpen({
				isOpen: !isOpen
			})
		);
	};

	const handlePopOut = () => {
		if (isOpen) {
			dispatch(
				setOpen({
					isOpen: false
				})
			);
		}

		dispatch(
			setSession({
				// force refresh across all tabs
				isStandalone: Math.random()
			})
		);
	};

	useEffect(() => {
		if (isStandalone) {
			dispatch(popOutChat());
		}
	}, [dispatch, isStandalone]);

	return (
		<div className={classes.root}>
			{isNeverOpen ? (
				<Button
					className={classNames('sw-chat-drag-handle', classes.button)}
					disabled={isDisabled}
					onClick={handleToggle}
				>
					{t('common:chat.buttons.chat')}
				</Button>
			) : (
				<div
					className={classNames('sw-chat-drag-handle', classes.button, classes.buttonExpanded, {
						[classes.disabled]: isDisabled
					})}
				>
					<span className={classes.buttonLabel}>{t('common:chat.buttons.chat')}</span>
					{isStandalone ? null : (
						<IconButton
							color="inherit"
							onClick={handleToggle}
							disabled={isDisabled}
							className={classes.iconButton}
							title={isOpen ? t('common:chat.labels.minimize') : t('common:chat.labels.maximize')}
						>
							<FontAwesomeIcon icon={isOpen ? faMinusCircle : faPlusCircle} />
						</IconButton>
					)}

					<IconButton
						color="inherit"
						onClick={handlePopOut}
						disabled={isDisabled}
						className={classes.iconButton}
						title={t('common:chat.labels.standalone')}
					>
						<FontAwesomeIcon icon={faArrowCircleUp} className={classes.iconExpand} />
					</IconButton>
				</div>
			)}
		</div>
	);
};

export default ChatFrameButton;
