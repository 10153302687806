/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get, CancelToken, isCancel } from 'axios';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDebounce, usePrevious } from '../../../utils';
import AutocompleteCustom from './custom';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LoaderCircular from '../../Loaders/Circular';

export const useLabelStyles = makeStyles((theme) => ({
	root: {
		'color': theme.palette.autocomplete.color,
		'&$focused': {
			color: theme.palette.autocomplete.color
		},
		'&$disabled': {
			color: theme.palette.autocomplete.colorDisabled
		}
	},
	outlined: {
		'transform': 'translate(12px, 12px) scale(1)',
		'&$shrink': {
			transform: 'translate(12px, -6px) scale(0.75)'
		}
	},
	shrink: {},
	focused: {},
	disabled: {}
}));

export const useTagStyles = makeStyles((theme) => ({
	chipDeleteIcon: {
		'fontSize': '12px',
		'color': theme.palette.autocomplete.chip.color,
		'opacity': 0.4,
		'paddingTop': '2px',
		'&:hover': {
			color: theme.palette.autocomplete.chip.color,
			opacity: 0.8
		}
	}
}));

export const useInputStyles = makeStyles((theme) => ({
	root: {
		'background': theme.palette.input.background,
		'& $notchedOutline': {
			'borderColor': theme.palette.autocomplete.border,
			'& legend': {
				overflow: 'hidden'
			}
		},
		'&:hover': {
			'& $notchedOutline': {
				borderColor: theme.palette.action.active
			}
		},
		'&$focused': {
			'& $notchedOutline': {
				color: theme.palette.action.active,
				borderWidth: 1,
				borderColor: theme.palette.action.active
			}
		},
		'&$disabled': {
			'color': theme.palette.autocomplete.colorDisabled,
			'boxShadow': 'none',
			'backgroundColor': theme.palette.autocomplete.backgroundDisabled,
			'& $notchedOutline': {
				borderColor: theme.palette.autocomplete.borderDisabled
			}
		},
		'&$error': {
			'&$focused': {
				'& $notchedOutline': {
					color: theme.palette.autocomplete.colorError,
					borderColor: theme.palette.autocomplete.borderError
				}
			}
		}
	},
	error: {},
	focused: {},
	disabled: {},
	notchedOutline: {}
}));

const Autocomplete = ({ options, id, data, label, disabled, loading, placeholder, callback, ...other }) => {
	const { t } = useTranslation();
	const labelClasses = useLabelStyles();
	const inputClasses = useInputStyles();
	const cancelRequest = useRef(false);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [state, setState] = useState({
		data: [],
		isError: false,
		isLoading: false
	});
	const { params = {}, url = null, typeAheadQueryKey = null, preFetch = false } = options || {};
	const [searchQuery, setSearchQuery] = useState(null);
	const debouncedSearchQuery = useDebounce(searchQuery, 300);

	const prevData = usePrevious(state.data);
	const prevParams = usePrevious(params);
	const filterOptions = typeAheadQueryKey
		? (options) => options
		: createFilterOptions({ stringify: other.getOptionLabel });

	const fetchData = useCallback(
		(value) => {
			setState({
				data: [],
				isError: false,
				isLoading: true
			});

			if (cancelRequest.current) {
				// cancel any running AJAX requests
				cancelRequest.current('User is typing...');
			}

			return get(url, {
				params: value
					? {
							...params,
							[typeAheadQueryKey]: value
					  }
					: params,
				cancelToken: new CancelToken(function executor(c) {
					cancelRequest.current = c;
				})
			})
				.then(({ data }) => {
					setState({
						data: Array.isArray(data) ? data : [],
						isError: false,
						isLoading: false
					});
				})
				.catch((error) => {
					if (!isCancel(error)) {
						setState({
							data: [],
							isError: true,
							isLoading: false
						});
					}
				});
		},
		[params, typeAheadQueryKey, url]
	);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleChange = useCallback(
		(event, value) => {
			setSelected(value);

			if (!value && typeAheadQueryKey) {
				setState({
					data: [],
					isError: false,
					isLoading: false
				});
			}

			if (callback) {
				callback(event, value);
			}
		},
		[callback, typeAheadQueryKey]
	);

	const handleInputChange = useCallback(
		(event, value) => {
			if (!typeAheadQueryKey || (!event && !value)) {
				return;
			}

			if (event) {
				const query = event.type !== 'blur' && value ? value : null;

				if (event.type !== 'click') {
					if (cancelRequest.current) {
						// cancel any running AJAX requests
						cancelRequest.current('User is typing...');
					}

					setSelected(null);

					setState({
						data: [],
						isError: false,
						isLoading: !!query
					});

					setSearchQuery(query);
				}
			}
		},
		[typeAheadQueryKey]
	);

	// hide options list on error
	useEffect(() => {
		if (state.isError) {
			setOpen(false);
		}
	}, [state.isError]);

	// handles value clearing when new and prev data doesn't match
	useEffect(() => {
		if (!typeAheadQueryKey && selected && state.data !== prevData) {
			handleChange(null, null);
		}
	}, [handleChange, state.data, prevData, selected, typeAheadQueryKey]);

	// handles type-ahead data updates on renders
	useEffect(() => {
		if (debouncedSearchQuery) {
			fetchData(debouncedSearchQuery);
		}
	}, [debouncedSearchQuery, fetchData]);

	// handles static data updates on renders
	useEffect(() => {
		if (data && data.length) {
			setState({
				data,
				isError: false,
				isLoading: false
			});
		}
	}, [data]);

	// handles param updates on renders
	useEffect(() => {
		let changedProps = [];

		if (prevData && prevParams) {
			Object.entries(params).forEach(([key, val]) => {
				if (prevParams[key] !== val) {
					// new value doesn't match previous
					changedProps.push(key);
				}
			});

			if (changedProps.length) {
				// reset the list
				changedProps = [];
				// update the data source
				fetchData();
			}
		}
	}, [fetchData, params, prevData, prevParams]);

	// handles initial data updates on renders
	useEffect(() => {
		if (preFetch && !state.isLoading && !prevData) {
			fetchData();
		}
	}, [fetchData, preFetch, prevData, state.isLoading]);

	const isComponentDisabled = disabled || loading || (state.isLoading && !typeAheadQueryKey);
	const isComponentLoading = state.isLoading || loading;

	return (
		<AutocompleteCustom
			id={id}
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
			options={state.data}
			loading={isComponentLoading}
			disabled={isComponentDisabled}
			onChange={handleChange}
			disablePortal={true}
			onInputChange={handleInputChange}
			includeInputInList={true}
			openOnFocus={!typeAheadQueryKey}
			filterOptions={filterOptions}
			openText={t('common:buttons.open')}
			clearText={t('common:buttons.clear')}
			closeText={t('common:buttons.close')}
			loadingText={t('common:messages.loading')}
			noOptionsText={t('common:messages.noMatch')}
			value={selected}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={state.isError}
					helperText={state.isError ? t('common:messages.error') : null}
					placeholder={placeholder}
					fullWidth={true}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						classes: inputClasses,
						endAdornment: isComponentLoading ? (
							<LoaderCircular size={20} />
						) : state.data.length && !disabled ? (
							params.InputProps.endAdornment
						) : null
					}}
					InputLabelProps={{
						...params.InputLabelProps,
						classes: labelClasses
					}}
				/>
			)}
			{...other}
		/>
	);
};

Autocomplete.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.array,
	getOptionLabel: PropTypes.func.isRequired,
	options: PropTypes.shape({
		url: PropTypes.string,
		params: PropTypes.object,
		preFetch: PropTypes.bool,
		typeAheadQueryKey: PropTypes.string
	})
};

export default Autocomplete;
