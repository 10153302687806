/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { StyledTableCell, StyledTableRow } from '../Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	cell: {
		width: ({ width }) => width,
		minWidth: ({ minWidth }) => minWidth,
		maxWidth: ({ maxWidth }) => maxWidth,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '100%!important',
			maxWidth: '100%!important'
		}
	},
	node: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			'padding': theme.spacing(0),
			'&[data-header]&:before': {
				content: 'attr(data-header) ":\\00A0"',
				fontWeight: theme.typography.fontWeightBold,
				whiteSpace: 'nowrap'
			}
		}
	},
	nodeLink: {
		display: 'block',
		textDecoration: 'none'
	},
	noUnderlineLink: {
		textDecoration: 'none'
	},
	nodeSorted: {
		fontWeight: theme.typography.fontWeightBold
	},
	cloudCell: {
		color: theme.palette.sidebar.icon,
		display: 'block',
		margin: '0 auto'
	},
	stopPropagationField: {
		cursor: 'default'
	}
}));

export const TableBodyRowCell = ({ row, cell, ...cellProps }) => {
	const classes = useStyles({
		width: cell.column.width,
		minWidth: cell.column.minWidth,
		maxWidth: cell.column.maxWidth
	});
	const { t } = useTranslation();

	let nodeProps = {
		component: 'div'
	};

	let link = null;

	if (cell.column.headerTitle) {
		nodeProps['data-header'] = cell.column.headerTitle;
	}

	if (cell.column.cellLinkHref) {
		nodeProps.component = 'a';
		nodeProps.href = cell.column.cellLinkHref(row.original);
	}

	return (
		<StyledTableCell
			align={cell.column.align}
			{...cellProps}
			className={cell.column.className ? classNames(classes.cell, cell.column.className) : classes.cell}
		>
			<Typography
				color="textPrimary"
				variant="inherit"
				noWrap={cell.column.noWrap}
				className={classNames(classes.node, {
					[classes.nodeLink]: !!link,
					[classes.nodeSorted]: cell.column.isSorted,
					[classes.noUnderlineLink]: cell.column.escapeLeftClick,
					[classes.stopPropagationField]: cell.column.stopPropagationField
				})}
				onClick={(e) => {
					if (cell.column.escapeLeftClick) {
						//If the cell is with a link href and only required for right click to open in new tab
						e.preventDefault();
					}
					if (cell.column.stopPropagationField) {
						e.stopPropagation();
					}
				}}
				{...nodeProps}
			>
				{cell.isGrouped ? (
					// If it's a grouped cell, add an expander and row count
					<>
						<Chip
							color="secondary"
							label={row.subRows.length}
							icon={<FontAwesomeIcon size="2x" icon={row.isExpanded ? faMinusCircle : faPlusCircle} />}
							{...row.getToggleRowExpandedProps()}
						/>
						{cell.render('Cell')}
					</>
				) : cell.isAggregated ? (
					// If the cell is aggregated, use the Aggregated
					// renderer for cell
					cell.render('Aggregated')
				) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
					// Otherwise, just render the regular cell
					<>
						{/* Unless it's a cloud network status icon... */}
						{cell.column.id === 'network-summary-cloud-status' && row.original.isCloudNetwork && (
							<FontAwesomeIcon
								title={t('datatables:administration.networks.headers.cloud')}
								className={classes.cloudCell}
								icon={faCloud}
							/>
						)}
						{cell.render('Cell')}
					</>
				)}
			</Typography>
		</StyledTableCell>
	);
};

export const TableBodyRow = ({ row, ...rowProps }) => {
	const canHover = !!row.cells.find((cell) => cell.column.cellLinkHref);

	return (
		<StyledTableRow hover={canHover} {...rowProps}>
			{row.cells.map((cell) => {
				const { key, ...cellProps } = cell.getCellProps();

				return <TableBodyRowCell key={key} row={row} cell={cell} {...cellProps} />;
			})}
		</StyledTableRow>
	);
};
