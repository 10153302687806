/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sendChatRequest, setForm } from '../../../../../state/actions/components/chat';
import * as Yup from 'yup';
import Button from '../../../../Inputs/Button';
import TextFieldCustom from '../../../../Inputs/Formik/TextField';
import SelectCustom from '../../../../Inputs/Formik/Select';
import { BaseView, BaseViewContent, BaseViewFooter } from '../../Base';

import useCampaignOptions from '../campaignList';

import InformationViewBackButton from '../Back';

const useFormStyles = makeStyles((theme) => ({
	field: {
		marginTop: theme.spacing(4)
	},
	button: {
		'color': theme.palette.action.active,
		'margin': theme.spacing(0, -1, 2, -1),
		'&.Mui-disabled': {
			color: theme.palette.text.hint
		}
	}
}));

const InformationViewTopicFlow = ({ handleSwap }) => {
	const { t } = useTranslation();
	const classes = useFormStyles();
	const dispatch = useDispatch();

	const { campaignOptions } = useCampaignOptions();

	const startChat = useCallback(
		(values) => {
			const data = {
				campaign: values.campaign.trim(),
				question: values.question.trim()
			};

			dispatch(setForm(data));
			dispatch(sendChatRequest(data));
		},
		[dispatch]
	);

	const ValidationSchema = Yup.object().shape({
		question: Yup.string(),
		campaign: Yup.string().required(t('common:chat.information.fields.campaign.errors.required'))
	});

	return (
		<Formik
			onSubmit={startChat}
			validationSchema={ValidationSchema}
			initialValues={{
				campaign: '',
				question: ''
			}}
			initialErrors={{
				campaign: ''
			}}
		>
			{({ submitForm, isSubmitting, isValid }) => (
				<BaseView className="sw-chat-view-info-topic">
					<InformationViewBackButton handleSwap={handleSwap} isSubmitting={isSubmitting} />
					<BaseViewContent>
						<Form autoComplete="off">
							<Field
								fullWidth
								name="campaign"
								component={SelectCustom}
								options={campaignOptions}
								placeholder={t('common:buttons.select')}
								label={t('common:chat.information.fields.campaign.label')}
							/>
							<Field
								multiline
								fullWidth
								rows={8}
								name="question"
								component={TextFieldCustom}
								className={classes.field}
								label={t('common:chat.information.fields.question.label')}
								helperText={t('common:chat.information.fields.question.helperText')}
								helperTextInline
							/>
						</Form>
					</BaseViewContent>
					<BaseViewFooter>
						<Button fullWidth onClick={submitForm} isLoading={isSubmitting} disabled={!isValid}>
							{t('common:chat.buttons.start')}
						</Button>
					</BaseViewFooter>
				</BaseView>
			)}
		</Formik>
	);
};

export default InformationViewTopicFlow;
