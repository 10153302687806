/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { changeLanguage } from '../../../../../state/actions/app/preferences';
import { languages } from '../../../../../utils/i18n';
import Dropdown from '../../../../Inputs/Dropdown';
import DropdownItem from '../../../../Inputs/Dropdown/Item';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative'
	},
	languageIcon: {
		marginLeft: theme.spacing(1)
	},
	button: {
		'fontSize': theme.typography.body2.fontSize,
		'color': theme.palette.footerBar.link,
		'&:hover, &:focus': {
			color: theme.palette.footerBar.linkHover,
			backgroundColor: 'transparent'
		}
	},
	buttonActive: {
		color: theme.palette.footerBar.linkHover,
		backgroundColor: 'transparent'
	}
}));

const LanguageSwitch = () => {
	const { i18n } = useTranslation('common');
	const classes = useStyles();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.preferences.language);
	const isLoading = useSelector((state) => state.preferences.isLoading);
	const [menuState, setMenuState] = useState({
		isOpen: false,
		anchorEl: null
	});

	const currentSelection = languages.find((item) => {
		return item.value === language;
	});

	const handleClick = (event) => {
		event.preventDefault();

		setMenuState({
			isOpen: true,
			anchorEl: event.currentTarget
		});
	};

	const handleClose = () => {
		setMenuState({
			isOpen: false
		});
	};

	const handleChange = (event) => {
		const value = event.target.value;
		const language = languages[value].value;

		dispatch(
			changeLanguage({
				language,
				i18n
			})
		);

		handleClose();
	};

	return (
		<div className={classes.root} onMouseLeave={handleClose}>
			<Button
				size="small"
				aria-controls="language-menu"
				aria-haspopup="true"
				className={classNames(classes.button, {
					[classes.buttonActive]: menuState.isOpen
				})}
				onClick={handleClick}
				onMouseOver={handleClick}
				disabled={isLoading}
			>
				{currentSelection.label}
				<FontAwesomeIcon icon={faGlobeAmericas} className={classes.languageIcon} />
			</Button>
			<Dropdown id="language-menu" placement="top" isOpen={menuState.isOpen} anchorEl={menuState.anchorEl}>
				{languages.map((option, index) => (
					<DropdownItem
						key={option.value}
						button={true}
						value={index}
						selected={option.value === currentSelection.value}
						onClick={handleChange}
					>
						{option.label}
					</DropdownItem>
				))}
			</Dropdown>
		</div>
	);
};

export default LanguageSwitch;
