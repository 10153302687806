/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import { post } from 'axios';
import { downloadFile } from '../../../../../utils';
import { getQueryString } from '../../../../Dashboards/Dashboard/useExportDrillDown';

export const useExportDrillDown = () => {
	let isLoading = false;
	let isError = null;
	const exportDrillDownData = (filter, fileType = 'pdf', fileName = '') => {
		isLoading = true;
		const axiosOptions = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			responseType: 'arraybuffer'
		};

		post(
			'/portal/reportsdrilldown/exportTopTalkersAggrData',
			getQueryString({
				searchFormJson: JSON.stringify(filter),
				csrfToken: window?.APP_PAGE_DATA?.csrfToken,
				type: fileType,
				fileName: fileName
			}),
			axiosOptions
		)
			.then((response) => {
				isLoading = false;
				downloadFile(response);
			})
			.catch((e) => {
				isLoading = false;
				isError = e;
			});
	};
	return { isLoading, isError, exportDrillDownData };
};
