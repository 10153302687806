/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import {
	RESET,
	SET_EWT,
	ADD_AGENT,
	REMOVE_AGENT,
	ADD_MESSAGE,
	SET_METADATA,
	SET_PROGRESS,
	SET_SESSION,
	INIT_SESSION,
	SET_POSITION,
	SET_API_URL,
	SET_ROOT_URL,
	SET_SESSION_URL,
	SET_SOCKET_URL,
	SET_SOCKET_META,
	SET_SIZE,
	IS_OPEN,
	IS_DUPLICATE,
	TOGGLE_SOUND,
	TOGGLE_MODAL,
	SET_FORM,
	SET_AGENT_TYPING,
	SET_MESSAGES,
	SET_MESSAGE
} from '../../actions/components/chat';

const initialState = {
	isOpen: null,
	isDuplicate: true,
	progress: '',
	agentTyping: false,
	messages: {},
	form: {
		ticketId: '',
		campaign: '',
		question: '',
		isGeneratedTicket: false
	},
	modals: {
		end: false
	},
	session: {
		id: null,
		ewt: {},

		contactId: null,
		contactName: null,
		contactEmail: null,

		tenantName: null,
		groupId: '-1',
		chatHistory: null,

		profileId: '-1',
		profiles: {},
		availableCampaigns: [],
		campaigns: [],

		owner: null,
		agents: {},

		isChatBot: false,
		isStandalone: false,
		wasQuestionShown: false,
		wasGeneratedTicketShown: false,

		farmId: null,
		tokenId: null,
		tenantId: null,

		apiHost: 'app.five9.com',
		apiPort: '',

		// tokenId
		sessionId: null,
		urls: {
			session: null,
			socket: null,
			root: null,
			api: null
		},
		headers: {
			Authorization: '',
			farmId: ''
		},
		isLoading: false
	},
	socketMeta: {
		count: 0,
		isTerminated: true
	},
	preferences: {
		sound: true
	},
	position: {
		x: -140,
		y: 0
	},
	size: {
		w: 320,
		h: 550
	}
};

export default function chatReducer(state = initialState, action) {
	switch (action.type) {
		case RESET:
			return {
				...initialState,
				...action.payload
			};
		case SET_EWT:
			return {
				...state,
				session: {
					...state.session,
					ewt: action.payload
				}
			};
		case SET_SIZE:
			return {
				...state,
				size: {
					...state.size,
					...action.payload
				}
			};
		case TOGGLE_MODAL:
			return {
				...state,
				modals: {
					...state.modals,
					[action.payload.name]: action.payload.value
				}
			};
		case TOGGLE_SOUND:
			return {
				...state,
				preferences: {
					...state.preferences,
					sound: action.payload
				}
			};
		case ADD_AGENT:
			return {
				...state,
				session: {
					...state.session,
					agents: {
						...state.session.agents,
						[action.payload.id]: {
							...action.payload
						}
					}
				}
			};
		case REMOVE_AGENT:
			return {
				...state,
				session: {
					...state.session,
					agents: action.payload
				}
			};
		case IS_OPEN:
			return {
				...state,
				isOpen: action.payload.isOpen
			};
		case IS_DUPLICATE:
			return {
				...state,
				isDuplicate: action.payload.isDuplicate
			};
		case SET_MESSAGES:
			return {
				...state,
				messages: {
					...state.messages,
					...action.payload
				}
			};
		case SET_MESSAGE:
			return {
				...state,
				messages: {
					...state.messages,
					[action.payload.messageId]: {
						...state.messages[action.payload.messageId],
						...action.payload
					}
				}
			};
		case ADD_MESSAGE:
			return {
				...state,
				messages: {
					...state.messages,
					[action.payload.messageId]: {
						...state.messages[action.payload.messageId],
						...action.payload
					}
				}
			};
		case SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.payload
				}
			};
		case SET_METADATA:
			return {
				...state,
				metadata: {
					...state.metadata,
					...action.payload
				}
			};
		case SET_PROGRESS:
			return {
				...state,
				progress: action.payload.progress
			};
		case SET_SESSION:
			return {
				...state,
				session: {
					...state.session,
					...action.payload
				}
			};
		case SET_POSITION:
			return {
				...state,
				position: action.payload
			};
		case SET_AGENT_TYPING:
			return {
				...state,
				agentTyping: action.payload
			};
		case INIT_SESSION:
			return {
				...state,
				session: {
					...state.session,
					...action.payload
				}
			};
		case SET_API_URL:
			return {
				...state,
				session: {
					...state.session,
					urls: {
						...state.session.urls,
						api: action.payload.url
					}
				}
			};
		case SET_ROOT_URL:
			return {
				...state,
				session: {
					...state.session,
					urls: {
						...state.session.urls,
						root: action.payload.url
					}
				}
			};
		case SET_SOCKET_URL:
			return {
				...state,
				session: {
					...state.session,
					urls: {
						...state.session.urls,
						socket: action.payload.url
					}
				}
			};
		case SET_SOCKET_META:
			return {
				...state,
				socketMeta: {
					...state.socketMeta,
					...action.payload
				}
			};
		case SET_SESSION_URL:
			return {
				...state,
				session: {
					...state.session,
					urls: {
						...state.session.urls,
						session: action.payload.url
					}
				}
			};
		default:
			return state;
	}
}
