/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fade, Grow, Popper, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	popper: {
		'zIndex': (props) => props.zIndex || theme.zIndex.tooltip,
		'width': (props) => props.width,
		'&[x-placement*="bottom"] $arrow': {
			'top': 0,
			'left': 0,
			'marginTop': '-0.9em',
			'width': '3em',
			'height': '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent ${theme.palette.dropdown.border} transparent`
			}
		},
		'&[x-placement*="top"] $arrow': {
			'bottom': 0,
			'left': 0,
			'marginBottom': '-0.9em',
			'width': '3em',
			'height': '1em',
			'&::before': {
				borderWidth: '1em 1em 0 1em',
				borderColor: `${theme.palette.dropdown.border} transparent transparent transparent`
			}
		},
		'&[x-placement*="right"] $arrow': {
			'left': 0,
			'marginLeft': '-0.9em',
			'height': '3em',
			'width': '1em',
			'&::before': {
				borderWidth: '1em 1em 1em 0',
				borderColor: `transparent ${theme.palette.dropdown.border} transparent transparent`
			}
		},
		'&[x-placement*="left"] $arrow': {
			'right': 0,
			'marginRight': '-0.9em',
			'height': '3em',
			'width': '1em',
			'&::before': {
				borderWidth: '1em 0 1em 1em',
				borderColor: `transparent transparent transparent ${theme.palette.dropdown.border}`
			}
		}
	},
	paper: {
		listStyle: 'none',
		color: theme.palette.dropdown.color,
		border: (props) => `${props.borderWidth}px solid ${theme.palette.dropdown.border}`,
		backgroundColor: theme.palette.dropdown.background,
		padding: (props) =>
			theme.spacing(props.menuPadding[0], props.menuPadding[1], props.menuPadding[2], props.menuPadding[3]),
		margin: (props) => `${props.menuOffset}px 0 0 0`,
		display: (props) => (props.isOpen ? 'block' : 'none'),
		minWidth: (props) => props.minWidth
	},
	arrow: {
		'position': 'absolute',
		'fontSize': 6,
		'width': '3em',
		'height': '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid'
		}
	}
}));

function autoSizeDropdown(data) {
	data.offsets.popper.left = data.offsets.reference.left;
	data.offsets.popper.right = data.offsets.reference.right;
	data.offsets.popper.width = data.styles.width = Math.round(data.offsets.reference.width);

	return data;
}

const Dropdown = forwardRef(function Dropdown(props, ref) {
	const {
		children,
		anchorEl,
		placement,
		isOpen,
		hasArrow,
		PaperProps,
		width,
		borderWidth,
		menuPadding,
		menuOffset,
		minWidth,
		shouldFade,
		flipEnabled,
		flipPriority,
		keepMounted,
		disablePortal,
		useParentNode,
		autoSize,
		zIndex,
		...other
	} = props;

	const classes = useStyles({
		zIndex,
		borderWidth,
		menuPadding,
		menuOffset,
		minWidth,
		width,
		isOpen
	});

	const [arrowRef, setArrowRef] = useState(null);
	const parentNode = useParentNode ? anchorEl && anchorEl.parentNode : null;
	const Transition = shouldFade ? Fade : Grow;

	return (
		<Popper
			ref={ref}
			open={isOpen}
			role="listbox"
			anchorEl={anchorEl}
			container={parentNode}
			placement={placement}
			className={classes.popper}
			disablePortal={disablePortal}
			keepMounted={keepMounted}
			transition
			modifiers={{
				flip: {
					enabled: flipEnabled,
					boundariesElement: document.body
				},
				autoSizing: {
					enabled: autoSize,
					fn: autoSizeDropdown,
					order: 840
				},
				preventOverflow: {
					padding: 5,
					enabled: true,
					priority: flipPriority,
					boundariesElement: document.body
				},
				keepTogether: {
					enabled: true
				},
				arrow: {
					enabled: hasArrow,
					element: arrowRef
				},
				computeStyle: {
					gpuAcceleration: false
				}
			}}
			{...other}
		>
			{({ TransitionProps }) => (
				<Transition {...TransitionProps} timeout={350}>
					<div>
						<Paper square component="ul" elevation={3} className={classes.paper} {...PaperProps}>
							{children}
						</Paper>
						{hasArrow && <span className={classes.arrow} ref={setArrowRef} />}
					</div>
				</Transition>
			)}
		</Popper>
	);
});

Dropdown.defaultProps = {
	borderWidth: 1,
	menuPadding: [0, 0, 0, 0],
	menuOffset: 0,
	minWidth: 50,
	shouldFade: true,
	flipEnabled: false,
	flipPriority: ['left', 'right'],
	keepMounted: false,
	disablePortal: false,
	useParentNode: true,
	autoSize: false,
	hasArrow: true,
	placement: 'bottom',
	width: 'auto'
};

export default Dropdown;
