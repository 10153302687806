/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React, { useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { Typography, Fade } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { acknowledgeAgentMessage, sendMessage } from '../../../../../state/actions/components/chat';
import { makeStyles } from '@material-ui/core/styles';
import ChatMessage from './message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Link from '../../../../Link';

const useStyles = makeStyles((theme) => ({
	root: {
		'display': 'flex',
		'flexFlow': 'row wrap',
		'marginTop': theme.spacing(1),
		'&:first-child': {
			marginTop: 0
		}
	},
	meta: {
		flexGrow: 1,
		width: '100%',
		fontSize: 10,
		padding: '5px 0'
	},
	metaTime: {
		fontWeight: theme.typography.fontWeightRegular,
		textTransform: 'uppercase'
	},
	content: {
		whiteSpace: 'normal',
		wordBreak: 'break-word',
		padding: theme.spacing(1, 2),
		borderRadius: theme.spacing(1)
	},
	rootIn: {
		'justifyContent': 'flex-start',
		'& $meta': {
			textAlign: 'left'
		},
		'& $content': {
			color: theme.palette.chat.message.in.color,
			backgroundColor: theme.palette.chat.message.in.background,
			marginRight: theme.spacing(5)
		}
	},
	rootOut: {
		'justifyContent': 'flex-end',
		'& $meta': {
			textAlign: 'right'
		},
		'& $content': {
			color: theme.palette.chat.message.out.color,
			backgroundColor: theme.palette.chat.message.out.background,
			marginLeft: theme.spacing(5)
		}
	},
	rootError: {
		'& $content': {
			backgroundColor: theme.palette.background.default
		}
	},
	failedToSend: {
		flexGrow: 1,
		width: '100%',
		fontSize: 13,
		padding: '5px 0',
		textAlign: 'right'
	},
	failedToSendText: {
		display: 'block',
		color: theme.palette.error.main,
		fontStyle: 'italic'
	},
	failedToSendIcon: {
		fontSize: 15,
		width: 15,
		marginLeft: theme.spacing(1)
	},
	failedToSendAction: {
		display: 'inline-flex',
		alignItems: 'center'
	}
}));

const formatMessageText = (text) =>
	text
		.trim()
		.split('\n')
		.map((item, key) => {
			return (
				<Fragment key={key}>
					{item}
					<br />
				</Fragment>
			);
		});

const formatMessageLink = ({ message, links }) => {
	let result = message;

	links.forEach(({ value }) => {
		result = result.replace(new RegExp(`${value}(?!]])`), `[[${value}]]`);
	});

	return result.split(new RegExp(/\[\[(.*?)\]\]/)).reduce((prev, current, i) => {
		if (!i) {
			return [current];
		}

		const match = links.find(({ value }) => current === value);

		return prev.concat(
			match ? (
				<Link key={i + current} href={match.href} target="_blank" rel="noopener noreferrer">
					{match.value}
				</Link>
			) : (
				current
			)
		);
	}, []);
};

const contentComponentList = {
	createServiceRequestLink: function CreateServiceRequestLink(ticketId) {
		return (
			<Link href={`/portal/servicerequests/${ticketId}`} target="_blank" rel="noopener noreferrer">
				{ticketId}
			</Link>
		);
	}
};

const ConverstationViewMessage = ({ message, previousMessage }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		content,
		messageId,
		messageTime,
		displayName,
		isInboundMessage,
		isOutboundMessage,
		hasToSendAcknowledgement
	} = new ChatMessage(message);

	useEffect(() => {
		if (hasToSendAcknowledgement && !message.acknowledged) {
			dispatch(
				acknowledgeAgentMessage({
					messageId
				})
			);
		}
	}, [hasToSendAcknowledgement, messageId, dispatch, message.acknowledged]);

	// show meta when names differ or there is at least one minute difference between messages
	const showMeta = previousMessage
		? displayName !== previousMessage.displayName ||
		  `${message.timestamp}`.slice(0, -5) !== `${previousMessage.timestamp}`.slice(0, -5)
		: true;

	const authorName = displayName === 'System' ? 'Secureworks' : displayName;

	function handleRetry(event) {
		event.preventDefault();

		dispatch(
			sendMessage({
				content,
				retryCount: 99, // disabled
				data: {
					messageId: message.messageId,
					displayName: message.displayName,
					content: message.content,
					contentType: message.contentType,
					ownerId: message.ownerId,
					userIds: message.userIds
				}
			})
		);
	}

	return (
		<Fade in={true}>
			<div
				className={classNames(classes.root, {
					[classes.rootIn]: isInboundMessage,
					[classes.rootOut]: isOutboundMessage,
					[classes.rootError]: message.failedToSend
				})}
			>
				{showMeta ? (
					<div className={classes.meta}>
						<Typography variant="h6">
							{authorName}{' '}
							<span className={classes.metaTime}>
								{t('common:luxon', {
									date: {
										value: messageTime,
										format: 't'
									}
								})}
							</span>
						</Typography>
					</div>
				) : null}
				<Typography variant="body2" className={classes.content}>
					{content.i18n ? (
						<Trans
							i18nKey={content.i18n}
							values={content.data || {}}
							components={
								content.components
									? content.components.map(({ component, data }) =>
											contentComponentList[component](data)
									  )
									: []
							}
						/>
					) : content.message ? (
						formatMessageLink(content)
					) : (
						formatMessageText(content)
					)}
					{message.failedToSend ? (
						<span className={classes.failedToSendText}>{t('common:chat.conversation.failedToSend')}</span>
					) : null}
				</Typography>
				{message.failedToSend ? (
					<div className={classes.failedToSend}>
						<Link href="#" onClick={handleRetry} className={classes.failedToSendAction}>
							{t('common:buttons.retry')}
							<FontAwesomeIcon className={classes.failedToSendIcon} icon={faSyncAlt} />
						</Link>
					</div>
				) : null}
			</div>
		</Fade>
	);
};

export default ConverstationViewMessage;
