/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ReviewRow from '../ReviewRow';
import { useTranslation } from 'react-i18next';

/**
 * Label and data for three rows
 * @param {String} label - Translated string
 * @param {String} data - Translated strings
 * @returns {Object}
 */
const ReviewBlock = ({ label, data }) => {
	const { t } = useTranslation('common');
	return (
		<Grid>
			<Box mb={6}>
				<Typography component="h2" variant="h4">
					{label}
				</Typography>
				<ReviewRow label={`${t('modals.editNetwork.networkType')}:`} value={data.type} />
				<ReviewRow label={`${t('modals.editNetwork.cidrRange')}:`} value={data.ip} />
				<ReviewRow label={`${t('modals.editNetwork.description')}:`} value={data.description} />
			</Box>
		</Grid>
	);
};

export default ReviewBlock;
