/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';

import PrivateHeader from '../../components/Headers/Private';
import PublicHeader from '../../components/Headers/Public';
import { checkRouteIfPublic } from '../../utils/access';

const Header = () => {
	const isPublicRoute = checkRouteIfPublic();

	return isPublicRoute ? <PublicHeader /> : <PrivateHeader />;
};

export default Header;
