/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { hasRouteAccess } from '../utils/access';
import { pageRoutes } from './components/page';
import routeList from './bootstrap';

const DefaultErrorPage = lazy(() => import('../pages/Errors/Default'));

/**
 * Returns appropriate page for given route path, based on access controls.
 *
 * @param {Object} route - Route configuration.
 *
 * @returns {Route} component.
 */
export function AppRoute(route) {
	const {
		name = null,
		path = null,
		exact = true,
		routes = null,
		sensitive = true,
		accessOptions = { requireLogin: true }
	} = route;

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	const renderRoute = (props) => {
		const hasAccess = hasRouteAccess(route, accessOptions);

		if (window.APP_STATUS_CODE) {
			return <DefaultErrorPage {...props} />;
		}

		if (hasAccess) {
			return <route.component {...props} name={name} routes={routes} />;
		}

		if (!hasAccess && isAuthenticated) {
			return <DefaultErrorPage statusCode="403" {...props} />;
		}

		return (
			<Redirect
				to={{
					pathname: routeList.auth.login.path[1],
					state: { from: props.location }
				}}
			/>
		);
	};

	if (route.computedMatch && !route.computedMatch.isExact && exact) {
		return <DefaultErrorPage />;
	}

	return <Route exact={exact} path={path} render={renderRoute} sensitive={sensitive} />;
}

/**
 * Renders all available routes.
 *
 * @returns {Switch} component.
 */
export const AppRoutes = () => (
	<Switch>
		{pageRoutes.map((route, i) => (
			<AppRoute key={route.path || i} {...route} />
		))}
		<Route>
			<DefaultErrorPage />
		</Route>
	</Switch>
);
