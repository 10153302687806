/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

export const LEGACY_REPORTS_PAGE = 'LEGACY_REPORTS_PAGE';

export const updateToReportsPage = ({ pageData }) => ({
	type: LEGACY_REPORTS_PAGE,
	payload: {
		reportsPage: pageData
	}
});
