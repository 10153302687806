import React from 'react';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomCheckbox = withStyles((theme) => ({
	root: {
		'&$checked': {
			color: theme.palette.action.active
		},
		'&$disabled': {
			color: theme.palette.action.disabledBackground
		}
	},
	checked: {},
	disabled: {}
}))((props) => <Checkbox onClick={(event) => event.stopPropagation()} color="default" {...props} />);

export default CustomCheckbox;
