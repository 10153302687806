import React, { useState } from 'react';
import { EventAnalysis } from '@portal-reporting/report-ui-templates';
import '@portal-reporting/report-ui-templates/dist/styles/report-styles.css';
import 'highcharts/css/highcharts.css';
import { EventAnalysisDrillDown } from './Events';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TimePeriodDialog, validateTimeRange } from './Events/TimePeriodMessage';

const EventAnalysisMain = (props) => {
	const drillDownRef = React.useRef(null);
	const { data, locale, t, dataParams } = props;
	const [showTable, setShowTable] = useState(false);
	const [timeRangeMsg, setTimeRangeMsg] = useState({
		isValid: false,
		message: ''
	});
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [parameters, setParameters] = useState({});

	const handleDrillDown = (props) => {
		const updatedParams = { ...parameters, ...props };
		loadParameters(updatedParams);
		updateShowHelpModal(updatedParams);
	};

	const updateShowHelpModal = (params) => {
		if (!timeRangeMsg.isValid) {
			const isValidTimeRange = validateTimeRange({
				startDate: params.startDate,
				t: t
			});
			setTimeRangeMsg(isValidTimeRange);
		}
	};

	const loadParameters = (params) => {
		scrollToDDView();
		axios
			.get('/portal/reports/executedReport?reportId=' + dataParams.reportId)
			.then((response) => {
				const res = response.data;
				setParameters({
					...params,
					clientLocationHrefs: res.clientLocationHrefs
				});
				setShowTable(true);
			})
			.catch(() => {
				setShowTable(false);
				setIsError(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const scrollToDDView = () => {
		setShowTable(false);
		setIsLoading(true);
		drillDownRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	};

	return (
		<>
			<EventAnalysis
				data={data}
				t={t}
				dataParams={dataParams}
				locale={locale}
				handleDrillDown={handleDrillDown}
				reportName={props.reportName}
			></EventAnalysis>
			{showTable && (
				<TimePeriodDialog
					t={t}
					open={!timeRangeMsg.isValid}
					onClose={() => {
						setTimeRangeMsg({
							...timeRangeMsg,
							isValid: true
						});
					}}
					message={timeRangeMsg.message}
				></TimePeriodDialog>
			)}
			<div ref={drillDownRef}>
				{showTable && !isLoading && parameters?.categoryType ? (
					<EventAnalysisDrillDown fromChart={parameters} isError={isError} />
				) : (
					isLoading && (
						<p>
							{t('events:summaryMessages.loadingPage')}
							<FontAwesomeIcon icon={faSpinner} spin={true}></FontAwesomeIcon>
						</p>
					)
				)}
			</div>
		</>
	);
};

export default EventAnalysisMain;
