/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React, { Suspense } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import FooterBar from './Bar';
import FooterNav from './Nav';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.footer.background,
		color: theme.palette.footer.text,
		flex: '0 1 auto',
		width: '100%',
		minHeight: '0%',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	navSkeleton: {
		borderTop: `2px solid ${theme.palette.footerNav.border}`,
		background: `${theme.palette.footerNav.background}`
	}
}));

const PrivateFooter = () => {
	const classes = useStyles();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<footer className={classNames('sw-footer', classes.root)} role="contentinfo">
			<Suspense fallback={<Skeleton variant="rect" height={126} className={classes.navSkeleton} />}>
				{isAuthenticated && <FooterNav />}
			</Suspense>
			<FooterBar />
		</footer>
	);
};

export default PrivateFooter;
