/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import { useSelector } from 'react-redux';

const languageList = {
	en_US: 'en',
	es_ES: 'es',
	ja_JP: 'jp'
};

const ticketOptionLabels = {
	en: 'Question About This Ticket',
	es: 'Pregunta sobre este ticket',
	jp: 'このチケットについての質問'
};

const ticketOptionValues = {
	en: 'Chat',
	es: 'Chat',
	jp: 'Chat - JP'
};

function getCampaignOption(campaign, campaigns, language) {
	const lang = languageList[language];
	const result = campaigns.find(({ name }) => campaign === name);

	if (result) {
		return result.labels[lang]
			? {
					label: result.labels[lang],
					value: result.name
			  }
			: null;
	} else if (Object.values(ticketOptionValues).includes(campaign)) {
		return ticketOptionLabels[lang]
			? {
					label: ticketOptionLabels[lang],
					value: campaign
			  }
			: null;
	} else {
		return null;
	}
}

const useCampaignOptions = ({ withTicket = false } = {}) => {
	const language = useSelector((state) => state.preferences.language);
	const campaigns = useSelector((state) => state.chat.session.campaigns);

	if (!language || !campaigns) {
		return { campaignOptions: [], ticketOption: {} };
	}

	const lang = languageList[language];

	let campaignOptions = campaigns
		.map(({ name, labels }) =>
			labels[lang]
				? {
						label: labels[lang],
						value: name
				  }
				: null
		)
		.filter(Boolean);

	const isJpSegment = campaigns.find(({ name }) => name.indexOf('- JP') > -1);

	const ticketOption = {
		label: ticketOptionLabels[lang],
		value: isJpSegment ? ticketOptionValues.jp : ticketOptionValues.en
	};

	if (withTicket) {
		campaignOptions.push(ticketOption);
	}

	return { campaignOptions, ticketOption };
};

export default useCampaignOptions;

export { getCampaignOption };
