/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const AutocompleteCustomStyles = (theme) => ({
	/* Styles applied to the root element. */
	root: {
		'&:hover $clearIndicatorDirty, &$focused $clearIndicatorDirty': {
			visibility: 'visible'
		}
	},
	/* Pseudo-class applied to the root element if focused. */
	focused: {},
	/* Styles applied to the tag elements, e.g. the chips. */
	tag: {
		'backgroundColor': theme.palette.autocomplete.chip.background,
		'border': `1px solid ${theme.palette.autocomplete.chip.borderColor}`,
		'color': `${theme.palette.autocomplete.chip.color} !important`,
		'borderRadius': '3px',
		'fontSize': '15px',
		'height': '22px',
		'& .MuiChip-label': {
			paddingLeft: theme.spacing(1)
		}
	},
	/* Styles applied to the Input element. */
	inputRoot: {
		'flexWrap': 'wrap',
		'paddingRight': theme.spacing(2),
		'&[class*="MuiOutlinedInput-root"]': {
			'padding': theme.spacing(0, 1),
			'& $input': {
				padding: theme.spacing(2, 1)
			},
			'& $input:first-child': {
				paddingLeft: 2,
				paddingRight: theme.spacing(2)
			}
		},
		'&[class*="MuiFilledInput-root"]': {
			'paddingTop': theme.spacing(4),
			'paddingLeft': theme.spacing(2),
			'& $input': {
				paddingLeft: theme.spacing(1),
				paddingTop: theme.spacing(2)
			}
		},
		'& $input:not(:first-child)': {
			paddingLeft: theme.spacing(1)
		},
		'& $input': {
			width: 0,
			minWidth: theme.spacing(6)
		}
	},
	/* Styles applied to the input element. */
	input: {
		flexGrow: 1,
		textOverflow: 'ellipsis',
		opacity: 0
	},
	/* Styles applied to the input element if tag focused. */
	inputFocused: {
		opacity: 1
	},
	/* Styles applied to the clear indictator. */
	clearIndicator: {
		marginRight: -2,
		padding: 4,
		color: theme.palette.action.active,
		visibility: 'hidden'
	},
	/* Styles applied to the clear indictator if the input is dirty. */
	clearIndicatorDirty: {},
	/* Styles applied to the popup indictator. */
	popupIndicator: {
		padding: 2,
		marginRight: -2,
		color: theme.palette.action.active
	},
	/* Styles applied to the popup indictator if the popup is open. */
	popupIndicatorOpen: {
		transform: 'rotate(180deg)'
	},
	/* Styles applied to the popper element. */
	popper: {
		zIndex: theme.zIndex.modal
	},
	/* Styles applied to the popper element if `disablePortal={true}`. */
	popperDisablePortal: {
		position: 'absolute'
	},
	/* Styles applied to the `Paper` component. */
	paper: {
		...theme.typography.body1,
		'margin': '1px 0',
		'& > ul': {
			maxHeight: '40vh',
			overflow: 'auto'
		}
	},
	/* Styles applied to the `listbox` component. */
	listbox: {
		listStyle: 'none',
		margin: 0,
		padding: theme.spacing(1, 0),
		position: 'relative',
		maxHeight: '30vh !important'
	},
	/* Styles applied to the loading wrapper. */
	loading: {
		color: theme.palette.text.secondary,
		padding: theme.spacing(2, 3)
	},
	/* Styles applied to the no option wrapper. */
	noOptions: {
		color: theme.palette.text.secondary,
		padding: theme.spacing(2, 3)
	},
	/* Styles applied to the option elements. */
	option: {
		'color': theme.palette.dropdownItem.color,
		'minHeight': theme.spacing(7),
		'display': 'flex',
		'justifyContent': 'flex-start',
		'alignItems': 'center',
		'cursor': 'pointer',
		'paddingTop': theme.spacing(1),
		'outline': '0',
		// Remove grey highlight
		'WebkitTapHighlightColor': 'transparent',
		'paddingBottom': theme.spacing(1),
		'paddingLeft': theme.spacing(2),
		'paddingRight': theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			minHeight: 'auto'
		},
		'&[aria-selected="true"]': {
			color: theme.palette.dropdownItem.colorActive,
			backgroundColor: theme.palette.dropdownItem.backgroundActive
		},
		'&[data-focus="true"]': {
			color: theme.palette.dropdownItem.colorHover,
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		},
		'&[aria-disabled="true"]': {
			'opacity': 1,
			'pointerEvents': 'none',
			'color': theme.palette.dropdownItem.colorDisabled,
			'backgroundColor': theme.palette.dropdownItem.backgroundDisabled,
			'&:hover, &:focus': {
				color: theme.palette.dropdownItem.colorDisabled,
				backgroundColor: theme.palette.dropdownItem.backgroundDisabled
			}
		},
		'&:active': {
			color: theme.palette.dropdownItem.colorHover,
			backgroundColor: theme.palette.dropdownItem.backgroundHover
		}
	},
	/* Styles applied to the group's label elements. */
	groupLabel: {
		backgroundColor: theme.palette.background.paper,
		top: theme.spacing(-1)
	},
	/* Styles applied to the group's ul elements. */
	groupUl: {
		padding: 0
	}
});

export default withStyles(AutocompleteCustomStyles, { name: 'AutocompleteCustom' })(Autocomplete);
