/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

const PublicFooter = () => {
	const { t, ready } = useTranslation('common', { useSuspense: false });
	const currentYear = new Date().getFullYear();

	return (
		<footer className="sw-footer public" role="contentinfo">
			<p className="sw-footer-legal">
				{ready
					? t('legal.copyright', {
							currentYear
					  })
					: '.'}
			</p>
		</footer>
	);
};

export default PublicFooter;
