/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

import { LEGACY_REPORTS_PAGE } from '../../actions/app/legacy';

const initialState = {
	reportsPage: null,
	isError: false,
	isLoading: true
};

export default function legacyReducer(state = initialState, action) {
	switch (action.type) {
		case LEGACY_REPORTS_PAGE:
			return {
				...state,
				reportsPage: {
					//...state.reportsPage,
					...action.payload.reportsPage
				}
			};
		default:
			return state;
	}
}
