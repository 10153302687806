/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { REPORT_CONSTANTS } from '../../../../pages/Reports/Executed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
	scrollToTop: {
		'position': 'fixed',
		'bottom': '30px',
		'right': '75px',
		'opacity': 0.5,
		'border': '1px solid #d1d1d1',
		'width': '50px',
		'height': '50px',
		'display': 'block',
		'borderRadius': '5px',
		'zIndex': 99,
		'transition': 'all .3s ease',
		'color': theme.palette.link.color,
		'alignItems': 'center',
		'paddingTop': '10px',
		'background': '#efefef',
		'&:hover': {
			opacity: 1,
			background: '#efefef'
		},
		'cursor': 'pointer'
	},
	scrollToTopIcon: {
		width: '50px !important',
		height: '30px',
		color: theme.palette.link.color
	}
}));

const ScrollToTop = () => {
	const classes = useStyles();
	const reports = useSelector((state) => state.reports);
	const handleScrollPosition = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<>
			{reports?.progress === REPORT_CONSTANTS.VIEWPAGE && (
				<span className={classes.scrollToTop} onClick={handleScrollPosition}>
					<FontAwesomeIcon icon={faArrowUp} className={classes.scrollToTopIcon} size={'sm'} />
				</span>
			)}
		</>
	);
};

export default ScrollToTop;
