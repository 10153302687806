/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */

export const REPORTS_PREFIX = '@@reports';

export const RESET = `${REPORTS_PREFIX}/RESET`;
export const SET_PROGRESS = `${REPORTS_PREFIX}/PROGRESS`;
export const SET_REPORT_NAME = `${REPORTS_PREFIX}/REPORT_NAME`;
export const SET_REPORT_VIEW = `${REPORTS_PREFIX}/SET_REPORT_VIEW`;
export const SET_REPORT_VIEW_PARAMS = `${REPORTS_PREFIX}/SET_REPORT_VIEW_PARAMS`;

export const resetReportsStore = () => ({
	type: RESET
});

export const setReportView = ({ payload }) => ({
	type: SET_REPORT_VIEW,
	payload: {
		...payload
	}
});

export const setProgress = ({ progress }) => ({
	type: SET_PROGRESS,
	progress: progress
});

export const setReportName = ({ reportName }) => ({
	type: SET_REPORT_NAME,
	reportName: reportName
});

export const setViewParams = ({ viewParams }) => ({
	type: SET_REPORT_VIEW_PARAMS,
	viewParams: viewParams
});
