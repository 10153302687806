/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2021 SecureWorks, Inc. All rights reserved.
 */
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { showAlert } from '../../../state/actions/components/alerts';
import { hideModal } from '../../../state/actions/components/modals';
import { fetchDataTableData } from '../../../state/actions/components/datatables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { AlertCustom } from '../../../components/Alert';
import Button from '../../../components/Inputs/Button';

const useStyles = makeStyles((theme) => ({
	closeButton: {
		color: theme.palette.grey[500],
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(3)
	},
	listRoot: {
		margin: theme.spacing(3, 0, 3, 2),
		maxHeight: 140,
		overflow: 'auto',
		padding: 0,
		width: 'auto'
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0,
		width: 'auto'
	},
	listItemText: {
		margin: theme.spacing(0)
	},
	listItemError: {
		color: theme.palette.error.main
	},
	listItemSuccess: {
		color: theme.palette.success.main
	},
	listItemIcon: {
		minWidth: 12,
		fontSize: 4,
		color: theme.palette.text.primary
	},
	dialogActions: {
		backgroundColor: theme.palette.background.default
	},
	noteStrong: {
		fontWeight: 700
	}
}));

const DeleteNetworksModal = ({ isOpen, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const { networks } = data;
	const networksCount = networks.length;

	const [requestState, setRequestState] = useState({
		isError: false,
		isLoading: false
	});

	function handleClose() {
		setRequestState({
			isError: false,
			isLoading: false
		});

		dispatch(hideModal({ name: 'DELETE_NETWORKS' }));
	}

	function handleSubmit() {
		setRequestState({
			isError: false,
			isLoading: true
		});

		const url = `/portal/administration/networks/delete/${networks[0].networkId}`;
		const method = 'DELETE';

		return axios({
			url,
			method
		})
			.then(() => {
				setRequestState({
					isError: false,
					isLoading: false
				});

				dispatch(
					showAlert({
						id: `DELETE_NETWORKS_SUCCESS_${new Date().getTime()}`,
						data: {
							severity: 'success',
							message: [
								'common:modals.deleteNetworks.messages.success',
								{
									count: networksCount,
									name: networks
										.map(({ networkType, networkAddress }) => `${networkType} ${networkAddress}`)
										.join(', ')
								}
							]
						}
					})
				);

				dispatch(
					fetchDataTableData({
						name: 'ADMINISTRATION_NETWORKS'
					})
				);

				handleClose();
			})
			.catch(() => {
				setRequestState({
					isError: true,
					isLoading: false
				});
			});
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="sm"
			aria-labelledby="delete-networks-modal"
			open={isOpen}
			onClose={handleClose}
		>
			<DialogTitle id="delete-networks-modal" disableTypography>
				<Typography variant="h2">
					<Box fontWeight="fontWeightRegular">
						{networks.length > 1
							? t('modals.deleteNetworks.title_plural')
							: t('modals.deleteNetworks.title')}
					</Box>
					<IconButton size="small" aria-label="close" onClick={handleClose} className={classes.closeButton}>
						<FontAwesomeIcon icon={faTimes} size="1x" />
					</IconButton>
				</Typography>
			</DialogTitle>

			<DialogContent dividers className={classes.dialogContent}>
				{requestState.isError ? (
					<AlertCustom severity="error" variant="outlined" gutterBottom>
						{t('modals.deleteNetworks.messages.error', { count: networksCount })}
					</AlertCustom>
				) : null}

				<Typography>
					{networks.length > 1
						? t('modals.deleteNetworks.description_plural')
						: t('modals.deleteNetworks.description')}
				</Typography>

				<List className={classes.listRoot} dense>
					{networks.map(({ networkType, networkAddress }, index) => {
						return (
							<ListItem key={`${networkAddress}-${index}`} className={classes.listItem}>
								<ListItemIcon className={classes.listItemIcon}>
									<FontAwesomeIcon icon={faCircle} />
								</ListItemIcon>
								<ListItemText
									className={classes.listItemText}
								>{`${networkType}, ${networkAddress}`}</ListItemText>
							</ListItem>
						);
					})}
				</List>

				<Box mb={2}>
					<Typography className={classes.noteStrong}>{t('modals.deleteNetworks.note')}</Typography>
				</Box>

				{/**Inline formatting from translation requires default copy as template.
				 * This is replaced at runtime.
				 */}
				<Trans i18nKey="common:modals.deleteNetworks.review.clickSubmit">
					Click <strong>Submit</strong> to proceed.
				</Trans>
				<Box mb={2} mt={0.5}>
					<Trans i18nKey="common:modals.deleteNetworks.review.clickCancel">
						Click <strong>Cancel</strong> to cancel the deletion.
					</Trans>
				</Box>
			</DialogContent>

			<DialogActions className={classes.dialogActions}>
				<Button onClick={handleClose} variant="outlined" color="default" disabled={requestState.isLoading}>
					{t('buttons.cancel')}
				</Button>
				<Button onClick={handleSubmit} isLoading={requestState.isLoading}>
					{t('buttons.delete')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteNetworksModal;
