/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
import { lazy } from 'react';
import { urls } from '../state/bootstrap';

/**
 * List of all application routes.
 * @see {@link https://confluence.secureworks.net/x/lIBaEg Client Portal Routes} for further information.
 */
const bootstrapRoutes = {
	auth: {
		login: {
			name: 'common:pages.auth.login.title',
			path: ['/portal/login', '/portal/loginIDP'],
			component: lazy(() => import('../pages/Auth/Login')),
			accessOptions: {
				toAuth: () => true
			}
		},
		logout: {
			name: 'common:pages.auth.logout.title',
			path: ['/portal/logout', '/portal/loggedOut'],
			component: lazy(() => import('../pages/Auth/Logout')),
			accessOptions: {
				toAuth: () => true
			}
		},
		switchUser: {
			name: 'common:pages.auth.switchUser.title',
			path: '/portal/engsudo',
			component: lazy(() => import('../pages/Auth/SwitchUser')),
			accessOptions: {
				toAuth: ({ isAuthenticated, isEngineer, isLoggedIn }) => {
					return isAuthenticated && isEngineer && !isLoggedIn;
				}
			}
		},
		multiClient: {
			name: 'common:pages.auth.multiClient.title',
			path: '/portal/multi-client',
			component: lazy(() => import('../pages/Auth/MultiClient')),
			accessOptions: {
				toAuth: ({ isAuthenticated, isMultiClient, isLoggedIn }) => {
					return isAuthenticated && isMultiClient && !isLoggedIn;
				}
			}
		}
	},
	dashboards: {
		dashboard: {
			name: 'common:pages.dashboards.dashboard.title',
			path: '/portal/dashboard',
			component: lazy(() => import('../pages/Dashboards/Dashboard')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		myPortal: {
			name: 'common:pages.dashboards.myPortal.title',
			path: '/portal/myportal',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['MY_PORTAL']
				}
			}
		},
		orchestration: {
			name: 'common:pages.dashboards.orchestration.title',
			path: '/portal/dashboard/orchestration',
			component: lazy(() => import('../pages/Dashboards/Orchestration')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['NCP_ORCH_DASHBOARD_ENABLED']
				},
				toPrivileges: {
					list: ['ORCHESTRATION']
				}
			}
		}
	},
	search: {
		results: {
			name: 'common:pages.search.results.title',
			path: '/portal/search',
			component: lazy(() => import('../pages/Search/Results')),
			accessOptions: {
				toCustom: ({ other }) => {
					return other.isAuthorisedToSearch;
				}
			}
		}
	},
	incidents: {
		details: {
			routes: {
				datacube: {
					name: 'common:datacube.title',
					path: '/portal/incidents/:ticketId/relational-view',
					component: lazy(() => import('../pages/Incidents/Details/DataCube')),
					accessOptions: {
						toRoles: {
							list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
							options: {
								hasAny: true
							}
						},
						toFeatures: {
							list: ['DATA_CUBE_ENABLED']
						},
						toCustom: ({ auth, features }) => {
							return auth.isEngineer || features['DATA_CUBE_ENABLED_CLIENT'];
						}
					}
				}
			}
		},
		incidents: {
			name: 'common:pages.incidents.incidents.title',
			path: '/portal/incidents',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		statistics: {
			name: 'common:pages.incidents.statistics.title',
			path: '/portal/incidentStatistics',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['MDR_CLIENT_SERVICE_LEVEL_ENABLED', 'INCIDENT_STATISTICS_TIME_SPENT_FEATURE_ENABLED'],
					options: {
						orHas: ['INCIDENT_AGGREGATIONS_FEATURE_ENABLED']
					}
				}
			}
		}
	},
	events: {
		realTime: {
			name: 'common:pages.events.realTime.title',
			path: '/portal/events/realTimeEvents',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		search: {
			name: 'common:pages.events.search.title',
			path: '/portal/events',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		monitoring: {
			name: 'common:pages.events.monitoring.title',
			path: '/portal/monitoring',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'NGP ENGINEER'],
					options: {
						hasAny: true
					}
				}
			}
		},
		rawLogs: {
			name: 'common:pages.events.rawLogs.title',
			path: '/portal/rawlogs',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['RAW_LOGS_QUERY']
				}
			}
		}
	},
	reports: {
		scheduled: {
			name: 'common:pages.reports.scheduled.title',
			path: '/portal/reports/getScheduleReportList',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		templates: {
			name: 'common:pages.reports.templates.title',
			path: '/portal/reports/templates',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		create: {
			name: 'common:pages.reports.create.title',
			path: '/portal/reports/category',
			component: lazy(() => import('../pages/Reports/CreateReport')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		createNew: {
			name: 'common:pages.reports.createNew.title',
			path: '/portal/reports/create',
			component: lazy(() => import('../pages/Reports/Create')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		executedNRS: {
			name: 'common:pages.reports.executedNRS.title',
			path: '/portal/reports/executed',
			component: lazy(() => import('../pages/Reports/Executed')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		parameter: {
			name: 'common:pages.reports.parameter.title',
			path: '/portal/reports/parameter',
			component: lazy(() => import('../pages/Reports/Parameter')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		acknowledge: {
			name: 'common:pages.reports.acknowledge.title',
			path: '/portal/reports/ackReport',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		}
	},
	assets: {
		details: {
			path: '/portal/assets/details/asset/:type/:id',
			routes: {
				datacube: {
					name: 'common:datacube.title',
					path: '/portal/assets/details/asset/:type/:id/relational-view',
					component: lazy(() => import('../pages/Assets/Details/DataCube')),
					accessOptions: {
						toRoles: {
							list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'SCAN_SSO_EXPOSURES'],
							options: {
								hasAny: true
							}
						},
						toFeatures: {
							list: ['DATA_CUBE_ENABLED', 'ASSETS_RELATIONALVIEW_ENABLED']
						},
						toCustom: ({ auth, features }) => {
							return auth.isEngineer || features['DATA_CUBE_ENABLED_CLIENT'];
						}
					}
				}
			}
		},
		managed: {
			name: 'common:pages.assets.managed.title',
			path: '/portal/assets',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'SCAN_SSO_EXPOSURES'],
					options: {
						hasAny: true
					}
				}
			}
		},
		unmanaged: {
			name: 'common:pages.assets.unmanaged.title',
			path: '/portal/assets/unmanaged',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'SCAN_SSO_EXPOSURES'],
					options: {
						hasAny: true
					}
				}
			},
			routes: [
				{
					import: {
						name: 'common:pages.assets.import.title',
						path: '/portal/assets/import',
						accessOptions: {
							toRoles: {
								list: ['ADMIN']
							}
						}
					},
					cidr: {
						name: 'common:pages.assets.cidr.title',
						path: '/portal/assets/unmanaged/cidr',
						accessOptions: {
							toRoles: {
								list: ['ADMIN', 'AUDITOR', 'SECURITY'],
								options: {
									hasAny: true
								}
							}
						}
					}
				}
			]
		},
		healthTickets: {
			name: 'common:pages.assets.healthTickets.title',
			path: '/portal/healthtickets',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'SCAN_SSO_EXPOSURES'],
					options: {
						hasAny: true
					}
				}
			}
		},
		provisioningLibrary: {
			name: 'common:pages.assets.provisioningLibrary.title',
			path: '/portal/assets/provisioning-library',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true,
						andHas: ['PROVISIONING AUTOMATION USER']
					}
				},
				toFeatures: {
					list: ['ORCHESTRATION_ENABLED_CLIENT', 'ASSETS_SHOW_PROVISIONING_LIBRARY']
				}
			}
		},
		efdConfiguration: {
			name: 'common:pages.assets.efd.title',
			path: '/portal/efdConfiguration',
			component: lazy(() => import('../pages/Assets/EfdConfiguration')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['EFD_TICKETS_SUPPRESSION']
				}
			}
		}
	},
	service: {
		serviceRequests: {
			name: 'common:pages.service.serviceRequests.title',
			path: '/portal/servicerequests',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		createServiceRequest: {
			name: 'common:pages.service.createServiceRequest.title',
			path: '/portal/servicerequests/create',
			accessOptions: {
				toAuth: ({ isFFIEC, isEngineer }) => {
					return !(isFFIEC && isEngineer);
				},
				toRoles: {
					list: ['ADMIN', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		}
	},
	intelligence: {
		dashboard: {
			name: 'common:pages.intelligence.dashboard.title',
			path: '/portal/intel/ti-dashboard',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		tips: {
			name: 'common:pages.intelligence.tips.title',
			path: '/portal/intel/ctu-tips',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		threatAnalyses: {
			name: 'common:pages.intelligence.threatAnalyses.title',
			path: '/portal/intel/threats-summary',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		advisories: {
			name: 'common:pages.intelligence.advisories.title',
			path: '/portal/intel/advisories',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		vulnerabilities: {
			name: 'common:pages.intelligence.vulnerabilities.title',
			path: '/portal/intel/vulns',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		manageProfiles: {
			name: 'common:pages.intelligence.manageProfiles.title',
			path: '/portal/intel/profile',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS'],
					options: {
						hasAny: true
					}
				}
			}
		},
		attackerDatabase: {
			name: 'common:pages.intelligence.attackerDatabase.title',
			path: '/portal/intel/attackerdb',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS', 'ATTACKERDB_XML'],
					options: {
						hasAny: true
					}
				}
			}
		},
		countermeasuresDownload: {
			name: 'common:pages.intelligence.countermeasuresDownload.title',
			path: '/portal/intel/ctu-ctm',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_STANDARD', 'TI_ADVANCED', 'TI_ENTERPRISE', 'TI_STANDARDPLUS', 'CTU_COUNTERMEASURE'],
					options: {
						hasAny: true
					}
				}
			}
		},
		library: {
			name: 'common:pages.intelligence.library.title',
			path: '/portal/intel/library',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: [
						'TI_STANDARD',
						'TI_ADVANCED',
						'TI_ENTERPRISE',
						'TI_STANDARDPLUS',
						'ENTERPRISE_BRAND_SURVEILLANCE'
					],
					options: {
						hasAny: true
					}
				}
			}
		},
		feedsAndExports: {
			name: 'common:pages.intelligence.feedsAndExports.title',
			path: '/portal/intel/feedsandexports',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_ENTERPRISE']
				}
			}
		},
		borderlessThreatMonitoring: {
			name: 'common:pages.intelligence.borderlessThreatMonitoring.title',
			path: '/portal/intel/btm',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TARGETED_THREAT_SURV', 'BORDERLESS_THREAT_MONITORING'],
					options: {
						hasAny: true
					}
				}
			}
		},
		threatIntelligenceAlerts: {
			name: 'common:pages.intelligence.threatIntelligenceAlerts.title',
			path: '/portal/intel/tiew',
			accessOptions: {
				toFeatures: {
					list: ['TIEW']
				}
			}
		}
	},
	orchestration: {
		orchestrationActions: {
			name: 'common:pages.orchestration.orchestrationActions.title',
			path: '/portal/orchestrationactions',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['NCP_ORCH_ACTION_HISTORY_ENABLED']
				},
				toPrivileges: {
					list: ['ORCHESTRATION']
				}
			}
		},
		existingConnectors: {
			name: 'common:pages.orchestration.existingConnectors.title',
			path: '/portal/orchestration/existing-connectors',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true,
						andHas: ['PROVISIONING AUTOMATION USER']
					}
				},
				toFeatures: {
					list: ['ORCHESTRATION_ENABLED_CLIENT', 'SHOW_ORCHESTRATION_CONNECTORS']
				},
				toPrivileges: {
					list: ['ORCHESTRATION']
				}
			}
		},
		provisioningLibrary: {
			name: 'common:pages.orchestration.provisioningLibrary.title',
			path: '/portal/orchestration/provisioning-library',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY'],
					options: {
						hasAny: true,
						andHas: ['PROVISIONING AUTOMATION USER']
					}
				},
				toFeatures: {
					list: ['ORCHESTRATION_ENABLED_CLIENT', 'SHOW_ORCHESTRATION_CONNECTORS']
				},
				toPrivileges: {
					list: ['ORCHESTRATION']
				}
			}
		}
	},
	administration: {
		deviceRegistration: {
			name: 'common:pages.administration.deviceRegistration.title',
			path: '/portal/administration/device-registration',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['NCP_DEVICE_REGISTRATION_AND_CONFIGURATION_ENABLED']
				}
			}
		},
		monitoringConfiguration: {
			name: 'common:pages.administration.monitoringConfiguration.title',
			path: '/portal/administration/monitoring-configuration',
			component: lazy(() => import('../pages/Administration/MonitoringConfiguration')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY'],
					options: {
						hasAny: true
					}
				},
				toFeatures: {
					list: ['NCP_MONITORING_CONFIGURATION_ENABLED']
				}
			}
		},
		phishOps: {
			name: 'common:pages.administration.phishOps.title',
			path: '/portal/administration/phishops',
			accessOptions: {
				toRoles: {
					list: ['ADMIN']
				},
				toFeatures: {
					list: ['PHISHING_OPERATIONS_CONFIGURATION_ENABLED']
				},
				toPrivileges: {
					list: ['PHISH_OPS_O365_EXCHANGE']
				}
			}
		},
		networks: {
			name: 'common:pages.administration.networks.title',
			path: '/portal/administration/networks',
			component: lazy(() => import('../pages/Administration/Networks')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN']
				}
			},
			routes: [
				{
					addNetworks: {
						name: 'common:pages.administration.addNetwork.title',
						path: '/portal/administration/networks/add',
						accessOptions: {
							toRoles: {
								list: ['ADMIN']
							}
						}
					}
				}
			]
		},
		addNetworks: {
			name: 'common:pages.administration.addNetwork.title',
			path: '/portal/administration/networks/add',
			component: lazy(() => import('../pages/Administration/Networks/AddNetwork')),
			isSubMenu: true,
			accessOptions: {
				toRoles: {
					list: ['ADMIN']
				}
			}
		},
		escalationProcedures: {
			name: 'common:pages.administration.escalationProcedures.title',
			path: '/portal/administration/escalation-procedures',
			component: lazy(() => import('../pages/Administration/EscalationProcedures')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN']
				}
			}
		},
		administration: {
			name: 'common:pages.administration.administration.title',
			path: '/portal/administration',
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		idm: {
			name: 'common:pages.other.idm.title',
			path: '/portal/idm',
			isExternal: true,
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true,
						andHas: ['USER ADMIN']
					}
				}
			}
		}
	},
	documents: {
		gdpr: {
			terms: {
				name: 'common:documents.gdpr.terms.title',
				path: `${urls.legal.terms}`,
				isExternal: true,
				accessOptions: {
					toFeatures: {
						list: ['GDPR_SPLASH_SCREEN']
					}
				}
			},
			readiness: {
				name: 'common:documents.gdpr.readiness.title',
				path: `${urls.legal.readiness}`,
				isExternal: true,
				accessOptions: {
					toFeatures: {
						list: ['GDPR_SPLASH_SCREEN']
					}
				}
			},
			subprocessor: {
				name: 'common:documents.gdpr.subprocessor.title',
				path: `${urls.legal.subprocessor}`,
				isExternal: true,
				accessOptions: {
					toFeatures: {
						list: ['GDPR_SPLASH_SCREEN']
					}
				}
			}
		}
	},
	preferences: {
		preferences: {
			name: 'common:pages.preferences.preferences.title',
			nameLong: 'common:pages.preferences.preferences.titleLong',
			path: '/portal/preferences',
			component: lazy(() => import('../pages/Preferences/General'))
		},
		notifications: {
			name: 'common:pages.preferences.notifications.title',
			path: '/portal/preferences/notifications',
			component: lazy(() => import('../pages/Preferences/Notifications')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'SECURITY', 'THREAT INTELLIGENCE ANALYST', 'INFRASTRUCTURE'],
					options: {
						hasAny: true
					}
				}
			}
		},
		token: {
			name: 'common:pages.preferences.token.title',
			path: '/portal/preferences/token',
			component: lazy(() => import('../pages/Preferences/Token')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'SECURITY', 'THREAT INTELLIGENCE ANALYST'],
					options: {
						hasAny: true
					}
				},
				toPrivileges: {
					list: ['TI_ENTERPRISE', 'ATTACKERDB_XML', 'CTU_COUNTERMEASURE'],
					options: {
						hasAny: true
					}
				}
			}
		}
	},
	tools: {
		filterSets: {
			name: 'common:pages.tools.filterSets.title',
			path: '/portal/filtersets',
			accessOptions: {
				toAuth: ({ isAuthenticated }) => {
					return isAuthenticated;
				},
				toRoles: {
					list: ['NGP ENGINEER', 'FILTERSETADMIN']
				}
			}
		},
		manageLibrary: {
			name: 'common:pages.tools.manageLibrary.title',
			path: '/portal/intel/library-manage',
			accessOptions: {
				toAuth: ({ isAuthenticated }) => {
					return isAuthenticated;
				},
				toRoles: {
					list: ['CTU', 'ENTERPRISE_BRAND_SURVEILLANCE'],
					options: {
						hasAny: true,
						andHas: ['NGP ENGINEER']
					}
				}
			}
		}
	},
	other: {
		idm: {
			name: 'common:pages.other.idm.title',
			path: '/idm',
			isExternal: true
		},
		help: {
			name: 'common:pages.other.help.title',
			path: `${urls.cdn}/help/index.htm`,
			isExternal: true
		},
		contactUs: {
			name: 'common:pages.other.contactUs.title',
			path: '/portal/contactUs',
			component: lazy(() => import('../pages/Support/Contact'))
		},
		browserSupport: {
			name: 'common:pages.other.browserSupport.title',
			path: `${urls.cdn}/help/Supported_Browsers.htm`,
			isExternal: true
		},
		learningCenter: {
			name: 'common:pages.other.learningCenter.title',
			path: urls.sabaCloud,
			isExternal: true
		},
		siteOverview: {
			name: 'common:pages.other.siteOverview.title',
			path: '/portal/info/site-overview',
			component: lazy(() => import('../pages/Support/Overview'))
		},
		carbonBlack: {
			name: 'common:pages.other.carbonBlack.title',
			path: urls.carbonBlack,
			isExternal: true,
			accessOptions: {
				toRoles: {
					list: ['CARBONBLACK USER']
				}
			}
		},
		redCloak: {
			name: 'common:pages.other.redCloak.title',
			path: urls.redCloak,
			isExternal: true,
			accessOptions: {
				toRoles: {
					list: ['ETDR USER']
				}
			}
		},
		kenna: {
			name: 'common:pages.other.kenna.title',
			path: urls.kenna,
			isExternal: true,
			accessOptions: {
				toCustom: ({ other }) => {
					return other.hasKennaSubscription;
				}
			}
		},
		nessus: {
			name: 'common:pages.other.nessus.title',
			path: urls.nessus,
			isExternal: true,
			accessOptions: {
				toCustom: ({ other }) => {
					return other.hasNessusSubscription;
				}
			}
		},
		cloudSecurity: {
			name: 'common:pages.other.cloudSecurity.title',
			path: urls.cloudSecurity,
			isExternal: true,
			accessOptions: {
				toPrivileges: {
					list: ['CLOUD_GUARDIAN_SCM']
				}
			}
		},
		acknowledgeReportFromEmail: {
			name: 'common:pages.other.acknowledgeReportFromEmail.title',
			path: '/portal/acknowledge-report',
			component: lazy(() => import('../pages/Reports/AcknowledgeFromEmail')),
			accessOptions: {
				toAuth: () => true
			}
		},
		deviceConfiguration: {
			name: 'Device Configuration',
			path: '/portal/administration/monitoring-configuration/device-configuration',
			component: lazy(() => import('../pages/Administration/MonitoringConfiguration/DeviceConfiguration')),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		tenantConfiguration: {
			name: 'Tenant Configuration',
			path:
				'/portal/administration/monitoring-configuration/device-configuration/azure-sync/tenant-configuration',
			component: lazy(() =>
				import('../pages/Administration/MonitoringConfiguration/DeviceConfiguration/TenantConfiguration')
			),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		},
		eventHubConfiguration: {
			name: 'EventHub Configuration',
			path:
				'/portal/administration/monitoring-configuration/device-configuration/event-hubs/tenant-configuration',
			component: lazy(() =>
				import('../pages/Administration/MonitoringConfiguration/DeviceConfiguration/EventHubConfiguration')
			),
			accessOptions: {
				toRoles: {
					list: ['ADMIN', 'ANALYST', 'AUDITOR', 'INFRASTRUCTURE', 'SECURITY'],
					options: {
						hasAny: true
					}
				}
			}
		}
	}
};

export default bootstrapRoutes;

export const bootstrapPublicRoutes = [
	...bootstrapRoutes.auth.login.path,
	...bootstrapRoutes.auth.logout.path,
	bootstrapRoutes.other.acknowledgeReportFromEmail.path
];
