/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2019 SecureWorks, Inc. All rights reserved.
 */
const initialState = {};

export default function privilegesReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}
