/*
 * Classification: //SecureWorks/Restricted - Internal Use Only
 * Copyright © 2022 SecureWorks, Inc. All rights reserved.
 */

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Dashboard from '../../../../../utils/dashboard';
import { useExportDrillDown } from './useExportDrillDown';

function getLocations(clientLocationHrefs) {
	return clientLocationHrefs?.length > 0 && clientLocationHrefs[0] !== 'alllocations' ? clientLocationHrefs : [];
}

const getDefaultGroupingOption = function (categoryType, groupingOptions) {
	return categoryType === 'TOTAL_ESCALATIONS' ? groupingOptions[1] : groupingOptions[3];
};

const getGroupingOptions = function (t, categoryType) {
	const options = [];
	if (categoryType === 'TOTAL_ESCALATIONS') {
		options.push({ id: 'categorization', text: t('reports:eventAnalysisStats.drillDown.categorization') });
	} else {
		options.push({ id: 'device', text: t('reports:eventAnalysisStats.drillDown.device') });
		options.push({ id: 'deviceType', text: t('reports:eventAnalysisStats.drillDown.deviceType') });
		options.push({
			id: 'escalatedVsResolved',
			text: t('reports:eventAnalysisStats.drillDown.escalatedVsResolved')
		});
		options.push({ id: 'eventCategory', text: t('reports:eventAnalysisStats.drillDown.eventCategory') });
	}
	return options;
};

const apiUrl = '/portal/reportsdrilldown/eventAnalysisStatsAggrData?limit=10&page=1';
const method = 'POST';
const drillDownId = 'REPORTS_EAS_DRILLDOWN';
const showPagination = false;
const useEventAnalysisDrillDown = (fromChart) => {
	const { t } = useTranslation(['events', 'reports', 'dashboard']);
	const groupingOptions = getGroupingOptions(t, fromChart.categoryType);
	const [requestPayload, setRequestPayload] = useState({
		categoryType: fromChart.categoryType,
		endTime: fromChart.endDate,
		limit: 50,
		locations: getLocations(fromChart.clientLocationHrefs),
		page: 1,
		rowGrouping: fromChart.categoryType === 'TOTAL_ESCALATIONS' ? 'categorization' : 'eventCategory',
		startTime: fromChart.startDate
	});
	const { exportDrillDownData } = useExportDrillDown();

	// download
	const downloadPdf = (rowGrouping) => {
		exportDrillDownData(
			{
				...requestPayload,
				rowGrouping: rowGrouping || requestPayload.rowGrouping
			},
			'pdf'
		);
	};

	const downloadCsv = (rowGrouping) => {
		exportDrillDownData(
			{
				...requestPayload,
				rowGrouping: rowGrouping || requestPayload.rowGrouping
			},
			'csv'
		);
	};

	return {
		apiUrl,
		method,
		requestPayload,
		setRequestPayload,
		groupingOptions,
		defaultGroupingOption: getDefaultGroupingOption(fromChart.categoryType, groupingOptions),
		downloadPdf,
		downloadCsv,
		fetchDataResponseFormatter: Dashboard.DrillDown.fetchDataResponseFormatter,
		drillDownId,
		showPagination
	};
};
useEventAnalysisDrillDown.propTypes = Dashboard.DrillDown.hookPropTypes;
export { useEventAnalysisDrillDown };
