/**
 * Classification: //SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../../Inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const useFormStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2, 2, 0, 1)
	},
	icon: {
		fontSize: 18,
		width: 9
	},
	button: {
		'color': theme.palette.action.active,
		'padding': theme.spacing(1, 2),
		'&.Mui-disabled': {
			color: theme.palette.text.hint
		}
	}
}));

const InformationViewBackButton = ({ handleSwap, isSubmitting }) => {
	const { t } = useTranslation();
	const classes = useFormStyles();

	return (
		<div className={classes.root}>
			<Button
				size="small"
				variant="text"
				className={classes.button}
				disabled={isSubmitting}
				startIcon={<FontAwesomeIcon icon={faAngleLeft} className={classes.icon} />}
				onClick={() => {
					handleSwap(true);
				}}
			>
				{t('common:chat.buttons.back')}
			</Button>
		</div>
	);
};

export default InformationViewBackButton;
